<template lang="pug">
    editor-mobile-header-menu.editor-mobile-header-download(ref="menu")
        .list
            .item(@click="exportDocumentDocx(document)") Microsoft word (docx)
            .item(@click="exportDocumentPdf(document)") PDF Document (pdf)
            .item(@click="exportDocumentHtml(document)") HTML
</template>

<script>
import EditorMobileHeaderMenu from '@/components/editor/header/EditorMobileHeaderMenu';
import DownloadDocumentMixin from '@/mixins/DownloadDocumentMixin';

export default {
    name: 'editor-mobile-header-download',

    components: {
        EditorMobileHeaderMenu,
    },

    mixins: [DownloadDocumentMixin],

    props: {
        document: {
            type: Object,
            required: true,
        },
    },

    methods: {
        open() {
            this.$refs.menu.open();
        },

        close() {
            this.$refs.menu.close();
        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header-download {
    .item {
        &:not(:last-child) {
            margin-bottom: rem(32px);
        }

        font-size: rem(18px);
        font-weight: 500;
    }
}
</style>
