<template lang="pug">
    .accesses-context
        .accesses-context__item(
            v-for="(item, i) in list"
            :class="{ active: activeAccess && item.name === activeAccess.name }"
            @click="getInviteLink(i, documentId)"
        )
            p {{ item.name }}
            span {{ item.description }}
</template>

<script>
import { DOCUMENT_ACCESSES } from '@/js/const';
import  getInviteLinkMixin  from '@/js/mainContextMenu/getInviteLinkMixin';
export default {
    name: 'accesses-context',

    mixins: [getInviteLinkMixin],

    props: {
        value: {
            type: null,
            required: false,
            default: null,
        },

        type: {
            type: String,
            required: true,
        },

        preselectFirst: {
            type: Boolean,
            required: false,
        },

        documentId: {
            type: Number,
            default: 0,
        },

        forInviteInline: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        list() {
            if (this.type === 'document') {
                return DOCUMENT_ACCESSES;
            }
            return [];
        },

        activeAccess: {
            get() {
                return this.value;
            },

            set(access) {
                this.$emit('input', access);
            },
        },
    },

    created() {
        if (this.preselectFirst) {
            this.activeAccess = this.list[0];
        }
    },
};
</script>

<style scoped lang="scss">
.accesses-context {
    width: rem(180px);
    padding: 0.25rem 0;

    background: #fff;
    box-shadow: 0 0 rem(10px) 0 rgba(6, 7, 7, 0.15);

    &__item {
        padding: rem(6px) 0.5rem;

        cursor: pointer;

        user-select: none;

        p {
            font-size: rem(14px);
            font-weight: 500;
        }

        span {
            font-size: rem(9px);
        }

        &.active {
            background: url(../../assets/images/icons/checked.svg) no-repeat right 0.5rem center / rem(16px) auto;
        }

        @media (hover: hover) {
            &:hover {
                background-color: #E6E6E6;
            }
        }
    }
}
</style>
