<template lang="pug">
    .app-breadcrumbs
        .app-breadcrumbs__item(v-for="(item, index) in getSlicesBreadcrumbs"
            @mouseover="$emit('mouseoverBreadcrumb', item)"
            @mouseup="mouseup(item,index)"
            @click="click(item)"
        )
            router-link.app-breadcrumbs__icon(
                v-if="!noIcons && item.icon && item.id !== 0"
                event=""
                :to="item.link ? item.link : ''"
                :style="{ backgroundColor: item.color ? item.color : '', display: 'none' ? item.id === 0 : 'block'}"
            )
                p(v-if="item.id !== 0" ) {{ item.icon }}

            router-link.app-breadcrumbs__link(:to="item.link" event="" :class = "linkStateClasses(index)")
                span( v-html="item.name" :style="stylesBreadcrumb(index)" @mouseover="setIndexBreadcrumb(index)" @mouseleave="setIndexBreadcrumb(null)")
            .app-breadcrumbs__divider(v-if="index < items.length-1") /
</template>

<script>
import ChildrenMixin from '@/mixins/ChildrenMixin';

export default {
    name: 'app-breadcrumbs',

    mixins: [ChildrenMixin],

    props: {
        items: {
            type: Array,
            required: true,
        },

        noIcons: {
            type: Boolean,
            required: false,
        },

        noHoverEffect: {
            type: Boolean,
            required: false,
        },

    },

    data() {
        return {
            indexBreadcrumb: null,
        };
    },

    computed: {
        getSlicesBreadcrumbs() {
            if (this.items.length <= 3) {
                return this.items;
            }
            return [
                this.items[0],
                { name: '...', link: '#' },
                this.items.slice(-2)[0],
                this.items.slice(-2)[1],
            ];
        },
    },

    methods: {
        checkLastItem(index) {
            return index === this.items.length - 1;
        },
        checkNotLastItem(index) {
            return !this.checkLastItem(index);
        },
        mouseup(item, index) {
            if (this.checkNotLastItem(index)) {
                this.$emit('mouseupBreadcrumb', item);
            }
        },
        click(item) {
            this.$emit('clickBreadcrumb', item, true);
        },
        linkStateClasses(index) {
            if (!this.checkNotLastItem(index)) {
                return ['app-breadcrumbs__link_last'];
            }
            return [];
        },
        stylesBreadcrumb(index) {
            if (!this.noHoverEffect && this.indexBreadcrumb === index) {
                return { 'background-color': '#E6E6E6' };
            } else {
                return { 'background-color': 'transparent' };
            }

        },
        setIndexBreadcrumb(index) {
            this.indexBreadcrumb = index;
        },


    },
};
</script>
