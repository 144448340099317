<template lang="pug">
    form.info-form(@submit.prevent="submit")
        .left
            arrow-back.info-form__arrow-back(@click="$emit('back')")
            app-text-field(
                v-model="firstName"
                label="Name"
                :error="firstNameError"
            )
            app-text-field(
                v-model="lastName"
                label="Surname"
                :error="lastNameError"
            )
            app-select(
                v-model="country"
                label="Where are you from?"
                :options="countries"
                searchable
                :error="countryError"
            )
            app-text-field(
                v-model="password"
                label="Password"
                type="password"
                :error="passwordError"
            )
            app-text-field(
                v-model="passwordConfirm"
                label="Repeat Password"
                type="password"
                :error="passwordConfirmError"
            )
            app-button Continue
        .right

</template>

<script>
import arrowBack from '@/assets/images/arrow_back.svg?inline';
import { required, minLength } from 'vuelidate/lib/validators';
import { getCountries } from '@/js/api/requests/common';

export default {
    name: 'info-form',

    components: {
        arrowBack,
    },

    data: () => ({
        countries: [],

        firstName: '',
        lastName: '',
        country: '',
        password: '',
        passwordConfirm: '',
    }),

    validations: {
        firstName: { required },

        lastName: { required },

        country: { required },

        password: {
            required,
            minLength: minLength(8),
        },

        passwordConfirm: {
            required,
            minLength: minLength(8),
            matches(value) {
                return this.password === value;
            },
        },
    },

    computed: {
        firstNameError() {
            if (this.$v.$dirty) {
                if (!this.$v.firstName.required) {
                    return 'Required field';
                }
            }
            return null;
        },

        lastNameError() {
            if (this.$v.$dirty) {
                if (!this.$v.lastName.required) {
                    return 'Required field';
                }
            }
            return null;
        },

        countryError() {
            if (this.$v.$dirty) {
                if (!this.$v.country.required) {
                    return 'Required field';
                }
            }
            return null;
        },

        passwordError() {
            if (this.$v.$dirty) {
                if (!this.$v.password.required) {
                    return 'Required field';
                } else if (!this.$v.password.minLength) {
                    return 'The minimum password length is 8 characters';
                }
            }
            return null;
        },

        passwordConfirmError() {
            if (this.$v.$dirty) {
                if (!this.$v.passwordConfirm.required) {
                    return 'Required field';
                } else if (!this.$v.passwordConfirm.minLength) {
                    return 'The minimum password length is 8 characters';
                } else if (!this.$v.passwordConfirm.matches) {
                    return 'Password mismatch';
                }
            }
            return null;
        },
    },

    created() {
        this.getCountries();
    },

    methods: {
        async getCountries() {
            const countries = await getCountries();
            this.countries = Object.keys(countries).map(key => ({
                name: countries[key].en_name,
                key,
            }));
        },

        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.$emit('continue', {
                firstName: this.firstName,
                lastName: this.lastName,
                country: this.country,
                password: this.password,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.info-form {
    display: flex;

    width: 100vw;
    height: 100vh;

    background: #fff;

    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        width: rem(600px);
        padding: rem(64px) rem(76px);

        background: #F8FBFF;
    }

    .right {
        width: calc(100% - #{rem(600px)});

        background: url(../../../../assets/images/info_form_bg.svg) no-repeat center bottom / 100% auto;
    }

    &__arrow-back {
        width: rem(40px);
        margin-bottom: 2rem;

        cursor: pointer;
    }

    @include mobile_or_tablet {
        height: initial;

        background: transparent;

        .left {
            width: 90%;
            max-width: 100vmin;
            margin: 0 auto;
            padding: 1rem;

            background: #fff;
            border-radius: rem(6px);
            box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);
        }

        .right {
            display: none;
        }
    }
}
</style>
