<template lang="pug">
    .notifications
        header
            span Notifications
            .notifications__btns
                .notifications__filter
                    app-button.notifications__btn.notifications__unread(
                        @click="getUnreadNotifications()"
                        :class="{ 'notifications__select-filter': filter.statusMessages === 'Unread' }"
                    ) Unread
                    app-button.notifications__btn.notifications__all(
                        @click="getAllNotifications()"
                        :class="{ 'notifications__select-filter': filter.statusMessages === 'All' }"
                    ) All

                app-button(
                    @click="setReadAll"
                    class="notifications__read-all notifications__btn"
                )
                    checker-icon

        main(ref="list" class="notifications__main")
            perfect-scrollbar(
                v-if="userNotifications.length"
                @ps-y-reach-end="scrollEnd"
            )
                notification(
                    v-for="notification in userNotifications"
                    :notification="notification"
                    :key="notification.id || notification._id"
                )

            .notification-empty(v-else)
                img.notification-empty__img(src="@/assets/images/no-notifications.svg")
                .notification-empty__text No notifications!
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Notification from '@/components/layouts/notifications/Notification';
import CheckerIcon from '@/assets/images/icons/checker.svg?inline';

export default {
    name: 'notifications',

    components: {
        Notification,
        CheckerIcon,
    },

    data() {
        return {
            filter: { statusMessages: 'Unread' },
            page: 1,
            isLastPage: false,
        };
    },

    computed: {
        ...mapGetters(['userWS', 'userNotifications']),

        getNotificationsPayload() {
            return {
                page: this.page,
                status: this.filter.statusMessages === 'All',
            };
        },
    },

    mounted() {
        this.initSocketListeners();
        this.getNotifications(this.getNotificationsPayload);
    },

    methods: {
        ...mapActions(['getNotifications']),

        initSocketListeners() {
            this.userWS.on('box-notification', data => {
                if (!data.meta.next) {
                    this.isLastPage = true;
                }
            });

            this.userWS.on('refresh-notification', () => {
                this.getNotifications({
                    ...this.getNotificationsPayload, page: 1, limit: 20 * this.page,
                });
            });
        },

        getAllNotifications() {
            this.filter.statusMessages = 'All';
            this.initGetNotificationsPayload();
            this.getNotifications(this.getNotificationsPayload);
        },

        getUnreadNotifications() {
            this.filter.statusMessages = 'Unread';
            this.initGetNotificationsPayload();
            this.getNotifications(this.getNotificationsPayload);
        },

        initGetNotificationsPayload() {
            this.page = 1;
            this.isLastPage = false;
        },

        setReadAll() {
            if (!this.userNotifications.length) {
                return;
            }

            this.userNotifications.forEach(notification => {
                this.userWS.emit('viewed-notification', { notification_id: notification.id });
            });
        },

        scrollEnd () {
            if (this.isLastPage) {
                return;
            }

            this.page ++;
            this.getNotifications(this.getNotificationsPayload);
        },
    },
};
</script>

<style lang="scss">
.ps {
    height: rem(400px);
    @include mobile_or_tablet {
        height: 100%;
    }
}
.notifications {
    width: rem(350px);
    font-family: Montserrat, sans-serif;
    font-size: rem(14px);
    background: $white;
    border-radius: rem(14px);
    box-shadow: 0 0 14px 1px rgb(6 7 7 / 15%);

    @include mobile_or_tablet {
        box-shadow: none;
        width: 100%;
        background: $grey-light24;
        border-radius: rem(0px);
    }

    &__btns {
        display: flex;
    }

    &__filter {
        background: $grey-light14;
        border-radius: rem(3px);
        padding: rem(3px) rem(3px);
    }

    header {
        z-index: 1;
        background: $white;
        position: relative;
        display: flex;
        padding: rem(17px) rem(9px);
        border-radius: rem(14px);

        @include mobile_or_tablet {
            background: $grey-light24;
            border-bottom: 1px solid $grey-light26;
            border-radius: rem(0px);
        }
    }

    &:before {
        box-shadow: 0 0 10px 0 rgba(6, 7, 7, 0.15);
        border: none !important;
    }

    &__unread {
        margin-right: rem(3px);
    }

    &__read-all {
        margin-left: rem(15px);
    }

    &__btn {
        font-size: rem(14px) !important;
        color: black !important;
        min-width: auto !important;
        min-height: auto !important;
        background: transparent !important;
        border-radius: rem(3px) !important;
        padding: rem(2px) rem(5px) !important;

        &:hover {
            color: $blue4  !important;
            background: $white  !important;
        }
    }

    &__select-filter {
        color: $blue4 !important;
        background: $white !important;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0.7rem 1.2rem;

        span {
            font-size: rem(16px);
            font-weight: bold;
        }

        svg {
            width: rem(21px);
            cursor: pointer;
        }
    }

    main {
        width: 100%;
        height: rem(400px);
        max-height: rem(400px);

        @include mobile_or_tablet {
            height: 100%;
            max-height: 100%;
        }
        overflow: auto;
    }
}

.notification-empty {
    font-family: Montserrat, sans-serif;
    padding: 1rem 1.1rem;
    text-align: center;
    width: 100%;
    max-width: rem(255px);
    margin: 0 auto;

    &__img {
        margin-top: rem(20px);
    }

    &__text {
        display: block;
        margin-top: rem(24px);
    }
}
</style>
