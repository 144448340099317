import { get, post, del } from '@/js/api/http-helper';

export async function getBin() {
    const data = await get('/bin/get');
    return data.data;
}

export function deleteAllBin() {
    return del(`/bin/delete/all`);
}

export function restoreAllBin() {
    return post(`/bin/restore`);
}

export function restoreFromBin(fd) {
    return post(`/bin/restore/single/entities `, fd);
}
