import { del, get, post } from '@/js/api/http-helper';

export async function leaveTeamReq(fd) {
    const data = await post('/team/leave', fd);
    return data.data;
}

export async function sendInviteReq(fd) {
    const data = await post('/team/invite', fd);
    return data.data;
}

export async function getMembersReq(fd) {
    const data = await get('/team/teammates', fd);
    return data.data;
}

export async function deleteMemberReq(fd) {
    const data = await del(`/team/teammates/${fd}`);
    return data.data;
}

export async function acceptMemberReq(fd) {
    const data = await post(`/team/accept-invite`, fd);
    return data.data;
}
