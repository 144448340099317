<template>
    <app-modal
        class="leave-team-popup"
        name="leave-team-popup"
        mobile-closer
    >
        <div class="header">
            <img
                src="@/assets/images/leave.svg"
                alt="leave"
            />
        </div>
        <div class="content">
            <div class="title">
                Do you really want to leave this team?
            </div>
            <div class="subtitle">
                If you leave this team you will have to enter billing details to continue using the Pro version. Your subscription will be terminated at the end of the billing period.
            </div>
            <div class="controls">
                <app-button
                    class="btn enter-bllling "
                    middle
                    @click="enterBillingInfo()"
                >
                    Enter Billing Info
                </app-button>
                <app-button
                    class="btn cancel-subscription"
                    @click="cancelSubscription()"
                >
                    Cancel Subscription
                </app-button>
            </div>
        </div>
    </app-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions(['leaveTeam']),
        enterBillingInfo () {
            this.leaveTeam();
        },
        cancelSubscription () {
            this.leaveTeam();
        },
    },
};

</script>

<style lang="scss" scoped>
    .leave-team-popup {
        &::v-deep .app-modal__container {
            padding: 0;
            width: rem(335px);
        }

        .header {
            height: rem(201px);
            width: 100%;
            background: #DCEEFF;
            border-radius: 9px 9px 40px 40px;
            padding: rem(23px) 0 0 rem(27px);
        }

        .title {
            font-weight: 500;
            font-size: rem(14px);
            line-height: rem(17px);
            color: #000000;
        }

        .subtitle {
            font-weight: 500;
            font-size: rem(12px);
            line-height: rem(15px);
            text-align: center;

            color: #828181;
        }

        .controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: rem(18px) rem(20px);
        }

        .title + .subtitle{
            margin-top: rem(12px);
        }

        .subtitle + .controls {
            margin-top: rem(20px);
        }

        .btn {
            width: 120px;
            height: 31px;
        }

        .cancel-subscription {
            color: #BCBCBC;
            border:  1px solid  #BCBCBC;
            background: $white;
            font-size: 12px;
            font-weight: 600;
        }
    }
</style>
