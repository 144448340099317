<template lang="pug">
    editor-mobile-header-menu.editor-mobile-header-burger-menu(ref="menu")
        .list
            .item(
                v-for="action in actions"
                @click="action.click"
            )
                .icon
                    img(:src="action.icon")
                .text {{ action.text }}
</template>

<script>
import EditorMobileHeaderMenu from '@/components/editor/header/EditorMobileHeaderMenu';
import { moveDocumentsToBin } from '@/js/api/requests/document';

export default {
    name: 'editor-mobile-header-burger-menu',

    components: {
        EditorMobileHeaderMenu,
    },

    props: {
        document: {
            type: Object,
            required: true,
        },
    },

    computed: {
        actions() {
            return [
                {
                    icon: require('@/assets/images/editor/icons/exit.svg'),
                    text: 'Exit to Dashboard',
                    click: () => {
                        this.$router.push({ name: 'Dashboard' });
                        window.enableScroll();
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/share_arrow.svg'),
                    text: 'Share',
                    click: () => {
                        this.close();
                        this.$modal.show('editor-invite-popup');
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/download.svg'),
                    text: 'Download',
                    click: () => {
                        this.$emit('download');
                    },
                },
                {
                    icon: require('@/assets/images/icons/version_history.svg'),
                    text: 'Save version',
                    click: () => {
                    },
                },
                {
                    icon: require('@/assets/images/icons/version_history.svg'),
                    text: 'Version History',
                    click: () => {
                    },
                },
                {
                    icon: require('@/assets/images/icons/delete.svg'),
                    text: 'Delete',
                    click: () => {
                        this.$confirm({
                            message: `Are you sure?`,
                            button: { no: 'No', yes: 'Yes' },
                            callback: async confirm => {
                                if (confirm) {
                                    try {
                                        await moveDocumentsToBin(this.document.id);
                                        this.$router.push({ name: 'Dashboard' });
                                    } catch (e) {
                                        if (e.response.status === 403) {
                                            this.$awn.alert('You cannot delete this document');
                                        } else {
                                            this.$awn.alert('Something went wrong, please try again later');
                                        }
                                    }
                                }
                            },
                        });
                    },
                },
            ];
        },
    },

    methods: {
        open() {
            this.$refs.menu.open();
        },

        close() {
            this.$refs.menu.close();
        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header-burger-menu {
    .item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: rem(32px);
        }

        .icon {
            width: rem(40px);
            height: rem(24px);
            margin-right: rem(12px);

            img {
                width: 100%;
                height: 100%;

                object-fit: contain;
            }
        }

        .text {
            font-size: rem(18px);
            font-weight: 500;
        }
    }
}
</style>
