<template lang="pug">
    app-modal.payment-method-modal(name="payment-method-modal" @beforeOpen="beforeOpen" @closed="closed")
        payment-method(:formData="subscriptionData" @updateCard="updateCard")
</template>

<script>
import PaymentMethod from '@/components/other/plans/PaymentMethod';

export default {
    name: 'payment-method-modal',

    components: {
        PaymentMethod,
    },

    data() {
        return {
            subscriptionData: {
                nameOnCard: '',
                cardNumber: '',
                monthAndYear: '',
                cvc: '',
            },
            updateCardParent: null,
        };
    },

    methods: {
        beforeOpen(e) {
            this.updateCardParent = e.params?.updateCard;
        },
        clear() {
            this.subscriptionData = {
                nameOnCard: '',
                cardNumber: '',
                monthAndYear: '',
                cvc: '',
            };
        },
        closed() {
            this.clear();
        },
        updateCard(newCard) {
            const { brand, last4 } = newCard;
            this.updateCardParent(brand, last4);
            this.clear();
        },
    },
};
</script>

<style scoped lang="scss">
@include plan-buy-form();
.payment-method-modal {
    &::v-deep {
        .app-modal__container {
            width: rem(616px);
            max-width: 100%;
        }
        .cvc {
            .app-text-field__input {
                width: rem(117px);
                @media (max-width: 1200px) {
                    width: 100%;
                }
            }
        }
    }
}

</style>
