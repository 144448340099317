<template lang="pug">
    .editor-selection-text-popup(
        v-if="isOpen"
        ref="selectionPopup"
        :style="styles"
        v-click-outside="close"
    )
        vue-file-toolbar-menu(:content="actions")
</template>

<script>
import { mapState } from 'vuex';
import VueFileToolbarMenu from 'vue-file-toolbar-menu';

import { paragraphList } from '@/js/editor/const';

export default {
    name: 'editor-selection-text-popup',

    components: {
        VueFileToolbarMenu,
    },

    props: {
        format: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        isOpen: false,
        styles: {},
    }),

    computed: {
        ...mapState({
            isPublicLink: state => state.editor.isPublicLink,
            publicLinkPermissions: state => state.editor.permissions,
        }),

        actions() {
            return [
                {
                    html: `<div class="bar-paragraph">${this.currentParagraph.name}</div>`,
                    class: 'bar-paragraph',
                    chevron: true,
                    menu: this.paragraphMenu,
                },
                { is: 'separator' },
                {
                    nameAction: 'comment',
                    html: `<img class="icon" src="${require('@/assets/images/editor/icons/comment-2.svg')}"/>&nbsp;Add Comment`,
                    click: () => {
                        this.$emit('insertComment');
                        this.close();
                    },
                },
                { is: 'separator' },
                {
                    class: 'bar-color',
                    type: 'sketch',
                    menu_class: 'align-center',
                    stay_open: false,
                    menu: [
                        this.createColorBtn('transparent', 'empty'),
                        this.createColorBtn('#FE9E9E', 'red'),
                        this.createColorBtn('#AAE8BB', 'green'),
                        this.createColorBtn('#CDDBFF', 'blue'),
                        this.createColorBtn('#C496FF', 'purple'),
                        this.createColorBtn('#FFFCB7', 'yellow'),
                        this.createColorBtn('#FFD3AB', 'orange'),
                        this.createColorBtn('#FFA8EC', 'lilac'),
                        this.createColorBtn('#EDEDED', 'grey'),
                    ],
                },
                {
                    class: 'bar-background',
                    type: 'sketch',
                    menu_class: 'align-center',
                    stay_open: false,
                    menu: [
                        this.createColorBtn('transparent', 'empty', false),
                        this.createColorBtn('#FE9E9E', 'red', false),
                        this.createColorBtn('#AAE8BB', 'green', false),
                        this.createColorBtn('#CDDBFF', 'blue', false),
                        this.createColorBtn('#C496FF', 'purple', false),
                        this.createColorBtn('#FFFCB7', 'yellow', false),
                        this.createColorBtn('#FFD3AB', 'orange', false),
                        this.createColorBtn('#FFA8EC', 'lilac', false),
                        this.createColorBtn('#EDEDED', 'grey', false),
                    ],
                    // this.createColorBtn('linear-gradient(90deg, #3927FF 0%, #FF29F6 53.12%, #FFAA29 97.92%)', 'purple-orange', false),
                    // this.createColorBtn('linear-gradient(90deg, #8EFFAE 0%, #93D1F3 97.92%)', 'green-blue', false),
                    // this.createColorBtn('linear-gradient(90deg, #FF2727 0%, #FFAA29 97.92%)', 'red-orange', false),
                    // this.createColorBtn('linear-gradient(90deg, #BA27FF 0%, #FFAA29 97.92%)', 'lilac-purple-orange', false),
                },
                {
                    html: `<img class="icon" src="${require('@/assets/images/editor/icons/bold.svg')}"/>`,
                    class: { active: this.format.bold },
                    click: () => {this.$emit('formatting', { bold: !this.format.bold });},
                },
                {
                    html: `<img class="icon" src="${require('@/assets/images/editor/icons/underline.svg')}"/>`,
                    class: { active: this.format.underline },
                    click: () => {this.$emit('formatting', { underline: !this.format.underline });},
                },
                {
                    html: `<img class="icon" src="${require('@/assets/images/editor/icons/link.svg')}"/>`,
                    class: { active: this.format.link },
                    click: () => {
                        this.$emit('insertLink');
                        this.close();
                    },
                },
                { is: 'separator' },
                {
                    html: `<img class="icon" src="${require('@/assets/images/editor/icons/copy.svg')}"/>`,
                    click: () => {
                        this.$emit('copy');
                        this.close();
                    },
                },
                {
                    html: `<img class="icon" src="${require('@/assets/images/editor/icons/bin.svg')}"/>`,
                    click: () => {
                        this.$emit('deleteText');
                        this.close();
                    },
                },
            ].filter(action => {
                if (!this.isPublicLink) {
                    return action;
                }

                if (action.nameAction === 'comment') {
                    if (this.publicLinkPermissions.includes('comment')) {
                        return action;
                    }
                }

                if (action.nameAction !== 'comment') {
                    if (this.publicLinkPermissions.includes('edit_content')) {
                        return action;
                    }
                }
            });
        },

        paragraphMenu() {
            return paragraphList.map(p => ({
                text: p.name,
                active: this.format.header === p.value || (!this.format.header && p.value === 0),
                click: () => {
                    this.$emit('formatting', { header: this.format.header !== p.value ? p.value : false });
                },
            }));
        },

        currentParagraph() {
            return paragraphList.find(p => this.format.header === p.value) || paragraphList.find(p => p.value === 0);
        },
    },

    methods: {
        createColorBtn(color, colorClass, textOrBg = true) {
            let property = 'color';
            if (!textOrBg) {
                property = 'background';
            }
            return {
                html: `<div class = "block-color-picker block-color-picker_${colorClass}"></div>`,
                click: () => this.$emit('formatting', { [property]: color }),
            };
        },

        open(params={}) {
            this.isOpen = true;
            this.$nextTick(() => {
                const editorWidth = document.getElementById('editor').offsetWidth;
                const popupWidth = this.$refs.selectionPopup.offsetWidth;
                let left = params.left;
                if (left + popupWidth > editorWidth - 10) {
                    left = editorWidth - popupWidth - 16;
                }
                this.styles = {
                    top: `${params.top + 5}px`,
                    left: `${left}px`,
                };
            });
        },

        close() {
            this.isOpen = false;
        },
    },
};
</script>

<style scoped lang="scss">
.editor-selection-text-popup {
    position: absolute;

    background: #fff;
    border-radius: rem(4px);
    box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

    .bar::v-deep {
        flex-wrap: nowrap;

        margin: 0;

        .bar-paragraph {
            .bar-menu-item {
                min-height: initial;
                padding: rem(6px) rem(8px);

                font-size: rem(12px);
                white-space: nowrap;
            }
        }

        .bar-button {
            font-size: rem(12px);
            font-weight: 500;

            .icon {
                width: rem(18px);
                height: rem(22px);

                object-fit: contain;
            }
        }

        .bar-color, .bar-background {
            display: flex;
            flex-direction: column;
            align-items: center;

            &:before {
                display: block;
            }

            .color-square {
                width: rem(16px);
                height: rem(6px);
                margin: 0 rem(2px);
            }

            .bar-menu-items {
                display: flex;
                align-items: center;
                padding: rem(5px);
            }

            .bar-menu-item {
                padding: 0;
                min-height: 0;
                background: transparent !important;
                margin-right: rem(5px);
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .bar-color:before {
            content: 'A';

            width: rem(24px);
            height: rem(16px);

            font-size: rem(18px);
            text-align: center;
        }

        .bar-background:before {
            content: '';

            width: rem(24px);
            height: rem(26px);

            background: url(../../assets/images/editor/icons/background.svg) no-repeat center center / contain;
        }

        .bar-separator {
            flex-shrink: 0;

            width: 2px;
            margin: 0;

            background-color: #A9A8A8;
        }
    }

    @include mobile_or_tablet {
        .bar::v-deep {
            .bar-paragraph {
                .bar-menu-item {
                    font-size: rem(10px);
                }
            }

            .bar-button {
                padding: rem(4px) rem(2px);

                font-size: rem(10px);

                .icon {
                    width: rem(15px);
                    height: rem(20px);
                }

                & > .label {
                    padding: 0 rem(3px);
                }

                & > .chevron {
                    width: rem(16px);
                    margin: 0 0 0 rem(-3px);

                    font-size: rem(20px);
                }
            }

            .bar-color:before {
                width: rem(20px);
            }

            .bar-background:before {
                width: rem(20px);
            }

            .bar-separator {
                width: 1px;
            }
        }
    }
}
</style>
