import { render, staticRenderFns } from "./cardPopup.vue?vue&type=template&id=95054934&scoped=true&"
import script from "./cardPopup.vue?vue&type=script&lang=js&"
export * from "./cardPopup.vue?vue&type=script&lang=js&"
import style0 from "./cardPopup.vue?vue&type=style&index=0&id=95054934&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95054934",
  null
  
)

export default component.exports