
<template>
    <div
        v-click-outside="() => isOpenContextMenu = false"
        class="app-context"
    >
        <div
            @click="() => isOpenContextMenu = !isOpenContextMenu"
        >
            <slot name="activator"></slot>
        </div>
        <div
            :class="[
                'app-context__content',
                { 'app-context--opened': isOpenContextMenu },
            ]"
            tabindex="0"
            @click="closeOnClick ? close() : false"
        >
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        closeOnClick: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        isOpenContextMenu: false,
    }),

    methods: {
        close () {
            this.isOpenContextMenu = false;
        },
    },
};
</script>
