<template lang="pug">
    .mobile-menu
        .mobile-menu__wrapper
            .mobile-menu__link.mobile-menu__link--new(@click="$refs.creationList.toggle()")
                .mobile-menu__link-icon
                    plus-icon
                | New
            sidebar-creation-list(ref="creationList")
            router-link.mobile-menu__link(to="/" exact)
                .mobile-menu__link-icon
                    home-icon
                | Dashboard
            router-link.mobile-menu__link.mobile-menu__link--library(to="/library" exact)
                .mobile-menu__link-icon
                    book-icon
                | Library
            router-link.mobile-menu__link(to="/shared" exact)
                .mobile-menu__link-icon
                    shared-icon
                | Shared with me
            router-link.mobile-menu__link(to="/how-to" exact)
                .mobile-menu__link-icon
                    howto-icon
                | HowToReSkript
            router-link.mobile-menu__link(to="/qw" exact)
                .mobile-menu__link-icon
                    task-icon
                | Tasks
            router-link.mobile-menu__link(to="/bin" exact)
                .mobile-menu__link-icon
                    bin-icon
                | Bin
            a.mobile-menu__link(@click="$modal.show('settings', { tab: 'general' })")
                .mobile-menu__link-icon
                    settings-icon
                | Settings
            router-link.mobile-menu__link.mobile-menu__link--notification(to="/notifications" exact)
                .mobile-menu__link-icon
                    notification-icon
                    .mobile-menu__link-icon-circle(v-if="haveUnreadNotifications")
                | Notification
</template>

<script>
import homeIcon from '@/assets/images/icons/home.svg?inline';
import bookIcon from '@/assets/images/icons/book.svg?inline';
import howtoIcon from '@/assets/images/icons/howto.svg?inline';
import taskIcon from '@/assets/images/icons/task.svg?inline';
import favoriteIcon from '@/assets/images/icons/favorite.svg?inline';
import binIcon from '@/assets/images/icons/bin.svg?inline';
import cloudIcon from '@/assets/images/icons/cloud.svg?inline';
import settingsIcon from '@/assets/images/icons/settings.svg?inline';
import notificationIcon from '@/assets/images/icons/notification.svg?inline';
import sharedIcon from '@/assets/images/icons/shared.svg?inline';
import plusIcon from '@/assets/images/icons/plus.svg?inline';
import SidebarCreationList from '@/components/layouts/SidebarCreationList';
import NotifficationMixin from '@/mixins/NotifficationMixin';

export default {
    name: 'mobile-menu',

    components: {
        SidebarCreationList,
        homeIcon,
        bookIcon,
        howtoIcon,
        taskIcon,
        favoriteIcon,
        binIcon,
        cloudIcon,
        plusIcon,
        settingsIcon,
        notificationIcon,
        sharedIcon,
    },

    mixins: [NotifficationMixin],

    computed: {
        user() {
            return this.$store.getters.user;
        },
    },

    methods: {
        getUserFirstLetters(user) {
            return `${user.username?.[0] || ''}${user.surname?.[0] || ''}`;
        },
    },
};
</script>

<style scoped lang="scss">
.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 9px;

    overflow: auto;

    background-color: white;

    transform: translate3d(-100%, 0, 0);
    transition: transform .5s;

    &__link {
        display: flex;
        align-items: center;

        padding: 0.93rem rem(13px);

        font-size: rem(19px);
        font-weight: 500;
        color: #595959;

        &-icon {
            position: relative;

            display: flex;
            align-items: center;

            width: 2.7rem;
            height: 1.6rem;

            &-circle {
                position: absolute;
                top: 0.2rem;
                right: 0.7rem;

                width: rem(9px);
                height: rem(9px);

                background: $red;
                border-radius: 50%;
            }

            svg {

                object-fit: contain;

                stroke: #595959;
            }
        }

        &.router-link-active {
            font-weight: 500;
            color: black;
        }

        &.router-link-active svg{
          stroke: black;
        }

        &--new {
            font-size: rem(21px);
            color: white;
            background-color: #008CF6;
            border-radius: rem(4px);
            margin-bottom: rem(15px);
            padding: 0;
            height: rem(40px);

            .mobile-menu__link-icon {
                align-items: center;
                margin-left: rem(13px);
                font-family: serif;
                font-size: 3.2rem;
                font-weight: bold;
            }
        }

        &--library &-icon svg {
            width: 60%;
        }

        &--storage &-icon svg {
            width: 75%;
        }
    }

    &__profile {
        display: flex;
        align-items: center;

        padding: 1.6rem 1.45rem;

        &-avatar {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            width: rem(52px);
            height: rem(52px);
            margin-right: rem(14px);

            font-size: rem(24px);
            font-weight: 500;
            color: #fff;

            background: $blue;
            border-radius: 50%;
        }

        &-info {
            max-width: 75%;

            b, small {
                display: block;

                overflow: hidden;

                text-overflow: ellipsis;
            }

            b {
                margin-bottom: 0.5rem;

                font-size: rem(21px);
            }

            small {
                font-size: rem(16px);
                line-height: 1.2;
                font-weight: 500;
                color: #8c8c8c;
            }
        }
    }

    &--open {
        transform: translate3d(0,0,0);
    }
}
</style>
