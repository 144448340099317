<template lang="pug">
    app-modal(
        name="editor-snapshots-rename-popup"
        @beforeOpen="beforeOpen"
    )
        form(@submit.prevent="submit")
            h3 Renaming
            app-text-field(
                v-model="name"
                label="Enter a new snapshot name:"
                :error="$v.$dirty && !$v.name.required ? 'Required field' : ''"
            )
            .app-modal__bottom
                app-button.app-button--grey(tag="div" @click="$modal.hide('editor-snapshots-rename-popup')") Cancel
                app-button(:loading="loading") Save
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { renameSnapshot } from '@/js/api/requests/editor';

export default {
    name: 'editor-snapshots-rename-popup',

    data: () => ({
        snapshot: null,
        name: '',
        loading: false,
    }),

    validations: {
        name: { required },
    },

    methods: {
        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.loading) return;

            this.loading = true;

            try {
                await renameSnapshot(this.snapshot._id, this.name);
                this.$modal.hide('editor-snapshots-rename-popup');
                this.snapshot.title = this.name;
                this.$awn.success('Version renamed successfully');
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            } finally {
                this.loading = false;
            }
        },

        beforeOpen(e) {
            this.snapshot = e.params?.snapshot;
            this.name = this.snapshot.title || '';
        },
    },
};
</script>
