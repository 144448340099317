<template>
    <div
        class="project-detail-manage-team"
    >
        <div class="top-panel">
            <div class="top-panel__search">
                <app-text-field
                    v-model="searchString"
                    class="app-text-field--theme-solid"
                    placeholder="Search by name or email"
                >
                    <template #icon>
                        <search-icon class="search-field-icon" />
                    </template>
                </app-text-field>
            </div>
            <div
                v-if="isUserProjectOwnerWithPro"
                class="invite-btn"
                @click="invite"
            >
                Invite
                Members
            </div>
        </div>
        <documents-table
            :headers="headers"
            :items="invitedUsers"
            :project-owner="project.user.is_owner"
            users
            without-groups
            @updateMembers="$emit('updateMembers')"
        />
    </div>
</template>

<script>
import searchIcon from '@/assets/images/icons/search.svg?inline';
import { MANAGE_TEAM_TYPE } from '@/js/const';
import ManageTeam from '@/mixins/ManageTeamMixin';
import DocumentsTable from '@/components/common/documentsTable';

export default {
    name: 'manage-team',

    components: {
        DocumentsTable,
        searchIcon,
    },

    mixins: [ManageTeam],

    props: {
        room: {
            type: Object,
            default: () => null,
        },
        type: {
            type: String,
            default: MANAGE_TEAM_TYPE.PROJECT,
        },
    },

    data() {
        return {
            searchString: '',
            headers: ['Name', 'Last activity'],
        };
    },

    computed: {

        invitedUsers() {
            if (this.project.users) {
                return this.project.users.filter(u => {
                    return u.id !== this.user.id && (
                        this.searchString === ''
                        ||
                        (this.searchString && (this.checkSearchUser(u.username) || this.checkSearchUser(u.surname)))
                    );
                });
            }
            return [];
        },
    },

    methods: {
        checkSearchUser(text) {
            return text.toLowerCase().includes(this.searchString.toLowerCase());
        },
        invite() {
            let data = [{}];
            if (this.type === MANAGE_TEAM_TYPE.PROJECT) {
                data = {
                    project: this.project, successCallback: () => {
                        this.$emit('fetchProject');
                    },
                };
            } else if (this.type === MANAGE_TEAM_TYPE.ROOM) {
                data = { room: this.room, project: this.project };
            }

            !data.members ? data.members = [{}] : data.members;
            data.type = this.type;
            data.id = this.$route.params.id ? this.$route.params.id : this.$route.params.roomId;
            this.$modal.show('invite-members-popup', data);
        },
        getUserFirstLetters(user) {
            return `${user.username?.[0] || ''}${user.surname?.[0] || ''}`;
        },
    },
};
</script>

<style lang="scss">
.manage-team__project-name {
    @include name_reduction();
}

.project-detail-manage-team {
    .top-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 2rem;

        &__search {
            position: relative;

            &-icon {
                position: absolute;
                top: 50%;
                right: rem(14px);

                width: rem(25px);

                transform: translateY(-50%);

                pointer-events: none;
                fill: $blue;
            }

            .app-text-field {
                width: 300px;
                margin: 0;

                input {
                    padding: rem(8px) rem(8px) rem(8px) rem(48px) !important;
                    width: 100% !important;
                    height: auto !important;
                }
            }
        }

        .invite-btn {
            color: $white;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #008CF6;
            border-radius: 6px;
            width: 141px;
            height: 32px;
            font-size: 12px !important;
            cursor: pointer;
        }


    }

    .table {
        width: 100%;

        th, td {
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }

        th {
            padding-bottom: 0.5rem;

            font-weight: 500;

            border-bottom: 1px solid #A8A6A6;
        }

        td {
            padding: 1.25rem 0 0.75rem;

            font-size: rem(14px);
        }

        &__info {
            display: flex;
            align-items: center;

            &-avatar {
                display: flex;
                justify-content: center;
                align-items: center;

                width: rem(35px);
                height: rem(35px);
                margin-right: rem(11px);

                color: #fff;

                background: $blue;
                border-radius: 50%;
            }

            &-name {
                margin-bottom: rem(6px);

                font-size: rem(14px);
                color: #393838;
            }

            &-email {
                font-size: rem(10px);
                color: #6E6D6D;
            }
        }
    }

    @include mobile_or_tablet {
        padding: 1.3rem 1.6rem 1.2rem;

        .top-panel {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: 1rem;

            &__search {
                margin-bottom: rem(20px);
            }
        }

        .table {
            th {
                display: none;
            }

            td {
                padding: 0.75rem 0;

                &:not(:first-of-type) {
                    display: none;
                }
            }

            &__info {
                &-avatar {
                    width: rem(45px);
                    height: rem(45px);
                    margin-right: rem(14px);

                    font-size: rem(18px);
                }

                &-name {
                    margin-bottom: rem(4px);

                    font-size: rem(18px);
                }

                &-email {
                    font-size: rem(14px);
                }
            }
        }
    }
}
</style>
