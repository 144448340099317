import { getEntityLink, getEntityLinkUrl, getBreadcrumps, getEditorBreadcrumbs } from '@/js/utils/breadcrump';

export default {
    data: () => ({
        breadcrumbsOriginal: [],
    }),

    methods: {
        getEntityLink(entity) {
            return getEntityLink(entity);
        },
        getBreadcrumps(entity) {
            return getBreadcrumps(entity);
        },
        getEditorBreadcrumbs(entity) {
            return getEditorBreadcrumbs(entity);
        },
        getEntityLinkUrl(entity) {
            return getEntityLinkUrl(entity);
        },

    },
};
