<template lang="pug">
    editor-mobile-header-menu.editor-mobile-header-chat(ref="menu")
        chat(
            chat-type="document"
            :entity-id="document.id"
            :entity="document"
        )
</template>

<script>
import EditorMobileHeaderMenu from '@/components/editor/header/EditorMobileHeaderMenu';
import Chat from '@/components/other/chat/index';

export default {
    name: 'editor-mobile-header-chat',

    components: {
        EditorMobileHeaderMenu,
        Chat,
    },

    props: {
        document: {
            type: Object,
            required: true,
        },
    },

    methods: {
        open() {
            this.$refs.menu.open();
        },

        close() {
            this.$refs.menu.close();
        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header-chat {
    &::v-deep .content {
        height: 100%;
        padding: 0;
    }

    .chat {
        height: 100%;
    }
}
</style>
