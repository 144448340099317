<template>
    <app-modal
        class="change-bg-modal"
        name="change-bg-modal"
        @beforeOpen="beforeOpen"
    >
        <div class="change-modal">
            <div class="modal-tabs">
                <div
                    class="modal-tabs__container"
                    @click="prevent($event)"
                >
                    <button
                        v-for="(item, i) in tabs"
                        :key="i"
                        :class="{'active' : activeTab === item.value}"
                        @click="activeTab = item.value"
                    >
                        {{ item.title }}
                    </button>
                </div>
            </div>
            <div class="modal-content">
                <div class="modal-content__container">
                    <gradients
                        v-if="activeTab === 'gradients'"
                        :project-id="projectId"
                        @updateBackground="updateBackground($event)"
                    />
                    <unsplash
                        v-if="activeTab === 'unsplash'"
                        :project-id="projectId"
                        @updateBackground="updateBackground($event)"
                    />
                    <upload-popup
                        v-if="activeTab === 'upload'"
                        :project-id="projectId"
                        :recommend-resolution="isIcon ? ' 49 x 49 ' : ' 1282 x 170 '"
                        @updateBackground="updateBackground($event)"
                    />
                    <emoji-modal
                        v-if="activeTab === 'emoji'"
                        ref="emojis"
                        max-width="100%"
                        width="100%"
                        @insertEmoji="updateIcon"
                    />
                </div>
            </div>
        </div>
    </app-modal>
</template>

<script>

import appModal from '@/components/app/app-modal';
import Gradients from '@/components/pages/projects/detail/popup/gradients/gradients';
import Unsplash from '@/components/other/unsplash/Unsplash';
import UploadPopup from '@/components/pages/projects/detail/popup/uploadPopup';
import EmojiModal from '@/components/other/EmojiModal';
import { getProjectById, updateProject } from '@/js/api/requests/project';

export default {
    name: 'popup',
    components: {
        EmojiModal,
        UploadPopup,
        Unsplash,
        Gradients,
        appModal,
    },

    data: () => ({
        projectId: null,
        tabs: null,
        height: null,
        activeTab: null,
        isIcon: false,
        projectName: null,
    }),

    methods: {
        beforeOpen(e) {
            this.projectId = e.params.projectId;
            this.tabs = e.params.tabs;
            this.activeTab = e.params.activeTab;
            this.height = e.params.height;
            setTimeout(() => {
                if (this.activeTab === 'emoji') {
                    this.$refs.emojis.open({
                        top: 50, left: 0, styles: {
                            position: 'absolute',
                            height: '100%',
                        },
                    });
                }
            }, 0);
            getProjectById(this.projectId).then(v => this.projectName = v.name);
        },
        updateIcon(emoji) {
            try {
                updateProject(this.projectId, { image: emoji.native }).then(() => {
                    this.$awn.success('Project icon was updated successfully');
                    this.$modal.hide('change-bg-modal');
                    this.$emit('updateEmoji', emoji.native);
                });
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
                this.$modal.hide('change-bg-modal');
            }
        },
        updateBackground(background) {
            this.$emit('updateBackground', background);
        },

        prevent(evt) {
            evt.stopPropagation();
        },
    },
};
</script>

<style lang="scss" scoped>

.change-modal {
    height: 100%;
}

.change-bg-modal {
    height: 100%;


    &::v-deep .app-modal__container {
        width: 555px;
        height: 588px;
        padding: 0;
    }

    &::v-deep .vm--modal {
        border-radius: 9px;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
        overflow: hidden;
    }

    .modal-tabs {
        border-bottom: 1px solid $grey-light35;

        &__container {
            padding: 15px 10px;

            button {
                padding: 5px 10px;
                border-radius: 6px;
                cursor: pointer;
            }

            .active {
                background-color: $grey-light32;
            }
        }
    }

    &::v-deep .modal-content {
        height: 100%;
        width: 100%;

        &__container {
            height: 100%;
            overflow: scroll;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                background-color: #4A4A4A;
            }

        }
    }

    &::v-deep {
        .emoji-mart .emoji-mart-static {
            width: 100% !important;
            height: 100% !important;
        }
    }
}
</style>
