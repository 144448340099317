<template>
    <div
        :class="{'background': background}"
        class="table"
    >
        <div
            v-show="title"
            class="table-title"
        >
            Documents
        </div>
        <div class="table-row">
            <div
                v-for="(item, i) in headers"
                :key="i"
                class="table-head"
            >
                {{ item }}
            </div>
        </div>
        <div
            v-if="!withoutGroups"
            class="table-row"
        >
            <div class="table-cell">
                <div
                    v-for="(item, i) in groupItemsBy"
                    :key="i"
                    class="item"
                >
                    <div
                        :class="{'bolder' : bolder}"
                        class="item-title"
                    >
                        {{ item.name }}
                    </div>
                    <div
                        v-for="(item, i) in items[item.value]"
                        :key="i"
                        :class="{'selectedItem': selectedTab === i}"
                        class="table-row-item"
                        @click="getSelectedTab(i)"
                        @dblclick="documentDblclickHandler(item)"
                        @contextmenu.prevent.stop="openDocContext($event, item)"
                    >
                        <div class="table-cell__child">
                            <div class="item-info">
                                <div class="item-info__icon">
                                    <img
                                        v-if="item.type === 'document'"
                                        :alt="item.name"
                                        src="@/views/dashboard/images/list-item.svg"
                                    />
                                    <p
                                        v-else
                                    >
                                        {{ item.icon !== null ? item.icon : null }}
                                    </p>
                                </div>
                                <div class="item-info__title">
                                    {{ splitText(item.name, isMobileOrTablet ? 22 : 33) }}
                                </div>
                            </div>
                        </div>

                        <template v-if="isShared">
                            <div class="table-cell__child avatar-username">
                                <div class="avatar-button__circle">
                                    <img
                                        v-if="item.inviter.avatar !== null"
                                        :src="item.image"
                                        alt="#"
                                    />
                                    <span v-else> {{ userFirstLetters(item.inviter) }} </span>
                                </div>
                                <span class="avatar-username__text">{{
                                    item.inviter.name
                                }} {{ item.inviter.surname }}</span>
                            </div>
                            <div class="table-cell__child">
                                {{ formatedDate(item.invited_at) }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="table-cell__child">
                                {{ formatedDate(item.updated_at) }}
                            </div>
                            <div class="table-cell__child">
                                {{ formatedDate(item.created_at) }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else-if="withoutGroups && !users"
            class="table-row"
        >
            <div class="table-cell">
                <div
                    v-for="(item, i) in items"
                    :key="i"
                    :class="{'selectedItem': selectedTab === i}"
                    class="table-row-item"
                    @click="getSelectedTab(i)"
                    @dblclick="documentDblclickHandler(item)"
                    @contextmenu.prevent.stop="openDocContext($event, item)"
                >
                    <div class="table-cell__child">
                        <div class="item-info">
                            <div class="item-info__icon">
                                <img
                                    :alt="item.name"
                                    src="@/views/dashboard/images/list-item.svg"
                                />
                            </div>
                            <div class="item-info__title">
                                {{ splitText(item.name, isMobileOrTablet ? 22 : 33) }}
                            </div>
                        </div>
                    </div>
                    <template v-if="isShared">
                        <div class="table-cell__child">
                            {{ item.shared_by }}
                        </div>
                        <div class="table-cell__child">
                            {{ formatedDate(item.shared_at) }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="table-cell__child">
                            {{ formatedDate(item.updated_at) }}
                        </div>
                        <div class="table-cell__child">
                            {{ formatedDate(item.created_at) }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div
            v-else-if="teamTable"
            class="table-row"
        >
            <div class="table-cell">
                <div
                    v-for="(item, i) in items"
                    :key="i"
                    :class="{'selectedItem': selectedTab === i}"
                    class="table-row-item"
                    @click="getSelectedTab(i)"
                    @dblclick="documentDblclickHandler(item)"
                    @contextmenu.prevent.stop="openDocContext($event, item)"
                >
                    <div class="table-cell__child">
                        <div class="item-info">
                            <div class="item-info__icon">
                                <img
                                    :alt="item.name"
                                    src="@/views/dashboard/images/list-item.svg"
                                />
                            </div>
                            <div class="item-info__title">
                                {{ splitText(item.name, isMobileOrTablet ? 22 : 33) }}
                            </div>
                        </div>
                    </div>
                    <template v-if="isShared">
                        <div class="table-cell__child">
                            {{ item.shared_by }}
                        </div>
                        <div class="table-cell__child">
                            {{ formatedDate(item.shared_at) }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="table-cell__child">
                            {{ formatedDate(item.updated_at) }}
                        </div>
                        <div class="table-cell__child">
                            {{ formatedDate(item.created_at) }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div
            v-if="users"
            class="table-row"
        >
            <div class="table-cell">
                <!--поправить для юзер id-->
                <div
                    v-for="(item, idx) in items"
                    :key="idx"
                    class="table-row-item"
                >
                    <div class="table-cell__child">
                        <div class="item-info">
                            <div class="item-info__icon__circle">
                                <img
                                    v-if="item.image"
                                    :src="item.image"
                                />
                                <span v-else> {{ userFirstLetters(item) }}</span>
                            </div>
                            <div class="item-info__title__users">
                                <p>
                                    {{
                                        splitText((item.username + ' ' + item.surname), isMobileOrTablet ? 22 : 33)
                                    }}
                                </p>
                                <span> {{ item.email }}</span>
                            </div>
                        </div>
                        <!--вернуть когда понадобится 2я колонка-->
                        <!--                        <template v-if="1 == 0">-->
                        <!--                            <div class="table-cell__child">-->
                        <!--                                <dropdown-users :items="items" />-->
                        <!--                            </div>-->
                        <!--                        </template>-->
                    </div>
                    <template>
                        <div class="table-cell__child">
                            {{ formatedDate(item.login_at) }}
                        </div>
                    </template>
                    <app-context v-if="projectOwner">
                        <template #activator>
                            <div class="actions">
                                <three-dots-horizontal-icon
                                    height="3"
                                />
                            </div>
                        </template>
                        <template #content>
                            <ul class="member-context-menu">
                                <li
                                    class="member-context-menu__item member-context-menu__item--warning"
                                    @click="deleteFromProject(item.id)"
                                >
                                    Remove Member
                                </li>
                            </ul>
                        </template>
                    </app-context>
                </div>
            </div>
        </div>
        <editor-invite-popup />
        <context-menu
            ref="contextMenu"
            :is-move-to-for-folder="true"
            :items="currentElemType !== 'folder' ? documentItems : folderItems "
            @update="$emit('update')"
        />

        <move-to-folders-context ref="moveToFoldersContext" />
        <export-document-context ref="exportDocumentContext" />
    </div>
</template>

<script>

import moment from 'moment';
import documentsAndTableMixin from '@/mixins/documentsAndTableMixin';
import EditorInvitePopup from '@/components/editor/invitePopup/EditorInvitePopup';
import MoveToFoldersContext from '@/components/other/MoveToFoldersContext';
import ExportDocumentContext from '@/components/other/ExportDocumentContext';
import dashboardContextMenuGeneratorMixin from '@/mixins/ContextMenuGeneratorMixin';
import threeDotsHorizontalIcon from '@/assets/images/icons/threeDotsHorizontal.svg?inline';
import { deleteProjectMember } from '@/js/api/requests/project';


export default {
    name: 'documents-table',
    components: {
        ExportDocumentContext,
        MoveToFoldersContext,
        EditorInvitePopup,
        threeDotsHorizontalIcon,
    },
    mixins: [documentsAndTableMixin, dashboardContextMenuGeneratorMixin],
    props: {
        items: {
            type: [Object, Array],
            required: true,
        },

        headers: {
            type: Array,
            required: true,
        },

        groupItemsBy: {
            type: Array,
            default: null,
        },

        withoutGroups: {
            type: Boolean,
            default: false,
        },

        isShared: {
            type: Boolean,
            default: false,
        },

        bolder: {
            type: Boolean,
            default: false,
        },

        title: {
            type: Boolean,
            default: false,
        },

        background: {
            type: Boolean,
            default: false,
        },

        teamTable: {
            type: Boolean,
            default: false,
        },
        users: {
            type: Boolean,
            default: false,
        },
        projectOwner: {
            type: Boolean,
            default: false,
        },
        isDashboard: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        folderItems: ['moveTo', 'rename', 'delete', 'duplicate'],
        documentItems: ['share', 'download', 'rename', 'moveTo', 'duplicate', 'delete'],
    }),

    computed: {
        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },

        user() {
            return this.$store.getters.user;
        },
    },

    methods: {
        formatedDate(date) {
            return moment(date).fromNow();
        },

        userFirstLetters(user) {
            return `${user.name?.[0] || ''}${user.surname?.[0] || ''}`;
        },

        async deleteFromProject(userId) {
            try {
                await deleteProjectMember(this.$route.params.id, userId);
                this.$emit('updateMembers');
                this.$awn.success('User was successfully deleted from the project');
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            }
        },


    },
};
</script>

<style lang="scss" scoped>
.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 7px;
    padding: 0 13px;

    .table-row {
        display: flex;
        align-items: center;
        transition: .4s ease-in-out;
        width: 100%;

        &:nth-child(2) {
            .table-head {
                position: relative;
                border-bottom: 1px solid $grey-light13;
                border-top: 1px solid $grey-light13;

                &:not(:first-child) {
                    &:before {
                        content: '';
                        position: absolute;
                        height: 70%;
                        left: -13px;
                        background-color: $grey-light13;
                        width: 1px;
                    }
                }
            }
        }

        .table-cell__child {
            display: inline-flex;
            align-items: center;
            padding: 7px 0;

            &:first-child {
                width: 50%;
            }

            &:not(:first-child) {
                width: 25%;
            }
        }

        .avatar-button__circle {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            width: rem(30px);
            height: rem(30px);
            margin-right: rem(6px);
            overflow: hidden;

            font-size: rem(12px);
            color: #fff;

            background: $blue;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        &:not(:first-child) {
            padding: rem(7px) 0;

            &.selectedTab {
                .table-cell {
                    background-color: $grey-light28;
                }
            }
        }
    }

    .table-head {
        color: #787878;
        padding: 5px 0 5px;

        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        width: 25%;

        &:first-child {
            padding-left: rem(13px);
            width: 50%;
        }
    }

    .table-cell {
        cursor: pointer;
        vertical-align: middle;

        padding: 7px 0;

        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #787878;

        transition: .4s ease-in-out;
        width: 100%;

    }

    .table-title {
        margin: 10px 0 17px 0;
        font-weight: 500;
        font-size: 16px;
    }

    .item-info {
        display: flex;
        align-items: center;
        padding-left: rem(13px);

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;

            width: rem(20px);
            height: rem(26px);
            margin-right: rem(18px);

            &__circle {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                width: 30px;
                height: 30px;
                background-color: $blue;
                border-radius: 50%;
                margin-right: 7px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }

        &__title {
            color: #000000;

            &__users {
                p {
                    color: #000000;
                }

                display: flex;
                flex-direction: column;

                span {
                    color: $grey-dark7;
                    font-size: 10px;
                }
            }
        }


    }

    .item {

        &:first-child {
            .item-title {
                padding: 0 0 15px 0;
            }
        }
    }

    .item-title {
        font-size: 12px;
        padding: 15px 0;

        &.bolder {
            font-size: 14px;
            color: $black;
            font-weight: 500;
        }
    }

    .table-row-item {
        display: flex;
        align-items: center;
        border-radius: 10px;

        &:hover {
            background-color: $grey-light28;
        }
    }

    .actions {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        cursor: pointer;
        padding: 10px;
    }

    .member-context-menu {
        width: rem(130px);
        background: $white;
        color: $black0;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
        border-radius: 9px;
        margin-top: rem(4px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: rem(12px);

        &__item {
            font-weight: 500;
            font-size: rem(12px);
            line-height: rem(15px);

            &--warning {
                color: $red2;
            }
        }

        .member-context-menu__item + .member-context-menu__item {
            margin-top: rem(10px);
        }
    }

    @include mobile {
        .table-row {
            &:first-child {
                display: none;
            }

            &:not(:first-child) {
                .table-cell:not(:first-child) {
                    display: none;
                }
            }
        }
    }
}

.background {
    background: #F6F6F6;
    border-radius: 9px;
    padding-top: 10px;
}
</style>
