<template lang="pug">
    transition(name="editor-mobile-header-menu")
        .editor-mobile-header-menu(
            v-if="isOpen"
            @click.self="close"
        )
            .content
                slot
</template>

<script>
export default {
    name: 'editor-mobile-header-menu',

    props: {
      disableScroll: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
        isOpen: false,
    }),

    methods: {
        open() {
            this.isOpen = true;
            if (this.disableScroll) {
              window.disableScroll();
            }

        },

        close() {
            this.isOpen = false;
            this.$emit('close');
            if (this.disableScroll){
              window.enableScroll();
            }

        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999999;

    display: flex;
    align-items: flex-end;

    background-color: rgba($black, 0.6);

    .content {
        position: relative;

        width: 100%;
        max-height: 75vh;
        padding: rem(34px) rem(30px);
        overflow: auto;

        background-color: #fff;
        border-radius: rem(20px) rem(20px) 0 0;
    }

    &-enter-active,
    &-leave-active {
        transition:  background-color .5s;

        .content {
            transition: transform .5s;
        }
    }

    &-enter,
    &-leave-to {
        background-color: transparent;

        .content {
            transform: translateY(100%);
        }
    }
}
</style>
