<template lang="pug">
    .editor-at-popup(
        v-show="isOpen"
        v-click-outside="closeAndClear"
        :style="styles"
    )
        .menu-content(ref="popup")
            .search-enteties
                .search-enteties__listing
                    .search-enteties__listing-item
                        .search-enteties__listing-item-title People
                        ul.search-enteties-list
                            li.search-enteties-list__item(
                                v-for="(user, index) in users"
                                @click="clickUserHandler(user)"
                                @mousedown.prevent
                                :key="index"
                            )
                                a.app-link
                                    span.app-link__text {{ user.username + (user.surname ? ' ' + user.surname : '') }}
                    .search-enteties__listing-item(v-if="documentFilteredList")
                        .search-enteties__listing-item-title Link to Page
                        ul.search-enteties-list
                            li.search-enteties-list__item(
                                v-for="(document, index) in documentFilteredList"
                                :key="document.id"
                                @mouseover="changePreview(document)"
                                @mousedown.prevent
                                @click="clickDocumentHandler(selectedItem)"
                                :class="classes(index)"
                            )
                                a.app-link
                                    .app-link__icon(v-if="document.icon") {{document.icon}}
                                    span.app-link__text {{document.name}}
                .search-enteties__preview(v-if="selectedItem")
                    .search-enteties__preview-inner(@click="clickDocumentHandler(selectedItem)")
                        app-card-document(
                            class="app-card-document--preview"
                            :icon="selectedItem.icon"
                            :name="selectedItem.name"
                            :contentPreview="selectedItem.image"
                        )
</template>

<script>
    import Scrollbar from 'smooth-scrollbar';
    import { keyboards } from '@/js/editor/const';
    export default {
        name: 'editor-at-popup',

        props: {
            users: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            documents: {
                type: Array,
                default: () => {
                    return [];
                },
            },
        },

        data() {
            return {
                isOpen: false,
                styles: {},
                scrollbar: null,
                searchText: '',
                selectedItemIndex: -1,
                closeCounter: 1,
                indexPosition: 0,
                selectedItem: null,
                heightPopup: 300,
            };
        },

        computed: {
            documentFilteredList() {
                if (this.searchText) return [...this.documents].filter(document => {
                    return document.name.toLowerCase().trim().indexOf(this.searchText.toLowerCase().trim()) > -1;
                });
                return this.documents;
            },

            notFilteredList() {
                return this.documentFilteredList.length === 0;
            },

            range() {
                return {
                    index: this.indexPosition - 1,
                    length: this.searchText.length + 1,
                };
            },
        },

        methods: {
            classes(index) {
                return [{ 'selected': index === this.selectedItemIndex }];
            },

            open(params) {
                window.disableScroll();
                this.isOpen = true;
                this.styles = {
                    top: `${params.top}px`,
                    left: `${params.left}px`,
                };
                this.$nextTick(() => {
                    this.scrollbar = Scrollbar.init(this.$refs.popup);
                });
            },

            close() {
                this.isOpen = false;
                window.enableScroll();
            },

            closeAndClear(needDeleteText = true) {
                this.close();
                this.clear(needDeleteText);
            },

            clear(needDeleteText = true) {
                if (needDeleteText) {
                    this.$emit('deleteText', this.range);
                }
                this.inputSearchClear();
                this.clearSelected();
                this.setIndexPositionClear();
                this.closeCounter = 1;
                this.selectedItemIndex = -1;
            },

            changePreview(document) {
                this.selectedItem = document;
            },

            addLetter(globalIndexPosition, letter) {
                this.setText(globalIndexPosition, (endIndexPosition) => {
                    return this.searchText.slice(0, endIndexPosition)
                        + letter
                        + this.searchText.slice(endIndexPosition);
                });
            },

            removeLetter(globalIndexPosition) {
                this.setText(globalIndexPosition, (endIndexPosition) => {
                    return this.searchText = this.searchText.slice(0, endIndexPosition) + this.searchText.slice(endIndexPosition + 1);
                });
            },

            setText(globalIndexPosition, getText) {

                // if (this.notFilteredList) {
                //     if (this.closeCounter === 3) {
                //         this.closeAndClear();
                //         return;
                //     }
                //     this.closeCounter++;
                //     return;
                // }

                const indexPosition = globalIndexPosition - this.indexPosition;
                this.searchText = getText(indexPosition);
            },

            inputSearchClear() {
                this.searchText = '';
            },

            clearSelected() {
                this.selectedItem = null;
            },

            setIndexPosition(index) {
                this.indexPosition = index;
            },

            setIndexPositionClear() {
                this.indexPosition = 0;
            },

            clickUserHandler(user) {
                this.close();
                this.$emit('insertMentor', user);
            },

            clickDocumentHandler(document) {
                this.$emit('insertDocument', {
                    meta: {
                        createBy: 'popupSymbol',
                        typeDocument: 'existingDocument',
                        popup: 'atPopup',
                        searchText: this.searchText,
                    },
                    document: document,
                });
                this.close();
            },

            onEnter() {
                if (!this.notFilteredList && this.selectedItemIndex >= 0) {
                    this.clickDocumentHandler(this.documentFilteredList[this.selectedItemIndex]);
                } else {
                    this.closeAndClear(false);
                }
            },

            scroll(upOrDown = true) {
                const value = upOrDown ? -200 : 200;
                this.scrollbar.scrollTop = this.scrollbar.offset.y + value;
                this.scrollbar.update();
            },

            onArrow(keyName) {
                if (keyName === keyboards.up.keyName) {
                    if (this.selectedItemIndex + 1 > 1) {
                        if (this.selectedItemIndex + 1 < this.documentFilteredList.length && (this.selectedItemIndex % 3 === 0)) {
                            this.scroll();
                        }
                        this.selectedItemIndex--;
                    }
                } else if (keyName === keyboards.down.keyName) {
                    if (this.selectedItemIndex + 1 < this.documentFilteredList.length) {
                        if (this.selectedItemIndex > -1 && ((this.selectedItemIndex+1) % 3 === 0)) {
                            this.scroll(false);
                        }
                        this.selectedItemIndex++;
                    }
                }
            },
        },
    };
</script>

<style scoped lang="scss">
.editor-at-popup {
    position: absolute;
    display: flex;
    flex-direction: row;

    .menu-content {
        border-radius: rem(9px);
        background: $white;
        box-shadow: 0px 0px rem(9.8px) rgba($black0, 0.25);
        height: 300px;

        .search-enteties {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            padding: rem(6px);

            &-list {
                &__item {
                    .app-link {
                        display: flex;
                        font-weight: 500;
                        font-size: rem(12px);
                        line-height: rem(15px);
                        color: $black0;
                        padding: rem(7px) rem(6px);
                        border-radius: 4px;
                        &:hover {
                            background-color:  $grey-light37;
                        }
                    }
                }
                &__item.selected {
                    background-color:  $grey-light37;
                }
                &__item + .search-enteties-list__item {
                    margin-top: rem(3px);
                }
            }

            &__listing {
                width: rem(281px);
                &-item {
                    &-title {
                        font-weight: 500;
                        font-size: rem(12px);
                        line-height: rem(15px);
                        color: $grey-dark6;
                        padding: rem(6px) rem(6px);
                    }
                    &-title + .search-enteties-list {
                        margin-top: rem(4px);
                    }
                }
                &-item + .search-enteties__listing-item {
                    margin-top: rem(7px);
                }
            }

            &__preview {
                padding: rem(6px);
                &-inner {
                    width: rem(230px);
                    height: rem(276px);
                    padding: rem(15px);
                    background: $grey-light38;
                    border-radius: 9px;
                }
            }

            &__listing + .search-enteties__preview {
                margin-left: rem(15px);
            }
        }
    }
}
</style>
