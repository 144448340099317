<template lang="pug">
    .editor-header-share
        button.editor-header-share__btn(@click="$modal.show('editor-invite-popup', {document: document, invitedUsers: documentUsers })") Share
</template>

<script>
export default {
    name: 'editor-header-share',
    props: {
        document: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        documentUsers() {
            return this.document?.users || [];
        },
    },
};
</script>

<style lang="scss" scoped>
.editor-header-share {
    &__btn {
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
}
</style>
