<template lang="pug">
    ul.editor-actions-blocks-popup
        li.editor-actions-blocks-popup__item(
            v-for="item in blocks"
            draggable
            :key="item.title"
            :data-name="item.title"
            @dragstart="dragStart"
            @dragend="dragEnd"
         )
            img.editor-actions-blocks-popup__icon(:src="item.icon")
            p.editor-actions-blocks-popup__text {{ item.title }}
            img.editor-actions-blocks-popup__dots(src="@/assets/images/editor/icons/dots.svg")

</template>

<script>
export const BLOCK_NAMES = {
    CHECKLIST: 'Checklist',
    LINK: 'Link',
    IMAGE: 'Image',
    MENTION: 'Mention',
    EMOJI: 'Emoji',
    TABLE: 'Table',
    FORMULA: 'Formula',
    CODE: 'Code',
    PAGE: 'Page',
};

export default {
    name: 'editor-actions-blocks-popup',
    props: {
        format: {
            type: Object,
            default: () => ({}),
        },
        document: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            blocks: [
                { icon: require('@/assets/images/editor/icons/checkbox.svg'), title: BLOCK_NAMES.CHECKLIST },
                { icon: require('@/assets/images/editor/icons/link.svg'), title: BLOCK_NAMES.LINK },
                { icon: require('@/assets/images/editor/icons/image_add.svg'), title: BLOCK_NAMES.IMAGE },
                { icon: require('@/assets/images/editor/icons/mention.svg'), title: BLOCK_NAMES.MENTION },
                { icon: require('@/assets/images/editor/icons/smile.svg'), title: BLOCK_NAMES.EMOJI },
                { icon: require('@/assets/images/editor/icons/table.svg'), title: BLOCK_NAMES.TABLE },
                { icon: require('@/assets/images/editor/icons/equation.svg'), title: BLOCK_NAMES.FORMULA },
                { icon: require('@/assets/images/editor/icons/code.svg'), title: BLOCK_NAMES.CODE },
                { icon: require('@/assets/images/editor/icons/page.svg'), title: BLOCK_NAMES.PAGE },
            ],
        };
    },
    methods: {
        dragStart(event) {
            event.target.classList.add('selected');
            event.dataTransfer.setData('block-name', event.target.dataset.name);
        },
        dragEnd({ target }) {
            target.classList.remove('selected');
        },
    },
};
</script>

<style lang="scss" scoped>
.editor-actions-blocks-popup {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    &__item {
        display: flex;
        align-items: center;
        padding: 13px 7px 12px 21px;
        background: #FAFAFA;
        border-radius: 4px;
        cursor: grab;

        &.selected {
            opacity: 0.999;
            background: #FAFAFA;
            cursor: grabbing;
        }
    }

    &__icon {
        width: 15px;
        height: 15px;
        margin-right: 13px;
        pointer-events: none;
    }

    &__text {
        flex-grow: 1;
        font-size: 12px;
    }

    &__dots {
        height: 14px;
        pointer-events: none;
    }
}
</style>
