<template lang="pug">
    vue-context.context-menu(
        ref="context"
        @open="openHandler"
    )
        .context-menu__link(
            v-for="item in items"
            @click="$emit(itemsList[item].event, $event)"
        )
            .context-menu__icon(v-if="itemsList[item].icon" :class="{bigIcon: projectPage}")
                component(:is="itemsList[item].icon")
            | {{ itemsList[item].name }}
            component(v-if="itemsList[item].additionalIcon" :is="itemsList[item].additionalIcon")
</template>

<script>
import createFolderIcon from '@/assets/images/icons/create_folder.svg?inline';
import createDocumentIcon from '@/assets/images/icons/create_document.svg?inline';
import uploadDocumentIcon from '@/assets/images/icons/upload_document.svg?inline';

import moveToIcon from '@/assets/images/icons/move_to_document.svg?inline';
import moveToFolderIcon from '@/assets/images/icons/move_to_folder.svg?inline';
import renameIcon from '@/assets/images/icons/rename.svg?inline';
import favoriteIcon from '@/assets/images/icons/favorite.svg?inline';
import downloadIcon from '@/assets/images/icons/download.svg?inline';
import editIcon from '@/assets/images/icons/edit.svg?inline';
import deleteIcon from '@/assets/images/icons/delete.svg?inline';
import shareIcon from '@/assets/images/icons/share.svg?inline';
import dublicateIcon from '@/assets/images/icons/dublicate.svg?inline';
import versionHistoryIcon from '@/assets/images/icons/version_history.svg?inline';
import arrowIcon from '@/assets/images/icons/arrow_right_4.svg?inline';

export default {
    name: 'context-menu',

    components: {
        createFolderIcon,
        createDocumentIcon,
        uploadDocumentIcon,

        moveToIcon,
        moveToFolderIcon,
        renameIcon,
        favoriteIcon,
        downloadIcon,
        editIcon,
        deleteIcon,
        shareIcon,
        versionHistoryIcon,
        dublicateIcon,
        arrowIcon,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
        isMoveToForFolder: {
            type: Boolean,
            required: false,
            default: false,
        },
        projectPage: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            itemsList: {
                addToFavorite: {
                    icon: 'favoriteIcon',
                    name: 'Add to Favorite',
                    event: 'addToFavorite',
                },
                createDocument: {
                    icon: 'createDocumentIcon',
                    name: 'Create Document',
                    event: 'createDocument',
                },
                createFolder: {
                    icon: 'createFolderIcon',
                    name: 'Create Folder',
                    event: 'createFolder',
                },
                createRoom: {
                    icon: 'createFolderIcon',
                    name: 'Create Room',
                    event: 'createRoom',
                },
                delete: {
                    icon: 'deleteIcon',
                    name: 'Delete',
                    event: 'delete',
                },
                download: {
                    icon: 'downloadIcon',
                    name: 'Export',
                    event: 'download',
                    additionalIcon: 'arrowIcon',
                },
                moveTo: {
                    icon: this.isMoveToForFolder ? 'moveToFolderIcon' : 'moveToIcon', //else for document
                    name: 'Move to',
                    event: 'moveTo',
                },
                moveToFolder: {
                    icon: 'moveToFolderIcon',
                    name: 'Move to',
                    event: '',
                },
                edit: {
                    icon: 'editIcon',
                    name: 'Edit',
                    event: 'edit',
                },
                rename: {
                    icon: 'editIcon',
                    name: 'Edit',
                    event: 'rename',
                },
                saveVersion: {
                    icon: 'versionHistoryIcon',
                    name: 'Save version',
                    event: 'saveVersion',
                },
                share: {
                    icon: 'shareIcon',
                    name: 'Share',
                    event: 'share',
                },
                duplicate: {
                    icon: 'dublicateIcon',
                    name: 'Duplicate',
                    event: 'duplicate',
                },
                uploadDocument: {
                    icon: 'uploadDocumentIcon',
                    name: 'Upload Document',
                    event: 'uploadDocument',
                },
                versionHistory: {
                    icon: 'versionHistoryIcon',
                    name: 'Version History',
                    event: 'versionHistory',
                },
                resendInvite: {
                    icon: false,
                    name: 'Resend Invite',
                    event: 'resendInvite',
                },
                removeMember: {
                    icon: false,
                    name: 'Remove Member',
                    event: 'removeMember',
                },
            },
        };
    },

    computed: {
        notPressedCtrStatus() {
            return !this.$store.getters.getPressedCtrStatus;
        },
    },

    methods: {
        open(e) {
            if (this.notPressedCtrStatus) {
                this.$refs.context.open(e);
            }
        },

        openHandler() {
            if (this.notPressedCtrStatus) {
                // Это все дабы закрыть меню при открытии другого меню
                if (window.openedContextMenu && window.openedContextMenu !== this.$refs.context) {
                    window.openedContextMenu.close();
                    delete window.openedContextMenu;
                }
                window.openedContextMenu = this.$refs.context;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.context-menu {
    border-radius: 9px;
    box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
    width: 120px;
    padding: 2px 3px;

    &__link {
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        font-weight: 500;

        padding: 5px 8px;
        border-radius: 6px;

        cursor: pointer;
        transition: .2s ease-in-out;

        @media (hover: hover) {
            &:hover {
                background: $grey-light28;
            }
        }

        svg {
            margin-left: auto;
        }

    }

    &__icon {
        display: flex;
        justify-content: center;

        width: rem(15px);
        height: rem(15px);
        margin-right: rem(16px);
    }

    .bigIcon {
        width: 21px;
    }
}
</style>
