import { PLANS_NAMES } from '@/js/const';

export default {
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },

        planName() {
            return this.$store.getters.planName;
        },

        isUserProjectOwner() {
            return this.project.user.is_owner;
        },

        isUserProjectOwnerWithPro() {
            return this.planName === PLANS_NAMES.PRO && this.isUserProjectOwner || this.user.has_pro_functions;
        },
    },
};
