<template lang="pug">
    editor-mobile-header-menu.editor-mobile-header-text-formatting(
        ref="menu"
        @close="closeHandler"
    )
        .tabs
            .tab(
                :class="{ active: activeTab === 'text' }"
                @click="activeTab = 'text'"
            ) Text
            .tab(
                :class="{ active: activeTab === 'paragraph' }"
                @click="activeTab = 'paragraph'"
            ) Paragraph

        transition(mode="out-in")
            .text-actions(v-if="activeTab === 'text'" key="text")
                .row
                    a(
                        :class="{ active: format.bold }"
                        @click="changeFormat('bold', !format.bold)"
                    )
                        img(src="@/assets/images/editor/icons/bold.svg")

                    a(
                        :class="{ active: format.italic }"
                        @click="changeFormat('italic', !format.italic)"
                    )
                        img(src="@/assets/images/editor/icons/italic-mobile.svg")

                    a(
                        :class="{ active: format.underline }"
                        @click="changeFormat('underline', !format.underline)"
                    )
                        img(src="@/assets/images/editor/icons/underline-mobile.svg")

                    a(
                        :class="{ active: format.strike }"
                        @click="changeFormat('strike', !format.strike)"
                    )
                        img(src="@/assets/images/editor/icons/strikethrough.svg")

                    a(
                        :class="{ active: format.script === 'sub' }"
                        @click="changeFormat('script', format.script !== 'sub' ? 'sub' : false)"
                    )
                        img(src="@/assets/images/editor/icons/subscript.svg")

                    a(
                        :class="{ active: format.script === 'super' }"
                        @click="changeFormat('script', format.script !== 'super' ? 'super' : false)"
                    )
                        img(src="@/assets/images/editor/icons/superscript.svg")

                .link(@click="openSubmenu('style')") Style
                    span {{ currentParagraph.name }}
                .link(@click="openSubmenu('font')") Font
                    span {{ currentFont.name }}
                .link(@click="openSubmenu('size')") Size
                    span {{ currentSize.name }}
                .link(@click="openSubmenu('color')") Font color
                    span
                        .circle(:style="{ backgroundColor: format.color || '#000' }")

            .paragraph-actions(v-else-if="activeTab === 'paragraph'" key="paragraph")
                .row
                    a(
                        :class="{ active: format.align === undefined }"
                        @click="changeFormat('align', undefined)"
                    )
                        img(src="@/assets/images/editor/icons/align_left.svg")
                    a(
                        :class="{ active: format.align === 'center' }"
                        @click="changeFormat('align', 'center')"
                    )
                        img(src="@/assets/images/editor/icons/align_center.svg")
                    a(
                        :class="{ active: format.align === 'right' }"
                        @click="changeFormat('align', 'right')"
                    )
                        img(src="@/assets/images/editor/icons/align_right.svg")
                .row
                    a(
                        :class="{ active: format.list === 'bullet' }"
                        @click="changeFormat('list', format.list !== 'bullet' ? 'bullet' : false)"
                    )
                        img(src="@/assets/images/editor/icons/bulleted_list-mobile.svg")

                    a

                    a(
                        :class="{ active: format.list === 'ordered' }"
                        @click="changeFormat('list', format.list !== 'ordered' ? 'ordered' : false)"
                    )
                        img(src="@/assets/images/editor/icons/numbered_list-mobile.svg")



        transition
            .submenu(v-if="openedSubmenu === 'style'")
                .top(@click="closeSubmenu")
                    .back-button
                    | Style
                .list
                    .item(
                        v-for="p in paragraphMenu"
                        :class="{ active: p.active }"
                        @click="p.click"
                    ) {{ p.name }}

        transition
            .submenu(v-if="openedSubmenu === 'font'")
                .top(@click="closeSubmenu")
                    .back-button
                    | Font
                .list
                    .item(
                        v-for="f in fontMenu"
                        :class="{ active: f.active }"
                        @click="f.click"
                        v-html="f.html"
                    )

        transition
            .submenu(v-if="openedSubmenu === 'size'")
                .top(@click="closeSubmenu")
                    .back-button
                    | Size
                .list
                    .item(
                        v-for="s in sizeMenu"
                        :class="{ active: s.active }"
                        @click="s.click"
                    ) {{ s.name }}

        transition
            .submenu(v-if="openedSubmenu === 'color'")
                .top(@click="closeSubmenu")
                    .back-button
                    | Font color
                color-picker(
                    :value="format.color || '#000'"
                    @input="changeFormat('color', $event.hex)"
                )
</template>

<script>
import EditorMobileHeaderMenu from '@/components/editor/header/EditorMobileHeaderMenu';
import { Sketch } from 'vue-color';
import { paragraphList, fontList, sizeList } from '@/js/editor/const';

export default {
    name: 'editor-mobile-header-text-formatting',

    components: {
        EditorMobileHeaderMenu,
        ColorPicker: Sketch,
    },

    data: () => ({
        format: {},
        activeTab: 'text', // text, paragraph
        openedSubmenu: null,
    }),

    computed: {
        paragraphMenu() {
            return paragraphList.map(p => ({
                name: p.name,
                active: this.format.header === p.value || (!this.format.header && p.value === 0),
                click: () => {
                    this.changeFormat('header', this.format.header !== p.value ? p.value : false);
                },
            }));
        },

        currentParagraph() {
            return paragraphList.find(p => this.format.header === p.value) || paragraphList.find(p => p.value === 0);
        },

        fontMenu() {
            return fontList.map(font => ({
                html: `<span style="font-family: ${font.name}">${font.name}</span>`,
                active: this.currentFont.value === font.value,
                click: () => {
                    this.changeFormat('font', font.value);
                },
            }));
        },

        currentFont() {
            return fontList.find(f => this.format.font === f.value) || fontList[0];
        },

        sizeMenu() {
            return sizeList.map(size => ({
                name: size.name,
                active: this.currentSize.value === size.value,
                click: () => {
                    this.changeFormat('size', size.value);
                },
            }));
        },

        currentSize() {
            return sizeList.find(s => this.format.size === s.value) || sizeList[0];
        },
    },

    methods: {
        open(format) {
            this.format = format;
            this.$refs.menu.open();
        },

        close() {
            this.$refs.menu.close();
        },

        openSubmenu(name) {
            this.openedSubmenu = name;
        },

        closeSubmenu() {
            this.openedSubmenu = null;
        },

        closeHandler() {
            this.$emit('formatting', this.format);
        },

        changeFormat(name, value) {
            this.$set(this.format, name, value);
        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header-text-formatting {
    .tabs {
        display: flex;
        justify-content: space-around;

        margin-bottom: rem(18px);

        .tab {
            font-size: rem(24px);
            font-weight: 600;

            transition: color .3s;

            &.active {
                color: $blue;
            }
        }
    }

    .text-actions {
        .row, .link {
            height: rem(74px);
            padding: 0 rem(10px);

            border-bottom: 1px solid #A6A6A6;
        }

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                width: rem(30px);
                height: rem(27px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                }

                &.active {
                    box-shadow: 0 0 0 rem(8px) $hover-background, inset 0 0 0 rem(20px) $hover-background;
                }
            }
        }

        .link {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: rem(20px);
            font-weight: 500;

            span {
                display: flex;
                align-items: center;

                &:after {
                    content: '';

                    width: rem(10px);
                    height: rem(18px);
                    margin-left: rem(10px);

                    background: url(../../../assets/images/icons/arrow_right_2.svg) no-repeat center center / contain;
                }

                .circle {
                    width: rem(31px);
                    height: rem(31px);

                    background-color: #000;
                    border-radius: 50%;
                }
            }
        }
    }

    .paragraph-actions {
        .row {
            display: flex;
            justify-content: space-around;
            align-items: center;

            height: rem(74px);

            border-bottom: 1px solid #A6A6A6;

            a {
                width: rem(37px);
                height: rem(29px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                }

                &.active {
                    box-shadow: 0 0 0 rem(8px) $hover-background, inset 0 0 0 rem(20px) $hover-background;
                }
            }
        }
    }

    .submenu {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        padding: rem(34px) rem(20px);
        overflow: auto;

        background-color: #fff;

        .top {
            display: inline-flex;
            align-items: center;

            margin-bottom: rem(28px);
            padding: 0 rem(10px);

            font-size: rem(24px);
            font-weight: 600;

            .back-button {
                width: rem(10px);
                height: rem(18px);
                margin-right: rem(21px);

                background: url(../../../assets/images/icons/arrow_left_2.svg) no-repeat center center / contain;
            }
        }

        .item {
            margin-bottom: rem(14px);
            padding: rem(10px);

            font-size: rem(20px);
            font-weight: 500;

            &.active {
                background-color: $hover-background;
            }
        }

        .vc-sketch {
            margin: 0 auto;
        }
    }
}
</style>
