import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import Dashboard from '../views/dashboard/index';
import Library from '../views/library/index';
import HowTo from '../views/how-to/index';
import Favourite from '../views/favourite/index';
import Bin from '../views/bin/index';
import Storage from '../views/storage/index';
import Editor from '../views/editor/index';
import Shared from '../views/shared/index';

import ProjectDetail from '../views/projects/detail/index';
import RoomDetail from '../views/rooms/detail/index';
import FolderDetail from '../views/folders/detail/index';

import Checkout from '../views/checkout/index';
import Register from '../views/auth/register/index';
import Login from '../views/auth/login/index';
import ResetPassword from '../views/auth/reset-password/index';
import RefreshPassword from '../views/auth/refresh-password/index';

import AcceptInviteEmail from '../views/accept-invite/email/index';
import AcceptInviteToken from '../views/accept-invite/token/index';
import AcceptInviteTeam from '../views/accept-invite/team/index';

import NotificationsPage from '../views/notifications/index';

import Page404 from '../views/404/index';
import isValidJson from '../js/utils/isValidJson';
import VueRouterBackButton from 'vue-router-back-button';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', link: '/' },
            ],
        },
    },
    {
        path: '/notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
        meta: {
            requiresAuth: true,
            layout: 'default-layout',
        },
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
            requiresAuth: true,
            layout: 'checkout-layout',
        },
    },
    {
        path: '/notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
        meta: {
            requiresAuth: true,
            layout: 'default-layout',
        },
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
            requiresAuth: true,
            layout: 'checkout-layout',
        },
    },
    {
        path: '/library',
        name: 'Library',
        component: Library,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Library', link: '/library' },
            ],
        },
    },
    {
        path: '/library/:folderId',
        name: 'LibraryFolder',
        component: Library,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Library', link: '/library' },
            ],
        },
    },
    {
        path: '/how-to',
        name: 'HowTo',
        component: HowTo,
        meta: {
            breadcrumb: [
                { name: 'How to', link: '/how-to' },
            ],
        },
    },
    {
        path: '/shared',
        name: 'Shared',
        component: Shared,
        meta: {
            breadcrumb: [
                { name: 'Shared with me', link: '/shared-with-me' },
            ],
        },
    },
    {
        path: '/favourite',
        name: 'Favourite',
        component: Favourite,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Favourite', link: '/favourite' },
            ],
        },
    },
    {
        path: '/bin',
        name: 'Bin',
        component: Bin,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Bin', link: '/bin' },
            ],
        },
    },
    {
        path: '/storage',
        name: 'Storage',
        component: Storage,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Storage', link: '/storage' },
            ],
        },
    },
    {
        path: '/editor/:id',
        name: 'Editor',
        component: Editor,
        meta: {
            layout: 'editor-layout',
            requiresAuth: true,
            breadcrumb: [
                { name: 'Editor', link: '/editor/:id' },
            ],
        },
    },
    {
        path: '/public/:linkHash',
        name: 'PublicLink',
        component: Editor,
        meta: {
            layout: 'editor-layout',
        },
    },
    {
        path: '/public/:linkHash/:idSubDoc',
        name: 'PublicLink',
        component: Editor,
        meta: {
            layout: 'editor-layout',
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            layout: 'welcome-layout',
            breadcrumb: [
                { name: 'Register', link: '/register' },
            ],
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'welcome-layout',
            breadcrumb: [
                { name: 'Login', link: '/login' },
            ],
        },
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            layout: 'welcome-layout',
            breadcrumb: [
                { name: 'Reset password', link: '/reset-password' },
            ],
        },
    },
    {
        path: '/refresh-password',
        name: 'RefreshPassword',
        component: RefreshPassword,
        meta: {
            layout: 'welcome-layout',
            breadcrumb: [
                { name: 'Refresh password', link: '/reset-password' },
            ],
        },
    },
    {
        path: '/projects/:id',
        name: 'ProjectDetail',
        component: ProjectDetail,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/rooms/:roomId',
        name: 'RoomDetail',
        component: RoomDetail,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/folders/:folderId',
        name: 'FolderDetail',
        component: FolderDetail,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/share-invite/:token',
        name: 'AcceptInviteEmail',
        component: AcceptInviteEmail,
        meta: {
            layout: 'loading-layout',
            requiresAuth: true,
        },
    },

    {
        path: '/team-invite-id/:id/',
        name: 'AcceptInviteTeam',
        component: AcceptInviteTeam,
        meta: {
            layout: 'loading-layout',
        },
    },
    {
        path: '/accept/invite/:entity_type/:token',
        name: 'AcceptInviteToken',
        component: AcceptInviteToken,
        meta: {
            layout: 'loading-layout',
        },
    },

    {
        path: '/404',
        name: '404',
        component: Page404,
        meta: {
            layout: 'welcome-layout',
        },
    },
    {
        path: '*',
        redirect: '/404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    const setPageLoaded = () => {
        if (!store.state.pageLoaded) {
            setTimeout(() => {
                store.commit('updatePageLoaded', true);
            });
        }
    };

    const resolveRoute = (resolveParams = null) => {
        setPageLoaded();

        if (!resolveParams) {
            return next();
        }
        return next(resolveParams);
    };

    if (store.state.auth.user) {
        if (to.name === '') {
            return resolveRoute({ name: 'Dashboard' });
        }
        return resolveRoute();
    }

    const token = Vue.cookie.get('token');

    if (!token || !isValidJson(token)) {
        if (to.name === 'PublicLink') {
            try {
                await store.dispatch('getPublicDocumentLinkToken', to.params.linkHash);
                await store.dispatch('fetchUserByXAccessToken');
            } catch (e) {
                return resolveRoute({ name: '404' });
            }
            return resolveRoute();
        }
        if (to.meta.requiresAuth) {
            return resolveRoute({ name: 'Login' });
        }
        return resolveRoute();
    }

    await store.dispatch('fetchUser', { token: JSON.parse(token) });

    if (to.meta.requiresAuth && !store.state.auth.user) {
        return resolveRoute({ name: 'Login' });
    }
    if (to.meta.requiresAuth && !store.state.auth.user.username) {
        return resolveRoute({ name: 'Register', query: { complete: true } });
    }
    if (to.name === 'Login' && store.state.auth.user) {
        return resolveRoute({ name: 'Dashboard' });
    }
    if (to.name === 'PublicLink') {
        await store.dispatch('getPublicDocumentLinkToken', to.params.linkHash);
    }

    return resolveRoute();
});

Vue.use(VueRouterBackButton, {
    router,
    ignoreRoutesWithSameName: true,
});

export default router;
