<template>
    <div
        class="item"
        :style="{width: width , height: height }"
        @click="$emit('click')"
        @dblclick="$emit('dblclick')"
        @contextmenu.prevent.stop="$emit('contextmenu', $event)"
    >
        <div class="item__header">
            <div
                v-if="item.icon"
                class="item__header-icon"
            >
                {{ item.icon }}
            </div>
            <div
                v-else
                class="item__header-icon"
            >
                ✌
            </div>

            <div class="item__header-title">
                {{ splitText(item.name, 20) }}
            </div>
        </div>

        <div class="item__content">
            <img
                v-if="item.image !== null"
                :src="item.image"
            />
            <p v-else>
                Please edit the document for the preview to load
            </p>
        </div>
    </div>
</template>

<script>

import { splitText } from '@/js/utils/text';
export default {
    name: 'document-item',

    props: {
        width: {
            type: String,
            required: true,
        },

        height: {
            type: String,
            required: true,
        },

        item: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        splitText,
    }),
};
</script>

<style scoped lang="scss">
.item {
    padding: rem(15px) 0;
    margin: 5px 0;
    background: $white;
    box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    overflow: hidden;

    &__header {
        width: 90%;
        display: flex;
        align-items: center;
        padding: 15px 0 7px 0;
        border-bottom: 1px solid $grey2;
        margin: auto;

        &-icon {
            width: rem(20px);
            height: rem(20px);
        }
        &-title {
            font-weight: 500;
            font-size: 14px;
            line-height: rem(17px);
            color: $black;
        }
        &-icon + &-title {
            margin-left: rem(7px);
        }
    }
    &.selected {
        background: rgba(106, 112, 255, 0.08);
    }

    &__content {
        height: 100%;

        img {
            width: 100%;
            height: 80%;
            padding: 0 13px;
        }
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 7px;
            height: 100%;
            font-weight: 500;
            line-height: 10px;
            color: $grey-light3;

        }
    }

    &__header + &__content {
        margin-top: 12px;
    }
}
</style>
