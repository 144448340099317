<template>
    <div class="unsplash">
        <app-loader v-if="moduleLoading" />
        <template v-else>
            <div
                v-if="isOpen"
                class="unsplash-search"
            >
                <search-icon />
                <input
                    v-model="query"
                    placeholder="Search for pictures"
                    type="text"
                    @input="searchByQuery()"
                />
            </div>
            <div class="unsplash-container">
                <button
                    class="open-search"
                    @click="openSearch"
                >
                    <plus-icon :class="{'open': isOpen}" />
                </button>

                <card-popup
                    v-for="(item, id) in images"
                    :key="id"
                    :project-id="projectId"
                    :styles="getBackground(item.urls.regular)"
                    :text="item.user.username"
                    class="unsplash-container__card"
                    image
                    @updateBackground="$emit('updateBackground', $event)"
                >
                    <div class="unsplash-container__card-author">
                        {{ item }}
                    </div>
                </card-popup>
                <intersect @enter="isOpen ? searchByQuery() : getImages() ">
                    <p></p>
                </intersect>
            </div>
        </template>
    </div>
</template>

<script>

import searchIcon from '@/assets/images/icons/search_3.svg?inline';
import plusIcon from '@/assets/images/icons/big-plus.svg?inline';
import Intersect from 'vue-intersect';


import { getPhotos, getPhotosByQuery } from '@/js/unsplash';

import CardPopup from '@/components/pages/projects/detail/popup/cardPopup';
import _ from 'lodash';

export default {
    name: 'unsplash',
    components: {
        CardPopup,
        searchIcon,
        plusIcon,
        Intersect,
    },

    props: {
        projectId: {
            type: Number,
            default: 0,
        },
    },

    data: () => ({
        query: '',
        images: [],
        isOpen: false,
        moduleLoading: false,
        page: 1,
    }),

    watch: {
        isOpen() {
            this.page = 1;
            this.images = [];
        },
    },

    mounted() {
        this.getImages();
    },

    methods: {

        async getImages() {
            let images = null;
            try {
                images = await getPhotos(this.page, 21);
                this.images = [...this.images, ...images];
                this.page++;
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            }

        },
        openSearch() {
            this.isOpen = !this.isOpen;
        },

        async searchByQuery() {
            _.debounce(await getPhotosByQuery(this.page, 21, this.query).then((v) => {
                this.images = [...this.images, ...v];
                this.page++;
            }), 500);
        },

        getBackground(value) {
            return value;
        },
    },


};
</script>

<style lang="scss" scoped>
.unsplash {
    &-container {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        padding: 20px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 68px;
            width: 160px;
            min-width: rem(168px);
            border: 1px dashed #D7D7D7;
            border-radius: 9px;
            cursor: pointer;
        }

        svg {
            width: 24px;
            height: 24px;
            transition: .2s ease-in-out;
        }

        .open {
            transform: rotate(45deg) !important;
        }
    }

    &-search {
        display: flex;
        align-items: center;
        padding: 5px 13px;
        background-color: $grey-light12;
        margin: 20px 20px 0 20px;
        border-radius: 4px;

        input {
            font-size: 13px;
            font-weight: 500;
        }

        svg {
            margin-right: 20px;
        }
    }
}
</style>
