import AppHorizontalTabs from '@/components/app/app-horizontal-tabs/index';

export default {
    components: {
        AppHorizontalTabs,
    },

    data: () => ({
        activeTabId: null,
    }),

    created() {
        this.selectTab();
    },

    watch: {
        '$route.query'() {
            this.selectTab();
        },
    },

    methods: {
        changeTab(id) {
            let route = this.$router.currentRoute;

            if (this.isProject && route.name !== 'ProjectDetail') {
                this.$router.push(`/projects/${this.project.id}?tab=${this.tabs[id]?.tab}`);
            }
            if (this.activeTabId !== id) {
                this.$router.push({ query: { tab: this.tabs[id]?.tab } });
            } else {
                this.activeTabId = null;
                this.$router.push({ query: {} });
            }
        },

        selectTab() {
            const activeTabIndex = this.tabs.findIndex((c) => c.tab === this.$route.query.tab);
            if (~activeTabIndex) {
                this.activeTabId = activeTabIndex;
            } else {
                this.activeTabId = null;
            }
        },
    },
};
