export default {
    methods: {
        share(doc) {
            let documentUsers = doc.users || [];

            this.$modal.show('editor-invite-popup', {
                document: doc,
                invitedUsers: documentUsers,
                isAddingPeople: true,
            });
        },
    },
};
