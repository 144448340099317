<template lang="pug">
    .editor-invite-popup-accesses-select(:class="classes")
        .access__selected(@click="toggleList") {{ activeAccessesName }}
            arrow-bottom-icon
        accesses-context(
            v-show="listOpened"
            v-model="activeAccesses"
            type="document"
            :document-id="document.id"
            :preselect-first="preselectFirst"
            v-click-outside="closeList"
            :forInviteInline="forInviteInline"
        )
</template>

<script>
import arrowBottomIcon from '@/assets/images/icons/arrow_bottom.svg?inline';
import AccessesContext from '@/components/other/AccessesContext';

export default {
    name: 'editor-invite-popup-accesses-select',

    components: {
        AccessesContext,
        arrowBottomIcon,
    },

    props: {
        value: {
            type: null,
            required: true,
        },

        preselectFirst: {
            type: Boolean,
            required: false,
        },

        document: {
            type: Object,
            required: true,
        },

        forInviteInline: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        listOpened: false,
    }),

    computed: {
        activeAccesses: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
                this.closeList();
            },
        },

        classes() {
            return {
                opened: this.listOpened,
            };
        },

        activeAccessesName() {
            return this.activeAccesses?.name;
        },
    },

    methods: {
        toggleList() {
            if (this.listOpened) {
                this.closeList();
            } else {
                setTimeout(() => {
                    this.listOpened = true;
                });
            }
        },

        closeList() {
            this.listOpened = false;
        },
    },
};
</script>

<style scoped lang="scss">
.editor-invite-popup-accesses-select {
    position: relative;

    &.opened .access__selected svg {
        transform: rotate(180deg);
    }
}

.access__selected {
    color: #9F9E9E;
    white-space: nowrap;

    cursor: pointer;
    transition: color .2s;

    user-select: none;

    svg {
        width: rem(16px);
        margin-left: rem(8px);

        transition: transform .2s, stroke .2s;

        stroke: $grey;
    }

    @media (hover: hover) {
        &:hover {
            color: $black;

            svg {
                stroke: $black;
            }
        }
    }
}

.accesses-context {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    z-index: 1;
}
</style>
