<template lang="pug">
    .actions.noprint(
        @click.stop
    )
        button(
            @click="openPopup('blocks')"
            :class="{'active': currentActionPopup === 'blocks'}"
        )
            plus-icon.icon

        button(
            @click="openPopup('formatting')"
            :class="{'active': currentActionPopup === 'formatting'}"
        )
            typography-icon.icon

        button(
            @click="openPopup('share')"
            :class="{'active': currentActionPopup === 'share'}"
        )
            divide-icon.icon
</template>

<script>
import plusIcon from '@/assets/images/editor/icons/plus.svg?inline';
import informationIcon from '@/assets/images/editor/icons/information.svg?inline';
import typographyIcon from '@/assets/images/editor/icons/typography.svg?inline';
import divideIcon from '@/assets/images/editor/icons/divide.svg?inline';

export default {
    name: 'editor-side-actions',

    components: {
        plusIcon,
        informationIcon,
        typographyIcon,
        divideIcon,
    },

    props: {
        currentActionPopup: {
            type: String,
            required: true,
        },
    },

    methods: {
        openPopup (namePopup) {
            this.$emit('openPopup', namePopup);
        },
    },
};
</script>

<style lang="scss" scoped>
.actions {
    position: fixed;
    display: flex;
    flex-direction: column;
    float: right;
    gap: 10px 0;
    padding: 5px 4px;
    right: 15px;
    top: rem(200px);
    color: $grey-light39;
    z-index: 1;

    button {
        padding: 13px;
        cursor: pointer;
        border-radius: 6px;

        &:hover {
            background-color: #EDEDED;

            .icon {
                fill: #221B38;
                stroke: #221B38;
            }
        }

        &:active, &.active {
            background-color: #E2ECFF;

            .icon {
                fill: #4A46FF;
                stroke: #4A46FF;
            }
        }
    }
}

@media print {
    .noprint {
        display:none;
    }
}
</style>
