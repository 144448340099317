import { get, post } from '@/js/api/http-helper';

export async function tariffSubscription(fd) {
    const data = await post('/tariff/subscription', fd);
    return data.data;
}

export async function tariffCancel() {
    const data = await get('/tariff/subscription/cancel');
    return data.data;
}

export async function tariffInvoices() {
    const data = await get('/tariff/subscription/invoices');
    return data.data;
}

//
export async function tariffCouponCreate(fd) {
    const data = await post('/tariff/coupon/create', fd);
    return data.data;
}

//
export async function tariffDiscount(fd) {
    const data = await post('/tariff/coupon/discount', fd);
    return data.data;
}
