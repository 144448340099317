<template lang="pug">
    app-modal.editor-not-found-popup(
        name="editor-not-found-popup"
        @beforeClose="goToDashboard"
    )
        .text This document has been deleted or is currently unavailable
        app-button(@click="goToDashboard") Go to dashboard
</template>

<script>
export default {
    name: 'editor-not-found-popup',

    methods: {
        goToDashboard() {
            this.$router.push({ name: 'Dashboard' });
        },
    },
};
</script>

<style scoped lang="scss">
.editor-not-found-popup {
    &::v-deep .app-modal__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .text {
        margin-bottom: rem(16px);

        font-size: rem(18px);
        line-height: 1.25;
        text-align: center;
    }
}
</style>
