<template>
    <div
        :class="classes"
        :style="styles"
        class="list-child-move"
    >
        <div
            v-if="child.type !== 'folder'"
            class="blured"
        >
            <document-item
                :height="'169px'"
                :item="child"
                :width="'189px'"
            />
        </div>
        <div
            v-else
            class="bluredFolder"
        >
            <div class="folder">
                <div class="folder__link">
                    <span class="folder__icon">{{ child.icon }}</span><span
                        class="folder__name"
                    >{{ child.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CODE_CHILDREN_ANIMATION } from '@/js/const';
import DocumentItem from '@/components/common/documentItem';

export default {
    name: 'list-child-move',
    components: { DocumentItem },
    props: {
        child: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            mouseCoordinates: null,
            increaseSpeed: 0,
            increaseSpeedConst: 60,

            timerId: 0,
            timerIntervalId: 0,

            top: 0,
            left: 0,
            width: 0,
            height: 0,

            topOld: 0,
            leftOld: 0,
            widthOld: 0,
            heightOld: 0,
        };
    },

    inject: {
        selectedChildrenSetStatusAnimation: { default: null },
        selectedChildrenDelAnimation: { default: null },
    },

    computed: {
        styles() {
            return {
                top: this.top + 'px',
                left: this.left + 'px',
                width: this.width + 'px',
                height: this.height + 'px',
            };
        },
        domId() {
            return this.child.type + '_' + this.child.id;
        },
        classes() {
            return this.child.codeAnimation === CODE_CHILDREN_ANIMATION.CREATED ? '' : 'list-child-move__move';
        },
    },

    mounted() {
        const parentArray = document.getElementsByClassName(this.child.type + '_' + this.child.id);
        if (parentArray) {
            const parent = parentArray[0];
            const bound = parent.getBoundingClientRect();
            this.top = bound.top;
            this.left = bound.left;
            this.width = parent.offsetWidth;
            this.height = parent.offsetHeight;
            this.increaseSpeed = this.increaseSpeedConst;

            this.timerId = setTimeout(() => {
                this.timerIntervalId = setInterval(() => {
                    if (this.mouseCoordinates && this.child) {
                        if (this.child.codeAnimation === CODE_CHILDREN_ANIMATION.RETURN_POSITION_START) {
                            //если оригинала нет, то это значит, что его переместили или удалили, поэтому анимацию тоже сразу удаляем
                            const parentArray = document.getElementsByClassName(this.child.type + '_' + this.child.id);
                            if (parentArray) {
                                const parent = parentArray[0];
                                const bound = parent.getBoundingClientRect();
                                this.topOld = bound.top;
                                this.leftOld = bound.left;
                                this.widthOld = parent.offsetWidth;
                                this.heightOld = parent.offsetHeight;
                                this.increaseSpeed = this.increaseSpeedConst;
                                this.selectedChildrenSetStatusAnimation(this.child, CODE_CHILDREN_ANIMATION.RETURN_POSITION);
                            } else {
                                this.selectedChildrenDelAnimation(this.child);
                            }
                        } else if (this.child.codeAnimation === CODE_CHILDREN_ANIMATION.RETURN_POSITION) {
                            if (Math.round(this.topOld) === Math.round(this.top) && Math.round(this.leftOld) === Math.round(this.left)) {
                                this.top = this.topOld;
                                this.left = this.leftOld;
                                this.selectedChildrenSetStatusAnimation(this.child, CODE_CHILDREN_ANIMATION.RETURN_POSITION_FINISH);
                            } else {
                                this.move(this.topOld, this.leftOld, this.widthOld, this.heightOld);
                            }
                        } else if (this.child.codeAnimation === CODE_CHILDREN_ANIMATION.RETURN_POSITION_FINISH) {
                            this.selectedChildrenDelAnimation(this.child);
                        } else if (this.child.codeAnimation === CODE_CHILDREN_ANIMATION.CREATED_FINISH) {
                            this.top = this.mouseCoordinates.top;
                            this.left = this.mouseCoordinates.left;
                        } else if (this.child.codeAnimation === CODE_CHILDREN_ANIMATION.CREATED) {
                            if (this.mouseCoordinates.top === Math.round(this.top) && this.mouseCoordinates.left === Math.round(this.left)) {
                                this.top = this.mouseCoordinates.top;
                                this.left = this.mouseCoordinates.left;
                                this.selectedChildrenSetStatusAnimation(this.child, CODE_CHILDREN_ANIMATION.CREATED_FINISH);
                            } else {
                                this.move(this.mouseCoordinates.top, this.mouseCoordinates.left, 165, 40);
                            }
                        }
                    }
                }, 1);
            }, 110);
        }
    },

    created() {
        window.addEventListener('mousemove', this.onmousemoveHandler);
    },

    destroyed() {
        window.removeEventListener('mousemove', this.onmousemoveHandler);
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        if (this.timerIntervalId) {
            clearInterval(this.timerIntervalId);
        }
    },

    methods: {
        onmousemoveHandler(e) {
            const topMouse = e.clientY - 45;
            const leftMouse = e.clientX;
            this.mouseCoordinates = { top: topMouse, left: leftMouse };
        },
        move(topVal, leftVal, widthVal, heightVal) {
            const topDiff = topVal - this.top;
            const leftDiff = leftVal - this.left;
            if (this.increaseSpeed > 1) {
                this.increaseSpeed--;
            }
            const top = topDiff / this.increaseSpeed;
            const left = leftDiff / this.increaseSpeed;
            this.width = widthVal;
            this.height = heightVal;
            this.top += top;
            this.left += left;
        },
    },
};
</script>

<style lang="scss" scoped>
.list-child-move {
    position: fixed;
    z-index: 3;


    &__move {
        transition-property: width, height;
        transition-duration: 0.6s, 0.6s;
        transition-timing-function: linear;
    }

    .blured {
        position: relative;
        width: 189px;
        height: 169px;
        margin: 10px;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            background: rgba(253, 253, 253, 0.63);
            width: 100%;
        }
    }

    .bluredFolder {
        position: relative;
        height: rem(100px);
        width: rem(100px);

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            background: rgba(253, 253, 253, 0.63);
            width: 100%;
        }

        .folder {
            @include folder();
        }
    }
}

.name {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.child-item {
    position: relative;
}
</style>
