<template lang="pug">
    .login
        form.login-form(@submit.prevent)
            img.login-form__logo(src="@/assets/images/logo.svg" alt="ReSkript")
            app-text-field(
                v-model="email.data"
                label="Email"
                name = "email"
                :error="emailError"
            )
                email-icon(slot="icon")

            app-text-field(
                v-model="password.data"
                label="Password"
                name = "password"
                :isPassword="true"
                :error="passwordError"
            )
                password-icon(slot="icon")

            p(v-if="registrationNotCompleted") Registration not completed.&#32;
                router-link(:to="{ name: 'Register', query: { complete: true } }") Complete

            router-link.login-form__forgot(to="/reset-password") Forgot Password

            .login-form__divider or
            .login-form__google-button(
                ref="login-form__google-button"
                @click="loginGoogle"
            )
                logo-google
                span Sign in with Google
            p Don’t have an account?&#32;
                router-link(to="/register") Sign Up

            app-button.app-button--full-width(
                :loading="password.loading"
                @click="loginWithPassword"
            ) Log In
</template>

<script>
import logoGoogle from '@/assets/images/logo_google.svg?inline';
import emailIcon from '@/assets/images/icons/email.svg?inline';
import passwordIcon from '@/assets/images/icons/password.svg?inline';

import { required, email } from 'vuelidate/lib/validators';
import { signIn } from '@/js/api/requests/auth';

export default {
    name: 'login',

    components: {
        logoGoogle,
        emailIcon,
        passwordIcon,
    },

    data: () => ({
        email: {
            data: '',
            loading: false,
            error: null,
        },
        password: {
            data: '',
            loading: false,
            error: null,
        },
        registrationNotCompleted: false,
    }),

    validations: {
        email: {
            data: {
                required, email,
            },
        },

        password: {
            data: {
                required,
            },
        },
    },

    computed: {
        emailError() {
            if (this.$v.email.data.$dirty) {
                if (!this.$v.email.data.required) {
                    return 'Required field';
                } else if (!this.$v.email.data.email) {
                    return 'Invalid Email Format';
                }
            }
            return this.email.error;
        },

        passwordError() {
            if (this.$v.password.data.$dirty) {
                if (!this.$v.password.data.required) {
                    return 'Required field';
                }
            }
            return this.password.error;
        },

        redirectPath () {
            return this.$route.query.redirect || '/';
        },
    },

    methods: {
        async loginWithPassword() {
            if (this.password.loading) return;

            if (this.$v.password.data.$invalid || this.$v.email.data.$invalid) {
                this.$v.password.data.$touch();
                this.$v.email.data.$touch();
                return;
            }

            const fd = new FormData;
            fd.append('email', this.email.data);
            fd.append('login_password', this.password.data);

            this.password.loading = true;
            try {
                const token = await signIn(fd);
                await this.$store.dispatch('fetchUser', {
                    token: token,
                    redirectPath: this.redirectPath,
                });
                this.$cookie.set('token', JSON.stringify(token));
            } catch (e) {
                if (e?.response?.status === 401) {
                    this.password.error = 'Invalid email or password';
                } else {
                    this.password.error = 'Something went wrong, please try again later';
                }
            } finally {
                this.password.loading = false;
            }
        },

        loginGoogle() {
            window.open(process.env.VUE_APP_BACKEND_URL + 'authorization/google', 'Вход', 'width=660,height=760');

            let listener = async e => {
                const token = e.data?.data?.token;
                if (token) {
                    try {
                        await this.$store.dispatch('fetchUser', { token, redirectPath: this.redirectPath });
                        this.$cookie.set('token', JSON.stringify(token));
                        window.removeEventListener('message', listener);
                    } catch (e) {
                        this.$awn.alert('Something went wrong, please try again later');
                    }
                } else return;
            };
            window.addEventListener('message', listener);
        },
    },
};
</script>

<style scoped lang="scss">
.login {
    &-form {
        width: rem(350px);
        padding: 1rem;

        background: #FFFFFF;
        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

        &__logo {
            display: block;

            width: rem(184px);
            margin: 0 auto 1.5rem;
        }

        &__forgot {
            font-size: rem(13px);
            text-decoration: underline;
        }

        &__divider {
            display: flex;
            justify-content: center;
            align-items: center;

            margin: rem(18px) 0 rem(24px);

            font-size: rem(18px);
            color: #7C7C7C;

            &:before, &:after {
                content: '';

                display: block;

                width: 100%;
                height: 1px;

                background: #7C7C7C;
            }

            &:before {
                margin-right: rem(10px);
            }

            &:after {
                margin-left: rem(10px);
            }
        }

        p {
            margin-bottom: rem(23px);

            a {
                color: $blue;
            }
        }

        &__google-button {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: rem(40px);
            margin-bottom: rem(22px);

            font-size: rem(14px);
            font-weight: 500;
            color: #6D6D6D;

            background: #fff;
            border: 1px solid #eee;
            border-radius: rem(6px);
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);

            cursor: pointer;
            transition: box-shadow .3s;

            &:hover {
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
            }

            svg {
                width: rem(18px);
                margin-right: rem(38px);
            }
        }
    }
}
</style>
