import { render, staticRenderFns } from "./ThreeDotsMenu.vue?vue&type=template&id=9872095e&scoped=true&lang=pug&"
import script from "./ThreeDotsMenu.vue?vue&type=script&lang=js&"
export * from "./ThreeDotsMenu.vue?vue&type=script&lang=js&"
import style0 from "./ThreeDotsMenu.vue?vue&type=style&index=0&id=9872095e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9872095e",
  null
  
)

export default component.exports