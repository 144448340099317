<template>
    <div
        class="item"
        @click="openDocument"
    >
        <header>
            <div class="item-icon">
                {{ icon }}
            </div>
            <div class="item-title">
                {{ title }}
            </div>
        </header>
        <div
            v-if="text"
            class="item-text"
            v-html="itemText"
        >
        </div>
    </div>
</template>

<script>
export default {
  name: 'searcher-item',

  props: {
      title: {
        type: String,
        required: true,
      },

      id: {
        type: Number,
        required: true,
      },

      icon: {
        type: String,
        default: '✌',
      },

      text: {
        type: String,
        default: '',
      },

      value: {
        type: String,
        default: '',
      },
  },

  computed: {
    itemText () {
      if (!this.value || !this.text){
        return this.text;
      }

      const regexp = new RegExp(this.value.toLowerCase(),'g');

      const matches = [...this.text.toLowerCase().matchAll(regexp)];

      let result = this.text;

      for (let i = 0; i< matches.length; i++) {
        const match = matches[i];

        const temp = result.split('');

        const replacement = `<strong>${this.text.slice(match.index, match.index + match[0].length)}</strong>`;

        const replacementIdx = (replacement.length - match[0].length) * i;

        temp.splice(match.index + replacementIdx, match[0].length, replacement );

        result = temp.join('');
      }


      return result;
    },
  },

  methods: {
    openDocument() {
      this.$modal.hide('searcher');

      this.$router.push(`editor/${this.id}`);
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  padding: 18px 6px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #EBEBEB;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: rem(16px);
    font-weight: 500;
  }

  &-icon {
    margin-right: 17px;
    font-size: rem(22px);
  }

  &-text {
    margin-top: 9px;
    margin-left: 42px;
    font-size: rem(12px);
  }

    @include mobile_or_tablet {

    }
}
</style>
