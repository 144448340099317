import Quill from 'quill';
let BlockEmbed = Quill.import('blots/block/embed');

class DividerBlot extends BlockEmbed {}

DividerBlot.blotName = 'divider';
DividerBlot.className = 'ql-divider';
DividerBlot.tagName = 'hr';

Quill.register(DividerBlot);
