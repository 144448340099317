<template lang="pug">
    .editor-snapshots-sidebar
        .title Version History
        .versions(ref="versions")
            div
                .version(
                    v-for="sn in snapshots"
                    @click="$emit('selectSnapshot', sn)"
                    :class="{ active: sn._id === selectedSnapshot._id}"
                )
                    .version__day {{ getDay(sn) }}
                    .version__body
                        .version__date {{ getDate(sn) }}
                        .version__current(v-if="sn.current") Current version
                        .version__system(v-if="sn.author === 'system'") System
                        .version__user(v-else)
                            .version__user-color(:style="{ backgroundColor: getColor(sn) }")
                            .version__user-name {{ getName(sn) }}
                        three-dots-menu
                            template(v-if="!sn.current")
                                a(@click="$emit('restoreSnapshot', sn)") Restore version
                                a(@click="$modal.show('editor-snapshots-rename-popup', { snapshot: sn })") Name this Version
                            a(@click="$emit('duplicateSnapshot', sn)") Duplicate
        .bottom
            app-checkbox(
                v-model="showChanges"
                label="Show Changes"
            )
        editor-snapshots-rename-popup
</template>

<script>

import ThreeDotsMenu from '@/components/other/ThreeDotsMenu';
import EditorSnapshotsRenamePopup from '@/components/editor/snapshots/EditorSnapshotsRenamePopup';
import Scrollbar from 'smooth-scrollbar';
import moment from 'moment';

export default {
    name: 'editor-snapshots-sidebar',

    components: {
        ThreeDotsMenu,
        EditorSnapshotsRenamePopup,
    },

    props: {
        snapshots: {
            type: Array,
            required: true,
        },

        selectedSnapshot: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        showChanges: true,
    }),

    mounted() {
        Scrollbar.init(this.$refs.versions);
    },

    methods: {
        getDate(sn) {
            return moment(sn.created_at).format('LLL');
        },

        getDay(sn) {
            return moment(sn.created_at).format('dddd');
        },

        getName(sn) {
            return sn.author?.fullName;
        },

        getColor(sn) {
            return '#' + sn.author?.color;
        },
    },
};
</script>

<style scoped lang="scss">
.editor-snapshots-sidebar {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;

    width: rem(365px);
    height: 100vh;
    margin-top: rem(-77px);

    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .title {
        display: flex;
        flex-shrink: 0;
        align-items: center;

        height: rem(77px);
        padding: 0 rem(27px);

        font-weight: 500;
    }

    .versions {
        &::v-deep .scroll-content {
            padding-bottom: 2rem;
        }
    }
    .version {
        cursor: pointer;

        &:last-of-type {
            border-bottom: 1px solid #6C6C6C;
        }

        &__day {
            padding: rem(11px) rem(27px);

            font-size: rem(16px);
            font-weight: 500;

            border-top: 1px solid #6C6C6C;
            border-bottom: 1px solid #6C6C6C;
        }

        &__body {
            position: relative;

            padding: rem(22px) rem(41px) rem(27px);
        }

        &__date {
            position: relative;

            margin-bottom: rem(10px);

            font-size: rem(16px);
            font-weight: 500;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: -1rem;

                display: block;

                border: rem(5px) solid #444444;
                border-top-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;

                transform: translateY(-50%);
            }
        }

        &__current, &__system {
            margin-bottom: rem(8px);

            font-size: rem(14px);
            font-weight: 500;
            color: #565555;
        }

        &__user {
            display: flex;
            align-items: center;

            &-color {
                width: rem(10px);
                height: rem(10px);
                margin-right: rem(10px);

                border-radius: 50%;
            }

            &-name {
                font-size: rem(14px);
                font-weight: 500;
            }
        }

        &:hover {
            background-color: $hover-background;
        }

        &.active {
            background-color: #edf7fe;
        }

        .three-dots-menu {
            position: absolute;
            top: rem(20px);
            right: rem(16px);
        }
    }

    .bottom {
        margin-top: auto;
        padding: rem(10px) rem(27px);

        border-top: 1px solid #6C6C6C;
    }
}
</style>
