import { updateFolder } from '@/js/api/requests/folder';
import { updateDocument } from '@/js/api/requests/document';

export default {

    data: () => ({
        folders: [],
        entity: null,
        isDocOrFolder: true,
    }),

    methods: {
        moveTo(documentId ,entityId) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        let moveTextSuccess = 'The document was moved successfully';
                        if (this.isDocOrFolder === false) {
                            moveTextSuccess = 'Folder moved successfully';
                        }
                        try {

                            // TO DO смотри выше метод open
                            if (this.isDocOrFolder) {
                                await updateDocument(documentId, { folder_id: entityId });
                            } else {
                                await updateFolder(entityId, { document_id: documentId });
                            }
                            this.$emit('moveSuccess', {
                                entityType: 'folder',
                                entityId,
                            });
                            this.$awn.success(moveTextSuccess);
                        } catch (e) {
                            this.$awn.alert('Something went wrong, please try again later');
                        }
                    }
                },
            });
        },
    },
};
