<template lang="pug">
  .editor-actions-mode-popup
</template>

<script>
export default {
    name: 'editor-actions-mode-popup',
};
</script>

<style scoped>

</style>
