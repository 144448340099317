<template lang="pug">
    app-modal.upload-document-popup(
        name="upload-document-popup"
        @opened="initDropDown"
    )
        h3 Upload Documents
        .drop-place(
            ref="dropPlace"
            :class="{ disabled: loading }"
        )
            template(v-if="!loading")
                span.drag-text Drag and drop
                span or
                app-button(@click="browseFile") Browse
            app-loader(v-else)
</template>

<script>
import { importDocument } from '@/js/api/requests/editor';

const availableFileTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
];

export default {
    name: 'upload-document-popup',

    data: () => ({
        loading: false,
    }),

    computed: {
        user() {
            return this.$store.getters.user;
        },
    },

    methods: {
        browseFile() {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.docx,.doc';
            input.addEventListener('change', e => {
                const file = e.target.files[0];
                this.changeHandler(file);
            });

            input.click();
        },

        initDropDown() {
            let dropArea = this.$refs.dropPlace;
            ['dragenter', 'dragover'].forEach(eventName => {
                dropArea.addEventListener(eventName, () => {
                    dropArea.classList.add('highlight');
                });
            });
            ['dragleave', 'drop'].forEach(eventName => {
                dropArea.addEventListener(eventName, () => {
                    dropArea.classList.remove('highlight');
                });
            });
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                dropArea.addEventListener(eventName, preventDefaults, false);
            });

            function preventDefaults(e) {
                e.preventDefault();
                e.stopPropagation();
            }

            dropArea.addEventListener('drop', (e) => {
                let dt = e.dataTransfer;
                let files = dt.files;
                ([...files]).forEach((file) => {
                    this.changeHandler(file);
                });
            }, false);
        },

        async changeHandler(file) {
            if (!availableFileTypes.includes(file.type)) {
                this.$awn.alert('Incorrect file type. Try uploading another file');
                return;
            }
            const fileName = file.name.match(/(.*)\..+$/)[1];

            this.loading = true;

            try {
                const { documentId } = await importDocument(this.user.id, file, fileName);
                this.$modal.hide('upload-document-popup');
                this.$router.push({ name: 'Editor', params: { id: documentId } });
            } catch (e) {
                this.$awn.alert(e.response.data || 'Something went wrong, please try again later');
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.upload-document-popup {
    h3 {
        text-align: center;
    }

    .drop-place {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: rem(300px);

        background-color: #EBEBEB;
        border: 1px dashed #9C9C9C;

        span {
            margin-bottom: rem(12px);

            font-size: rem(18px);
            font-weight: 500;
        }

        .app-loader {
            padding: 0;
        }

        &.highlight {
            background-color: rgba(#EBEBEB, 0.5);
            border-color: $blue;

            .drag-text {
                color: $blue;
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }
}
</style>
