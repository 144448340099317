import axios from 'axios';

export const access_key = 'pjEc7BiHeD-muT7R9EyakNd8SCF-E-gTg28JPD35M3w';
export const secret_key = 'lLBuSoMDjp_4YecyfE8mgRXi6sbqFElytswlW3p-n94';

const apiClient = axios.create({
    baseURL: 'https://api.unsplash.com',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export async function getPhotos(page, perPage) {
    const data = await apiClient.get(
        `/photos?client_id=${access_key}&page=${page}&per_page=${perPage}`,
    );
    return data.data;
}

export async function getPhotosByQuery(page, perPage, query) {
    const data = await apiClient.get(
        `/search/photos?page=${page}&query=${query}&per_page=${perPage}&client_id=${access_key}`,
    );
    return data.data.results;
}
