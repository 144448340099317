<template lang="pug">
    .library(@mousedown="mousedownHandler" @mouseup="mouseupHandler" @mousemove="mousemoveHandler")
        .default-layout-head
            app-breadcrumbs(
                :items="this.currentFolder ? breadcrumbs : $route.meta.breadcrumb"
                @mouseoverBreadcrumb="mouseoverEntityHandler"
                @mouseupBreadcrumb="mouseupEntityHandler"
                @clickBreadcrumb="clickEntityHandler"
                class="app-breadcrumbs_mb app-breadcrumbs--theme-thin"
            )

        app-loader(v-if="pageLoading")
        template(v-else)
            template(v-if="documentsViewTypeCards")
                .default-layout-row.folders
                    folders(
                        :folders = "folders"
                        :createFolderParamsWhenEmpty = "createFolderOptions"
                        :createFolderParamsWhenNonEmpty = "createFolderOptions"
                        :entityClasses = "entityClasses"
                        :mouseoverEntityHandler = "mouseoverEntityHandler"
                        :mouseupEntityHandler = "mouseupEntityHandler"
                        :clickEntityHandler = "clickEntityHandler"
                        :dbclickEntityHandler = "dbclickEntityHandler"
                        :openFolderContext = "openFolderContext"
                        :showAddBtn = "true"
                        @get-folder-id="getFolderId"
                    )

                .default-layout-row.folder-docs
                    documents(
                        v-if="documents.length"
                        :documents="documents"
                        :createDocumentOptions="createDocumentOptions"
                        :moveActions="moveActions"
                        :showAddBtn="true"
                        @setDocId="movedDoc = $event"
                    )
                    no-docs(v-else)

            template(v-else-if="!documentsViewTypeCards && documents.length")
                documents-table(
                    :items="documentsAndFolders"
                    :headers="headersForTable"
                    :group-items-by="groupedByType"
                    :without-groups="isMobileOrTablet"
                    bolder
                    library
                )
        context-menu(
            ref="folderContext"
            :items="['moveTo', 'rename', 'delete']"
            :isMoveToForFolder="true"
        )
        list-children-move(:show="isChildrenHaveAndMove" :counter="childrenLength")
        list-child-move(v-for="(selectedChild, index) in selectedChildrenAnimations" :key="index" :child="selectedChild")
        move-to-folders-context(ref="moveToFoldersContext")
</template>

<script>
import FolderContextMenuGeneratorMixin from '@/mixins/FolderContextMenuGeneratorMixin';
import MoveToFoldersContext from '@/components/other/MoveToFoldersContext';
import FolderDetailSettings from '@/components/pages/folders/detail/Settings';
import folderIcon from '@/assets/images/icons/folder.svg?inline';
import FilesTable from '@/components/other/FilesTable';
import noFoldersIcon from '@/assets/images/icons/no_folders.svg?inline';
import noDocumentsIcon from '@/assets/images/icons/no_documents.svg?inline';
import noSharedDocumentsIcon from '@/assets/images/icons/no_shared_documents.svg?inline';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import ChildrenMixin from '@/mixins/ChildrenMixin';
import { getAllDocuments, updateDocument } from '@/js/api/requests/document';
import { getAllFolders, getFolderById } from '@/js/api/requests/folder';
import DocumentsTable from '@/components/common/documentsTable';

import draggable from 'vuedraggable';
import NoDocs from '@/components/other/noDocs/noDocs';

export default {
    name: 'library',

    components: {
        NoDocs,
        DocumentsTable,
        FilesTable,
        folderIcon,
        noFoldersIcon,
        noDocumentsIcon,
        noSharedDocumentsIcon,
        FolderDetailSettings,
        MoveToFoldersContext,
        draggable,
    },

    mixins: [CreateDocumentMixin, ChildrenMixin, FolderContextMenuGeneratorMixin],

    data: () => ({
        //это когда мы не в либрари, а внутри её дочерней папки(у которой нет родителя считается, что в либрари)
        currentFolder: null,
        pageLoading: true,
        headersForTable: ['Name', 'Updated', 'Created'],
        groupedByType: [{ name: 'Folders', value: 'folders' }, { name: 'Documents', value: 'documents' }],
        movedDoc: null,
        movedFolder: null,
    }),

    computed: {
        // хлебные крошки для папки либрари
        breadcrumbs() {
            return this.getBreadcrumps(this.currentFolder);
        },

        createFolderOptions() {
            return  { folder: { ...this.currentFolder }, goToFolder: true };
        },

        createDocumentOptions() {
            return this.currentFolder ? { folder_id: this.currentFolder.id } : {};
        },

        isLibrary() {
            return !this.isLibraryFolder;
        },

        isLibraryFolder() {
            return Boolean(this.currentFolder);
        },

        documentsAndFolders() {
            return { folders: [...this.folders], documents: [...this.documents] };
        },

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },

        documentsViewTypeCards() {
            return this.$store.getters.documentsViewTypeCards;
        },
    },

    watch: {
        $route() {
            this.fetchFoldersAndDocument();
        },
    },

    created() {
        this.fetchFoldersAndDocument();
    },

    methods: {

         getFolderId(folder) {
            this.movedFolder = folder.id;
            if (this.movedDoc) {
                updateDocument(this.movedDoc, { folder_id: this.movedFolder }).then(() => this.fetchFoldersAndDocument());
                this.movedFolder = null;
                this.movedDoc = null;
            }
        },

        createFolderPopup() {
            const options = this.currentFolder ? { folder: { ...this.currentFolder }, goToFolder: true } : { goToFolderInLibrary: true };
            this.$modal.show('create-folder-popup', options);
        },

        async fetchFoldersAndDocument() {
            this.pageLoading = true;
            await this.fetchData();
            this.pageLoading = false;
        },

        async fetchData() {
            const folderId = this.$route.params.folderId;
            if (folderId) {
                const currentFolder = await getFolderById(folderId);
                if (currentFolder) {
                    this.currentFolder = currentFolder;
                    this.currentFolder.type = this.ENTITY_TYPES_NAMES.folder;
                    this.fetchFolderDocuments(currentFolder);
                    this.fetchFolderFolders(currentFolder);
                }
            } else {
                this.currentFolder = null;
                await this.fetchLibraryDocuments();
                await this.fetchLibraryFolders();
            }
        },

        async fetchLibraryFolders() {
            try {
                const folders = await getAllFolders();
                if (folders) {
                    this.$store.dispatch('setContentFolders', this.addTypeForChild(folders, this.ENTITY_TYPES_NAMES.folder));
                } else {
                    this.$store.dispatch('setContentFolders', []);
                }
            } catch (e) {
                this.$awn.alert('An error occurred while loading data, please try again later');
            }
        },
        async fetchLibraryDocuments() {
            try {
                const documents = await getAllDocuments();
                if (documents) {
                    if (documents.my_documents) {
                        this.$store.dispatch('setContentDocuments', this.addTypeForChild(documents.my_documents, this.ENTITY_TYPES_NAMES.document));

                    }
                } else {
                    this.$store.dispatch('setContentDocuments', []);
                }
            } catch (e) {
                this.$awn.alert('An error occurred while loading data, please try again later');
            }
        },

        fetchFolderFolders(currentFolder) {
            if (currentFolder && currentFolder.folder_childFolders) {
                this.$store.dispatch('setContentFolders', this.addTypeForChild(currentFolder.folder_childFolders, this.ENTITY_TYPES_NAMES.folder));
            } else {
                this.$store.dispatch('setContentFolders', []);
            }
        },
        fetchFolderDocuments(currentFolder) {
            if (currentFolder && currentFolder.folder_documents) {
                this.$store.dispatch('setContentDocuments', this.addTypeForChild(currentFolder.folder_documents, this.ENTITY_TYPES_NAMES.document));
            } else {
                this.$store.dispatch('setContentDocuments', []);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.library {
    max-width: calc(100vw - 195px);
    padding: 0 22px 0 13px;

    .default-layout-row {
        align-items: flex-start;
    }
}
.folder-docs {
    justify-content: center;
}
@include mobile_or_tablet {
    .library {
       max-width: 100%;
    }
}
</style>
