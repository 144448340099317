<template>
    <div
        :style="setBackground"
        class="card-popup"
        @click="updateBackground()"
    >
        <div
            v-if="image"
            class="card-popup--text"
        >
            {{ 'by ' + text }}
        </div>
    </div>
</template>

<script>
import { getProjectById, updateProject } from '@/js/api/requests/project';

export default {
    name: 'card-popup',

    props: {

        image: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: '',
        },

        styles: {
            type: String,
            default: '',
        },

        projectId: {
            type: Number,
            default: 0,
        },
    },

    data: () => ({
        projectName: '',
    }),

    computed: {
        setBackground() {
            return this.styles.includes('https') ? `background: url(${this.styles}) ` : `background: ${this.styles}`;
        },
    },

    methods: {
        async updateBackground() {
            try {
                await getProjectById(this.projectId).then(v => this.projectName = v.name);

                await updateProject(this.projectId, { background: this.styles }).then(() => {
                    this.$awn.success('Project background was updated successfully');
                });

                this.$modal.hide('change-bg-modal');
                this.$emit('updateBackground', this.styles);

            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.card-popup {
    background-size: cover;
    position: relative;
    width: 160px;
    min-width: 160px;
    height: 68px;
    border-radius: 9px;
    cursor: pointer;

    &--text {
        position: absolute;
        font-size: 8px;
        font-weight: 500;
        line-height: 10px;
        bottom: 2px;
        left: 5px;
        color: $white;
    }
}
</style>
