import {
    exportDocumentAsDocx,
    exportDocumentAsHTML,
    exportDocumentAsPdf,
    getDocumentInfo,
} from '@/js/api/requests/editor';
import downloadFile from '@/js/utils/downloadFile';

const checkAccess = async document => {
    try {
        await getDocumentInfo({ documentId: document.id });
    } catch (e) {
        return Promise.reject('No access!');
    }
};

export default {
    methods: {
        async exportDocument(document, format) {
            try {
                if (format === 'docx') {
                    const file = await exportDocumentAsDocx(document.id);
                    downloadFile(file, document.name, 'docx');
                } else if (format === 'pdf') {
                    const file = await exportDocumentAsPdf(document.id);
                    downloadFile(file, document.name, 'pdf');
                } else if (format === 'html') {
                    const file = await exportDocumentAsHTML(document.id);
                    downloadFile(file, document.name, 'html');
                }
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            }
        },
        exportDocumentDocx(document) {
            checkAccess(document).then(() => this.exportDocument(document,'docx')).catch(e => this.$awn.alert(e));
        },
        exportDocumentHtml(document) {
            checkAccess(document).then(() => this.exportDocument(document,'html')).catch(e => this.$awn.alert(e));
        },
        exportDocumentPdf(document) {
            checkAccess(document).then(() => this.exportDocument(document,'pdf')).catch(e => this.$awn.alert(e));
        },
    },
};
