import { render, staticRenderFns } from "./EditorNotFoundPopup.vue?vue&type=template&id=05b8caba&scoped=true&lang=pug&"
import script from "./EditorNotFoundPopup.vue?vue&type=script&lang=js&"
export * from "./EditorNotFoundPopup.vue?vue&type=script&lang=js&"
import style0 from "./EditorNotFoundPopup.vue?vue&type=style&index=0&id=05b8caba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b8caba",
  null
  
)

export default component.exports