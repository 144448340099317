<template lang="pug">
    .settings-subscription
        main
            .title Subscription & Billing
            period-switch
            plans-for-profile
            billing-form(v-if="user" :formData="billingData")
            payment-method-btn
            billings
</template>

<script>
import PeriodSwitch from '@/components/other/plans/PeriodSwitch';
import PlansForProfile from '@/components/other/plans/PlansForProfile';
import BillingForm from '@/components/other/settings/BillingForm';
import Billings from '@/components/other/settings/Billings';
import PaymentMethodBtn from '@/components/other/plans/PaymentMethodBtn';

export default {
    name: 'settings-subscription',
    components: {
        PlansForProfile,
        PeriodSwitch,
        BillingForm,
        Billings,
        PaymentMethodBtn,
    },
    data() {
        return {
            billingData: {
                nameOfCompany: '',
                address: '',
                country: '',
                city: '',
                postalCode: '',
                vatNumber: '',
            },

            loading: false,
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
    },
    created() {
        if (this.user) {
            this.billingData.nameOfCompany = this.user.company ?? '';
            this.billingData.address = this.user.address ?? '';
            this.billingData.country = this.user.country;
            this.billingData.city = this.user.city ?? '';
            // this.phone = this.user.phone ?? '';
            this.billingData.postalCode = this.user.zip ?? '';
            this.billingData.vatNumber = this.user.eu_vat ?? '';
        }
    },
};
</script>

<style scoped lang="scss">
.settings-subscription {
    padding: rem(15px) rem(16px);
    &::v-deep {
        .billing {
            .title {
                margin-top: rem(70px);
                @media (max-width: 1200px) {
                    margin-top: rem(47px);
                }
                font-size: rem(16px);
            }
            .app-text-field__label {
                font-size: rem(12px);
            }
        }
        .app-toggler__label {
            font-size: rem(11px);
        }
        .app-toggler {
            margin-bottom: rem(38px);
        }
        .app-toggler__indicator {
            width: rem(37px);
            height: rem(16px);
            margin: rem(0px) rem(8px);
        }
        .app-toggler__indicator:after {
            width: rem(14px);
            height: rem(14px);
            top: rem(1px);
            left: rem(1px);
        }
        .app-toggler--active .app-toggler__indicator:after {
            left: rem(22px);
        }
        .app-text-field__input {
            font-size: rem(12px) !important;
        }


        .save-billing {
            @media (min-width: 1200px) {
                font-size: rem(12px);
                padding: rem(6px) 0px;
            }
        }
    }
}
.title {
    font-size: rem(18px);
    font-weight: 500;
    margin-bottom: rem(13px);
}
</style>
