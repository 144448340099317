import { get, post } from '@/js/api/http-helper';

export async function getCountries() {
    const data = await get('/countries/get');
    return data.data || [];
}

export async function getHowTo() {
    const data = await get(`/dashboard/pages/how-to-rescript`);
    return data.data[0]?.video_links;
}

export async function getDashboard() {
    const data = await get(`/dashboard/get/data`);
    return data.data;
}

export async function shareInvite(fd) {
    return post('/share-invites', fd);
}

export async function acceptInvite(invite_id) {
    return post('/accept-share-invite', invite_id);
}

export async function sendEmail(payload) {
    return post('/send/email/template', payload);
}
