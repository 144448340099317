<template>
    <div
        v-click-outside="close"
        class="shared-filters-menu"
    >
        <div
            class="title"
            @click="openFilter"
        >
            <p>{{ currentFilter }}</p>
            <arrow-icon />
        </div>
        <div
            v-if="isOpen"
            class="filters"
        >
            <div
                v-for="(filter, i ) in SHARED_FILTERS"
                :key="i"
                class="item"
                @click="selectFilter(filter)"
            >
                <div class="icon">
                    <checked-icon v-if="currentFilter === filter.title" />
                </div>
                <p> {{ filter.title }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import sharedFilterMenuMixin from '@/mixins/sharedFilterMenuMixin';

export default {
    name: 'shared-filters-menu',
    mixins: [sharedFilterMenuMixin],

    methods: {
        openFilter() {
            if (!this.isMobileOrTablet) {
                this.isOpen = !this.isOpen;
            } else {
                this.$modal.show('shared-filters-menu-popup');
            }
        },
    },
};
</script>

<style scoped lang="scss">
.shared-filters-menu {
    position: absolute;
    right: 13px;

    .title {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        p {
            margin-right: 6px;
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            color: $grey-light43;
        }
    }

    .filters {
        margin-top: 15px;
        padding: 11px;
        background-color: $white;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
        border-radius: 9px;

        .item {
            display: flex;
            align-items: center;
            cursor: pointer;

            p {
                font-size: 11px;
                font-weight: 500;
            }

            .icon {
                width: 15px;
                height: 10px;
                margin-right: 9px;
            }

            &:not(:first-child) {
                margin-top: 17px;
            }
        }
    }
}
</style>
