<template lang="pug">
    vue-context.export-document-context(
        ref="context"
        @open="openHandler"
    )
        .list
            .list__item(@click="exportDocumentDocx(doc)") Microsoft word (docx)
            .list__item(@click="exportDocumentPdf(doc)") PDF Document (pdf)
            .list__item(@click="exportDocumentHtml(doc)") HTML
</template>

<script>
import DownloadDocumentMixin from '@/mixins/DownloadDocumentMixin';
// todo: Сделать этот компонент и меню для переноса в другую попку одним компонентом ContextMenu
export default {
    name: 'export-document-context',

    mixins: [DownloadDocumentMixin],

    data: () => ({
        doc: null,
    }),

    methods: {
        async open(e, doc) {
            this.doc = doc;
            setTimeout(() => {
                this.$refs.context.open(e);
            });
        },

        openHandler() {
            // Это все дабы закрыть меню при открытии другого меню
            if (window.openedContextMenu && window.openedContextMenu !== this.$refs.context) {
                window.openedContextMenu.close();
                delete window.openedContextMenu;
            }
            window.openedContextMenu = this.$refs.context;
        },
    },
};
</script>

<style scoped lang="scss">
.export-document-context {
    width: 15rem;

    .list {
        &__item {
            padding: rem(16px);

            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    background-color: rgba($grey, 0.1);
                }
            }
        }
    }
}
</style>
