import ListChildrenMove from '@/components/other/ListChildrenMove.vue';
import ListChildMove from '@/components/other/ListChildMove.vue';
import Folders from '@/components/other/Folders';
import Documents from '@/components/other/Documents';
import PageRedirectMixin from '@/mixins/PageRedirectMixin';
import { updateFolder } from '@/js/api/requests/folder';
import { updateDocument } from '@/js/api/requests/document';
import { CODE_CHILDREN_ANIMATION, ENTITY_TYPES_NAMES } from '@/js/const';
import { isEmpty } from 'lodash';

export default {
    mixins: [PageRedirectMixin],

    data: () => ({
        selectedChildren: {},
        selectedChildrenAnimations: {},
        countCompletedMoveAnimations: 0,

        isChildrenMove: false,
        isMousePressed: false,
        haveMoveRequest: false,
        currentEntity: null,
        firstEntityShiftClick: null,

        ENTITY_TYPES_NAMES,
    }),

    components: {
        ListChildrenMove,
        Folders,
        Documents,
        PageRedirectMixin,
        ListChildMove,
    },

    created() {
        window.addEventListener('keydown', this.pressKeyHandler);
        window.addEventListener('keyup', this.uppKeyHandler);
    },

    destroyed() {
        window.removeEventListener('keydown', this.pressKeyHandler);
        window.removeEventListener('keyup', this.uppKeyHandler);
    },

    provide() {
        return {
            selectedChildrenSetStatusAnimation: this.selectedChildrenSetStatusAnimation,
            selectedChildrenDelAnimation: this.selectedChildrenDelAnimation,
        };
    },

    computed: {
        documents() {
            return this.$store.getters.getDocuments;
        },
        folders() {
            return this.$store.getters.getFolders;
        },
        allChildren() {
            return this.$store.getters.getFoldersAndDocuments;
        },

        haveSelectedChildren() {
            return Object.keys(this.selectedChildren).length > 0;
        },

        pressedCtrStatus() {
            return this.$store.getters.getPressedCtrStatus;
        },
        pressedShiftStatus() {
            return this.$store.getters.getPressedShiftStatus;
        },
        getPressedShiftAndCtrStatus() {
            return this.$store.getters.getPressedShiftAndCtrStatus;
        },

        isChildrenHaveAndMove() {
            return (
                !this.pressedCtrStatus &&
                this.isChildrenMove &&
                this.haveSelectedChildren &&
                this.countCompletedMoveAnimations === this.childrenLength
            );
        },

        childrenLength() {
            return Object.keys(this.selectedChildren).length;
        },

        moveActions() {
            return {
                entityClasses: this.entityClasses,
                mouseoverEntityHandler: this.mouseoverEntityHandler,
                mouseupEntityHandler: this.mouseupEntityHandler,
                clickEntityHandler: this.clickEntityHandler,
                dbclickEntityHandler: this.dbclickEntityHandler,
            };
        },
    },

    watch: {
        selectedChildrenAnimations: {
            handler() {
                this.countCompletedMoveAnimations = Object.keys(
                    this.selectedChildrenAnimations,
                ).reduce((previousValue, id) => {
                    return this.selectedChildrenAnimations[id].codeAnimation ===
                        CODE_CHILDREN_ANIMATION.CREATED_FINISH
                        ? previousValue + 1
                        : previousValue;
                }, 0);
            },
            deep: true,
        },
    },

    methods: {
        selectedChildrenSetStatusAnimation(entity, value) {
            if (entity) {
                const animations = { ...this.selectedChildrenAnimations };
                animations[entity.id + entity.type].codeAnimation = value;
                this.selectedChildrenAnimations = animations;
            }
        },
        selectedChildrenDelAnimation(entity) {
            const animation = this.selectedChildrenAnimations[entity.id + entity.type];
            if (animation) {
                const animations = { ...this.selectedChildrenAnimations };
                delete animations[entity.id + entity.type];
                this.selectedChildrenAnimations = animations;
            }
        },

        isEmpty,

        clearMouseVariables() {
            this.selectedChildren = {};
            this.firstEntityShiftClick = null;
        },

        mousedownHandler() {
            this.isMousePressed = true;
        },
        mousemoveHandler() {
            if (
                !this.pressedCtrStatus &&
                this.haveSelectedChildren &&
                this.isMousePressed &&
                !this.pressedShiftStatus
            ) {
                this.isChildrenMove = true;
                Object.keys(this.selectedChildren).forEach((id) => {
                    if (!this.selectedChildrenAnimations[id]) {
                        this.selectedChildrenAnimations[id] = {
                            ...this.selectedChildren[id],
                            codeAnimation: CODE_CHILDREN_ANIMATION.CREATED,
                        };
                    }
                });
            }
        },
        mouseupHandler(ev) {
            Object.keys(this.selectedChildren).forEach((id) => {
                const old = this.selectedChildrenAnimations[id];
                if (old) {
                    this.$set(this.selectedChildrenAnimations, old.id + old.type, {
                        ...old,
                        codeAnimation: CODE_CHILDREN_ANIMATION.RETURN_POSITION_START,
                    });
                }
            });
            const isTargetFolderOrDocument = Boolean(
                ev.target.closest('.folder') || ev.target.closest('.files-table__link'),
            );
            // очистка после отпускания кнопки на дочернем элементе
            setTimeout(() => {
                this.isChildrenMove = false;
                this.isMousePressed = false;
                if (
                    !this.pressedCtrStatus &&
                    !this.pressedShiftStatus &&
                    !isTargetFolderOrDocument
                ) {
                    this.clearMouseVariables();
                }
            });
        },

        // checkChildrenNotFromCurrentEntity(entity) {
        //     if (this.haveSelectedChildren) {
        //         const childrenFromCurrentEntity = Object.values(this.selectedChildren).some(
        //             (child) => child.entity_id === entity.id,
        //         );
        //         return !childrenFromCurrentEntity;
        //     }
        //     return true;
        // },

        pressKeyHandler(ev) {
            if (ev.key === 'Control' || ev.key === 'Meta') {
                this.$store.commit('setPressedCtrStatus');
            }
            if (ev.key === 'Shift') {
                this.$store.commit('setPressedShiftStatus');
            }
        },
        uppKeyHandler(ev) {
            if (ev.key === 'Control' || ev.key === 'Meta') {
                this.$store.commit('setPressedCtrStatus', false);
            }
            if (ev.key === 'Shift') {
                this.$store.commit('setPressedShiftStatus', false);
            }
        },

        checkCurrentEntity(entity) {
            return this.currentEntity.id === entity.id && this.currentEntity.type === entity.type;
        },

        removeFolder(id) {
            this.$store.dispatch('removeContentFolder', id);
        },
        removeDocument(id) {
            this.$store.dispatch('removeContentDocument', id);
        },

        async moveRequest() {
            this.haveMoveRequest = true;
            try {
                let { id: parentId, type: parentType } = this.currentEntity;
                if (parentType !== ENTITY_TYPES_NAMES.document) {
                    const options =
                        parentType === ENTITY_TYPES_NAMES.library
                            ? { library: true }
                            : { [parentType + '_id']: parentId };
                    // eslint-disable-next-line no-unused-vars
                    for (const [idKey, entity] of Object.entries(this.selectedChildren)) {
                        if (
                            entity.type === ENTITY_TYPES_NAMES.folder &&
                            parentType !== ENTITY_TYPES_NAMES.project
                        ) {
                            await updateFolder(entity.id, options).then(() =>
                                this.removeFolder(entity.id),
                            );
                        } else if (entity.type === ENTITY_TYPES_NAMES.document) {
                            await updateDocument(entity.id, options).then(() =>
                                this.removeDocument(entity.id),
                            );
                        }
                    }
                }
            } catch (data) {
                console.log(data, 'error moveRequest');
            } finally {
                this.haveMoveRequest = false;
            }
        },
        selectEntityWhichIsNot(entity) {
            if (!this.selectedChildren[entity.id + entity.type]) {
                this.selectEntity(entity);
                return true;
            }
            return false;
        },
        selectOrDeselectEntity(entity) {
            if (!this.selectEntityWhichIsNot(entity)) {
                delete this.selectedChildren[entity.id + entity.type];
            }
        },
        selectEntity(entity) {
            this.$set(this.selectedChildren, entity.id + entity.type, { ...entity });
        },
        clickEntityHandler(entity, isBreadcrumpOrRoom = false) {
            if (!this.isChildrenMove && !isBreadcrumpOrRoom) {
                if (this.getPressedShiftAndCtrStatus) {
                    this.clearMouseVariables();
                } else if (this.pressedShiftStatus) {
                    if (!this.firstEntityShiftClick) {
                        this.firstEntityShiftClick = entity;
                    }
                    const indexEndFound = this.allChildren.findIndex((obj) => {
                        return obj.type === entity.type && obj.id === entity.id;
                    });
                    if (indexEndFound >= 0) {
                        const indexFirstFound = this.allChildren.findIndex(
                            (obj) =>
                                obj.type === this.firstEntityShiftClick.type &&
                                obj.id === this.firstEntityShiftClick.id,
                        );
                        if (indexFirstFound >= 0) {
                            let i;
                            if (indexEndFound > indexFirstFound) {
                                for (i = indexFirstFound; i <= indexEndFound; i++) {
                                    this.selectEntityWhichIsNot(this.allChildren[i]);
                                }
                            } else if (indexEndFound < indexFirstFound) {
                                for (i = indexEndFound; i <= indexFirstFound; i++) {
                                    this.selectEntityWhichIsNot(this.allChildren[i]);
                                }
                            }
                        }
                    }
                    this.selectEntityWhichIsNot(entity);
                } else if (this.pressedCtrStatus) {
                    this.selectOrDeselectEntity(entity);
                } else {
                    //на мобиле сразу редирект на страницу
                    if (window.isMobileOrTablet) {
                        this.dbclickEntityHandler(entity);
                    } else {
                        this.clearMouseVariables();
                        this.selectOrDeselectEntity(entity);
                    }
                }
            } else if (isBreadcrumpOrRoom) {
                this.dbclickEntityHandler(entity);
            }
        },
        dbclickEntityHandler(entity) {
            this.pageRedirect(entity, this.clearMouseVariables);
        },
        mouseupEntityHandler(entity) {
            // перемещаем дочер элементы в текущий элемент(над которым отпускаешь лкм)
            if (
                // когда они есть
                this.haveSelectedChildren &&
                // когда дети перемещаются
                this.isChildrenMove &&
                // когда тек элемента нет среди перемещаемых
                !this.selectedChildren[entity.id + entity.type] &&
                // когда текущий элемент точно тот, над которым сейчас курсор
                this.checkCurrentEntity(entity)
                // когда тек элемент не является родителем дочер элементов(это когда на его странице и переносишь в него же в хлеб крошках)
                // && this.checkChildrenNotFromCurrentEntity(entity)
            ) {
                this.moveRequest();
            }
        },
        mouseoverEntityHandler(entity) {
            this.currentEntity = { type: entity.type, id: entity.id };
        },

        entityClasses(entity, classesSelected, classes = '') {
            return [
                classes,
                entity.type + '_' + entity.id,
                { [classesSelected]: !!this.selectedChildren[entity.id + entity.type] },
            ];
        },

        addTypeForChild(arr, type) {
            return arr.map((obj) => {
                return { ...obj, type };
            });
        },

        //
        removeFolderForContextMenu(folder) {
            this.$store.dispatch('removeContentFolder', folder.id);
            // TO DO пока не помню для чего это было
            // if (this.currentFolder?.id === folder.id) {
            //     this.$router.replace({ name: 'Library' });
            // }
        },

        openFolderContext(e, folder) {
            this.openFolderContextCreator(
                e,
                folder,
                () => {
                    this.removeFolderForContextMenu(folder);
                },
                (name, icon) => {
                    this.$store.dispatch('setContentNameFolder', { id: folder.id, name, icon });
                },
                () => {
                    this.removeFolderForContextMenu(folder);
                },
            );
        },
    },
};
