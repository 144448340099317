<template lang="pug">
    div.wrapper
        reskriptIcon.icon(@click="openReskriptSite")
        span(@click="openReskriptSite") Try ReSkript
</template>

<script>
import reskriptIcon from '@/assets/images/icons/reskript_1.svg?inline';

export default {
    name: 'editor-header-try-reskript',

    components: {
        reskriptIcon,
    },

    methods: {
        openReskriptSite () {
            window.open('https://www.reskript.com/', '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin: 0 0 0 20px;
    height: 31px;
    border-left: 1px solid #DADADA;
    padding: 0 16px;
    display: flex;
    align-items: center;

    .icon {
        cursor: pointer;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        padding: 0 0 0 7px;
        cursor: pointer;
    }
}
</style>
