<template lang="pug">
    .chat-mention-list
        .items(ref="items")
            div
                .item(
                    v-for="user in invitedUsersFiltered"
                    @click="$emit('insertMention', getUserFullName(user))"
                )
                    .avatar
                        img(
                            v-if="user.image"
                            :src="user.image"
                        )
                        span(v-else) {{ getUserFirstLetters(user) }}
                    .name {{ getUserFullName(user) }}
</template>

<script>
import Scrollbar from 'smooth-scrollbar';

export default {
    name: 'chat-mention-list',

    props: {
        users: {
            type: Array,
            required: true,
        },
    },

    computed: {
        invitedUsersFiltered() {
            return this.users.filter((obj) => obj.username);
        },
    },

    mounted() {
        this.initScrollbar();
    },

    methods: {
        getUserFullName(user) {
            return [user.username, user.surname].join(' ');
        },

        getUserFirstLetters(user) {
            return [user.username?.[0], user.surname?.[0]].join('');
        },

        initScrollbar() {
            this.scrollbar = Scrollbar.init(this.$refs.items);
        },
    },
};
</script>

<style scoped lang="scss">
.chat-mention-list {
    position: absolute;
    right: 0;
    bottom: calc(100% + 1px);
    left: 0;
    z-index: 1;

    background-color: #fff;
    box-shadow: 0 0 rem(24px) 0 rgba(6, 7, 7, 0.1);

    .items {
        max-height: rem(238px);

        &::v-deep .scrollbar {
            &-track {
                right: 2px;

                width: rem(2px);

                background: none;
            }

            &-thumb {
                width: inherit;

                background: $grey-dark;
            }
        }
    }

    .item {
        display: flex;
        align-items: center;

        padding: rem(2px) rem(8px);

        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                background-color: $hover-background;
            }
        }

        .avatar {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            width: rem(30px);
            height: rem(30px);
            margin-right: rem(6px);
            overflow: hidden;

            font-size: rem(12px);
            color: #fff;

            background: $blue;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        .name {
            font-size: rem(14px);
        }
    }
}
</style>

