<template lang="pug">
    editor-mobile-header-menu.editor-mobile-header-move-to(ref="menu")
        .list
            .item(
                v-for="folder in folders"
                @click="moveTo(folder.id)"
            ) {{ folder.name }}
            .list__empty(v-if="!folders.length") Nowhere to move
</template>

<script>
import EditorMobileHeaderMenu from '@/components/editor/header/EditorMobileHeaderMenu';
import { updateDocument } from '@/js/api/requests/document';
import { getAllFolders } from '@/js/api/requests/folder';
import { ENTITY_TYPES } from '@/js/const';

export default {
    name: 'editor-mobile-header-move-to',

    components: {
        EditorMobileHeaderMenu,
    },

    props: {
        document: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        folders: [],
    }),

    methods: {
        async open() {
            const folders = await getAllFolders();
            this.folders = folders.filter(f => ENTITY_TYPES[this.document.entity_type] !== 'folder' || f.id !== this.document.entity_id);
            this.$refs.menu.open();
        },

        close() {
            this.$refs.menu.close();
        },

        moveTo(entityId) {
            this.$confirm({
                message: `Are you sure?`,
                button: { no: 'No', yes: 'Yes' },
                callback: async confirm => {
                    if (confirm) {
                        const entityType = Object.keys(ENTITY_TYPES).find(t => ENTITY_TYPES[t] === 'folder');
                        try {
                            await updateDocument(
                                this.document.id,
                                {
                                    entity_type: entityType,
                                    entity_id: entityId,
                                },
                            );
                            this.document.entity_type = entityType;
                            this.document.entity_id = entityId;
                            this.$awn.success('The document was moved successfully');
                        } catch (e) {
                            this.$awn.alert('Something went wrong, please try again later');
                        }
                    }
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header-move-to {
    &::v-deep .content {
        min-height: 30vh;
    }

    .item {
        &:not(:last-child) {
            margin-bottom: rem(32px);
        }

        font-size: rem(18px);
        font-weight: 500;
    }
}
</style>
