<template lang="pug">
    component.app-button(
        :is="tag"
        :class="classes"
        v-bind="attributes"
        v-on="listeners"
    )
        slot
        loader.app-button__loader
</template>

<script>
import loader from '@/assets/images/loader.svg?inline';

export default {
    name: 'app-button',

    components: {
        loader,
    },

    props: {
        tag: {
            type: String,
            default: 'button',
        },

        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },

        loading: {
            type: Boolean,
            default: false,
            required: false,
        },

        block: {
            type: Boolean,
            default: false,
            required: false,
        },

        outline: {
            type: Boolean,
            default: false,
            required: false,
        },

        red: {
            type: Boolean,
            default: false,
            required: false,
        },

        grey: {
            type: Boolean,
            default: false,
            required: false,
        },

        small: {
            type: Boolean,
            default: false,
            required: false,
        },

        middle: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    computed: {
        classes() {
            return {
                'app-button--disabled': this.disabled,
                'app-button--loading': this.loading,
                'app-button--full-width': this.block,
                'app-button--style-outline': this.outline,
                'app-button--color-red': this.red,
                'app-button--color-grey': this.grey,
                'app-button--size-sm': this.small,
                'app-button--size-m': this.middle,
            };
        },

        attributes() {
            return this.$attrs;
        },

        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>

