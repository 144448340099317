<template>
    <div class="accept-invite-team"></div>
</template>

<script>


import { acceptMemberReq } from '@/js/api/requests/team';
import { mapActions } from 'vuex';

export default {
    name: 'accept-invite-team',

    computed: {
        user() {
            return this.$store.getters.user;
        },
    },

     created() {
        if (!this.user) {
            this.$store.dispatch('updateTeamInviteId', Number(this.$route.params.id));
            this.$router.replace('/login');
        } else {
             acceptMemberReq({ id: Number(this.$route.params.id) });
             this.$router.replace('/');
        }

    },

    methods: {
        ...mapActions(['updateTeamInviteId']),

    },
};
</script>

<style scoped>

</style>
