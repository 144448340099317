<template lang="pug">
    .choose-department
        arrow-back.arrow-back(@click="$emit('back')")
        form(@submit.prevent="$emit('continue', department)")
            .title Choose your Business Department&#32;
                small (optional)
            app-select(
                v-model="department"
                :options="departmentList"
            )
            app-button Continue
</template>

<script>
import arrowBack from '@/assets/images/arrow_back.svg?inline';

export default {
    name: 'choose-department',

    components: {
        arrowBack,
    },

    data: () => ({
        department: '',
        departmentList: [
            {
                name: 'Marketing',
                key: 'Marketing',
            },
            {
                name: 'Sales',
                key: 'Sales',
            },
            {
                name: 'IT',
                key: 'IT',
            },
            {
                name: 'Legal',
                key: 'Legal',
            },
            {
                name: 'Media',
                key: 'Media',
            },
            {
                name: 'Science',
                key: 'Science',
            },
            {
                name: 'Education',
                key: 'Education',
            },
            {
                name: 'HR',
                key: 'HR',
            },
            {
                name: 'Design',
                key: 'Design',
            },
        ],
    }),
};
</script>

<style scoped lang="scss">
.choose-department {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background: url(../../../../assets/images/choose_department_bg.svg) no-repeat center center / cover #fff;

    .arrow-back {
        position: absolute;
        top: rem(64px);
        left: rem(76px);

        width: rem(40px);

        cursor: pointer;
    }
    form {
        width: rem(600px);
        padding: 2rem 4rem;

        background: #fff;
        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);
    }

    .title {
        margin-bottom: 1.5rem;

        font-size: rem(18px);
        line-height: rem(22px);

        small {
            font-size: rem(16px);
            color: $grey;
        }
    }

    .app-select {
        margin-bottom: 1.5rem;
    }

    .app-button {
        display: flex;

        margin-left: auto;
    }

    @include mobile_or_tablet {
        display: block;

        width: 90%;
        max-width: 100vmin;
        height: initial;
        margin: 0 auto;
        padding: 1rem;

        background: #fff;
        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

        .arrow-back {
            position: static;

            margin-bottom: 2rem;
        }

        form {
            width: 100%;
            padding: 0;

            box-shadow: none;
        }
    }
}
</style>
