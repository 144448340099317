<template lang="pug">
    .editor-link-menu(
        v-if="isOpen"
        ref="linkMenu"
    )
        .editor-link-menu__block
            img.editor-link-menu__favicon(v-if="linkFavicon" :src="linkFavicon")
            a.editor-link-menu__link(:href="link" target="_blank") {{ link }}
            img.editor-link-menu__copy(@click="copy" src="@/assets/images/icons/link-popup/copy.svg")
            img.editor-link-menu__icon.editor-link-menu__edit(@click="edit" src="@/assets/images/icons/link-popup/edit.svg")
            img.editor-link-menu__icon.editor-link-menu__remove-link(@click="removeLink" src="@/assets/images/icons/link-popup/remove-link.svg")
        img.editor-link-menu__miniature(v-if="linkMiniature" :src="linkMiniature" @click="redirect(linkUrl)")
</template>

<script>
import DomElementMixin from '@/mixins/DomElementMixin';
import { getHtmlByUrl } from '@/js/api/requests/other';

export default {
    name: 'editor-link-menu',

    mixins: [DomElementMixin],

    data() {
        return {
            isOpen: false,
            link: '',
            textLength : '',
            domNode: null,
            linkMiniature: null,
            linkFavicon: null,
            indexPosition: 0,
        };
    },

    computed: {
        linkUrl() {
            return this.addHttp(this.link);
        },
        range() {
            return {
                index: this.indexPosition,
                length: this.textLength,
            };
        },
    },

    methods: {
        open(params={}) {
            this.isOpen = true;
            this.link = params.link;
            this.domNode = params.domNode;
            this.indexPosition = params.indexPosition;
            this.textLength = params.textLength;

            this.getFaviconAndMiniature();

            this.$nextTick(() => {
                this.$refs.linkMenu.style.cssText = `top: ${params.top}px; left: ${params.left}px;`;
                document.addEventListener('click', this.clickOutsideHandler);
                this.$refs.linkMenu.style.cssText = `top: ${this.domElNotInViewportTopShift(params.top, this.$refs.linkMenu)}px; left: ${params.left}px;`;
            });
        },

        clickOutsideHandler(e) {
            if (!e.target.closest('.editor-link-menu') && e.target !== this.domNode) {
                this.close();
            }
        },

        redirect(link) {
          location.href = link;
        },

        close() {
            this.isOpen = false;
            this.link = '';
            this.domNode = null;
            document.removeEventListener('click', this.clickOutsideHandler);
        },

        copy() {
            navigator.clipboard.writeText(this.link);
        },

        addHttp(url) {
            if (!/^(?:f|ht)tps?:\/\//.test(url)) {
                url = 'https://' + url;
            }
            return url;
        },

        async getFaviconAndMiniature() {
            this.linkFavicon = null;
            this.linkMiniature = null;

            const html = await getHtmlByUrl({ url: this.linkUrl });

            if (html) {
                const el = document.createElement('html');
                el.innerHTML = html;

                let miniature = el.querySelectorAll( 'head > meta[property="og:image"]');
                if (miniature && miniature[0] && miniature[0].content) {
                    miniature = miniature[0].content;
                } else {
                    miniature = null;
                }

                this.linkMiniature = miniature;

                let faviconUrl = null;
                const urlObj = new URL(this.linkUrl);
                if (urlObj && urlObj.origin) {
                    faviconUrl = urlObj.origin + '/favicon.ico';

                    const img2 = new Image();
                    img2.src = faviconUrl;
                    // eslint-disable-next-line no-unused-vars
                    img2.onload = (im2) => {
                        this.linkFavicon = img2.src;
                    };
                    // eslint-disable-next-line no-unused-vars
                    img2.onerror = (err) => {
                        let faviconUrl2 = el.querySelectorAll('[rel="shortcut icon"]');
                        if (faviconUrl2 && faviconUrl2[0] && faviconUrl2[0].href) {
                            faviconUrl2 = faviconUrl2[0].href;
                        } else {
                            faviconUrl2 = el.querySelectorAll('[rel="icon"]');
                            if (faviconUrl2 && faviconUrl2[0] && faviconUrl2[0].href) {
                                faviconUrl2 = faviconUrl2[0].href;
                            } else {
                                faviconUrl2 = null;
                            }
                        }
                        if (faviconUrl2) {
                            faviconUrl = faviconUrl2;
                        }
                        if (faviconUrl) {
                            const img = new Image();
                            img.src = faviconUrl;
                            img.onload = (im) => {
                                if (im) {
                                    this.linkFavicon = img.src;
                                }
                            };
                        }
                    };
                }
            }
        },

        edit() {
            this.isOpen = false;
            this.$emit('openEditLink', this.range);
        },

        removeLink() {
            this.isOpen = false;
            this.$emit('removeLink', this.range);
        },
    },
};
</script>

<style scoped lang="scss">
.editor-link-menu {
    position: absolute;

    width: rem(300px);
    padding: rem(10px) rem(10px);

    background: #fff;
    border-radius: rem(4px);
    box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

    a {
        display: block;

        width: 100%;
        overflow: hidden;

        font-size: rem(14px);
        line-height: 1.2;
        font-weight: 500;
        color: #06c;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__favicon {
        width: rem(16px);
        height: rem(16px);
        margin-right: rem(10px);
    }

    &__link {
        font-size: rem(10px) !important;
    }

    &__icon {
        width: rem(16px);
        height: rem(16px);
        cursor: pointer;

        margin-right: rem(16px);

        &:last-child {
            margin-right: rem(0px);
        }
    }

    &__edit {

    }

    &__copy {
        width: rem(16px);
        height: rem(16px);
        cursor: pointer;
        margin-right: rem(16px);
        margin-left: rem(16px);
    }

    &__remove {

    }

    &__miniature {
        width: 100%;
        max-height: rem(200px);
        margin-top: rem(10px);
        cursor: pointer;
    }

    &__block {
        display: flex;
        align-items: center;
    }
}
</style>
