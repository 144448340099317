<template lang="pug">
    .payment-method-btn(v-if="card")
        .title Payment Method
        .card
            .card__information Card Information
            .card__text {{ card }}
        br
        app-button.modal-btn(@click="openModal") Edit Payment Method
</template>

<script>
import { cardLast } from '@/js/api/requests/auth';
export default {
    name: 'payment-method-btn',

    data() {
        return {
            card: '',
        };
    },

    async created() {
        try {
            const cardLastData = await cardLast();
            const { brand, last4 } = cardLastData;
            this.updateCard(brand, last4);
        } catch (e) {
            const status = e?.response?.status;
            if (status === 404) {
                this.card = `No credit card`;
            } else {
                this.$awn.alert(`Failed get card`);
                this.card = `Failed get card`;
            }
        }
    },

    methods: {
        updateCard(brand, last4) {
            this.card = `${brand} ending in ${last4}`;
        },
        openModal() {
            this.$modal.show('payment-method-modal', {
                updateCard: (brand, last4) => this.updateCard(brand, last4),
            });
        },
    },
};
</script>

<style scoped lang="scss">
.payment-method-btn {
    margin-top: rem(62px);
    .title {
        line-height: rem(20px);
        font-size: rem(16px);
        font-weight: 500;
        @media (max-width: 1200px) {
            font-size: rem(18px);
            line-height: rem(21px);
        }
    }
    .card {
        margin-top: rem(11px);
        margin-bottom: rem(13px);
        border: 1px solid $blue4;
        box-sizing: border-box;
        border-radius: 6px;
        display: inline-block;
        padding: rem(16px) rem(15px);
        @media (max-width: 1200px) {
            padding: rem(0px);
            border: none;
            margin-top: rem(33px);
            margin-bottom: rem(33px);
        }
        &__text {
            font-size: rem(12px);
            font-weight: 500;
            line-height: rem(15px);

            @media (max-width: 1200px) {
                font-size: rem(18px);
                line-height: rem(21px);
            }
        }
        &__information {
            display: none;
            color: $grey-light8;
            font-size: rem(14px);
            font-weight: 500;
            line-height: rem(17px);
            margin-bottom: rem(10px);

            @media (max-width: 1200px) {
                display: block;
            }
        }
    }
    .modal-btn {
        min-height: auto;
        font-size: rem(12px);
        font-weight: 500;
        padding: rem(7px) rem(8px);
        display: inline-block;
        min-width: auto;

        @media (max-width: 1200px) {
            font-size: rem(14px);
            font-weight: 600;
            padding: rem(10px) rem(7px);
        }
    }
}
</style>
