const fontList = [
    { name: 'Arial', value: 'arial' },
    { name: 'Avenir', value: 'avenir' },
    { name: 'Calibri', value: 'calibri' },
    { name: 'Comfortaa', value: 'comfortaa' },
    { name: 'Comic Sans MS', value: 'comic-sans-ms' },
    { name: 'Courier New', value: 'courier-new' },
    { name: 'EB Garamond', value: 'eb-garamond' },
    { name: 'Georgia', value: 'georgia' },
    { name: 'Garamond', value: 'garamond' },
    { name: 'Helvetica', value: 'helvetica' },
    { name: 'Impact', value: 'impact' },
    { name: 'Lato', value: 'lato' },
    { name: 'Lobster', value: 'lobster' },
    { name: 'Lora', value: 'lora' },
    { name: 'Merriweather', value: 'merriweather' },
    { name: 'Montserrat', value: 'montserrat' },
    { name: 'Monospace', value: 'monospace' },
    { name: 'Nunito', value: 'nunito' },
    { name: 'Oswald', value: 'oswald' },
    { name: 'Playfair Display', value: 'playfair-display' },
    { name: 'PT Sans Narrow', value: 'pt-sans-narrow' },
    { name: 'Roboto', value: 'roboto' },
    { name: 'Roboto Mono', value: 'roboto-mono' },
    { name: 'Spectral', value: 'spectral' },
    { name: 'Sans serif', value: 'sans-serif' },
    { name: 'Times New Roma', value: 'times-new-roma' },
    { name: 'Trebuchet MS', value: 'trebuchet-ms' },
    { name: 'Verdana', value: 'verdana' },
];

const sizeList = [
    { name: '16', value: '1em' },
    { name: '18', value: '1.125em' },
    { name: '20', value: '1.25em' },
    { name: '32', value: '2em' },
    { name: '48', value: '3em' },
];

const paragraphList = [
    {
        name: 'Heading 1',
        value: 1,
    },
    {
        name: 'Heading 2',
        value: 2,
    },
    {
        name: 'Heading 3',
        value: 3,
    },
    {
        name: 'Regular Text',
        value: 0,
    },
];

const alignList = [
    {
        icon: 'format_align_left',
        value: undefined,
        text: 'Left',
    },
    {
        icon: 'format_align_center',
        value: 'center',
        text: 'Center',
    },
    {
        icon: 'format_align_right',
        value: 'right',
        text: 'Right',
    },
    {
        icon: 'format_align_justify',
        value: 'justify',
        text: 'Justify',
    },
];

const typesList = [
    {
        value: 'number-letter',
        html: '<div class="num-l__icon num-l__number-letter"></div>',
    },
    {
        value: 'number-letter-bracket',
        html: '<div class="num-l__icon num-l__number-letter-bracket"> </div>',
    },
    {
        value: 'number-dot',
        html: '<div class="num-l__icon num-l__number-dot"></div>',
    },
    {
        value: 'letter',
        html: '<div class="num-l__icon num-l__letter"></div>',
    },
    {
        value: 'latin-number-letter-number',
        html: '<div class="num-l__icon num-l__latin-number-letter-number"></div>',
    },
    {
        value: 'full-number-letter',
        html: '<div class="num-l__icon num-l__full-number-letter"></div>',
    },
];

const keyboards = {
    up: { keyName: 'up', keyCode: 38 },
    down: { keyName: 'down', keyCode: 40 },
    enter: { keyName: 'enter', keyCode: 'Enter' },
    '@': { keyName: '@', keyCode: 50, options: { shiftKey: true } },
    ';': { keyName: ';', keyCode: ';', keyNameIsValue: true },
    '; SK': { keyName: '; SK', keyCode: ';', keyNameIsValue: true, options: { shiftKey: true } },
    ':': { keyName: ':', keyCode: ':', options: { shiftKey: true }, keyNameIsValue: true },
    '!': { keyName: '!', keyCode: '!', options: { shiftKey: true }, keyNameIsValue: true },
    '?': { keyName: '?', keyCode: '?', options: { shiftKey: true }, keyNameIsValue: true },
    '.': { keyName: '.', keyCode: '.', keyNameIsValue: true },
    '. SK': { keyName: '.  SK', keyCode: '.', options: { shiftKey: true }, keyNameIsValue: true },
    ',': { keyName: ',', keyCode: ',', keyNameIsValue: true },
    ', SK': { keyName: ',  SK', keyCode: ',', options: { shiftKey: true }, keyNameIsValue: true },
};

const mimeTypes = {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    html: 'text/html',
};

export { fontList, sizeList, paragraphList, alignList, typesList, keyboards, mimeTypes };
