<template lang="pug">
    .editor-slash-helper(v-if="isOpen" :style="styles")
        .editor-slash-helper__text Just start typing or type "/" for commands
</template>

<script>
export default {
    name: 'editor-slash-helper',

    data() {
        return {
            isOpen: false,
            styles: {},
        };
    },

    methods: {
        open(params) {
            this.styles = {
                top: `${params.top}px`,
                left: `${params.left}px`,
            };
            this.isOpen = true;
        },

        close() {
            this.isOpen = false;
        },
    },
};
</script>

<style scoped lang="scss">
.editor-slash-helper {
    position: absolute;
    height: rem(20px);

    &__text {
        color: $grey2;
    }
}
</style>
