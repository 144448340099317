<template lang="pug">
    .billings(v-if="billings && billings.length > 0")
        .billings__title Invoice
        table(class="billings__table")
            thead
                tr
                    th(class="billings__th-date") Date
                    th(class="billings__th-plan") Plan
                    th Amount
                    th(class="billings__th-download") Download
            tbody
                tr(v-for="billing in billingsResult" :key="billing.id")
                    td {{ moment(billing.period_start).format('MMM DD, YYYY') }}
                    td {{ billing.tariff_name }}
                    td {{ billing.amount_paid + '$' }}
                    td(class="billings__td-download")
                        a(:href="billing.invoice_pdf")
                            img(src="@/assets/images/icons/download2.svg" alt="")
        .see-more(v-if="haveSeeMoreBtn" @click="seeMore = true") See more
</template>

<script>
import { tariffInvoices } from '@/js/api/requests/plans';
import moment from 'moment';

export default {
    name: 'billings',

    data() {
        return {
            billings: [],
            loading: false,
            seeMore: false,
        };
    },

    computed: {
        haveSeeMoreBtn() {
            return !this.seeMore && this.billings.length > 2;
        },
        billingsResult() {
            if (this.haveSeeMoreBtn) {
                return [{ ...this.billings[0] }, { ...this.billings[1] }];
            }
            return [...this.billings];
        },
    },

    created() {
        this.getInvoices();
    },

    methods: {
        async getInvoices() {
            if (this.loading) return;

            this.loading = true;

            try {
                this.billings = await tariffInvoices();
            } catch (e) {
                console.log(e);
                this.$awn.alert('Fail get invoices');
            } finally {
                this.loading = false;
            }
        },
        moment,
    },
};
</script>

<style scoped lang="scss">
@import './src/assets/scss/other/billing.scss';
</style>
