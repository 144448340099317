import Vue from 'vue';
import VueAWN from 'vue-awesome-notifications';
let options = { icons: {
        prefix: '<img src="',
        tip: require('@/assets/images/icons/awn/tip.svg'),
        success: require('@/assets/images/icons/awn/success.svg'),
        warning: require('@/assets/images/icons/awn/warning.svg'),
        alert: require('@/assets/images/icons/awn/alert.svg'),
        async: require('@/assets/images/icons/awn/async.svg'),
        suffix: '"/>',
    },
};
Vue.use(VueAWN, options);
import 'vue-awesome-notifications/dist/styles/style.css';


