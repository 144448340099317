<template lang='pug'>
    .editor-header.noprint
        .top-panel
            router-link.goBack-icon(
                v-if="!isPublicLink" event="" to="/"
            )
                img(@click="goBackPage" src="@/assets/images/icons/goBack.svg")

            .titleDocument(
                v-if="isPublicLink && publicLinkPermissions.includes('view_title')"
            ) {{ document.icon }} {{ document.name }}

            .left
                app-breadcrumbs(
                    v-if="!isPublicLink"
                    class="app-breadcrumbs--theme-editor"
                    noIcons
                    @clickBreadcrumb="clickEntityHandler"
                    :items="getEditorBreadcrumbs(document)"
                )

            .right
                editor-header-search

                editor-header-share.editor-header-share(
                    v-if="!isPublicLink"
                    :document="document"
                )

                div.chat-button(
                    v-if="!isPublicLink"
                    @click="$refs.chat.toggleChat()"
                )
                    img(src='@/assets/images/editor/icons/chat.svg')
                    div.unreadChatMessages(v-if="hasUnreadChatMessages")

                app-button.options-button(
                    v-if="!isPublicLink"
                    @click.stop="$refs.optionsContext.open"
                ) Options

                editor-header-duplicate.editor-header-duplicate(
                    v-if="isPublicLink && publicLinkPermissions.includes('duplicate')"
                    :document="document"
                )

                editor-header-report(
                    v-if="isPublicLink && !isLoggedUser"
                )

                editor-header-try-reskript(
                    v-if="isPublicLink && !isLoggedUser"
                )

        context-menu(
            ref="optionsContext"
            :items="['share', 'download', 'saveVersion', 'versionHistory', 'delete']"
            @share="$modal.show('editor-invite-popup', {document: document, invitedUsers: documentUsers })"
            @download="openExportDocumentContext"
            @saveVersion="$emit('saveVersion')"
            @versionHistory="$emit('openSnapshots')"
            @delete="deleteDoc"
        )

        move-to-folders-context(ref="moveToFoldersContext")
        export-document-context(ref="exportDocumentContext")

        emoji-modal(
            ref="emojiModal"
            @insertEmoji="$emit('insertEmoji', $event)"
        )

        chat(
            v-if="!isPublicLink"
            ref="chat"
            chat-type="document"
            :entity-id="document.id"
            :entity="document"
        )
</template>

<script>
import { mapState } from 'vuex';
import EditorHeaderRenameInput from '@/components/editor/header/EditorHeaderRenameInput';
import EditorHeaderSearch from '@/components/editor/header/EditorHeaderSearch';
import EditorHeaderTableSizeSelector from '@/components/editor/header/EditorHeaderTableSizeSelector';
import EditorHeaderShare from '@/components/editor/header/EditorHeaderShare';
import EditorHeaderDuplicate from '@/components/editor/header/EditorHeaderDuplicate';
import EditorHeaderTryReskript from '@/components/editor/header/EditorHeaderTryReskript';
import EditorHeaderReport from '@/components/editor/header/EditorHeaderReport';
import EmojiModal from '@/components/other/EmojiModal';
import VueFileToolbarMenu from 'vue-file-toolbar-menu';
import MoveToFoldersContext from '@/components/other/MoveToFoldersContext';
import ExportDocumentContext from '@/components/other/ExportDocumentContext';
import Chat from '@/components/other/chat/index';
import { fontList, sizeList, alignList, paragraphList } from '@/js/editor/const';
import { moveDocumentsToBin } from '@/js/api/requests/document';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import DownloadDocumentMixin from '@/mixins/DownloadDocumentMixin';
import BreadcrumbsMixin from '@/mixins/BreadcrumpsMixin';
import childrenMixin from '@/mixins/ChildrenMixin';

export default {
    name: 'editor-header',

    components: {
        EditorHeaderShare,
        EditorHeaderDuplicate,
        EditorHeaderTryReskript,
        EditorHeaderReport,
        EditorHeaderRenameInput,
        Chat,
        MoveToFoldersContext,
        ExportDocumentContext,
        EditorHeaderSearch,
        EditorHeaderTableSizeSelector,
        EmojiModal,
        VueFileToolbarMenu,
    },

    mixins: [CreateDocumentMixin, DownloadDocumentMixin, BreadcrumbsMixin, childrenMixin],

    props: {
        document: {
            type: Object,
            required: true,
        },

        format: {
            type: Object,
            required: true,
        },

        mode: {
            type: String,
            required: true,
        },

        users: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapState({
            isPublicLink: state => state.editor.isPublicLink,
            publicLinkPermissions: state => state.editor.permissions,
            isLoggedUser: state => state.auth.user.id !== 'anonymous',
            hasUnreadChatMessages: state => state.auth.hasUnreadChatMessages,
        }),

        fontMenu() {
            return fontList.map(font => ({
                html: '<span class="ellipsis" style="font-family:'+font.name+'">'+font.name+'</span>',
                active: this.currentFont.value === font.value,
                click: () => {
                    this.$emit('formatting', { font: font.value });
                },
            }));
        },

        sizeMenu() {
            return sizeList.map(size => ({
                html: '<span>'+size.name+'</span>',
                active: this.currentSize.value === size.value,
                click: () => {
                    this.$emit('formatting', { size: size.value });
                },
            }));
        },

        paragraphMenu() {
            return paragraphList.map(p => ({
                text: p.name,
                class: 'no-icon',
                active: this.format.header === p.value || (!this.format.header && p.value === 0),
                click: () => {
                    this.$emit('formatting', { header: this.format.header !== p.value ? p.value : false });
                },
            }));
        },

        currentFont() {
            return fontList.find(f => this.format.font === f.value) || fontList[0];
        },

        currentSize() {
            return sizeList.find(s => this.format.size === s.value) || sizeList[0];
        },

        currentAlign() {
            return alignList.find(align => this.format.align === align.value);
        },

        documentUsers() {
            return this.document?.users || [];
        },
    },

    methods: {
        goBackPage() {
            this.$router.go(-1);
        },

        async openExportDocumentContext(e) {
            this.$refs.exportDocumentContext.open(e, this.document);
        },

        deleteDoc() {
            this.$confirm({
                message: `Are you sure?`,
                button: { no: 'No', yes: 'Yes' },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await moveDocumentsToBin(this.document.id);
                            this.$router.push({ name: 'Dashboard' });
                        } catch (e) {
                            if (e.response.status === 403) {
                                this.$awn.alert('You cannot delete this document');
                            } else {
                                this.$awn.alert('Something went wrong, please try again later');
                            }
                        }
                    }
                },
            });
        },

        moveTo(e) {
            this.$refs.moveToFoldersContext.open(e, this.document);
            this.$refs.moveToFoldersContext.$off('moveSuccess');
            this.$refs.moveToFoldersContext.$on('moveSuccess', ({ entityType, entityId }) => {
                this.document.entity_type = entityType;
                this.document.entity_id = entityId;
            });
        },
    },
};
</script>

<style scoped lang='scss'>
.editor-header {
    position: sticky;
    top: 0;
    z-index: 1000;

    background: $white;

    .emoji-modal {
        position: fixed;
    }

    .chat {
        position: absolute;
        top: calc(100% + #{rem(24px)});
        right: rem(40px);

        width: rem(430px);
        max-height: calc(100vh - #{rem(153px)} - #{rem(24px)} - #{rem(80px)});
    }

    &::v-deep {
        .num-l {
            .active-type-list {
                box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);
            }
            .bar-menu-items {
                display: flex;
                flex-wrap: wrap;
                padding: rem(10px);
                width: rem(290px);
                border-radius: rem(10px);
            }
            .bar-menu-item {
                flex: 0 0 rem(80px);
                padding: 0px !important;
                margin: rem(5px);
            }
            .bar-menu-item.active {
                background: transparent !important;
                .num-l__icon {
                    box-shadow: 0 0 rem(7px) rgba(0, 0, 0, 0.7);
                    border-radius: 10px;
                }
            }
            &__icon {
                width: rem(80px);
                height: rem(80px);
            }
            &__number-letter {
                background: url(../../../assets/images/icons/lists/number-letter.svg) no-repeat center center;
            }
            &__number-letter-bracket {
                background: url(../../../assets/images/icons/lists/number-letter-bracket.svg) no-repeat center center;
            }
            &__number-dot {
                background: url(../../../assets/images/icons/lists/number-dot.svg) no-repeat center center;
            }
            &__letter {
                background: url(../../../assets/images/icons/lists/letter.svg) no-repeat center center;
            }
            &__latin-number-letter-number {
                background: url(../../../assets/images/icons/lists/latin-number-letter-number.svg) no-repeat center center;
            }
            &__full-number-letter {
                background: url(../../../assets/images/icons/lists/full-number-letter.svg) no-repeat center center;
            }
        }
    }
}

.top-panel {
    display: flex;
    align-items: center;

    width: 100%;
    padding: rem(20px) rem(34px) rem(10px) rem(22px);

    .titleDocument {
        font-size: 14px;
        font-weight: 500;
    }

    .editor-header-rename-input {
        margin-bottom: rem(16px);
    }

    .left {
        margin-left: rem(18px);

        .app-breadcrumbs--theme-editor {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            span {
                cursor: pointer !important;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;

        margin-left: auto;

        .options-button {
            font-size: 12px;
            max-height: 27px;
            background-color: $blue8;
        }
    }

    &::v-deep {
        .bar-button {
            margin-right: rem(10px);
        }

        .bar-menu-items {
            width: rem(240px);

            .active {
                background: transparent;
            }
        }

        .bar-menu-item {
            &.no-icon > .label {
                padding-left: 34px;
            }

            .icon {
                width: 24px;
                margin-right: 10px;
            }
        }
    }

    .camera-button {
        width: rem(50px);
        margin-right: rem(30px);

        cursor: pointer;
    }

    .editor-header-users {
        margin-right: rem(26px);
    }

    .editor-header-share, .editor-header-duplicate {
        margin: 0 20px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
    }

    .editor-header-duplicate {
        margin: 0 0 0 20px;
    }

    .chat-button {
        position: relative;
        width: rem(30px);
        margin-right: rem(20px);
        cursor: pointer;
    }

    .unreadChatMessages {
        position: absolute;
        top: -0.20rem;
        right: -0.20rem;
        width: rem(10px);
        height: rem(10px);
        background: $red;
        border-radius: 50%;
        pointer-events: none;
    }
}

.toolbar {
    display: flex;
    justify-content: center;
    align-items: center;

    height: rem(46px);
    padding: rem(3px) 0;

    &::v-deep {
        .bar-font {
            width: rem(100px);
            overflow: hidden;

            line-height: 1.5;
            text-overflow: ellipsis;
        }

        .bar-size {
            width: rem(20px);
        }

        .bar-color {
            display: flex;
            flex-direction: column;
            align-items: center;

            &:before {
                content: 'A';

                display: block;

                width: rem(24px);
                height: rem(18px);

                font-size: rem(20px);
                text-align: center;
            }

            .color-square {
                width: rem(20px);
                height: rem(8px);
            }
        }

        .bar-separator {
            width: 1px;
            margin: 0 rem(12px);
        }

        .bar-button {
            margin: 0 1px;

            .icon {
                width: rem(24px);

                font-size: rem(24px);

                &.undo, &.redo {
                    width: rem(28px);
                }

                &.bold {
                    width: rem(20px);
                }

                &.italic {
                    height: rem(20px);
                }
            }
        }
    }
}

@media print {
    .noprint {
        display:none;
    }
}
</style>
