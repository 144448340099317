import { render, staticRenderFns } from "./noDocs.vue?vue&type=template&id=96982448&scoped=true&"
import script from "./noDocs.vue?vue&type=script&lang=js&"
export * from "./noDocs.vue?vue&type=script&lang=js&"
import style0 from "./noDocs.vue?vue&type=style&index=0&id=96982448&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96982448",
  null
  
)

export default component.exports