<template lang="pug">
    .accept-invite-email
</template>

<script>
import { acceptInvite } from '@/js/api/requests/common';

export default {
    name: 'accept-invite-email',

    computed: {
        user() {
            return this.$store.getters.user;
        },
    },

    async created() {
        const invite_id = this.$route.params.token;

        try {
            let entity = await acceptInvite({ invite_id });

            if (entity.data.entity_type === 'document') {
                this.$router.replace(`/editor/${entity.data.entity_id}`);
            } else if (entity.data.entity_type === 'project') {
                this.$router.replace(`/projects/${entity.data.entity_id}`);
            } else if (entity.data.entity_type === 'room') {
                this.$router.replace(`/rooms/${entity.data.entity_id}`);
            }
        } catch (e) {
            this.$awn.alert('Something went wrong, please try again later');
        }
    },
};
</script>

