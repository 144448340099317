import { updateDocument } from '@/js/api/requests/document';

class PreviewDocument {
    document = null

    constructor({ document }) {
        this.document = document;
    }

    async makePreview (html2canvas) {
        const editor = document.getElementsByClassName('ql-editor')[0];
        const parentForClone = document.getElementById('editor');
        let cloneEditor = null;

        try {
            cloneEditor = editor.cloneNode(true);
            this.#prepateDocument(cloneEditor);
        } catch (err) {
            return err;
        }

        parentForClone.appendChild(cloneEditor);
        cloneEditor.classList.add('previewImage');

        let imgBase64 = await html2canvas(cloneEditor, {
            logging: false,
            type: 'dataURL',
            height: 439,
        });

        parentForClone.removeChild(cloneEditor);

        function dataURLtoFile(dataurl, filename) {
            let arr = dataurl?.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        }

        let file = dataURLtoFile(imgBase64, `${this.document.name}.png`);

        let fd = new FormData;
        fd.append('image', file);

        await updateDocument(this.document.id, fd);
    }

    #prepateDocument (document) {
        const olLists = document.querySelectorAll('ol');

        olLists.forEach(olList => {
            if (!this.isOrderedList(olList)) {
                return;
            }

            const counters = { decimal: 1, alpha: 'a', roman: 1 };
            const listItems = olList.querySelectorAll('li');

            listItems.forEach(listItem => {
                const span = listItem.querySelector('span');
                span.textContent = this.counterValue(this.typeList(listItem), counters);
                span.classList = 'ql-ordered-list';
            });
        });
    }

    isOrderedList (olList) {
        const dataAttr = olList.querySelector('li').dataset;

        return dataAttr['list'] === 'ordered';
    }

    counterValue (typeList, counters) {
        if (typeList === 'decimal') {
            return `${counters.decimal++}.`;
        }

        if (typeList === 'lower-alpha') {
            const currentValue = counters.alpha;
            counters.alpha = String.fromCharCode(currentValue.charCodeAt() + 1);

            return `${currentValue}.`;
        }

        if (typeList === 'lower-roman') {
            return `${this.romanize(counters.roman++)}.`;
        }
    }

    typeList (listItem) {
        const className = listItem.classList.value;

        if (['', 'ql-indent-3', 'ql-indent-6'].includes(className)) {
            return 'decimal';
        }
        if (['ql-indent-1', 'ql-indent-4', 'ql-indent-7'].includes(className)) {
            return 'lower-alpha';
        }
        if (['ql-indent-2', 'ql-indent-5', 'ql-indent-8'].includes(className)) {
            return 'lower-roman';
        }
    }

    romanize (num) {
        const digits = String(num).split('');
        const key = [
            '','C','CC','CCC','CD','D','DC','DCC','DCCC','CM','','X','XX','XXX','XL','L',
            'LX','LXX','LXXX','XC','','I','II','III','IV','V','VI','VII','VIII','IX',
        ];
        let roman = '';
        let i = 3;

        while (i--) {
            roman = (key[+digits.pop() + (i * 10)] || '') + roman;
        }

        return Array(+digits.join('') + 1).join('M') + roman;
    }
}

export default PreviewDocument;
