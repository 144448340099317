<template lang="pug">
    Vue100vh.welcome-layout
        slot
        img.welcome-layout__logo(v-if="showLogo" src="@/assets/images/logo.svg" alt="ReSkript")

</template>

<script>
import Vue100vh from 'vue-div-100vh';

export default {
    name: 'welcome-layout',

    components: {
        Vue100vh,
    },

    computed: {
        showLogo() {
            return this.$route.name !== 'Login';
        },
    },
};
</script>
