<template lang="pug">
    .editor-invite-popup-copy-link
        .copy-text(@click="toggleList")
            img(src="@/assets/images/icons/copy_link.svg")
            | Copy Link
        accesses-context(
            v-if="listOpened"
            type="document"
            :documentId="document.id"
            @input="doCopy"
            v-click-outside="closeList"
        )
</template>

<script>
import AccessesContext from '@/components/other/AccessesContext';

export default {
    name: 'editor-invite-popup-copy-link',

    components: {
        AccessesContext,
    },

    inject: {
        copyLink: { default: null },
    },

    props: {
        document: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        listOpened: false,
    }),

    methods: {
        doCopy(access) {
            if (this.copyLink) {
                this.copyLink(access);
            }
        },

        toggleList() {
            if (this.listOpened) {
                this.closeList();
            } else {
                setTimeout(() => {
                    this.listOpened = true;
                });
            }
        },

        closeList() {
            this.listOpened = false;
        },
    },
};
</script>

<style scoped lang="scss">
.editor-invite-popup-copy-link {
    position: relative;

    .copy-text {
        display: flex;
        align-items: center;

        font-size: rem(14px);

        cursor: pointer;

        img {
            width: 1.25rem;
            margin-right: rem(6px);
        }
    }

    .accesses-context {
        position: absolute;
        right: 0;
        bottom: calc(100% + #{rem(4px)});
    }
}
</style>
