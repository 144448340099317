<template lang="pug">
    plans(:btnClick="btnClick" :btnClasses="btnClasses" :btnDisabled="btnDisabled" :btnText="btnText")
</template>

<script>
import Plans from '@/components/other/plans/Plans';
import { PLAN_PERIOD, PLANS_NAMES } from '@/js/const';

export default {
    name: 'plans-for-profile',

    components: {
        Plans,
    },

    computed: {
        planName() {
            return this.$store.getters.planName;
        },
        planPeriod() {
            return this.$store.getters.planPeriod;
        },
        planPeriodSwitcher() {
            return this.$store.getters.planPeriodSwitcher;
        },
    },

    methods: {
        async btnClick(plan) {
            if (plan.name === PLANS_NAMES.PRO) {
                this.$store.commit('setPlanSelectedForCheckout', { name: plan.name + '_' + this.planPeriodSwitcher });
                this.$modal.hide('settings'); //возможно, компонент будет не ток в модалке,но это не мешает
                this.$router.push({ name: 'Checkout' });
            } else if (plan.name === PLANS_NAMES.PERSONAL) {
                this.$modal.show('cancel-tariff-modal');
            }
        },
        // eslint-disable-next-line no-unused-vars
        btnClasses(plan) {
            if (this.btnDisabled(plan)) {
                return '';
            }
            if (this.btnText(plan) === 'Current') {
                return 'app-button--grey2';
            }
        },
        btnDisabled(plan) {
            // отключаем когда  тек план и план - это персонал
            // либо когда текущий план ПРО на год, а план ПРо на месяц
            // либо когда тек план ПРО на месяц и план про на месяц
            // либо когда тек план ПРО на год и план про на год
            return (this.planName === PLANS_NAMES.PERSONAL && plan.name === PLANS_NAMES.PERSONAL)
                || (
                    this.planName === PLANS_NAMES.PRO && this.planPeriod === PLAN_PERIOD.ANNUAL
                    && plan.name === PLANS_NAMES.PRO && this.planPeriodSwitcher === PLAN_PERIOD.MONTH
                )
                || (
                    this.planName === PLANS_NAMES.PRO && this.planPeriod === PLAN_PERIOD.MONTH
                    && plan.name === PLANS_NAMES.PRO && this.planPeriodSwitcher === PLAN_PERIOD.MONTH
                )
                || (
                    this.planName === PLANS_NAMES.PRO && this.planPeriod === PLAN_PERIOD.ANNUAL
                    && plan.name === PLANS_NAMES.PRO && this.planPeriodSwitcher === PLAN_PERIOD.ANNUAL
                );
        },
        btnText(plan) {
            if (plan.name === PLANS_NAMES.PERSONAL) {
                if (this.planName === plan.name) {
                    return 'Current';
                }
                return 'Switch to personal';
            }
            if (plan.name === PLANS_NAMES.PRO) {
                if (this.planName === PLANS_NAMES.PRO && this.planPeriod === PLAN_PERIOD.MONTH && this.planPeriodSwitcher === PLAN_PERIOD.ANNUAL) {
                    return 'Switch to annual';
                }
                if (
                    this.planName === PLANS_NAMES.PERSONAL
                    || (this.planName === PLANS_NAMES.PRO && this.planPeriod === PLAN_PERIOD.ANNUAL && this.planPeriodSwitcher === PLAN_PERIOD.MONTH)
                ) {
                    return 'Upgrade';
                }
                if (this.planName === PLANS_NAMES.PRO && this.planPeriod === this.planPeriodSwitcher) {
                    return 'Current';
                }
            }
            return '';
        },
    },
};
</script>
