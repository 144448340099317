import { DOCUMENT_ACCESSES } from '@/js/const';
import { shareInvite } from '@/js/api/requests/common';

export default {
    computed: {
        user() {
            return this.$store.getters.user;
        },
    },
    methods: {
        getInviteLink(accessType, docId) {
            if (!this.forInviteInline) {
                this.activeAccess = DOCUMENT_ACCESSES[accessType];
            } else {
                shareInvite({
                    entity_id: docId,
                    inviter_name: this.user.username,
                    entity_type: 'document',
                    role: DOCUMENT_ACCESSES[accessType].settings,
                }).then((data) => {
                    navigator.clipboard.writeText(data.link);
                    this.$awn.success('Invite link successfully copied');
                });
            }
        },
    },
};
