<template lang="pug">
    editor-mobile-header-menu.editor-mobile-header-actions(
        ref="menu"
        @close="closeHandler"
    )
        .top
            .title Actions
            .done-button(@click="close") Done
        .list
            .item(
                v-for="action in actions"
                @click="action.click"
            )
                .icon
                    img(:src="action.icon")
                .text {{ action.text }}
</template>

<script>
import EditorMobileHeaderMenu from '@/components/editor/header/EditorMobileHeaderMenu';

export default {
    name: 'editor-mobile-header-actions',

    components: {
        EditorMobileHeaderMenu,
    },

    data: () => ({
        format: {},
    }),

    computed: {
        actions() {
            return [
                {
                    icon: require('@/assets/images/editor/icons/checkbox.svg'),
                    text: (this.format.list === 'unchecked' || this.format.list === 'checked') ? 'Remove checkbox' : 'Add checkbox',
                    active: this.format.list === 'unchecked' || this.format.list === 'checked',
                    click: () => {
                        this.changeFormat('list', this.format.list !== 'unchecked' && this.format.list !== 'checked' ? 'unchecked' : false );
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/text_formatting.svg'),
                    text: 'Text formatting',
                    click: () => {
                        this.$emit('openTextFormattingMenu', this.format);
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/undo-mobile.svg'),
                    text: 'Undo',
                    click: () => {
                        this.$emit('undo');
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/redo-mobile.svg'),
                    text: 'Redo',
                    click: () => {
                        this.$emit('redo');
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/camera.svg'),
                    text: 'Start Video Call',
                    click: () => {
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/chat.svg'),
                    text: 'Chat',
                    click: () => {
                        this.$emit('openChat');
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/download.svg'),
                    text: 'Download',
                    click: () => {
                        this.$emit('download');
                    },
                },
                {
                    icon: require('@/assets/images/editor/icons/move_to-mobile.svg'),
                    text: 'Move to',
                    click: () => {
                        this.$emit('moveTo');
                    },
                },
            ];
        },
    },

    methods: {
        open(format) {
            this.format = format;
            this.$refs.menu.open();
        },

        close() {
            this.$refs.menu.close();
        },

        closeHandler() {
            this.$emit('formatting', this.format);
        },

        changeFormat(name, value) {
            this.$set(this.format, name, value);
        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header-actions {
    .top {
        position: relative;

        margin-bottom: rem(43px);

        .title {
            font-size: rem(24px);
            font-weight: 600;
            text-align: center;
        }

        .done-button {
            position: absolute;
            top: 50%;
            right: 0;

            font-size: rem(18px);
            font-weight: 500;
            color: $blue;

            transform: translateY(-50%);
        }
    }

    .item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: rem(32px);
        }

        .icon {
            width: rem(40px);
            height: rem(24px);
            margin-right: rem(12px);

            img {
                width: 100%;
                height: 100%;

                object-fit: contain;
            }
        }

        .text {
            font-size: rem(18px);
            font-weight: 500;
        }
    }
}
</style>
