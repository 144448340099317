import { getComments, getCommentMessages } from '@/js/api/requests/editor';

class Comments {
    editor = null
    document = null
    editorContainer = null
    commentPopup = null
    userSocket = null
    emittingUserInfo = null
    awn = null
    quil = null
    viewComments = null

    constructor ({ editor, document, editorContainer, commentPopup, userSocket, emittingUserInfo, awn, quil, viewComments }) {
        this.editor = editor;
        this.document = document;
        this.editorContainer = editorContainer;
        this.commentPopup = commentPopup;
        this.userSocket = userSocket;
        this.emittingUserInfo = emittingUserInfo;
        this.awn = awn;
        this.quil = quil;
        this.viewComments = viewComments;
    }

    async initComments () {
        const commentTexts = Array.from(document.querySelectorAll('.ql-comment-text'))
            .reduce((total, c) => {
                if (total.some(t => t.getAttribute('data-comment-id') === c.getAttribute('data-comment-id'))) {
                    return total;
                }
                return total.concat(c);
            }, []);

        commentTexts.forEach(c => {
            const commentId = c.getAttribute('data-comment-id');

            const commentIndicator = document.createElement('div');
            commentIndicator.classList.add('ql-comment-indicator');
            commentIndicator.setAttribute('data-comment-id', commentId);

            commentIndicator.addEventListener('click', async () => {
                const comment = await getCommentMessages(commentId);

                const fragment = Array.from(document.querySelectorAll(`.ql-comment-text[data-comment-id="${commentId}"]`))
                    .map(c => c.innerText)
                    .join('');

                this.commentPopup.open({
                    top: commentIndicator.offsetTop,
                    commentId,
                    fragment,
                    messages: comment.messages,
                });

                this.addEventListeners(commentId);
            });

            if (this.viewComments) {
                this.editorContainer.appendChild(commentIndicator);
            }
        });

        await this.updateCommentsMessagesCount();
        this.updateCommentsPositionsAndExistence();
    }

    async updateCommentsMessagesCount() {
        const comments = await getComments(this.document.id);

        const indicators = document.querySelectorAll('.ql-comment-indicator');

        indicators.forEach(i => {
            const commentId = i.getAttribute('data-comment-id');
            i.innerHTML = comments.find(c => c._id === commentId)?.message_count || 0;
        });
    }

    updateCommentsPositionsAndExistence () {
        const indicators = Array.from(document.querySelectorAll('.ql-comment-indicator'));

        indicators.forEach((indicator, index) => {
            const commentId = indicator.getAttribute('data-comment-id');
            const commentText = document.querySelector(`.ql-comment-text[data-comment-id="${commentId}"]`);

            if (!commentText) {
                return;
            }

            const commentsInCurrentLine = indicators.slice(0, index).filter(t => {
                return t.offsetTop > commentText.offsetTop - 20 && t.offsetTop < commentText.offsetTop + 20;
            });

            indicator.classList.remove('hidden'); // Если удалили и отменили изменения

            let top = commentText.offsetTop;
            let marginLeft = 0;

            if (commentsInCurrentLine.length > 0) {
                top = Math.min(...commentsInCurrentLine.map(c => c.offsetTop), top);
                marginLeft = commentsInCurrentLine.length * 3;
            }

            indicator.style.top = `${top}px`;
            indicator.style.marginLeft = `${marginLeft}rem`;
        });
    }

    insertComment (range) {
        if (!range || !range.length) {
            this.awn.tip('Please select text');
            return;
        }

        const bounds = this.editor.getBounds(range);
        const fragment = this.editor.getText(range);

        this.commentPopup.open({
            top: bounds.top,
            fragment,
        });

        this.editor.formatText(range, 'comment-text-selected', true);

        this.commentPopup.$on('close', () => {
            this.editor.formatText(range, 'comment-text-selected', false);
        });

        this.commentPopup.$off('send');
        this.commentPopup.$on('send', message => {
            this.userSocket.emit('addComment', {
                documentId: this.document.id,
                owner: this.emittingUserInfo,
                fragment,
                message,
            });

            this.userSocket.on('createdComment', comment => {
                this.editor.formatText(range, 'comment-text', { id: comment._id }, 'application');
                this.userSocket.removeAllListeners('createdComment');

                this.commentPopup.addMessage(comment);
                this.commentPopup.updateCommentId(comment._id);

                this.addEventListeners(comment._id);
            });
        });
    }

    clearRangeText (commentId) {
        const indicator = document.querySelector(
            `.ql-comment-indicator[data-comment-id="${commentId}"]`,
        );

        indicator?.parentElement.removeChild(indicator);

        if (this.commentPopup.isOpen && this.commentPopup.commentId === commentId) {
            this.commentPopup.close();
        }

        const commentElements = document.querySelectorAll(
            `.ql-comment-text[data-comment-id="${commentId}"]`,
        );

        commentElements.forEach(elem => {
            const blot = this.quil.find(elem);
            const range = {
                index: this.editor.getIndex(blot),
                length: blot.length(),
            };
            this.editor.formatText(range, 'comment-text', false, 'application');
        });
    }

    addEventListeners (commentId) {
        this.commentPopup.$off(['resolveComment']);
        this.commentPopup.$on('resolveComment', () => {
            this.userSocket.emit('editComment', {
                commentId,
                documentId: this.document.id,
                resolved: true,
            });
        });

        this.commentPopup.$off(['deleteComment']);
        this.commentPopup.$on('deleteComment', () => {
            this.userSocket.emit('deleteComment', {
                documentId: this.document.id,
                commentId,
            });
        });

        this.commentPopup.$off(['send']);
        this.commentPopup.$on('send', message => {
            this.userSocket.emit('addMessage', {
                commentId,
                documentId: this.document.id,
                owner: this.emittingUserInfo,
                message,
            });
        });

        this.commentPopup.$off(['editMessage']);
        this.commentPopup.$on('editMessage', message => {
            this.userSocket.emit('editMessage', {
                documentId: this.document.id,
                message,
            });
        });

        this.commentPopup.$off(['deleteMessage']);
        this.commentPopup.$on('deleteMessage', message => {
            this.userSocket.emit('deleteMessage', {
                documentId: this.document.id,
                message,
            });
        });
    }

    addUserSocketListeners () {
        this.userSocket.on('newComment', () => {
            setTimeout(() => {
                const indicators = document.querySelectorAll('.ql-comment-indicator');

                indicators.forEach(i => {
                    i.parentElement.removeChild(i);
                });

                this.initComments();
            }, 100);
        });

        this.userSocket.on('newMessage', message => {
            if (this.commentPopup.isOpen && this.commentPopup.commentId === message.comment_id) {
                this.commentPopup.addMessage(message);
            }
            this.updateCommentsMessagesCount();
        });

        this.userSocket.on('editedMessage', message => {
            if (this.commentPopup.isOpen && this.commentPopup.commentId === message.comment_id) {
                this.commentPopup.updateMessage(message);
            }
        });

        this.userSocket.on('deletedMessage', message => {
            if (this.commentPopup.isOpen && this.commentPopup.commentId === message.comment_id) {
                this.commentPopup.removeMessage(message);

                if (!this.commentPopup.messages.length) {
                    this.clearRangeText(message.comment_id);
                }
            }
            this.updateCommentsMessagesCount();
        });

        this.userSocket.on('resolvedComment', ({ commentId }) => {
            this.clearRangeText(commentId);
        });

        this.userSocket.on('deletedComment', ({ commentId }) => {
            this.clearRangeText(commentId);
        });
    }
}

export default Comments;
