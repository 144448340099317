<template lang="pug">
    .editor-header-search
        search-icon.icon

        input.input(
            ref="input"
            id="editor-search-input"
            v-model="value"
            placeholder="Search"
        )
        .count.disable
            span(id="editor-search-number") 0
            | &#32;of&#32;
            span(id="editor-search-total") 0

</template>

<script>
import searchIcon from '@/assets/images/icons/search_2.svg?inline';

import arrowBottomIcon from '@/assets/images/icons/arrow_bottom.svg?inline';

export default {
    name: 'editor-header-search',

    components: {
        searchIcon,
        arrowBottomIcon,
    },

    data: () => ({
        value: '',
    }),

    mounted() {
        document.addEventListener('keydown', this.ctrlFHandler);
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.ctrlFHandler);
    },

    methods: {
        ctrlFHandler(e) {
            if (e.code === 'KeyF' && e.ctrlKey) {
                e.preventDefault();
                this.$refs.input.focus();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.disable {
    display: none;
}
.editor-header-search {
    display: flex;
    align-items: center;

    width: 173px;
    height: 27px;

    border-radius: 4px;
    background-color: #ECECEC;

    padding: 6px 0  6px 13px;

}

.icon {
    margin-right: rem(14px);
}

.input {
    width: 100%;
    padding-right: 0.5rem;

    font-weight: 500;
    font-size: rem(12px);
    color: $grey;
}
</style>
