<template lang="pug">
    .publish-to-web-settings(
        v-click-outside="close"
        @click.stop
        :class="{ 'opened' : isOpen }"
    )
        div
            .item.item-modaltitle Publish to Web Settings

            .item.item-title Page Links
            .item
                .item-description
                    img(src="@/assets/images/icons/share_page.svg")
                    span Share Page Links
                app-toggler.item-toggle(v-model="accessNested" size="sm")

            .item.item-title Editing
            .item
                .item-description
                    img(src="@/assets/images/icons/rename.svg")
                    span Allow Editing
                app-toggler.item-toggle(v-model="allowEdit" size="sm")

            .item.item-title Duplicate
            .item
                .item-description
                    img(src="@/assets/images/icons/copy.svg")
                    span Allow to Duplicate
                app-toggler.item-toggle(v-model="allowDuplicate" size="sm")

            .item.item-title Search Engine
            .item
                .item-description
                    img(src="@/assets/images/icons/robot.svg")
                    span Search Engine Indexing
                app-toggler.item-toggle(v-model="allowSearchEngineIndexing" size="sm")

            .item.item-title Comments
            .item
                .item-description
                    img(src="@/assets/images/icons/comment.svg")
                    span Show Comments
                app-toggler.item-toggle(v-model="showComments" size="sm")
            .item
                .item-description
                    img(src="@/assets/images/icons/add_comment.svg")
                    span Allow add comments
                app-toggler.item-toggle(v-model="allowComments" size="sm")

            .item.item-title Header Settings
            .item
                .item-description
                    span Show Title
                app-toggler.item-toggle(v-model="showTitle" size="sm")
            .item
                .item-description
                    span Show Author
                app-toggler.item-toggle(v-model="showAuthor" size="sm")

            .item.item-title Footer
            .item
                .item-description
                    span "Created with ReSkript" badge
                app-toggler.item-toggle(v-model="showBadge" size="sm")

            .item.item-title
            .item
                .item-description
                    app-button(
                        @click="deletePublicLink"
                        :loading="isLoadingUnPublish"
                        outline red block small
                    ) Unpublish

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { deletePublicDocumentLink } from '@/js/api/requests/document';
export default {
    name: 'publish-to-web-settings',

    data () {
        return {
            isOpen: false,
            isLoadingUnPublish: false,
        };
    },

    computed: {
        ...mapState({
            documentId: state => state.editor.documentId,
            publishToWebSettings: state => state.editor.publishToWebSettings,
        }),
    },

    beforeCreate() {
        const settings = [
            'accessNested', 'allowEdit', 'allowDuplicate', 'allowSearchEngineIndexing',
            'showComments', 'allowComments', 'showTitle', 'showAuthor', 'showBadge',
        ];

        settings.forEach(nameSetting => {
            this.$options.computed[nameSetting] = {
                get () {
                    return this.publishToWebSettings[nameSetting];
                },
                set (value) {
                    this.setPublicDocumentLinkSetting({ key: nameSetting, value });
                },
            };
        });
    },

    mounted () {
        this.getSettings();

        this.$root.$on('open-publish-to-web-settings', this.open);
        this.$root.$on('close-publish-to-web-settings', this.close);
    },

    beforeDestroy () {
        this.$root.$off('open-publish-to-web-settings', this.open);
        this.$root.$off('close-publish-to-web-settings', this.close);
    },

    methods: {
        ...mapActions(['getPublicDocumentLinkSettings']),

        ...mapMutations(['setPublicDocumentLinkSetting', 'setToggledPublishToWeb']),

        async getSettings () {
            try {
                await this.getPublicDocumentLinkSettings(this.documentId);

                this.setToggledPublishToWeb(true);
            } catch (e) {
                //
            }
        },

        async deletePublicLink () {
            this.isLoadingUnPublish = true;

            try {
                await deletePublicDocumentLink(this.documentId);
            } catch (e) {
                // this.$awn.alert('Delete document link went wrong, please try again later');
            }

            this.isLoadingUnPublish = false;
            this.$root.$emit('close-publish-to-web-settings');
        },

        open () {
            this.isOpen = !this.isOpen;
        },

        close () {
            this.isOpen = false;
        },
    },
};
</script>

<style scoped lang="scss">
.publish-to-web-settings {
    position: fixed;
    border: 1px solid #E2E2E2;
    padding: 12px;
    box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    width: 256px;
    transform: translateX(calc(100vw + 300px));
    top: rem(220px);
    background-color: #fff;
    overflow-y: auto;
    z-index: 1;
    -ms-overflow-style: none;
    transition: .5s ease-in-out;

    &::-webkit-scrollbar {
        width: 0;
    }

    &.opened {
        transform: translateX(calc(100vw - 640px));
        transition: .5s ease-in-out;
    }

    .item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        color: $black0;
        padding: rem(5px) 0;
        width: 100%;

        &-modaltitle {
            font-size: rem(14px);
            line-height: rem(17px);
            color: #000000;
        }

        &-title {
            font-weight: 500;
            font-size: rem(12px);
            line-height: rem(15px);
            color: #6f6f6f;
        }

        &-description {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 15px;
            }
            span {
                display: flex;
                font-size: rem(12px);
                line-height: rem(15px);
            }
            img + span {
                margin-left: rem(13px);
            }
        }

        &-toggle {
            margin: 0;
        }

        & + .item-title {
            margin-top: rem(5px);
            border-top: 1px solid #dadada;
        }
    }
}
</style>
