<template lang="pug">
    .coupon
        .block
            .label Coupon code
            app-text-field(
                class="coupon__field"
                v-model="subscriptionData.couponCode"
                placeholder="Code"
                :disabled="loading"
            )
        app-button.coupon__btn(:loading="loading" :disabled="!subscriptionData.couponCode" @click="submit") Apply
</template>

<script>
import { tariffDiscount } from '@/js/api/requests/plans';

export default {
    name: 'coupon',

    props: {
        subscriptionData: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async submit() {
            this.loading = true;

            try {
                const data = {
                    tariff: this.$store.getters.planSelectedForCheckout.name,
                    coupon: this.subscriptionData.couponCode,
                };
                const info = await tariffDiscount(data);

                this.$emit('updateDataForm', { ...info, couponId: data.coupon });
                this.$awn.success(`Coupon applied`);
            } catch (e) {
                let message = '';
                const status = e?.response?.status;
                if (status === 404) {
                    message = 'Coupon does not exist';
                } else {
                    message = 'Failed to apply coupon';
                }
                this.$awn.alert(message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.coupon {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1200px) {
        background:  $grey-light20;
        margin-bottom: rem(10px);
        padding: rem(24px) rem(10px);
        padding-bottom: rem(66px);
    }

    .block {
        width: 69%;
        margin-top: rem(26px);
        @media (max-width: 1200px) {
            width: 58%;
            margin-top: rem(0px);
            border-radius: 10px;
        }
    }

    .label {
        font-size: rem(18px);
        line-height: rem(22px);
        color: $black0;
        font-weight: 500;
        margin-bottom: rem(8px);
    }

    input {
        border: 1px solid $grey-light30;
        border-radius: 5px;
        padding: rem(5px);
        font-size: rem(14px);
        line-height: rem(17px);
        font-weight: 500;
        height: rem(35px);
    }

    .coupon__field {
        margin-bottom: rem(0px);
    }

    .app-button--disabled {
        color: $grey-light17;
        background: $grey-light18;
    }

    .coupon__btn {
        width: 24%;
        font-size: rem(16px);
        min-height: auto;
        min-width: auto;
        height: rem(35px);
        @media (max-width: 1200px) {
            font-size: rem(14px);
            width: 40%;
        }
    }
}
</style>
