<template>
    <div
        class="project-detail"
        @mousedown="mousedownHandler"
        @mousemove="mousemoveHandler"
        @mouseup="mouseupHandler"
        @contextmenu.prevent="$refs.mainContext.open"
    >
        <app-loader v-if="pageLoading" />
        <template v-else>
            <div class="default-layout-head">
                <app-breadcrumbs
                    :items="breadcrumbs"
                    class="app-breadcrumbs_mb app-breadcrumbs--theme-thin app-breadcrumbs_projects"
                    @clickBreadcrumb="clickEntityHandler"
                    @mouseoverBreadcrumb="mouseoverEntityHandler"
                    @mouseupBreadcrumb="mouseupEntityHandler"
                />
            </div>
            <project-header
                :background="background"
                :emoji="emoji"
                :project="project"
                @open="openModal($event)"
            />

            <div class="default-layout-row">
                <div class="project-detail__left">
                    <transition mode="out-in">
                        <div
                            v-if="activeTabId === null"
                            key="null"
                        >
                            <div class="default-layout-block project-detail-rooms">
                                <p>Project Rooms</p>
                                <template v-if="!isEmpty(rooms)">
                                    <div
                                        v-for="(room, index) in rooms"
                                        :key="index"
                                        :style="stylesRoomHover(index)"
                                        class="project-detail-rooms__item"
                                        @click="$router.push(`/rooms/${room.id}`)"
                                        @contextmenu.prevent.stop="openRoomContextmenu($event, room)"
                                    >
                                        <div class="room">
                                            <span>{{ room.name }}</span>
                                        </div>
                                    </div>
                                    <div
                                        class="app-plus"
                                        @click="$modal.show('create-room-popup', {project, successCallback: roomCreatedCallback})"
                                    ></div>
                                </template>
                                <div
                                    v-else
                                    class="default-layout-block__empty default-layout-block__empty--clickable"
                                    @click="$modal.show('create-room-popup', {project, successCallback: roomCreatedCallback})"
                                >
                                    <no-folders-icon />
                                    <p>Click here to create a room</p>
                                </div>
                            </div>
                            <documents-table
                                v-if="documents.length"
                                :headers="headersForTable"
                                :items="documents"
                                background
                                bolder
                                without-groups
                            />
                            <no-docs v-else />
                        </div>
                        <project-detail-library
                            v-else-if="activeTabId === 0"
                            key="0"
                            :project="project"
                            @updateDocumentName="updateDocumentName"
                        />
                        <project-detail-manage-team
                            v-else-if="activeTabId === 1"
                            key="2"
                            :project="project"
                            @fetchProject="fetchProject"
                            @updateMembers="fetchProject"
                        />
                        <project-detail-settings
                            v-else-if="activeTabId === 3"
                            key="3"
                            :project="project"
                        />
                    </transition>
                </div>
                <div class="project-detail__right">
                    <chat
                        ref="chat"
                        :chat-type="ENTITY_TYPES_NAMES.project"
                        :entity="project"
                        :entity-id="project.id"
                        always-open
                    />
                </div>
            </div>
            <context-menu
                ref="mainContext"
                :items="['createRoom', 'createDocument', 'uploadDocument']"
                project-page
                @createDocument="createDocument({project_id: project.id})"
                @createRoom="$modal.show('create-room-popup', {project, successCallback: roomCreatedCallback })"
            />
            <context-menu
                ref="roomContext"
                :items="['rename', 'delete']"
            />
            <list-children-move
                :counter="childrenLength"
                :show="isChildrenHaveAndMove"
            />
            <list-child-move
                v-for="(selectedChild, index) in selectedChildrenAnimations"
                :key="index"
                :child="selectedChild"
            />
            <project-edit-popup
                @updateBackground="updateBackground($event)"
                @updateEmoji="updateEmoji($event)"
            />
        </template>
    </div>
</template>

<script>
import Chat from '@/components/other/chat/index';
import TabsMixin from '@/mixins/TabsMixin';
import noFoldersIcon from '@/assets/images/icons/no_folders.svg?inline';
import ProjectDetailLibrary from '@/components/pages/projects/detail/Library';
import ProjectDetailSettings from '@/components/pages/projects/detail/Settings';
import ProjectDetailManageTeam from '@/components/other/ManageTeam';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import { isEmpty } from 'lodash';
import { getProjectById } from '@/js/api/requests/project';
import { moveRoomsToBin } from '@/js/api/requests/room';
import { ENTITY_TYPES_NAMES, MANAGE_TEAM_TYPE } from '@/js/const';
import ChildrenMixin from '@/mixins/ChildrenMixin';
import projectEditPopup from '@/components/pages/projects/detail/popup/projectEditPopup';
import DocumentsTable from '@/components/common/documentsTable';
import NoDocs from '@/components/other/noDocs/noDocs';
import projectHeader from '@/components/pages/projects/detail/projectHeader';
import reactiveUpdateProjectMixin from '@/mixins/reactiveUpdateProjectMixin';

export default {
    name: 'project-detail',

    components: {
        NoDocs,
        DocumentsTable,
        projectEditPopup,
        Chat,
        noFoldersIcon,
        ProjectDetailLibrary,
        ProjectDetailSettings,
        ProjectDetailManageTeam,
        projectHeader,
    },

    mixins: [reactiveUpdateProjectMixin, TabsMixin, CreateDocumentMixin, ChildrenMixin],

    data() {
        return {
            MANAGE_TEAM_TYPE,
            ENTITY_TYPES_NAMES,
            indexRoomHover: null,
            defaultBackground: 'background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(91.48deg, #5B69FF 0%, #FF59F8 52.08%, #FFAC58 100%)',

            headersForTable: ['Name', 'Updated', 'Created'],
            popupTabsBg: [
                {
                    title: 'Gradients',
                    value: 'gradients',
                },
                {
                    title: 'Unsplash',
                    value: 'unsplash',
                },
                {
                    title: 'Upload an image',
                    value: 'upload',
                },
            ],
            popupTabsIcon: [
                {
                    title: 'Emoji',
                    value: 'emoji',
                },
            ],

            tabs: [
                {
                    id: 0,
                    name: 'Project Library',
                    tab: 'library',
                },
                {
                    id: 1,
                    name: 'Team Management',
                    tab: 'manage-team',
                },
                {
                    id: 2,
                    name: 'Tasks',
                    tab: 'calendar',
                },
                {
                    id: 3,
                    name: 'Settings',
                    tab: 'settings',
                },
            ],

            project: null,

            pageLoading: true,
        };
    },


    computed: {
        breadcrumbs() {
            return this.getBreadcrumps({ ...this.project });
        },

        createDocumentOptions() {
            return { project_id: this.project.id };
        },

    },
    async created() {
        await this.fetchProject();
        this.pageLoading = false;
    },

    methods: {
        async fetchProject() {
            this.project = await getProjectById(this.$route.params.id);
            this.project = { ...this.project, type: this.ENTITY_TYPES_NAMES.project };
            this.$store.dispatch('setContentDocuments', this.addTypeForChild(this.project.project_documents, this.ENTITY_TYPES_NAMES.document));
            this.rooms = this.addTypeForChild(this.project.project_rooms, this.ENTITY_TYPES_NAMES.room);
        },

        openRoomContextmenu(e, room) {
            const menu = this.$refs.roomContext;
            menu.open(e);

            menu.$off(['rename', 'delete']);

            menu.$on('rename', () => {
                this.$modal.show('rename-popup', {
                    entity: room,
                    entityType: 'room',
                    successCallback: (name) => {
                        room.name = name;
                        this.$refs.chat.updateChatList();
                    },
                });
            });

            menu.$on('delete', () => {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: 'No',
                        yes: 'Yes',
                    },
                    callback: async confirm => {
                        if (confirm) {
                            try {
                                await moveRoomsToBin(room.id);
                                this.rooms = this.rooms.filter(r => r.id !== room.id);
                                this.$refs.chat.updateChatList();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    },
                });
            });
        },

        updateDocumentName({ id, name }) {
            const document = this.documents.find(d => d.id === id);
            document.name = name;
        },

        roomCreatedCallback() {
            this.fetchProject();
            this.$refs.chat.updateChatList();
        },

        isEmpty,

        stylesRoomHover(index) {
            if (this.indexRoomHover === index) {
                return { 'background': '#edf7fe' };
            }
            return {};
        },

        openModal(args) {
            this.$modal.show('change-bg-modal', args);
        },

    },
};
</script>

<style lang="scss" scoped>
.project-detail {
    padding-bottom: rem(72px);
    margin: 0 13px;

    &__left {
        width: 100%;
    }

    &__right {
        flex-shrink: 0;

        width: rem(430px);
        margin-left: rem(20px);
    }

    &-rooms {
        padding: 11px;
        background-color: $grey-dark8;
        border-radius: 9px;

        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            margin-bottom: 30px;
        }

        min-height: rem(170px);
        padding-bottom: 0;

        &__item {
            background-color: $white;
            display: inline-flex;
            margin-right: 17px;
            box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.1);
            border-radius: 9px;

            width: 138px;
            height: 93px;
            margin-bottom: 30px;
            padding: 0.5rem 1rem;

            transition: background-color .2s;

            .room {
                display: flex;
                align-items: center;

                width: 100%;
                height: 100%;

                cursor: pointer;
            }

            svg {
                width: rem(90px);
                margin-bottom: rem(5px);
            }

            span {
                width: 100%;
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                overflow-wrap: break-word;

                line-height: normal;

                @include name_reduction();
            }

            @media (hover: hover) {
                &:hover {
                    background: $hover-background;
                }
            }

            &_selected {
                background: $hover-background;
            }
        }
    }

    &-files {
        min-height: 35vh;
    }

    @include mobile_or_tablet {
        &__right {
            width: 100%;
            margin-left: 0;
        }

        &-rooms, &-files {
            min-height: 15rem;
        }

        &-rooms {
            &__item {
                width: 33%;
                margin-bottom: 1rem;

                svg {
                    width: rem(80px);
                }
            }
        }
    }
}


</style>
