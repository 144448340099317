<template>
    <app-modal
        name="shared-filters-menu-popup"
        class="shared-filters-menu"
        @closed="close"
    >
        <div
            class="filters"
        >
            <div
                v-for="(filter, i ) in SHARED_FILTERS"
                :key="i"
                class="item"
                @click="selectFilter(filter)"
            >
                <div class="icon">
                    <checked-icon v-if="currentFilter === filter.title" />
                </div>
                <p> {{ filter.title }}</p>
            </div>
        </div>
    </app-modal>
</template>

<script>
import sharedFilterMenuMixin from '@/mixins/sharedFilterMenuMixin';

export default {
    name: 'shared-filters-menu-popup',

    mixins: [sharedFilterMenuMixin],
};
</script>

<style scoped lang="scss">
.shared-filters-menu {

    .filters {
        margin-top: 15px;
        padding: 20px;
        background-color: $white;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
        border-radius: 9px;

        .item {
            display: flex;
            align-items: center;
            cursor: pointer;

            p {
                font-size: 20px;
                font-weight: 500;
            }

            .icon {
                width: 15px;
                height: 10px;
                margin-right: 9px;
            }

            &:not(:first-child) {
                margin-top: 17px;
            }
        }
    }

    @include mobile_or_tablet {
        &::v-deep .app-modal__container {
            width: rem(700px);
            padding: 0;
        }

        &::v-deep {
            .vm--modal {
                position: absolute;
                bottom: 0 !important;
                top: auto !important;
                left: 0 !important;

                border-radius: rem(20px) rem(20px) 0 0;

                transform: initial;
                opacity: 1;
            }

            .vm--overlay {
                background-color: rgba($black, 0.6);
            }

            .vm-transition--overlay {
                &-enter-active,
                &-leave-active {
                    transition: opacity .5s;
                }

                &-enter,
                &-leave-to {
                    opacity: 0;
                }
            }

            .vm-transition--modal {
                &-enter-active,
                &-leave-active {
                    transition: transform .5s;
                }

                &-enter,
                &-leave-to {
                    transform: translateY(100%);
                }
            }

            .app-modal__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 100vw;
                height: 100%;
            }
        }
    }
}
</style>

