<template lang="pug">
    div.wrapper
        reskriptIcon.icon
        span Made in ReSkript
</template>

<script>
import reskriptIcon from '@/assets/images/icons/reskript_2.svg?inline';

export default {
    name: 'made-in-reskript',

    components: {
        reskriptIcon,
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    position: fixed;
    bottom: rem(20px);
    left: calc(100vw - #{rem(208px)});
    z-index: 100;
    border: 1px solid #E7E7E7;
    border-radius: 9px;
    padding: 9px;
    display: flex;
    align-items: center;

    span {
        font-size: 13px;
        font-weight: 600;
        color: #AAAAAA;
        padding: 0 0 0 13px;
    }
}
</style>
