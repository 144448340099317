import checkedIcon from '@/assets/images/icons/checked_3.svg?inline';
import arrowIcon from '@/assets/images/icons/arrow_bottom2.svg?inline';


export default {
    props: {
        filters: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        currentRoom: null,
        isOpen: false,
    }),
    components: { checkedIcon, arrowIcon },

    methods: {
        selectFilter(filter) {
            this.currentRoom = filter;
            this.$emit('changeFilter', filter);
            this.isOpen = false;
        },

        close() {
            this.isOpen = false;
        },

        openFilter() {
            this.isOpen = true;
        },
    },

    mounted() {
      this.currentRoom = this.filters[0];
    },
};
