<template lang="pug">
    .chat(v-show="isOpen || alwaysOpen" ref="chat" )
        .chat__wrapper(v-show="!openedDialog")
            .top
                .all-dialogs-title All Chats

            .dialogs(ref="dialogs")
                .dialogs__wrapper
                    .dialog(
                        v-for="dialog in dialogsList"
                        @click="openDialog(dialog)"
                    )
                        template(v-if="dialog.type === 'project' || dialog.type === 'room'")
                            .dialog__name {{ `#` + dialog.title }}

                        template(v-else)
                            .dialog__avatar
                                img(
                                    v-if="dialog.last_msg.author && dialog.last_msg.author.image"
                                    :src="dialog.last_msg.author.image"
                                )
                                img(
                                    v-else-if="dialog.author.image"
                                    :src="dialog.author.image"
                                )
                                span(v-else) {{ getUserFirstLetters(dialog.last_msg.author || dialog.author) }}
                            .dialog__info
                                .dialog__title {{ dialog.title }}
                                .dialog__description {{ dialog.last_msg.body }}

                        .dialog__last-message-date
                            span(v-if="dialog.last_msg.created_at") {{ moment(dialog.last_msg.created_at).fromNow()}}
                            star-icon.star(v-if="dialog.primary")

                        .dialog__button
                            arrow-right-icon

            app-button.new-dialog-button(
                v-if="chatType === 'document'"
                @click="createDialog"
            ) New

        .chat__wrapper(v-show="openedDialog")
            .top
                .back-button(@click="closeDialog")
                    arrow-left-icon

                input.dialog-name(
                    v-if="openedDialog"
                    :value="openedDialog.title"
                    :disabled="chatType !== 'document'"
                    @change="renameDialog"
                )

                .star(
                    v-if="openedDialog"
                    :class="{ active: openedDialog.primary, disabled: openedDialog.type !== 'dialog' }"
                    @click="setPrimary(openedDialog)"
                )
                    star-icon

                .close-button(@click="closeDialog")
                    closer-icon

            .messages(ref="messages")
                .messages__wrapper
                    .message(
                        v-for="message in messages"
                        :class="{ my: checkIsMyMessage(message) }"
                        @contextmenu.prevent.stop=" checkIsMyMessage(message) ? openMessageContext($event, message) : ''"
                    )
                        .message__avatar
                            img(
                                v-if="message.author.image"
                                :src="message.author.image"
                            )
                            span(v-else) {{ getUserFirstLetters(message.author) }}

                        .message__center
                            .message__content
                                .message__name {{ message.author.username }} {{ message.author.surname }}
                                .message__text(v-html="getMessageText(message.body)")
                                .message__time {{ moment(message.created_at).format('HH:mm') }}
                                three-dots-icon.message__three-dots(
                                    v-if="checkIsMyMessage(message)"
                                    @click.stop="openMessageContext($event, message)"
                                )

                            .message__reactions
                                .message__reaction(
                                    v-for="reaction in message.reactions"
                                    @click="addReaction(message, reaction._id)"
                                )
                                    span {{ reaction._id }}
                                    | {{ reaction.count }}
                                chat-reactions-select(@addReaction="addReaction(message, $event)")


            .bottom
                //attach-icon.attach-button
                textarea-autosize.chat__text-input.text-field(
                    ref="textField"
                    v-model.trim="text"
                    placeholder="Write a Message..."
                    rows="1"
                    @keydown.native.enter="sendMessage"
                    @keypress.native="typing"
                )
                smile-icon.smiles-button(@click="openEmojiModal")
                .send-button(@click="sendMessage")
                    arrow-top-icon

                transition
                    chat-mention-list(
                        v-if="showMentionList"
                        :users="openedDialogUsers"
                        @insertMention="insertMention"
                    )

                transition
                    chat-typing(
                        v-if="typingUsers.length"
                        :users="typingUsers"
                    )

                emoji-modal(
                    ref="emojiModal"
                    @insertEmoji="insertEmoji"
                )


        context-menu.message__context(
            ref="messageContext"
            :items="['edit', 'delete']"
            @contextmenu.native.prevent.stop
        )

</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Scrollbar from 'smooth-scrollbar';

import ChatReactionsSelect from '@/components/other/chat/ChatReactionsSelect';
import EmojiModal from '@/components/other/EmojiModal';
import ChatMentionList from '@/components/other/chat/ChatMentionList';
import ChatTyping from '@/components/other/chat/ChatTyping';

import attachIcon from '@/assets/images/icons/attach.svg?inline';
import smileIcon from '@/assets/images/icons/smile.svg?inline';
import arrowTopIcon from '@/assets/images/icons/arrow_top.svg?inline';
import arrowLeftIcon from '@/assets/images/icons/arrow_left.svg?inline';
import arrowRightIcon from '@/assets/images/icons/arrow_right.svg?inline';
import threeDotsIcon from '@/assets/images/icons/threeDotsHorizontal.svg?inline';
import closerIcon from '@/assets/images/icons/closer_2.svg?inline';
import starIcon from '@/assets/images/icons/star.svg?inline';

import { messageWithMentionUsers } from '@/js/utils/text';
import { getProjectById } from '@/js/api/requests/project';
import { getDocumentById } from '@/js/api/requests/document';
import { setupConnection, socketEvents } from '@/js/ws_connection';
import { ENTITY_TYPES_NAMES, websocketsEventsNotifications } from '@/js/const';
import { unique } from '@/js/utils/array';
import { getUserMainData } from '@/js/utils/user';
import moment from 'moment';

export default {
    name: 'chat',

    components: {
        ChatReactionsSelect,
        EmojiModal,
        ChatMentionList,
        ChatTyping,
        attachIcon,
        smileIcon,
        arrowTopIcon,
        arrowLeftIcon,
        arrowRightIcon,
        threeDotsIcon,
        closerIcon,
        starIcon,
    },

    props: {
        chatType: { // project, document
            type: String,
            required: true,
        },

        entityId: {
            type: Number,
            required: true,
        },

        entity: {
            type: Object,
            required: false,
            default: null,
        },

        alwaysOpen: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isOpen: false,
        chatSocket: null,

        dialogsList: [],
        messages: [],
        messagesMeta: null,
        messagesPerPage: 100,
        editableMessage: null,
        messagesScrollbar: null,
        text: '',

        openedDialog: null,
        openedDialogUsers: [],
        markedUsers: [],

        typingUsers: [],
        typingTimeout: null,
    }),

    computed: {
        ...mapGetters(['user', 'userWS']),

        showMentionList() {
            return this.text.slice(-1) === '@';
        },
    },

    mounted() {
        this.chatSocket = setupConnection();

        this.chatSocket.emit(socketEvents.getChats, {
            location: this.chatType,
            key: this.entityId,
        });

        this.chatSocket.on(socketEvents.chatList, ({ data }) => {
            this.log('== chat list: ==', data);
            this.dialogsList = data;
        });

        this.chatSocket.on(socketEvents.messageBox, ({ data, meta }) => {
            this.log('== messages: ==', data);
            const prevLimitY = this.messagesScrollbar.limit.y;

            this.messages = [...data.reverse(), ...this.messages];
            this.messagesMeta = meta;

            if (meta.current_page === 1) {
                setTimeout(() => {
                    this.messagesScrollbar.scrollTop = this.messagesScrollbar.limit.y;
                });
            } else {
                setTimeout(() => {
                    this.messagesScrollbar.scrollTop = this.messagesScrollbar.limit.y - prevLimitY;
                });
            }
        });

        this.chatSocket.on(socketEvents.newMessage, ({ data }) => {
            this.log('== new message: ==', data);
            this.messages.push(data);

            if (this.messagesScrollbar.scrollTop === this.messagesScrollbar.limit.y) {
                setTimeout(() => {
                    this.messagesScrollbar.scrollTop = this.messagesScrollbar.limit.y;
                });
            }

            this.defineShowUnreadMessagesMark();
        });

        this.chatSocket.on(socketEvents.removeMessage, ({ data }) => {
            this.messages = this.messages.filter(m => m._id !== data.message_id);
            this.log('== remove message: ==', data);
        });

        this.chatSocket.on(socketEvents.updateMessage, ({ data }) => {
            const editedMessage = this.messages.find(m => m._id === data.message_id);

            if (editedMessage) {
                editedMessage.body = data.body;
                editedMessage.edited = true;
            }
            this.log('== update message: ==', data);
        });

        this.chatSocket.on(socketEvents.userStartTyping, ({ data }) => {
            if (data.author.id !== this.user.id) {
                this.typingUsers.push(data.author);
            }
            this.log('== user start typing: ==', data.author);
        });

        this.chatSocket.on(socketEvents.userStopTyping, ({ data }) => {
            this.typingUsers = this.typingUsers.filter(u => u.id !== data.author.id);
            this.log('== user stop typing: ==', data.author);
        });

        this.chatSocket.on(socketEvents.newDialog, ({ data }) => {
            this.log('== new dialog: ==', data);
            this.dialogsList.push(data);
        });

        this.chatSocket.on(socketEvents.updateDialog, ({ data }) => {
            const dialog = this.dialogsList.find(d => d.key === data.dialog_id);

            if (dialog) {
                this.log('== update dialog: ==', data);
                if (data.title) {
                    dialog.title = data.title;
                }
                if (data.body) {
                    this.$set(dialog.last_msg, 'author', data.author);
                    this.$set(dialog.last_msg, 'body', data.body);
                    this.$set(dialog.last_msg, 'created_at', data.created_at);
                    this.$set(dialog.last_msg, 'edited', data.edited);
                }
            }
        });

        this.chatSocket.on(socketEvents.newReaction, ({ data }) => {
            const message = this.messages.find(m => m._id === data[0]._id);
            this.$set(message, 'reactions', data[0].reactions);
            this.log('== new reaction: ==', data[0]);
        });

        this.chatSocket.on(socketEvents.updatePrimary, ({ data }) => {
            const lastPrimaryDialog = this.dialogsList.find(d => d.primary);
            if (lastPrimaryDialog) {
                lastPrimaryDialog.primary = false;
            }
            const newPrimaryDialog = this.dialogsList.find(d => d.key === data.dialog_id);
            if (newPrimaryDialog) {
                newPrimaryDialog.primary = true;
            }
            this.log('== update primary: ==', data);
        });

        this.chatSocket.on(socketEvents.refreshChatList, ({ data }) => {
            this.dialogsList = data;
            this.log('== refresh chat list: ==', data);
        });

        this.initScrollbars();
        this.messagesScrollbar.addListener(() => {
            if (this.messagesScrollbar.scrollTop === 0 && this.messagesMeta?.next) {
                this.chatSocket.emit(socketEvents.getMessages, {
                    location: this.openedDialog.type,
                    key: this.openedDialog.key,
                }, {
                    page: this.messagesMeta.next,
                    limit: this.messagesPerPage,
                });
            }
        });
    },

    beforeDestroy() {
        this.closeDialog();
        this.leaveCommonGroup();
    },

    methods: {
        ...mapMutations(['setHasUnreadChatMessages']),

        toggleChat() {
            this.isOpen = !this.isOpen;

            this.setHasUnreadChatMessages(false);
        },

        defineShowUnreadMessagesMark() {
            // if (!this.isOpen) {
            //     this.setHasUnreadChatMessages(true);
            // }
        },

        getUserFullName(user) {
            return [user.username, user.surname].join(' ');
        },

        initScrollbars() {
            this.messagesScrollbar = Scrollbar.init(this.$refs.messages);
            Scrollbar.init(this.$refs.dialogs);
        },

        openDialog(dialog) {
            this.markedUsers = [];

            this.chatSocket.emit(socketEvents.joinChat, {
                location: dialog.type,
                key: dialog.key,
            });

            this.chatSocket.emit(socketEvents.getMessages, {
                location: dialog.type,
                key: dialog.key,
            }, {
                page: 1,
                limit: this.messagesPerPage,
            });

            this.openedDialog = dialog;
            this.getDialogUsers(dialog);
        },

        async getDialogUsers(dialog) {
            if (dialog.type === 'project' || dialog.type === 'room') {
                const project = await getProjectById(this.entityId);
                this.openedDialogUsers = project.users;
            } else if (dialog.type === 'dialog') {
                const document = await getDocumentById(this.entityId);
                this.openedDialogUsers = document.users;
            }
        },

        closeDialog() {
            if (this.openedDialog) {
                this.chatSocket.emit(socketEvents.leaveChat, {
                    location: this.openedDialog.type,
                    key: this.openedDialog.key,
                });
                this.openedDialog = null;
                this.messages = [];
                this.messagesMeta = null;
            }
        },

        leaveCommonGroup() {
            this.chatSocket.emit(socketEvents.leaveCommonGroup, {
                location: this.chatType,
                key: this.entityId,
            });
        },

        sendMessage(e) {
            if (this.editableMessage) {
                this.editMessage(e);
            } else {
                this.createMessage(e);
            }
        },

        createMessage(e) {
            if (!this.text) return;
            if (e.which === 13 && e.shiftKey) return;
            e.preventDefault();

            this.chatSocket.emit(socketEvents.addMessage, {
                location: this.openedDialog.type,
                key: this.openedDialog.key,
            }, {
                body: this.text,
                author: {
                    id: this.user.id,
                    username: this.user.username,
                    surname: this.user.surname,
                    image: this.user.image,
                },
            });

            this.text = '';
            this.messagesScrollbar.scrollTop = this.messagesScrollbar.limit.y;

            this.sendNotificationToMentionedUsers();
            // this.sendNotificationToAllUsers();
        },

        editMessage(e) {
            if (!this.text) return;
            if (e.which === 13 && e.shiftKey) return;
            e.preventDefault();

            this.chatSocket.emit(socketEvents.editMessage, {
                location: this.openedDialog.type,
                key: this.openedDialog.key,
                message_id: this.editableMessage._id,
            }, {
                body: this.text,
            });

            this.text = '';
            this.editableMessage = null;
            this.messagesScrollbar.scrollTop = this.messagesScrollbar.limit.y;

            this.sendNotificationToMentionedUsers();
            // this.sendNotificationToAllUsers();
        },

        sendNotificationToMentionedUsers() {
            if (!this.entity) return;

            const markedUsers = unique(this.markedUsers);
            if (!markedUsers.length) return;

            const sender = getUserMainData(this.user);
            if (!sender.firstName) return;

            let typeNotification = websocketsEventsNotifications.notedInDocument;
            if (this.chatType === ENTITY_TYPES_NAMES.project) {
                typeNotification = websocketsEventsNotifications.notedInProject;
            }

            markedUsers.forEach(usernameAndSurname => {
                const user = this.openedDialogUsers.find(user => {
                    return user.username + ' ' + user.surname === usernameAndSurname;
                });

                if (!user) return;

                const receiver = getUserMainData(user);
                if (!receiver.firstName) return;

                this.sendNotificationToUser(sender, receiver, typeNotification);
            });

            this.markedUsers = [];
        },

        sendNotificationToAllUsers() {
            if (!this.entity) return;

            const sender = getUserMainData(this.user);

            let typeNotification = websocketsEventsNotifications.asParticipantInDocument;
            if (this.chatType === ENTITY_TYPES_NAMES.project) {
                typeNotification = websocketsEventsNotifications.asParticipantInProject;
            }

            this.openedDialogUsers.forEach(user => {
                const receiver = getUserMainData(user);

                this.sendNotificationToUser(sender, receiver, typeNotification);
            });
        },

        sendNotificationToUser(sender, receiver, type) {
            this.log('== send notification to user ==');

            const data = {
                senderFirstLetters: sender.firstLetters,
                senderFullname: sender.fullName,
                haveLink: true,
            };

            if (this.chatType === ENTITY_TYPES_NAMES.project) {
                data.projectId = this.entityId;
                data.projectName = this.entity.name;
            } else if (this.chatType === ENTITY_TYPES_NAMES.document) {
                data.documentId = this.entityId;
                data.documentName = this.entity.name;
            }

            this.userWS.emit('send-notification', {
                sender: { id: sender.id, username: sender.fullName },
                receivers: [{ id: receiver.id, username: receiver.fullName }],
                type,
                data,
            });
        },

        getUserFirstLetters(user) {
            return [user.username?.[0], user.surname?.[0]].join('');
        },

        typing(e) {
            if (e.which === 13 && !e.shiftKey) return;

            const locationData = {
                location: this.openedDialog.type,
                key: this.openedDialog.key,
            };

            const userData = {
                author: {
                    id: this.user.id,
                    username: this.user.username,
                    surname: this.user.surname,
                },
            };

            if (!this.typingTimeout) {
                this.chatSocket.emit(socketEvents.startTyping, locationData, userData);
            }
            clearTimeout(this.typingTimeout);
            this.typingTimeout = setTimeout(() => {
                this.chatSocket.emit(socketEvents.stopTyping, locationData, userData);
                this.typingTimeout = null;
            }, 1000);
        },

        checkIsMyMessage(message) {
            return message.author.id === this.user.id;
        },

        getMessageText(message) {
            return messageWithMentionUsers(message, this.openedDialogUsers);
        },

        renameDialog({ target }) {
            this.chatSocket.emit(socketEvents.editDialog, {
                location: this.chatType,
                key: this.entityId,
                dialog_id: this.openedDialog.key,
            }, {
                title: target.value,
            });
        },

        updateChatList() {
            this.chatSocket.emit(socketEvents.updateChatList, {
                location: this.chatType,
                key: this.entityId,
            });
        },

        setPrimary(dialog) {
            this.chatSocket.emit(socketEvents.setPrimary, {
                location: this.chatType,
                key: this.entityId,
                dialog_id: dialog.key,
            });
        },

        openEmojiModal() {
            this.$refs.emojiModal.open();
        },

        insertEmoji(emoji) {
            const position = this.$refs.textField.$el.selectionStart;

            this.text = this.text.slice(0, position) + emoji.native + this.text.slice(position);
            this.$refs.emojiModal.close();
        },

        insertMention(user) {
            this.markedUsers.push(user);
            this.text += `${user} `;
            this.$refs.textField.$el.focus();
        },

        addReaction(message, reaction) {
            if (message.reactions?.some(r => r._id === reaction && r.author_list.some(a => a.author.id === this.user.id))) return;

            this.chatSocket.emit(socketEvents.addReaction, {
                location: this.openedDialog.type,
                key: this.openedDialog.key,
                message_id: message._id,
            }, {
                symbol: reaction,
                author: {
                    id: this.user.id,
                },
            });
        },

        createDialog() {
            this.chatSocket.emit(socketEvents.addDialog, {
                location: this.chatType,
                key: this.entityId,
            }, {
                document_id: this.entityId,
                type: 'dialog',
                author: {
                    full_name: this.getUserFullName(this.user),
                    username: this.user.username,
                    surname: this.user.surname,
                    image: this.user.image,
                },
            });
        },

        async openMessageContext(e, message) {
            const menu = this.$refs.messageContext;
            menu.open(e);

            menu.$off(['edit', 'delete']);

            menu.$on('edit', () => {
                this.editableMessage = message;
                this.text = message.body;
                this.$refs.textField.$el.focus();
                this.log('== edit message ==', message);
            });

            menu.$on('delete', () => {
                this.chatSocket.emit(socketEvents.deleteMessage, {
                    location: this.openedDialog.type,
                    key: this.openedDialog.key,
                    message_id: message._id,
                });
                this.log('== delete message== ', message);
            });
        },

        moment,

        log(...args) {
            if (process.env.NODE_ENV !== 'development') return;

            args.forEach(arg => console.log(arg));
        },
    },
};
</script>

<style lang="scss" scoped>
.chat__text-input {
    @include mobile_or_tablet {
        font-size: 16px;
    }
}

.chat {
    width: 100%;
    height: rem(540px);

    background: #fff;
    box-shadow: 0 0 rem(24px) 0 rgba(6, 7, 7, 0.1);

    &__wrapper {
        display: flex;
        flex-direction: column;

        height: 100%;
    }

    .top {
        display: flex;
        flex: 0 0 rem(50px);
        align-items: center;

        padding: 0 rem(16px);

        border-bottom: 1px solid #BBB9B9;

        .back-button, .close-button {
            cursor: pointer;

            svg {
                display: block;

                width: 100%;
            }

            @media (hover: hover) {
                &:hover svg {
                    stroke: $blue;
                }
            }
        }

        .back-button {
            flex: 0 0 rem(14px);

            margin-right: rem(24px);
        }

        .dialog-name {
            width: 100%;
            margin-right: rem(16px);
            padding: rem(4px);

            font-size: rem(20px);
            font-weight: 600;

            border: 1px solid transparent;
            border-radius: rem(4px);

            transition: border-color .2s;

            &:hover {
                border-color: $hover-background;
            }

            &:focus {
                border-color: $blue;
            }
        }

        .star {
            flex: 0 0 rem(18px);

            margin-right: rem(16px);

            cursor: pointer;

            svg {
                display: block;

                width: 100%;
            }

            &.disabled {
                pointer-events: none;
            }

            &:not(.active) {
                svg {
                    fill: none;
                    stroke: $black;
                }
            }
        }

        .close-button {
            flex: 0 0 rem(22px);
        }

        .all-dialogs-title {
            font-size: rem(20px);
            font-weight: 600;
        }
    }

    .messages {
        &__wrapper {
            padding: rem(18px) 0;
        }
    }

    .messages, .dialogs {
        &::v-deep .scrollbar {
            &-track {
                right: 2px;

                width: rem(4px);

                background: none;
            }

            &-thumb {
                width: inherit;

                background: $grey-dark;
            }
        }
    }

    .message {
        display: flex;

        margin-bottom: rem(4px);
        padding: rem(6px) rem(16px) 0;

        transition: background-color .1s;

        @media (hover: hover) {
            &:hover {
                background-color: rgba($blue, 0.05);
            }
        }

        &__avatar {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            width: rem(40px);
            height: rem(40px);
            margin-right: rem(6px);
            overflow: hidden;

            color: #fff;

            background: $blue;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__center {
            width: 70%;
        }

        &__content {
            position: relative;

            padding: rem(8px) rem(16px) rem(8px) rem(8px);

            font-size: rem(14px);

            background-color: #E4E4E5;
            border-radius: rem(6px);
            box-shadow: 0 0 rem(6px) rgba(#000, 0.1);
        }

        &__name {
            margin-bottom: rem(6px);

            font-weight: 500;
            color: #1758FF;
        }

        &__text {
            line-height: 1.3;
            word-break: break-word;

            &::v-deep {
                .mention {
                    margin-right: rem(4px);
                    padding: 0 rem(3px);

                    color: $blue2;

                    border-radius: rem(10px);
                    box-shadow: 0 0 0 rem(1px) #fff, inset 0 0 0 rem(30px) #fff;
                }

                a {
                    color: blue;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &__time {
            position: absolute;
            bottom: 0;
            left: calc(100% + #{rem(8px)});

            font-size: rem(12px);
            color: #6B6B6B;
        }

        &__three-dots {
            display: block;

            width: rem(18px);
            margin-left: auto;
            padding: rem(4px) 0;

            border-radius: rem(2px);

            cursor: pointer;
            transition: box-shadow .1s;

            @media (hover: hover) {
                &:hover {
                    box-shadow: 0 rem(2px) 0 rem(2px) rgba($blue, 0.25),
                    0 rem(-2px) 0 rem(2px) rgba($blue, 0.25),
                    inset 0 0 0 rem(10px) rgba($blue, 0.25);
                }
            }
        }

        &__reactions {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            padding: rem(4px) 0;
        }

        &__reaction {
            display: flex;
            align-items: center;

            min-width: calc((100% - #{rem(5px)} * 10) / 5);
            height: rem(22px);
            margin: 0 rem(5px) rem(3px);
            padding: rem(4px);

            font-size: rem(14px);

            background-color: #ECECEC;
            border-radius: rem(6px);

            cursor: pointer;
            transition: background-color .2s;

            span {
                margin-right: rem(8px);
            }

            @media (hover: hover) {
                &:hover {
                    background-color: rgba($blue, 0.25);
                }
            }
        }

        &__context {
            padding: 0;

            background-color: #CCDAFF;

            &::v-deep .context-menu {
                &__link {
                    min-width: 6rem;
                    padding: rem(8px);

                    font-size: rem(12px);
                }

                &__icon {
                    width: rem(20px);

                    svg {
                        width: rem(20px);
                        height: rem(20px);
                    }
                }
            }
        }

        .chat-reactions-select {
            margin-left: rem(8px);
        }

        &.my .message {
            &__avatar {
                display: none;
            }

            &__center {
                margin-left: auto;
            }

            &__content {
                background-color: #CCDAFF;
            }

            &__name {
                display: none;
            }

            &__time {
                right: calc(100% + #{rem(8px)});
                left: initial;
            }
        }
    }

    .bottom {
        position: relative;

        display: flex;
        align-items: center;

        margin-top: auto;
        padding: rem(6px) rem(10px);

        border-top: 1px solid #A6A6A6;

        .attach-button, .smiles-button {
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    .circle, path {
                        stroke: $blue;
                    }

                    .eye {
                        fill: $blue;
                    }
                }
            }
        }

        .attach-button {
            flex: 0 0 rem(30px);

            margin-right: rem(18px);
        }

        .text-field {
            width: 100%;
            min-height: rem(20px);
            max-height: rem(250px);
            margin-right: rem(12px);
            margin-left: rem(48px);
            padding: 0;
            overflow: auto !important;

            font-weight: 500;

            &::placeholder {
                color: #747272;
            }


        }

        .smiles-button {
            flex: 0 0 rem(20px);

            margin-right: rem(18px);
        }

        .send-button {
            display: flex;
            flex: 0 0 rem(32px);
            justify-content: center;
            align-items: center;

            height: rem(32px);

            background-color: #C4C4C4;
            border-radius: 50%;

            cursor: pointer;
            transition: background-color .2s;

            svg {
                width: rem(14px);
            }

            @media (hover: hover) {
                &:hover {
                    background-color: rgba($blue, 0.25);

                    svg path {
                        fill: $blue;
                    }
                }
            }
        }

        .emoji-modal {
            position: absolute;
            right: rem(16px);
            bottom: calc(100% + #{rem(10px)});

            max-width: calc(100% - #{rem(16px)} * 2);

            &::v-deep .emoji-mart {
                max-width: 100%;
                max-height: rem(240px);
            }
        }

        .chat-typing {
            position: absolute;
            bottom: calc(100% + #{rem(6px)});
            left: rem(20px);
        }
    }

    .dialogs {
        margin-bottom: rem(24px);
    }

    .dialog {
        display: flex;
        align-items: center;

        min-height: rem(74px);
        padding: rem(16px);

        border-bottom: 1px solid #BBB9B9;

        &__name {
            margin-right: auto;

            font-size: rem(20px);
            font-weight: 600;
        }

        &__avatar {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            width: rem(40px);
            height: rem(40px);
            margin-right: rem(12px);
            overflow: hidden;

            color: #fff;

            background: $blue;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        &__info {
            max-width: rem(200px);
            margin-right: auto;
            overflow: hidden;
        }

        &__title {
            margin-bottom: rem(8px);

            font-size: rem(18px);
            font-weight: 600;
            word-break: break-all;
        }

        &__description {
            overflow: hidden;
            line-height: normal;
            font-size: rem(14px);
            color: #535353;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__last-message-date {
            position: relative;

            margin-top: rem(8px);
            margin-right: rem(20px);

            font-size: rem(14px);
            color: #535353;
            white-space: nowrap;

            .star {
                position: absolute;
                right: 0;
                bottom: calc(100% + #{rem(4px)});

                width: rem(18px);
            }
        }

        &__button {
            flex-shrink: 0;

            width: rem(14px);

            svg {
                display: block;

                width: 100%;
            }
        }

        @media (hover: hover) {
            &:hover {
                background-color: $hover-background;

                cursor: pointer;

                .dialog__button svg {
                    stroke: $blue;
                }
            }
        }
    }

    .new-dialog-button {
        margin: auto auto rem(24px);

        border-radius: rem(22px);

        &:before {
            content: '+';

            margin-right: rem(32px);

            font-family: serif;
            font-size: rem(30px);
        }
    }
}
</style>
