<template lang="pug">
    .accept-invite-token
</template>

<script>

export default {
    name: 'accept-invite-token',

    computed: {
        user() {
            return this.$store.getters.user;
        },
    },

    async created() {
        const token = this.$route.params.token;
        const entity_type = this.$route.params.entity_type;

        if (this.user) {
            await this.$store.dispatch('acceptInviteToken', {
                token,
                entity_type,
            });
        } else {
            this.$cookie.set('inviteToken', JSON.stringify({
                token,
                entity_type,
            }));
            this.$router.replace({ name: 'Login' });
        }
    },
};
</script>

