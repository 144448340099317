<template lang="pug">
    .app-select.app-text-field(
        :id="uid"
        :class="classes"
    )

        label.app-text-field__label(
            v-if="label"
            v-html="label"
        )

        .app-text-field__wrapper

            multiselect(
                v-model="state"
                label="name"
                :options="options"
                :searchable="searchable"
                :allow-empty="allowEmpty"
                :preselect-first="preselectFirst"
                placeholder=""
                @open="focusHandler"
            )
                span(slot="noResult") {{ noResultText }}
                span(slot="noOptions") {{ noOptionsText }}

        .app-text-field__error(v-if="error" v-html="error")

</template>

<script>
import Multiselect from 'vue-multiselect';
import Scrollbar from 'smooth-scrollbar';
import { uniqueId } from 'lodash';

export default {
    name: 'app-select',

    components: {
        Multiselect,
    },

    props: {
        value: {
            validator(){
                return 1;
            },
            required: true,
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        options: {
            type: Array,
            default: () => [],
        },

        searchable: {
            type: Boolean,
            default: false,
        },

        noResultText: {
            type: String,
            default: 'Ничего не найдено',
        },

        noOptionsText: {
            type: String,
            default: 'Список пока пуст',
        },

        allowEmpty: {
            type: Boolean,
            required: false,
            default: true,
        },

        preselectFirst: {
            type: Boolean,
            required: false,
            default: false,
        },

        error: {
            type: String,
            required: false,
            default: '',
        },
    },

    data: () => ({
        uid: null,
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-text-field--invalid': this.error,
            };
        },
    },

    mounted() {
        this.uid = 'text-field-' + uniqueId();
    },

    methods: {
        focusHandler() {
            setTimeout(() => {
                Scrollbar.init(document.querySelector(`#${this.uid} .multiselect__content-wrapper`), {
                    alwaysShowTracks: true,
                });
            });
        },
    },
};
</script>
