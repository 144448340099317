import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import contents from './contents';
import team from './team';
import editor from './editor';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pageLoaded: false,
        listView: false,
        documentsViewTypeCards: true,
        pressedCtrStatus: false,
        pressedShiftStatus: false,
        menuOpen: false,
        teamInviteId: null,
        newUser: false,
    },

    getters: {
        documentsViewTypeCards: (state) => state.documentsViewTypeCards,
        getPressedCtrStatus: (state) => state.pressedCtrStatus,
        getPressedShiftStatus: (state) => state.pressedShiftStatus,
        getPressedShiftAndCtrStatus: (state) => state.pressedShiftStatus && state.pressedCtrStatus,
        getOpenMenu: (state) => state.menuOpen,
        getTeamInviteId: (state) => state.teamInviteId,
        getNewUserStatus: (state) => state.newUser,
    },

    mutations: {
        updatePageLoaded(state, value) {
            state.pageLoaded = value;
        },
        updatePageLayout(state, value) {
            state.listView = value;
        },
        updateDocumentsViewTypeCards(state, value) {
            state.documentsViewTypeCards = value;
        },
        setPressedCtrStatus(state, value = true) {
            state.pressedCtrStatus = value;
        },
        setPressedShiftStatus(state, value = true) {
            state.pressedShiftStatus = value;
        },
        setOpenMenu(state, value) {
            state.menuOpen = value;
        },
        setTeamInviteId(state, value) {
            state.teamInviteId = value;
        },
        setNewUserStatus(state, value) {
            state.newUser = value;
        },
    },

    actions: {
        updateTeamInviteId({ commit }, payload) {
            commit('setTeamInviteId', payload);
        },

        updateNewUserStatus({ commit }, payload) {
            commit('setNewUserStatus', payload);
        },
    },

    modules: {
        auth,
        contents,
        team,
        editor,
    },
});
