import urlify from '@/js/utils/urlify';
import { getUserFullName } from '@/js/utils/user';

const mentionTag = (mention) => {
    return `<span class="mention">${mention}</span>`;
};

const startTag = `<span class="mention">`;

export const messageWithMentionUsers = (message, users) => {
    let text = urlify(message.replace(/\n/g, '<br />'));
    users = [...users].map(user => {
        return `@${getUserFullName(user).trim()}`;
    });
    users.sort().reverse().forEach(user => {
        // TO DO нужна регулярка как эта
        // text = text.replaceAll(new RegExp( '(?<!<span class="mention">)'+ mention, 'g'), `<span class="mention">${mention}</span>`);
        // но https://caniuse.com/js-regexp-lookbehind не поддерживается аиосом и непонятно как сделать
        // text = text.replaceAll(new RegExp( 'a+(?!(<span class="mention">)'+ mention + ')', 'g'), `<span class="mention">${mention}</span>`);
        // поэтому пока такое решение
        text = text.replaceAll(new RegExp(user, 'gi'), (match, offset) => {
            if (offset === 0) {
                return mentionTag(user);
            } else {
                const indexStartTag = offset - startTag.length;
                if (indexStartTag < 0) {
                    return mentionTag(user);
                } else {
                    const prevText = text.substr(indexStartTag, startTag.length);
                    if (prevText === startTag) {
                        return user;
                    }
                    return mentionTag(user);
                }
            }
        });
    });
    return text;
};

export const shortenText = (text, moreNumber = 27) => {
    return text.length > moreNumber ? text.substring(0, moreNumber) + '...' : text;
};

export const splitText = (text, length) => {
    if (text.length > length) {
        return text.slice(0, length - 3).concat('...');
    } else return text;
};


