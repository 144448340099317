<template lang="pug">
    .chat-typing
        span(v-if="users.length === 1") {{ firstUserName }} is typing...
        span(v-else) {{ firstUserName }} and {{ users.length - 1 }} more people are typing...
</template>

<script>
export default {
    name: 'chat-typing',

    props: {
        users: {
            type: Array,
            required: true,
        },
    },

    computed: {
        firstUserName() {
            return this.users[0].username;
        },
    },
};
</script>

<style scoped lang="scss">
.chat-typing {
    min-width: rem(150px);
    padding: rem(5px);

    font-size: rem(14px);
    color: #989797;

    background: #E4E4E6;
    border-radius: rem(5px);
    box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.1);
}
</style>
