import Quill from 'quill';
let Inline = Quill.import('blots/inline');

class CommentText extends Inline{
    static create({ id }){
        let node = super.create();
        node.classList.add('ql-comment-text');
        node.setAttribute('data-comment-id',id);
        return node;
    }

    static formats(node) {
        return {
            id: node.getAttribute('data-comment-id'),
        };
    }
}
CommentText.blotName = 'comment-text';
CommentText.tagName = 'div';
Quill.register(CommentText);

class CommentTextSelectedBlot extends Inline {}
CommentTextSelectedBlot.blotName = 'comment-text-selected';
CommentTextSelectedBlot.className = 'ql-comment-text-selected';
CommentTextSelectedBlot.tagName = 'div';
Quill.register(CommentTextSelectedBlot);

