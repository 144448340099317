<template lang="pug">
    .editor-mobile-header
        img.burger(
            src="@/assets/images/editor/icons/burger.svg"
            @click="openMenu('burgerMenu')"
        )
        editor-header-rename-input(
            v-model="documentName"
            :document="document"
        )

        .three-dots(
            @click="openMenu('actions')"
            @mousedown.prevent
        )
            span
            span
            span

        img.share(
            src="@/assets/images/editor/icons/share.svg"
            @click="$modal.show('editor-invite-popup')"
        )

        editor-mobile-header-burger-menu(
            ref="burgerMenu"
            :document="document"
            @download="closeMenu('burgerMenu'); openMenu('download')"
        )

        editor-mobile-header-actions(
            ref="actions"
            @formatting="$emit('formatting', $event)"
            @undo="$emit('undo')"
            @redo="$emit('redo')"
            @openTextFormattingMenu="closeMenu('actions'); openMenu('textFormatting', $event)"
            @openChat="closeMenu('actions'); openMenu('chat')"
            @download="closeMenu('actions'); openMenu('download')"
            @moveTo="closeMenu('actions'); openMenu('moveTo')"
        )

        editor-mobile-header-text-formatting(
            ref="textFormatting"
            @formatting="$emit('formatting', $event)"
        )

        editor-mobile-header-chat(
            ref="chat"
            :document="document"
        )

        editor-mobile-header-download(
            ref="download"
            :document="document"
        )

        editor-mobile-header-move-to(
            ref="moveTo"
            :document="document"
        )
</template>

<script>
import EditorHeaderRenameInput from '@/components/editor/header/EditorHeaderRenameInput';
import EditorMobileHeaderBurgerMenu from '@/components/editor/header/EditorMobileHeaderBurgerMenu';
import EditorMobileHeaderActions from '@/components/editor/header/EditorMobileHeaderActions';
import EditorMobileHeaderTextFormatting from '@/components/editor/header/EditorMobileHeaderTextFormatting';
import EditorMobileHeaderChat from '@/components/editor/header/EditorMobileHeaderChat';
import EditorMobileHeaderDownload from '@/components/editor/header/EditorMobileHeaderDownload';
import EditorMobileHeaderMoveTo from '@/components/editor/header/EditorMobileHeaderMoveTo';

export default {
    name: 'editor-mobile-header',

    components: {
        EditorHeaderRenameInput,
        EditorMobileHeaderBurgerMenu,
        EditorMobileHeaderActions,
        EditorMobileHeaderTextFormatting,
        EditorMobileHeaderChat,
        EditorMobileHeaderDownload,
        EditorMobileHeaderMoveTo,
    },

    props: {
        document: {
            type: Object,
            required: true,
        },

        format: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        documentName: '',
    }),

    created() {
        this.documentName = this.document.name;
    },

    methods: {
        openMenu(ref, format) {
            this.$refs[ref].open(format || this.format);
            this.$emit('blurEditor');
        },

        closeMenu(ref) {
            this.$refs[ref].close();
        },
    },
};
</script>

<style scoped lang="scss">
.editor-mobile-header {
    position: fixed;
    top: 0;
    z-index: 1000;

    display: flex;
    align-items: center;

    @include mobile_or_tablet {
        width: 100%;
    }

    height: rem(75px);
    padding: 0 rem(22px);

    background-color: #F0EFEF;

    .burger {
        width: rem(42px);
        margin-right: rem(20px);
    }

    .editor-header-rename-input {
        margin-right: rem(10px);
    }

    .three-dots {
        display: flex;

        margin-right: rem(25px);
        padding: rem(14px) 0;

        span {
            width: rem(7px);
            height: rem(7px);
            margin: 0 rem(3px);

            background-color: #6F6E70;
            border-radius: 50%;
        }
    }

    .share {
        width: rem(23px);
    }
}
</style>
