<template>
    <app-modal-redesign
        closer="closer"
        name="create-folder-popup"
        @beforeOpen="beforeOpen"
        @closed="closed"
    >
        <form
            class="folder-form"
            @submit.prevent="submit"
        >
            <h3 class="folder-form__title">
                Create new folder
            </h3>
            <app-text-field
                v-model="name"
                :class="{ 'has-error': nameError }"
                :error="nameError"
                class="folder-form__input"
                placeholder="Folder Name"
            />
            <button
                class="folder-form__input folder-form__emoji"
                type="button"
                @click="toggleEmoji"
            >
                <span>Icon</span><span class="folder-form__icon">{{
                    emoji
                }}</span>
                <arrow-icon />
            </button>
            <button
                class="folder-form__button"
                type="submit"
            >
                Create
            </button>
            <emoji-modal
                ref="emojiModal"
                @insertEmoji="changeEmoji"
            />
        </form>
    </app-modal-redesign>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createFolder } from '@/js/api/requests/folder';
import GetRouteParamsMixin from '@/mixins/GetRouteParamsMixin';
import ArrowIcon from '@/assets/images/icons/arrow-left.svg?inline';
import EmojiModal from '@/components/other/EmojiModal';
import { getRandomEmoji } from './EmojiModal.vue';

export default {
    name: 'create-folder-popup',

    components: {
        ArrowIcon,
        EmojiModal,
    },

    mixins: [GetRouteParamsMixin],

    data: () => ({
        name: '',
        emoji: '',

        project: null,
        room: null,
        folder: null,

        goToFolder: false,
        goToFolderInLibrary: false,

        successCallback: null, // например, обновить список папок

        loading: false,
    }),

    validations: {
        name: { required },
    },

    computed: {
        nameError() {
            if (this.$v.$dirty && !this.$v.name.required) {
                return 'Required field';
            }
            return null;
        },
    },

    methods: {
        toggleEmoji() {
            if (this.$refs.emojiModal.isOpen) {
                this.$refs.emojiModal.close();
            } else {
                this.$refs.emojiModal.open({
                    top: 30, left: 0, styles: {
                        position: 'absolute',
                    },
                });
            }
        },
        changeEmoji(emoji) {
            this.emoji = emoji.native;
            this.$refs.emojiModal.close();

        },
        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.loading) return;

            this.loading = true;

            const fd = new FormData;
            fd.append('name', this.name);
            fd.append('icon', this.emoji);

            let projectId = this.project ? this.project.id : null;
            let roomId = this.room ? this.room.id : null;
            let folderId = this.folder ? this.folder.id : null;

            const { name } = this.$route;

            const dataParams = this.getRouteParams();
            if (dataParams) {
                const { folder_id, room_id } = dataParams;
                folderId = folder_id;
                roomId = room_id;
                this.goToFolder = true;
            }

            if (name === 'Library' || name === 'Dashboard') {
                this.goToFolderInLibrary = true;
            }

            if (projectId) {
                fd.append('project_id', projectId);
            } else if (roomId) {
                fd.append('room_id', roomId);
            } else if (folderId) {
                fd.append('folder_id', folderId);
            }

            try {
                const folderId = await createFolder(fd);
                this.$modal.hide('create-folder-popup');
                if (this.successCallback) {
                    this.successCallback();
                }
                if (this.goToFolder) {
                    this.$router.push({ name: 'FolderDetail', params: { folderId } });
                }
                if (this.goToFolderInLibrary) {
                    this.$router.push({ name: 'LibraryFolder', params: { folderId } });
                }
            } catch (e) {
                if (e.response.status === 422) {
                    this.$awn.alert('The name has already been taken');
                } else {
                    this.$awn.alert('Something went wrong, please try again later');
                }
            } finally {
                this.loading = false;
            }
        },

        beforeOpen(e) {
            this.project = e.params?.project;
            this.room = e.params?.room;
            this.folder = e.params?.folder;

            this.goToFolder = e.params?.goToFolder || false;
            this.goToFolderInLibrary = e.params?.goToFolderInLibrary || false;

            this.successCallback = e.params?.successCallback;

            this.emoji = getRandomEmoji();
        },

        closed() {
            this.name = '';
            this.$v.$reset();
        },
    },
};
</script>

<style lang="scss" scoped>
.folder-form {
    position: relative;

    &__title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: normal;
        border-bottom: 1px solid #DADADA;
    }

    &__input {
        width: 100%;
        margin-bottom: 10px;

        &.has-error {
            margin-bottom: 20px;
        }

        &:not(.has-error) {
            &::v-deep {
                input {
                    border: none;
                }
            }
        }

        &::v-deep {
            input {
                padding: 6px 15px;
                height: auto;
                font-size: 12px;
                border-radius: 6px;
                background: #F1F0F0;

                &::placeholder {
                    color: #616161;
                }
            }
        }
    }

    &__emoji {
        display: flex;
        align-items: center;
        padding: 6px 15px;
        font-size: 12px;
        border-radius: 6px;
        background: #F1F0F0;
        text-align: left;
        color: #616161;
        cursor: pointer;
    }

    &__icon {
        font-size: 12px;
        margin-left: auto;
        margin-right: 9px;
    }

    &__button {
        padding: 6px;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        color: #8A8A8A;
        background: #E0E0E0;
        border-radius: 6px;
        cursor: pointer;
    }
}
</style>
