<template lang="pug">
    .emoji-modal(
        v-if="isOpen"
        :style="styles"
    )
        picker(
            :data="emojiIndex"
            native
            :showPreview="false"
            :showSearch="false"
            :showSkinTones="false"
            @select="selectEmoji"
            :style="{maxWidth: maxWidth, width: width}"
        )
</template>

<script>
import { EmojiIndex, Picker } from 'emoji-mart-vue-fast';
import data from 'emoji-mart-vue-fast/data/all.json';
import 'emoji-mart-vue-fast/css/emoji-mart.css';

let catigories = [
    'smileys',
    'people',
    'nature',
    'foods',
    'activity',
    'places',
    'objects',
    'symbols',
];
let emojiIndex = new EmojiIndex(data, {
    include: catigories,
});

export function getRandomEmoji() {
    const emojis = Object.keys(emojiIndex._nativeEmojis);
    return emojis[Math.floor(Math.random() * emojis.length)];
}

export default {
    name: 'emoji-modal',

    components: {
        Picker,
    },

    props: {

        width: {
            type: String,
            default: '395px',
        },
        maxWidth: {
            type: String,
            default: '395px',
        },
    },

    data: () => ({
        isOpen: false,
        isCustomHeight: null,
        styles: {},

        emojiIndex: emojiIndex,
    }),

    methods: {
        open(params = {}) {
            this.isOpen = true;
            this.styles = {
                top: params.top + 'px',
                left: params.left + 'px',
                ...params.styles,
            };
            setTimeout(() => {
                document.addEventListener('click', this.clickOutsideHandler);
            });
        },

        close() {
            this.isOpen = false;
            document.removeEventListener('click', this.clickOutsideHandler);
        },

        selectEmoji(emoji) {
            this.$emit('insertEmoji', emoji);
        },

        clickOutsideHandler(e) {
            if (!e.target.closest('.emoji-modal')) {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.emoji-modal {
    box-shadow: 0 0 rem(10px) rgba(#000, 0.1);

    &::v-deep {

        .emoji-mart {
            border: none;
            height: 100%;
        }

        .emoji-mart-scroll {
            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                background-color: #4A4A4A;
            }
        }


        .emoji-mart-category-label {
            display: none;
        }

        .emoji-mart .emoji-mart-static {
            width: 100%;
        }

        .emoji-mart-anchors {
            display: none !important;
        }

    }

}
</style>
