import { io } from 'socket.io-client';
import store from '@/store';
import wsProtocol from '@/js/wsProtocol';

export const setupConnection = () => {
    const accessToken = store.state.auth.token.access_token;

    const socket = io(`${wsProtocol}://${process.env.VUE_APP_WS_HOST}`, {
        transports: ['websocket'],
        auth: { token: `Bearer ${accessToken}` },
    });

    return socket;
};

export const socketEvents = {
    // Emit Events
    getChats: 'get-chats', // chat-list
    joinChat: 'join-chat',
    leaveChat: 'leave-chat',
    getMessages: 'get-messages', // message-box
    addMessage: 'add-message', // new-message
    deleteMessage: 'delete-message', // remove-message
    editMessage: 'edit-message', // update-message
    startTyping: 'start-typing', // user-start-typing
    stopTyping: 'stop-typing', // user-stop-typing
    addDialog: 'add-dialog', // new-dialog
    editDialog: 'edit-dialog', // update-dialog
    addReaction: 'add-reaction', // new-reaction
    setPrimary: 'set-primary', // update-primary
    updateChatList: 'update-chat-list', // refresh-chat-list
    leaveCommonGroup: 'leave-common-group',

    // Listen Events
    chatList: 'chat-list',
    messageBox: 'message-box',
    newMessage: 'new-message',
    removeMessage: 'remove-message',
    updateMessage: 'update-message',
    userStartTyping: 'user-start-typing',
    userStopTyping: 'user-stop-typing',
    newDialog: 'new-dialog',
    updateDialog: 'update-dialog',
    newReaction: 'new-reaction',
    updatePrimary: 'update-primary',
    refreshChatList: 'refresh-chat-list',
};
