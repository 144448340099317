<template lang="pug">
    .default-layout-block.folders
        h3.folders__title {{ title }}

        horizontal-scroll.folders__list(v-if="!isEmpty(folders)")
            .folder(
                v-for="(folder, index) in folders"
                @contextmenu.prevent.stop="openFolderContext($event, folder)"
                :class = "entityClasses(folder, 'folder_selected')"
                @mouseover="mouseoverFolderHandler(folder, index), onMove(folder)"
                @mouseup="mouseupEntityHandler(folder)"
                @click="clickEntityHandler(folder)"
                @dblclick="dbclickEntityHandler(folder)"
            )
                router-link(
                    :to="getEntityLinkUrl(folder)"
                    event = ""
                    tag = "div"
                    class = "folder__link"
                )
                    span.folder__icon {{ folder.icon }}
                    span.folder__name {{ folder.name }}
            button.folders__plus(
                v-if="showAddBtn"
                @click="$modal.show('create-folder-popup', createFolderParamsWhenEmpty)"
            )
                plusIcon

        .default-layout-block__empty.default-layout-block__empty--clickable(
            v-else
            @click="$modal.show('create-folder-popup', createFolderParamsWhenNonEmpty)"
        )
            no-folders-icon
            p Click here to create a folder
</template>

<script>
import folderIcon from '@/assets/images/icons/folder.svg?inline';
import noFoldersIcon from '@/assets/images/icons/no_folders.svg?inline';
import { getEntityLinkUrl } from '@/js/utils/breadcrump';
import { isEmpty } from 'lodash';
import plusIcon from '@/assets/images/icons/big-plus.svg?inline';
import HorizontalScroll from 'vue-horizontal-scroll';

export default {
    name: 'folders',

    components: {
        folderIcon,
        noFoldersIcon,
        plusIcon,
        HorizontalScroll,
    },

    props: {
        showAddBtn: {
            type: Boolean,
            default: true,
        },

        title: {
            type: String,
            default: 'Folders',
        },
        folders: {
            type: Array,
            required: true,
        },
        openFolderContext: {
            type: Function,
            default: () => {
            },
        },
        createFolderParamsWhenEmpty: {
            type: Object,
            required: true,
        },
        createFolderParamsWhenNonEmpty: {
            type: Object,
            required: true,
        },

        entityClasses: {
            type: Function,
            required: true,
        },
        mouseoverEntityHandler: {
            type: Function,
            required: true,
        },
        mouseupEntityHandler: {
            type: Function,
            required: true,
        },
        clickEntityHandler: {
            type: Function,
            required: true,
        },
        dbclickEntityHandler: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            indexFolderHover: null,
        };
    },

    methods: {
        isEmpty,
        getEntityLinkUrl,
        mouseoverFolderHandler(folder) {
            this.mouseoverEntityHandler(folder);
        },
        onMove(folder) {
            this.$emit('get-folder-id', folder);
        },
    },
};
</script>

<style lang="scss" scoped>
.folders {
    min-height: rem(170px);
    padding: 18px 11px 35px;
    background: #F6F6F6;
    border-radius: 9px;
    box-shadow: none;

    &._horizontal-scroll {
        scroll-behavior: smooth;
    }

    &__title {
        padding: 10px 11px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 18px;
    }

    &__list {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: rem(20px);
        overflow-x: auto;
        -ms-overflow-style: none;


        &::-webkit-scrollbar {
            height: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #4A4A4A;
        }

        @include mobile_or_tablet {
            gap: rem(5px);
        }
    }

    &__plus {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(100px);
        width: rem(100px);
        min-width: rem(100px);
        padding: 12px;
        border: 2px dashed #D7D7D7;
        border-radius: 9px;
        cursor: pointer;

        svg {
            width: rem(30px);
            height: rem(30px);
        }
    }

    .folder {
        @include folder();
        margin-bottom: 5px;
    }
}
</style>
