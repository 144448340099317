<template lang="pug">
    Vue100vh.loading-layout
        app-loader
        slot
</template>

<script>
import Vue100vh from 'vue-div-100vh';

export default {
    name: 'loading-layout',

    components: {
        Vue100vh,
    },
};
</script>
