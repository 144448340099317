import Quill from 'quill';
let Inline = Quill.import('blots/inline');

class Space extends Inline {
    static create(){
        let node = super.create();
        node.innerHTML = ' ';
        return node;
    }
}

Space.blotName = 'space';
Space.className = 'ql-space';
Space.tagName = 'div';
Quill.register(Space);

