<template lang="pug">
    .billing(v-if="formData")
        .title Billing details:
        .fields
            form(@submit.prevent="save")

                app-text-field.app-text-field--small.name-of-company(
                    v-model="$v.formData.nameOfCompany.$model"
                    label="Name or company*"
                    :error="validateNameOnCard"
                    @input="validForParent"
                )
                //app-text-field.app-text-field--small.phone(
                //    v-model="$v.phone.$model"
                //    label="Phone*"
                //    :error="phoneError"
                //)
                app-text-field.app-text-field--small.address(
                    v-model="$v.formData.address.$model"
                    label="Address*"
                    :error="validateAddress"
                    @input="validForParent"
                )
                .fields-block
                    app-select.country.field-block-field(
                        v-if="country"
                        v-model="country"
                        label="Country*"
                        :options="countries"
                        searchable
                    )
                    app-text-field.app-text-field--small.city(
                        v-model="$v.formData.city.$model"
                        label="City*"
                        :error="validateCity"
                        @input="validForParent"
                    )
                    app-text-field.app-text-field--small.post-code(
                        v-model="$v.formData.postalCode.$model"
                        label="Postal Code*"
                        :error="validatePostalCode"
                        @input="validForParent"
                    )
                app-checkbox.have-vat(
                    v-model="haveVat"
                    label="I’m an EU-based business with VAT number"
                )
                app-text-field.app-text-field--small.vat-number(
                    v-model="$v.formData.vatNumber.$model"
                    label="VAT Number"
                    :disabled="!haveVat"
                    :error="validateVatNumber"
                    @input="validForParent"
                )

                app-button(:loading="loading" class="save-billing" :disabled="$v.$invalid") Save
</template>

<script>
import { getCountries } from '@/js/api/requests/common';
// eslint-disable-next-line no-unused-vars
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'billing-form',

    props: {
        formData: {
            type: Object,
            default: () => null,
        },
    },

    validations: {
        formData: {
            nameOfCompany: {
                required,
            },
            address: {
                required,
            },
            city: {
                required,
            },
            postalCode: {
                required,
            },
            vatNumber: {
                // required,
            },
        },
    },

    data() {
        return {
            loading: false,
            haveVat: false,
            countries: [],
        };
    },

    computed: {
        user() {
            return this.$store.getters.user;
        },
        country: {
            get() {
                if (this.formData.country && this.countries.length > 0) {
                    const country = this.countries.find((obj) => obj.key === this.formData.country);
                    if (country) {
                        return { ...country };
                    }
                    this.$awn.alert('The value in the country field is incorrect. The default value is now displayed. Try to select the desired country and save the form again.');
                }
                return { key: 'afghanistan', name: 'Afghanistan' };
            },
            set(country) {
                this.formData.country = country.key;
            },
        },
        // phoneError() {
        //     return this.$v.phone.$error && (!this.$v.phone.integer || !this.$v.phone.minLength) ? 'Need number with min length 9' : '';
        // },

        validateNameOnCard() {
            return this.$v.formData.nameOfCompany.$error && !this.$v.formData.nameOfCompany.required? 'Required field' : '';
        },
        validateAddress() {
            return this.$v.formData.address.$error && !this.$v.formData.address.required? 'Required field' : '';
        },
        validateCity() {
            return this.$v.formData.city.$error && !this.$v.formData.city.required? 'Required field' : '';
        },
        validatePostalCode() {
            return this.$v.formData.postalCode.$error && !this.$v.formData.postalCode.required? 'Required field' : '';
        },
        validateVatNumber() {
            return '';
            // return this.$v.formData.vatNumber.$error && !this.$v.formData.vatNumber.required? 'Required field' : '';
        },
    },

    created() {
        this.validForParent();
        this.getCountries();
    },

    methods: {
        validForParent() {
            if (Object.prototype.hasOwnProperty.call(this.formData, 'validBillingForm')) {
                this.formData.validBillingForm = !this.$v.$invalid;
            }
        },

        async getCountries() {
            try {
                const countries = await getCountries();
                this.countries = Object.keys(countries).map(key => ({
                    name: countries[key].en_name,
                    key,
                }));
            } catch (e) {
                this.$awn.alert('Failed to get countries');
            }
        },

        async save() {
            if (this.loading) return;

            this.loading = true;

            try {
                const fd = new FormData;

                if (this.formData.nameOfCompany) {
                    fd.append('company', this.formData.nameOfCompany);
                }
                // if (this.phone) {
                //     fd.append('phone', this.phone);
                // }
                if (this.formData.country) {
                    fd.append('country', this.formData.country);
                }
                if (this.formData.city) {
                    fd.append('city', this.formData.city);
                }
                if (this.formData.address) {
                    fd.append('address', this.formData.address);
                }
                if (this.formData.postalCode) {
                    fd.append('zip', this.formData.postalCode);
                }
                fd.append('eu_vat', this.formData.vatNumber);

                await this.$store.dispatch('updateUser', fd);
                this.$awn.success('Profile billing was updated successfully');
            } catch (e) {
                console.log(e);
                this.$awn.alert('Fail profile billing was updated');
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import './src/assets/scss/other/billing.scss';
</style>
