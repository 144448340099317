<template lang="pug">
    .wrapper(v-if="isOpen" v-click-outside="close")
        .title
            reskriptIcon
            span ReSkript

        .block
            .title Are you already a ReSkript user?
            app-button.btn(@click="login") Sign In

        .block
            .title Or just enter your name to start commenting
            .note So the author knows who sent the comment
            app-text-field.textField(v-model="anonymousName" customClass="field_2" placeholder="Your name")
            .errorTextField(v-if="errorTextField") {{ errorTextField }}
            app-button.btn(@click="enterName") Continue
</template>

<script>
import reskriptIcon from '@/assets/images/icons/reskript_3.svg?inline';

export default {
    name: 'editor-comments-try-add-by-anonymous',

    components: {
        reskriptIcon,
    },

    data() {
        return {
            isOpen: false,
            anonymousName: '',
            errorTextField: '',
        };
    },

    methods: {
        login () {
            this.$router.push({
                name: 'Login', query: { redirect: this.$route.fullPath },
            });
        },

        enterName () {
            if (!this.anonymousName) {
                this.errorTextField = 'Enter your name';
                return;
            }
            this.errorTextField = '';

            this.$emit('enteredName', this.anonymousName);
            this.close();
        },

        close () {
            setTimeout(() => this.isOpen = false);
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    position: fixed;
    width: 373px;
    left: calc(50% - 150px);
    top: 10%;
    background: $white;
    box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 25px;

    .title {
        display: flex;
        align-items: center;

        span {
            color: #1665FE;
            font-size: 21px;
            font-weight: 700;
            margin-left: 8px;
        }
    }

    .block {
        margin-top: 50px;

        .title {
            font-weight: 500;
            font-size: 14px;
        }
        .note {
            font-weight: 500;
            font-size: 12px;
            color: $grey-light3;
            margin: 8px 0 20px 0;
        }
        .textField {
            margin: 0;
        }
        .errorTextField {
            margin-top: 5px;
            font-size: 12px;
            color: $red2;
        }
    }

    .btn {
        cursor: pointer;
        margin-top: 20px;
        padding: 16px 0;
        background: $grey-light28;
        border-radius: 9px;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        color: $grey-light3;

        &:hover {
            background: $grey-light40;
        }
    }
}
</style>
