<template lang="pug">
    Vue100vh.checkout-layout
        slot
</template>

<script>
import Vue100vh from 'vue-div-100vh';

export default {
    name: 'welcome-layout',

    components: {
        Vue100vh,
    },
};
</script>
