export const getUserMainData = (user) => {
    const id = user.id;
    const firstName = user.username || '';
    const lastName = user.surname || '';
    const haveFullName = firstName && lastName;
    const fullName = haveFullName ? firstName + ' ' + lastName : firstName;
    const email = user.email ? user.email : '';
    const firstLetters = haveFullName ? `${firstName[0]}${lastName[0]}` : '';
    return {
        id,
        firstName,
        lastName,
        fullName,
        firstLetters,
        email,
    };
};

export const getUserFullName = (user) => {
    const { fullName } = getUserMainData(user);
    return fullName;
};
