<template lang="pug">
    .app-loader
        loader-icon
</template>

<script>
import LoaderIcon from '@/assets/images/loader.svg?inline';

export default {
    name: 'app-loader',

    components: { LoaderIcon },
};
</script>

<style scoped lang="scss">
.app-loader {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    padding: 6rem 0 2rem;

    svg {
        width: 5rem;
        height: auto;
    }
}
</style>
