<template lang="pug">
    div
        div.menuWrapper(v-click-outside="closeMenu")
            dotIcon.icon(@click="toggleOpenMenu")

            .menu(v-if="isMenuOpen")
                .menuItem
                    .menuItemIcon
                        reskriptIcon
                    span(@click="openReskriptSite") What is ReSkript?
                .menuItem
                    .menuItemIcon
                        signinIcon
                    span(@click="login") Sign up or Log in
                .menuItem.specificMenuItem
                    .menuItemIcon
                        reportPageIcon
                    span(@click="openReportForm") Report page

        .formReportWrapper(v-if="isFormReportOpen" v-click-outside="closeReportForm")
            .title Why are you reporting this page?

            .reportReasons
                .reasonItem(
                    v-for="(reason, index) in reportReasons"
                    :key="index"
                    @click="setReason(index)"
                )
                    .icon
                        component(:is="radioInput[index]")
                    .title {{ reason.title }}

            textarea-autosize.commentOtherReason(
                v-if="isReasonForAddComment"
                v-model="commentOtherReason"
                placeholder="Please state why are you reporting this page"
            )

            .errorFormReport(
                v-if="isReasonForAddComment && errorFormReport"
            ) {{ errorFormReport }}

            app-button.btnSendReport(
                @click="sendReport"
                :loading="isSendingReport"
            )
                span(v-if="!isSendingReport") Send
</template>

<script>
import dotIcon from '@/assets/images/icons/dot.svg?inline';
import reskriptIcon from '@/assets/images/icons/reskript_3.svg?inline';
import signinIcon from '@/assets/images/icons/signin.svg?inline';
import reportPageIcon from '@/assets/images/icons/report_page.svg?inline';
import radioUnSelectedIcon from '@/assets/images/icons/radio_unselected.svg?inline';
import radioSelectedIcon from '@/assets/images/icons/radio_selected.svg?inline';
import { sendEmail } from '@/js/api/requests/common';

export default {
    name: 'editor-header-report',

    components: {
        dotIcon,
        reskriptIcon,
        signinIcon,
        reportPageIcon,
        radioUnSelectedIcon,
        radioSelectedIcon,
    },

    data () {
        return {
            isMenuOpen: false,
            isFormReportOpen: false,
            reportReasons: [
                { selected: true, title: 'Phishing or spam' },
                { selected: false, title: 'Inappropriate content' },
                { selected: false, title: 'Other' },
            ],
            commentOtherReason: '',
            errorFormReport: '',
            isSendingReport: false,
        };
    },

    computed: {
        radioInput () {
           return this.reportReasons.map(reason => reason.selected ? 'radioSelectedIcon' : 'radioUnSelectedIcon');
        },

        titleActiveReason () {
           return this.reportReasons.find(reason => reason.selected).title;
        },

        isReasonForAddComment () {
            return this.titleActiveReason === 'Other';
        },
    },

    methods: {
        toggleOpenMenu () {
            this.isMenuOpen = !this.isMenuOpen;
        },

        closeMenu () {
            this.isMenuOpen = false;
        },

        openReskriptSite () {
            window.open('https://www.reskript.com/', '_blank');
            this.closeMenu();
        },

        login () {
            this.$router.push({
                name: 'Login', query: { redirect: this.$route.fullPath },
            });
        },

        openReportForm () {
            setTimeout(() => this.isFormReportOpen = true);
        },

        closeReportForm () {
            setTimeout(() => this.isFormReportOpen = false);
        },

        setReason (indexReason) {
            this.reportReasons.forEach(reason => reason.selected = false);
            this.reportReasons[indexReason].selected = true;
        },

        isValidFormReport () {
            if (!this.isReasonForAddComment) {
                return true;
            }

            return !!this.commentOtherReason;
        },

        reportPayload () {
            const publicLink = process.env.VUE_APP_URL.slice(0, -1) + this.$route.fullPath;
            const messageIntro = `На документ, доступный по публичной ссылке [${publicLink}] отправлена жалоба: `;
            const messageBody = this.isReasonForAddComment ? this.commentOtherReason : this.titleActiveReason;

            return {
                subject: 'Public link complaint',
                title: 'Public link complaint',
                email: process.env.VUE_APP_EMAIL_FOR_PUBLIC_LINK_DOCUMENT_REPORTS,
                message: messageIntro + messageBody,
            };
        },

        async sendReport () {
            if (!this.isValidFormReport()) {
                this.errorFormReport = 'Why are you reporting this page?';
                return;
            }

            this.errorFormReport = '';

            try {
                this.isSendingReport = true;
                await sendEmail(this.reportPayload());
                this.closeReportForm();
                this.$awn.success('Report successfully send');
            } catch (e) {
                this.$awn.alert('An error occurred while sending report, please try again later');
            } finally {
                this.isSendingReport = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.menuWrapper {
    position: relative;
    margin: 0 0 0 20px;
    height: 31px;
    display: flex;
    align-items: center;

    .icon {
        cursor: pointer;
    }

    .menu {
        position: absolute;
        top: 40px;
        right: 0;
        background: #FFFFFF;
        box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.15);
        border-radius: 9px;
        width: 145px;
        height: 90px;
        padding: 11px;

        .menuItem {
            font-size: 11px;
            margin-bottom: 10px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;

            .menuItemIcon {
                width: 22px;
            }

            &:hover {
                color: #AAAAAA;
            }
        }

        .specificMenuItem {
            color: #FF3333;
        }
    }
}

.formReportWrapper {
    position: fixed;
    width: 300px;
    //height: 353px;
    left: calc(50% - 150px);
    top: 10%;
    background: #FFFFFF;
    box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    padding: 20px;

    .title {
        font-weight: 500;
        font-size: 14px;
    }

    .reportReasons {
        margin-top: 30px;

        .reasonItem {
            margin-top: 20px;
            cursor: pointer;
            font-size: 11px;
            display: flex;
            align-items: center;

            .title {
                font-size: 11px;
                margin-left: 11px;
            }
        }
    }

    .commentOtherReason {
        margin-top: 20px;
        padding: 10px;
        background: #E7E7E7;
        border-radius: 4px;
        font-size: 10px;
        width: 100%;
    }

    .errorFormReport {
        margin-top: 5px;
        color: red;
        font-size: 12px;
    }

    .btnSendReport {
        cursor: pointer;
        margin-top: 40px;
        background: #E0E0E0;
        border-radius: 6px;
        height: 27px;
        width: 100%;
        font-weight: 600;
        font-size: 12px;
        color: #8A8A8A;

        &:hover {
            background: #C0C0C0;
        }
    }
}
</style>
