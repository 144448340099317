<template lang="pug">
    .notification(
        v-if="formedNotification"
        @click="onClickNotification(formedNotification)"
        :class="classes"
    )
        .left
            .avatar(v-if="formedNotification.userInitials") {{ formedNotification.userInitials }}

        .right
            h6(
                v-if="formedNotification.haveLink"
                class="underline-b"
            )
                div(v-html="formedNotification.title")
                router-link(:to="formedNotification.linkUrl") {{ formedNotification.linkText }}

            h6(
                v-else
                v-html="formedNotification.title"
                class="underline-b"
            )

            p(
                v-if="formedNotification.text"
                :class="'text underline ' + formedNotification.textClass"
            ) {{ formedNotification.text }}

            small(class="date") {{ formedNotification.date }}

            .btns(v-if="formedNotification.btns.length > 0")
                app-button(
                    v-for="(btn, index) in formedNotification.btns"
                    :key="index"
                    @click="btn.click"
                    class="btn"
                ) {{ btn.text }}
</template>

<script>
import { mapGetters } from 'vuex';
import { websocketsEventsNotifications, notificationInviteStatus } from '@/js/const';
import { getDiffCurrentTime } from '@/js/utils/moment';
import { getEntityLinkUrl } from '@/js/utils/breadcrump';
import { ENTITY_TYPES_NAMES } from '@/js/const';
import { getUserMainData } from '@/js/utils/user';
import { isEmpty } from 'lodash';
import moment from 'moment';

export default {
    name: 'notification',

    props: {
        notification: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters(['user', 'userWS']),

        classes() {
            return {
                'notification_unread': this.formedNotification.isRead === false,
            };
        },

        formedNotification() {
            const formedNotification = {
                id: this.notification.id,
                userInitials: this.notification.senderFirstLetters ?? '',
                haveLink: this.notification.data && this.notification.data.haveLink,
                isRead: this.notification.isRead,
                title: '',
                linkUrl: '',
                linkText: '',
                text: '',
                textClass: '',
                date: this.notification.created_at
                    ? getDiffCurrentTime(moment.utc(this.notification.created_at).local())
                    : '',
                btns: [],
            };

            const { type } = this.notification;

            if (type === websocketsEventsNotifications.notedInDocument) {
                const { senderFullname, documentId, documentName } = this.notification.data;

                formedNotification.title = `<b>${senderFullname}</b> tagged you in document`;
                formedNotification.url = { name: 'Editor', params: { id: documentId } };
                formedNotification.text = documentName;
                formedNotification.textClass = 'text__title';
                formedNotification.btns = this.viewButton(documentId, ENTITY_TYPES_NAMES.document);

            } else if (type === websocketsEventsNotifications.notedInProject) {
                const { senderFullname, projectId, projectName } = this.notification.data;

                formedNotification.title = `<b>${senderFullname}</b> tagged you in project`;
                formedNotification.url = { name: 'ProjectDetail', params: { id: projectId } };
                formedNotification.text = projectName;
                formedNotification.textClass = 'text__title';
                formedNotification.btns = this.viewButton(projectId, ENTITY_TYPES_NAMES.project);

            } else if (type === websocketsEventsNotifications.asParticipantInDocument) {
                const { senderFullname, documentId, documentName } = this.notification.data;

                formedNotification.title = `<b>${senderFullname}</b> tagged you in document AS part`;
                formedNotification.url = { name: 'Editor', params: { id: documentId } };
                formedNotification.text = documentName;
                formedNotification.textClass = 'text__title';
                formedNotification.btns = this.viewButton(documentId, ENTITY_TYPES_NAMES.document);

            } else if (type === websocketsEventsNotifications.inviteInDocument) {
                const { senderFullname, documentId, documentName, status } = this.notification.data;

                formedNotification.url = { name: 'Editor', params: { id: documentId } };
                formedNotification.text = documentName;
                formedNotification.textClass = 'text__title';

                if (status === notificationInviteStatus.accepted || status === notificationInviteStatus.rejected) {
                    formedNotification.btns = this.viewButton(documentId, ENTITY_TYPES_NAMES.document);
                }

                if (status === notificationInviteStatus.accepted) {
                    formedNotification.title = `You accept invite <b>${senderFullname}</b> in document`;
                } else if (status === notificationInviteStatus.rejected) {
                    formedNotification.title = `You reject invite <b>${senderFullname}</b> in document`;
                } else if (status === notificationInviteStatus.normal) {
                    formedNotification.title = `<b>${senderFullname}</b> invited you in document`;
                    formedNotification.btns = [
                        {
                            text: 'Accept',
                            click: () => {
                                this.sendNotificationToInviter(
                                    websocketsEventsNotifications.inviteInDocumentForInviterAccept,
                                );

                                this.userWS.emit('update-notification', {
                                    'notification_id': this.notification.id,
                                    data: { status: notificationInviteStatus.accepted },
                                });

                                let link = this.notification.data.invitelink;
                                link = link.slice(link.indexOf('share-invite'));

                                this.$router.push(`${link}?email=${this.user.email}`);
                            },
                        },
                        {
                            text: 'Reject',
                            click: () => {
                                this.sendNotificationToInviter(
                                    websocketsEventsNotifications.inviteInDocumentForInviterReject,
                                );

                                this.userWS.emit('update-notification', {
                                    'notification_id': this.notification.id,
                                    data: { status: notificationInviteStatus.rejected },
                                });
                            },
                        },
                    ];
                }
            } else if (type === websocketsEventsNotifications.inviteInDocumentForInviter) {
                this.inviteInDocumentForInviter(
                    formedNotification,
                    `You invited <b>${this.notification.data.invitedFullname}</b> in document`,
                );
            } else if (type === websocketsEventsNotifications.inviteInDocumentForInviterAccept) {
                this.inviteInDocumentForInviter(
                    formedNotification,
                    `<b>${this.notification.data.invitedFullname}</b> accept you invite in documentt`,
                );
            } else if (type === websocketsEventsNotifications.inviteInDocumentForInviterReject) {
                this.inviteInDocumentForInviter(
                    formedNotification,
                    `<b>${this.notification.data.invitedFullname}</b> reject you invite in document`,
                );
            }

            return formedNotification;
        },
    },

    methods: {
        onClickNotification(notification) {
            this.userWS.emit('viewed-notification', { notification_id: notification.id });
        },

        sendNotificationToInviter(event) {
            const { documentId, documentName, senderId } = this.notification.data;
            const sender = getUserMainData(this.user);

            const data = {
                documentId,
                documentName,
                invitedFullname: sender.fullName,
                senderFirstLetters: sender.firstLetters,
            };

            this.userWS.emit('send-notification', {
                sender: { id: sender.id },
                receivers: [{ id: senderId }],
                type: event,
                data,
            });
        },

        inviteInDocumentForInviter(formedNotification, title) {
            const { documentId, documentName } = this.notification.data;

            formedNotification.title = title;
            formedNotification.url = { name: 'Editor', params: { id: documentId } };
            formedNotification.text = documentName;
            formedNotification.textClass = 'text__title';
            formedNotification.btns = this.viewButton(documentId, ENTITY_TYPES_NAMES.document);
        },

        viewButton(id, entityTypeName) {
            return [{
                text: 'View',
                click: () => {
                    this.redirectPageWithCheckTypeCurrPage({
                        ...this.entity, id: id, type: entityTypeName,
                    });
                },
            }];
        },

        redirectPageWithCheckTypeCurrPage(entity) {
            const entityUrlLocation = getEntityLinkUrl(entity);

            const { href } = this.$router.resolve(entityUrlLocation);

            const isCurrentPath = href === this.$route.path;

            if (!isCurrentPath || (isCurrentPath && !isEmpty(this.$route.query))) {
                this.$router.push(entityUrlLocation);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.notification {
    display: flex;
    padding: 1rem 1.1rem;
    cursor: pointer;

    @include mobile_or_tablet {
        border-bottom: 1px solid $grey-light26;
    }

    .underline {
        text-decoration: underline;
    }

    .underline-b {
        &::v-deep {
          b {
              text-decoration: underline;
          }
        }
    }

    &_unread {
        background: $blue6;
    }

    .date {
        color: $grey-dark4;
        @include mobile_or_tablet {
            color: $grey-light27;
        }
    }

    .btn {
        display: inline-block;
        margin-top: rem(13px);
        min-height: auto;
        min-width: auto;
        font-size: rem(14px);
        padding: rem(8px) rem(20px);
    }

    .btns {
        button {
            margin-right: rem(15px);

            &:last-child {
                margin-right: rem(0px);
            }
        }
    }

    .left {
        flex-shrink: 0;
        width: rem(44px);
    }

    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(36px);
        height: rem(36px);
        font-size: rem(15px);
        font-weight: 500;
        color: $white;
        background: $blue;
        border-radius: 50%;

        @include mobile_or_tablet {
            background: $white;
            color: $grey-light25;
            border: 1px solid $grey-light26;
        }
    }

    .text {
        font-weight: bold;
        font-size: rem(14px);
        margin-bottom: 0.4rem;

        &__title {
            @include name_reduction(210px);
        }
    }

    h6 {
        margin-bottom: 0.2rem;
    }

    p {
        margin-bottom: 0.85rem;
        font-size: rem(13px);
        line-height: 1.1;
        letter-spacing: 0.008em;
    }

    small {
        font-size: rem(12px);
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }
}
</style>
