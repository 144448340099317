<template lang="pug">
    .editor-actions-popup-layout(
        v-click-outside="close"
        @click.stop
        :class="{'opened' : isOpen}"
    )
        p.title {{layoutTitle}}
        component(
            :is="action"
            :format="format"
            :document="document"
            :callout="callout"
            @formatting="formatting"
            @insert-block-callout="$emit('insert-block-callout')"
            @close="close"
        )
</template>

<script>
import formatting from '@/components/editor/actionsPopup/EditorActionsFormatPopup';
import mode from '@/components/editor/actionsPopup/EditorActionsModePopup';
import blocks from '@/components/editor/actionsPopup/EditorActionsBlocksPopup';
import share from '@/components/editor/actionsPopup/EditorActionsSharePopup';

export default {
    name: 'editor-actions-popup-layout',
    components: { formatting, mode, blocks, share },
    props: {
        format: {
            type: Object,
            default: () => ({}),
        },
        document: {
            type: Object,
            default: () => ({}),
        },

        callout: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isOpen: false,
            action: '',
        };
    },

    computed: {
        layoutTitle() {
            switch (this.action) {
                case 'blocks': return 'Add blocks';
                case 'formatting': return 'Text Formatting';
                case 'share': return 'Share';
                case 'mode': return 'Mode';
                default: return 'Add blocks';
            }
        },
    },

    methods: {
        formatting(options) {
            this.$emit('formatting', options);
        },
        close() {
            this.isOpen = false;
            this.action = '';
            this.$emit('closed');
        },
    },
};
</script>

<style scoped lang="scss">
.editor-actions-popup-layout {
    position: fixed;
    border: 1px solid #E2E2E2;
    padding: 12px 12px 9px 12px;
    box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    width: 256px;
    min-height: 300px;
    max-height: 545px;
    transform: translateX(calc(100vw + 300px));
    top: rem(220px);
    background-color: #fff;
    overflow-y: auto;
    z-index: 1;
    -ms-overflow-style: none;
    transition: .5s ease-in-out;

    &::-webkit-scrollbar {
        width: 0;
    }

    &.opened {
        transform: translateX(calc(100vw - 370px));
        transition: .5s ease-in-out;
    }

    .title {
        font-weight: 500;
        line-height: 17px;
        margin-bottom: 17px;
    }
}
</style>
