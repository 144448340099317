import BreadcrumpsMixin from '@/mixins/BreadcrumpsMixin';
import MoveChildrenMixin from '@/mixins/MoveChildrenMixin';
import { ENTITY_TYPES_NAMES } from '@/js/const';

export default {
    mixins: [MoveChildrenMixin, BreadcrumpsMixin],
    data() {
        return {
            ENTITY_TYPES_NAMES,
        };
    },
};
