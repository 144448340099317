<template lang="pug">
    .plan-checkout(v-if="tariff")
        .plan-checkout__main
            .plan-checkout__title.plan-checkout__block You new plan
            .plan-checkout__block
                .plan-checkout__tariff-and-price
                    .plan-checkout__tariff {{ tariff.name }}
                    .plan-checkout__price {{ tariff.old_price }} USD
                .plan-checkout__option(v-html="'Unlimited version history'")
                .plan-checkout__option Project Invitation
                .plan-checkout__discount(v-if="tariff.price_difference")
                    .plan-checkout__discount-title {{ tariff.price_difference_percent }}
                    .plan-checkout__discount-price {{ tariff.price_difference }}
            .plan-checkout__total.plan-checkout__block
                .plan-checkout__total-title Total
                .plan-checkout__total-price {{ tariff.total }} USD
            .plan-checkout__block.plan-checkout__peculiaritys
                .plan-checkout__peculiarity(v-for="(peculiarity, i) in peculiaritys" :key="i")
                    .plan-checkout__peculiarity-circle
                    .plan-checkout__peculiarity-text(v-html="peculiarity")
                app-button.plan-checkout__btn.plan-checkout__btn-pc(@click="submit" :loading="loading"
                    :disabled="!subscriptionData.validPaymentForm || !subscriptionData.validBillingForm"
                )
                    credit-icon(class="credit-icon")
                    span Pay and upgrade
</template>

<script>
import { PLAN_PERIOD } from '@/js/const';
import CreditIcon from '@/assets/images/icons/credit-card2.svg?inline';

export default {
    name: 'plan-checkout',

    components: {
        CreditIcon,
    },

    inject: {
        submit: { default: null },
    },

    props: {
        subscriptionData: {
            type: Object,
            default: () => null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            PLAN_PERIOD,
            peculiaritys: [
                'You\'ll pay 10 USD now, which is prorated for the current billing period.',
                'Your plan is billed monthly and will renew for 10 USD (plus any applicable taxes and minus any discounts)',
                'You can cancel any time before this date.',
            ],
        };
    },

    computed: {
        tariff() {
            const planSelectedForCheckout = this.$store.getters.planSelectedForCheckout;
            if (planSelectedForCheckout && this.subscriptionData) {
                const arr = planSelectedForCheckout.name.split('_');
                const period = arr[1];

                const tariffResult = {
                    //TO DO пока можеть быть ток ПРО год и месяц, иначе редирект на главную
                    name: period === PLAN_PERIOD.ANNUAL ? 'Pro (yearly)' : 'Pro (monthly)',

                    old_price: '',
                    price_difference: '',
                    price_difference_percent: '',
                    total: '',
                };

                const { discount_price, price, price_difference, price_difference_percent } = this.subscriptionData;
                if (discount_price !== '') {
                    tariffResult.old_price = price;
                    tariffResult.price_difference = this.priceDifference(price_difference);
                    tariffResult.price_difference_percent = this.priceDifferencePercent(price_difference_percent);
                    tariffResult.total = discount_price;
                } else {
                    if (period === PLAN_PERIOD.ANNUAL) {
                        tariffResult.old_price = 120;
                        tariffResult.price_difference = this.priceDifference(26);
                        tariffResult.price_difference_percent = this.priceDifferencePercent('20%');
                        tariffResult.total = 96;
                    } else if (period === PLAN_PERIOD.MONTH) {
                        tariffResult.old_price = 10;
                        tariffResult.total = 10;
                    }
                }

                return tariffResult;
            }

            return null;
        },
    },

    methods: {
        priceDifference(priceDifference) {
            return `-${priceDifference} USD`;
        },
        priceDifferencePercent(priceDifferencePercent, discountText = 'Coupon discount') {
            return `${discountText} (-${priceDifferencePercent})`;
        },
    },
};
</script>

<style lang="scss">
.plan-checkout {
    width: 100%;

    @media (max-width: 1200px) {
        margin: 0 auto;
        margin-top: rem(15px);
    }

    &__btn{
        @media (max-width: 1200px) {

        }
    }

    &__main {
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
        background: $white;
        border-radius: 10px;
        margin: 0 auto;

        @media (max-width: 1200px) {
            background:  $grey-light20;
            max-width: rem(600px);
            box-shadow: none;
            margin-bottom: rem(10px);
        }
    }

    &__block {
        padding: rem(13px) rem(12px);
    }

    &__title {
        font-weight: 500;
        font-size: rem(18px);
        border-bottom: 1px solid $grey-light15;
    }

    &__tariff-and-price {
        font-size: rem(16px);
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(13px);
    }

    &__tariff {
        font-size: rem(16px);
        font-weight: 500;
    }

    &__price {
        font-size: rem(16px);
        font-weight: 600;
        color: $black0;
        line-height: rem(20px);
    }

    &__option {
        color: $grey-light16;
        font-size: rem(13px);
        font-weight: 500;
        margin-bottom: rem(17px);
        line-height: 12px;
        &:last-child {
            margin-bottom: rem(13px);
        }
    }

    &__discount {
        color: $green;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__discount-title {
        font-size: rem(10px);
        font-weight: 500;
    }

    &__discount-price {
        font-size: rem(12px);
        font-weight: 600;
    }

    &__total {
        border-bottom: 1px solid $grey-light15;
        border-top: 1px solid $grey-light15;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1200px) {
            border-bottom: none;
        }
    }

    &__total-title {
        font-size: rem(18px);
        font-weight: 500;
    }

    &__total-price {
        font-size: rem(18px);
        font-weight: 500;
    }

    &__peculiarity {
        display: flex;
        margin-bottom: rem(20px);
        &:last-child {
            margin-bottom: 0px;
        }

        &-circle {
            display: block;
            width: rem(3px);
            height: rem(3px);
            background-color: black;
            border-radius: 200px;
            margin-top: rem(5px);
        }

        &-text {
            font-size: rem(16px);
            font-weight: 500;
            padding-left: rem(10px);
            width: calc(100% - #{rem(3px)});
        }
    }

    &__peculiaritys {
        padding: rem(28px) rem(20px);
        padding-bottom: rem(14px);
        padding-right: rem(25px);
        text-align: justify;
        @media (max-width: 1200px) {
            display: none;
        }
    }
}
</style>
