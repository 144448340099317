<template lang="pug">
    app-modal.register-completed-popup(
        name="register-completed-popup"
        closer
        @beforeOpen="beforeOpen"
        @closed="closed"
    )
        .title Welcome to ReSkript Early Access!
        p Dear {{ name }},
        p ReSkript team is pleased to welcome you to our platform! Thank you for joining the Early Access version of the product. Some bugs & issues may appear, and we would be glad to hear your opinion and feedback!
        p If you have any suggestions/ideas on improving ReSkript don’t hesitate to contact us*!
        p We hope you have a good time and experience on the platform.
        p Sincerely,
        p ReSkript Team
        .footnote *You can offer new ideas in Profile &#8209;> Help &#8209;> Request Feature
</template>

<script>
export default {
    name: 'register-completed-popup',

    data: () => ({
        name: '',
    }),

    computed: {
        planSelectedForCheckout() {
            return this.$store.getters.planSelectedForCheckout;
        },
    },

    methods: {
        beforeOpen(e) {
            this.name = e.params?.name;
        },
        // eslint-disable-next-line no-unused-vars
        closed(e) {
            if (this.planSelectedForCheckout) {
                this.$router.push({ name: 'Checkout' });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.register-completed-popup {
    &::v-deep .app-modal__container {
        width: 35rem;
        padding: 4rem 1.75rem 2rem;
    }

    .title {
        margin-bottom: rem(32px);

        font-size: rem(22px);
        font-weight: 600;
        text-align: center;
    }

    p {
        margin-bottom: rem(6px);

        font-size: rem(18px);
        line-height: rem(22px);
    }

    .footnote {
        margin-top: rem(32px);

        font-size: rem(14px);
        color: #585858;
    }

    @include mobile_or_P {
         &::v-deep {
             .vm--modal {
                 height: 100% !important;
             }

             .app-modal__container {
                 width: 100vw;
             }
         }
     }
}
</style>
