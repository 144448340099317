<template lang="pug">
    .default-layout(:class="classes")
        sidebar
        .default-layout__wrapper.container
            mobile-header
            main-header(@view:toggle="toggleViewHandler()")
            slot(:list="list")

</template>

<script>
import Sidebar from '@/components/layouts/Sidebar';
import MainHeader from '@/components/layouts/MainHeader';
import MobileHeader from '@/components/layouts/MobileHeader';

export default {
    name: 'default-layout',

    components: {
        MobileHeader,
        MainHeader,
        Sidebar,
    },
    data() {
        return {
            list: true,
            classes: '',
        };
    },
    created() {
        let classes = '';
        if (this.$route.name === 'NotificationsPage') {
            classes = 'default-layout_white';
        }
        this.classes = classes;
    },
    methods: {
        toggleViewHandler() {
            this.list = !this.list;
        },
    },
};
</script>
