import Quill from 'quill';
let Inline = Quill.import('blots/embed');

class SubDocument extends Inline {
    static create({ id, name, parent }) {
        let node = super.create();
        let icon = '';

        node.setAttribute('data-document-id', id);
        node.setAttribute('data-document-name', name);
        node.setAttribute('parent', parent);
        node.setAttribute('spellcheck', false);

        node.addEventListener('click', function() {
            let href = `/editor/${id}`;

            if (window.location.pathname.includes('/public/')) {
                href = `${window.location.pathname}/${id}`;
            }

            window.location.href = href;
        });

        if (parent === 'true') {
          node.classList.add('ql-document--sub');
          icon = `<image class='ql-document__icon' src=${require('@/assets/images/editor/icons/document.svg')} alt='document-${name}-icon'>`;
        } else {
            node.classList.add('ql-document--link');
            icon = `<image class='ql-document__icon' src=${require('@/assets/images/editor/icons/document-link.svg')} alt='document-${name}-icon'>`;
        }

        node.innerHTML = icon+`<span class='ql-document__text'>${name}</span>`;
        return node;
    }

    static value(node) {
        return {
            id: node.getAttribute('data-document-id'),
            name: node.getAttribute('data-document-name'),
            spellcheck: node.getAttribute('spellcheck'),
            parent: node.getAttribute('parent'),
        };
    }
}

SubDocument.blotName = 'sub-document';
SubDocument.className = 'ql-document';
SubDocument.tagName = 'div';
Quill.register(SubDocument);
