<template lang="pug">
    .settings-account
        .title Account

        app-text-field.app-text-field--small(
            v-model="email.data"
            placeholder="Email"
            label="Update Email Address"
            :error="emailError"
        )
        app-button(
            @click="saveEmail"
            :loading="email.loading"
        ) Save Changes

        hr

        //app-text-field.app-text-field--small.currentPassword(
            v-model="password.currentPassword"
            placeholder="Current Password"
            label="Change Password"
        //)
        app-text-field.app-text-field--small(
            v-model="password.newPassword"
            placeholder="New Password"
            :error="newPasswordError"
        )
        app-button(
            @click="savePassword"
            :loading="password.loading"
        ) Save Changes

</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { updateUserPassword } from '@/js/api/requests/auth';

export default {
    name: 'settings-account',

    data: () => ({
        email: {
            data: '',
            loading: false,
            error: null,
        },

        password: {
            currentPassword: '',
            newPassword: '',
            loading: false,
            error: null,
        },
    }),

    validations: {
        email: {
            data: {
                required,
                email,
            },
        },
        password: {
            newPassword: {
                required,
            },
        },
    },

    computed: {
        user() {
            return this.$store.getters.user;
        },

        emailError() {
            if (this.$v.email.$dirty) {
                if (!this.$v.email.data.required) {
                    return 'Required field';
                } else if (!this.$v.email.data.email) {
                    return 'Invalid Email Format';
                }
            }
            return this.email.error;
        },

        newPasswordError() {
            if (this.$v.password.$dirty) {
                if (!this.$v.password.newPassword.required) {
                    return 'Required field';
                }
            }
            return this.password.error;
        },
    },

    created() {
        this.email.data = this.user.email || '';
    },

    methods: {
        async saveEmail() {
            this.email.error = null;
            if (this.email.loading) return;

            if (this.$v.email.$invalid) {
                this.$v.email.$touch();
                return;
            }

            this.email.loading = true;

            try {
                await this.$store.dispatch('updateUser', {
                    email: this.email.data,
                });
            } catch (e) {
                this.email.error = 'Something went wrong, please try again later';
            } finally {
                this.email.loading = false;
            }
        },

        async savePassword() {
            this.password.error = null;
            if (this.password.loading) return;

            if (this.$v.password.$invalid) {
                this.$v.password.$touch();
                return;
            }

            this.password.loading = true;

            try {
                await updateUserPassword({
                    new_password: this.password.newPassword,
                });
            } catch (e) {
                this.password.error = 'Something went wrong, please try again later';
            } finally {
                this.password.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.settings-account {
    padding: 1.5rem;
}

.title {
    margin-bottom: 2rem;

    font-size: rem(20px);
    font-weight: bold;
}

.app-text-field {
    max-width: 18rem;
}

hr {
    height: 1px;
    margin: 1.5rem 0;

    background: rgba($grey, 0.25);
    border: none;
}

.currentPassword {
    margin-bottom: 0.75rem;
}
@include mobile_or_tablet {
    .title {
        font-size: 1.5rem;
    }

    .app-text-field {
        max-width: 100%;
    }
}
</style>
