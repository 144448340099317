<template lang="pug">
    .sidebar
        .sidebar-top
            logo.sidebar-logo
            .sidebar-new
                search-field
                app-button(@click="$refs.creationList.toggle()")
                    plus-icon
                    p New

                sidebar-creation-list(ref="creationList")
            .sidebar-links
                router-link.sidebar-link(to="/" exact)
                    .sidebar-link__icon
                        home-icon
                    | Dashboard
                router-link.sidebar-link(to="/library" ref="librabyLink")
                    .sidebar-link__icon
                        book-icon
                    | Library
                router-link.sidebar-link(to="/shared" exact)
                    .sidebar-link__icon
                        shared-icon
                    | Shared with me
                router-link.sidebar-link(to="/how-to" exact)
                    .sidebar-link__icon
                        howto-icon
                    | HowToReSkript
                router-link.sidebar-link(to="/qw" exact)
                    .sidebar-link__icon
                        task-icon
                    | Tasks
                router-link.sidebar-link(to="/bin" exact)
                    .sidebar-link__icon
                        bin-icon
                    | Bin
                //.sidebar-memory
                    .sidebar-memory__drag
                        .sidebar-memory__drag-indicator(style="width: 40%")
                    .sidebar-memory__used 700 mb used
                    .sidebar-memory__total 2 GB
</template>

<script>
import homeIcon from '@/assets/images/icons/home.svg?inline';
import bookIcon from '@/assets/images/icons/book.svg?inline';
import howtoIcon from '@/assets/images/icons/howto.svg?inline';
import taskIcon from '@/assets/images/icons/task.svg?inline';
import favoriteIcon from '@/assets/images/icons/favorite.svg?inline';
import binIcon from '@/assets/images/icons/bin.svg?inline';
import cloudIcon from '@/assets/images/icons/cloud.svg?inline';
import sharedIcon from '@/assets/images/icons/shared.svg?inline';
import plusIcon from '@/assets/images/icons/white-plus.svg?inline';


import SidebarCreationList from '@/components/layouts/SidebarCreationList';
import Logo from '@/components/layouts/Logo';
import SearchField from '@/components/layouts/SearchField';

export default {
    name: 'sidebar',

    components: {
        SidebarCreationList,
        plusIcon,
        homeIcon,
        bookIcon,
        howtoIcon,
        taskIcon,
        favoriteIcon,
        binIcon,
        cloudIcon,
        Logo,
        sharedIcon,
        SearchField,
    },

    mounted() {
        this.checkActiveClassNameOnLibrary();
    },

    methods: {
        checkActiveClassNameOnLibrary () {
            this.$watch('$route.path', newPath => {
                const el = this.$refs.librabyLink.$el;
                const currentLinkIsFolders = () => {
                    return newPath.includes('folders');
                };
                const libraryLinkHasNotActiveClass = () => {
                    return !el.className.includes('router-link-active');
                };
                const addActiveClassToLibraryLink = () => {
                    el.className = el.className + ' router-link-active';
                };
                const currentLinkIsNotLibrary = () => {
                    return !newPath.includes('library');
                };
                const removeActiveClassFromLibraryLink = () => {
                    el.className = el.className.replace('router-link-active', '').replace('  ', ' ');
                };

                if (currentLinkIsFolders()) {
                    if (libraryLinkHasNotActiveClass()) {
                        addActiveClassToLibraryLink();
                    }
                } else if (currentLinkIsNotLibrary()) {
                    removeActiveClassFromLibraryLink();
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.sidebar {
    position: sticky;
    top: 0;
    z-index: 1;

    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;

    width: 195px;
    height: 100vh;

    background-color: #f5f5fa;

    &-top {
        padding: 39px 11px;
    }

    &-logo {
        display: flex;
        justify-content: center;

        margin-bottom: rem(17px);

        img {
            width: 100%;
        }
    }


    &-new {
        position: relative;

        .search-field {
            width: 100%;
            height: 30px;

            margin-bottom: 9px;

            svg {
                margin-right: 12px;
            }
        }

        .app-button {
            height: 30px;
            justify-content: left;
            width: 100%;
            margin-bottom: 2vh;

            border-radius: rem(4px);

            font-size: rem(14px);
            font-weight: 600;

            p {
                margin-left: 20px;
            }
        }

        .sidebar-creation-list {
            position: absolute;
            top: 0;
            left: calc(100% + -2.6rem);
        }
    }


    hr {
        height: 1px;
        margin-bottom: 2.8vh;

        background: $grey;
        border: none;

    }

    &-link {
        display: flex;
        align-items: center;

        margin-bottom: 13px;
        padding: 4px 0;

        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #595959;

        transition: color .2s;

        @media (hover: hover)  {
            &:hover {
                background-color: #E6E4ED;
            }
        }

        &__icon {
            display: flex;
            justify-content: center;
            margin-left: 13px;
            margin-right: 21px;
            height: 20px;

            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;

                transition: stroke .2s;
                stroke: #595959;

            }
        }

        &--storage {
            margin-bottom: 0.9rem;

            .sidebar-link__icon svg {
                width: 75%;
            }
        }

        @media (hover: hover) {
            &:hover {
                color: black;

                .sidebar-link__icon svg {
                    stroke: #221B38;
                }
            }
        }

        &.router-link-active {
            font-weight: 500;
            color: $black;
            background-color: #E7E7E7;
            border-radius: 4px;

            .sidebar-link__icon svg {
                stroke: #221B38;
            }
        }
    }

    &-memory {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        width: 100%;

        font-size: rem(12px);

        &__drag {
            width: 97%;
            height: rem(4px);
            margin: 0 auto 0.6rem;

            background: $grey-light;

            &-indicator {
                height: 100%;

                background: $blue;
            }
        }

        &__used {
            color: $blue;
        }
    }
}
</style>
