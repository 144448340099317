import Quill from 'quill';
const Embed = Quill.import('blots/embed');

class Formula extends Embed {
    static create(value) {
        if (window.katex == null) {
            throw new Error('Formula module requires KaTeX.');
        }
        const node = super.create(value);
        if (typeof value === 'string') {
            window.katex.render(value, node, {
                throwOnError: false,
                errorColor: '#f00',
            });
            node.setAttribute('data-value', value);
        }
        return node;
    }

    static value(domNode) {
        return domNode.getAttribute('data-value');
    }

    html() {
        const { formula } = this.value();
        return `<span>${formula}</span>`;
    }
}
Formula.blotName = 'formula2';
Formula.className = 'ql-formula';
Formula.tagName = 'SPAN';

Quill.register(Formula);
