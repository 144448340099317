<template lang="pug">
    .privacy-agreement
        arrow-back.arrow-back(@click="back")
        .content
            h2 Privacy & Data Collection
            p We use cookies to securely identify your account, keep you signed in, and improve our service.
            p We use analytics to improve the product and solve customer issues. <br>Analytics are enabled by default because they are critical to our business.
            p You may address your consent <nobr>to analytics</nobr> by emailing&#32;
                a(href="mailto:help@reskript.com") help@reskript.com
                | .
            p We occasionaly send product updates & marketing emails. A link to unsubscribe is included in every email.
            p Please read our&#32;
                a(href="/files/policy.pdf" target="_blank")
                    nobr Terms of Service & Privacy Policy
                | &#32;for more information.
            .buttons
                app-button(@click="$emit('agree')") Agree
                app-button.app-button--grey(@click="back") Disagree
</template>

<script>
import arrowBack from '@/assets/images/arrow_back.svg?inline';

export default {
    name: 'privacy-agreement',

    components: {
        arrowBack,
    },

    methods: {
        back() {
            this.$router.replace({ name: 'Login' });
        },
    },
};
</script>

<style scoped lang="scss">
.privacy-agreement {
    width: 100vw;
    height: 100vh;
    padding: rem(64px) rem(76px);

    background: #fff;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        max-width: rem(800px);
        height: 100%;
        margin: 0 auto;
    }

    .arrow-back {
        width: rem(40px);

        cursor: pointer;
    }

    h2 {
        margin-bottom: rem(40px);

        font-size: rem(40px);
        text-align: center;
    }

    p {
        margin-bottom: rem(16px);

        font-size: rem(18px);
        line-height: 1.2;
    }

    a {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: rem(2px);
            left: 0;

            display: block;

            height: 1px;

            background: $black;
        }

        @media (hover: hover) {
            &:hover{
                color: $blue;

                &:after {
                    background: $blue;
                }
            }
        }
    }

    .buttons {
        margin: rem(32px) 0 rem(48px);

        .app-button {
            margin-right: rem(24px);
        }
    }

    @include mobile_or_tablet {
        width: 90%;
        max-width: 100vmin;
        height: initial;
        margin: 0 auto;
        padding: 1rem;

        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

        .arrow-back {
            margin-bottom: 2rem;
        }

        h2 {
            margin-bottom: rem(24px);

            font-size: rem(24px);
        }

        p {
            font-size: rem(16px);
        }

        .buttons {
            display: flex;
            justify-content: space-between;

            margin: rem(24px) 0;

            .app-button {
                margin-right: 0;
            }
        }
    }
}
</style>
