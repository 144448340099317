<template lang="pug">
    app-modal.settings(
        name="settings"
        @beforeOpen="beforeOpen"
        @opened="opened"
    )
        main
            aside
                .tab(v-for="(tab, index) in tabs")
                    a.tab-link(
                        :class="{active: activeTab === index, new: tab.new}"
                        @click="activeTab = index"
                    )
                        .tab-link__icon(v-if="tab.icon")
                            img(:src="require('@/assets/images/icons/profile-tabs/'+tab.icon+'.svg')" :alt="tab.icon")
                        .tab-link__name {{ tab.name }}
            section(ref="section")
                div
                    component(:is="tabs[activeTab].component")
</template>

<script>
import SettingsProfile from '@/components/other/settings/Profile';
import SettingsAccount from '@/components/other/settings/Account.vue';
import SettingsGeneral from '@/components/other/settings/General.vue';
import SettingsSubscription from '@/components/other/settings/Subscription.vue';
import SettingsTeamManagement from '@/components/other/settings/TeamManagement.vue';
import SettingsInviteFriends from '@/components/other/settings/InviteFriends.vue';
import Scrollbar from 'smooth-scrollbar';

export default {
    name: 'settings',

    components: {
        SettingsProfile,
        SettingsAccount,
        SettingsGeneral,
        SettingsSubscription,
        SettingsTeamManagement,
        SettingsInviteFriends,
    },

    data: () => ({
        activeTab: 2,
        tabs: [
            {
                name: 'Profile',
                component: 'SettingsProfile',
                id: 'profile',
                icon: 'profile',
            },
            {
                name: 'Account',
                component: 'SettingsAccount',
                id: 'account',
                icon: 'lock',
            },
            {
                name: 'General',
                component: 'SettingsGeneral',
                id: 'general',
                icon: 'planet',
            },
            {
                name: 'Subscription',
                component: 'SettingsSubscription',
                id: 'subscription',
                icon: 'credit',
            },
            {
                name: 'Team Management',
                component: 'SettingsTeamManagement',
                id: 'team-management',
                icon: 'people',
            },
            // It wil be necessary for tasks in future, just uncomment it
            // {
            //     name: 'Invite Friends',
            //     component: 'SettingsInviteFriends',
            //     id: 'invite-friends',
            //     new: true,
            //     icon: 'friends',
            // },
            // {
            //     name: 'Integrations',
            //     component: 'SettingsInviteFriends',
            //     id: 'integrations',
            //     icon: 'plug_off',
            // },
        ],
    }),

    methods: {
        beforeOpen(e) {
            const tabId = e.params?.tab;
            if (tabId) {
                const tabIndex = this.tabs.findIndex(t => t.id === tabId);
                if (tabIndex >= 0) {
                    this.activeTab = tabIndex;
                }
            }
        },

        opened() {
            if (!window.isMobileOrTablet) {
                Scrollbar.init(this.$refs.section);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.settings {
    &::v-deep .app-modal__container {
        width: rem(900px);
        padding: 0;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 2rem;

        background: #eee;
        box-shadow: 0 1px rem(6px) 1px rgba(6, 7, 7, 0.16);

        h2 {
            font-size: rem(28px);
            font-weight: 700;
        }
    }

    main {
        display: flex;

        height: rem(600px);
        max-height: 80vh;
    }

    aside {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        background-color: $grey-light20;

        width: rem(189px);
        padding: rem(23px) rem(5px);

        a {
            display: flex;
            align-items: center;

            padding: rem(8px) rem(7px);
            border-radius: 4px;

            font-size: rem(14px);
            line-height: 1;

            cursor: pointer;

            user-select: none;

            @media (hover: hover) {
                &:hover {
                    background-color: rgba($grey, 0.1);
                }
            }

            &.active {
                background-color: $grey-light28;
            }
        }

        // img {
        //     width: rem(17px);
        //     height: auto;
        //     margin-right: rem(7px);
        // }

        div {
            line-height: normal;
            font-weight: 500;
        }
    }

    section {
        width: 100%;

        &::v-deep .scroll-content {
            min-height: 100%;
        }
    }

    .tab {
        display: inline-block;
        width: 100%;
    }
    .tab + .tab {
        margin-top: rem(7px);
    }
    .tab-link {
        cursor: pointer;
        display: flex;
        padding: rem(6px) rem(4px) rem(6px) rem(11px);
        color: #000000;
        border-radius: 4px;
    }
    .tab-link.active {
        color: #000000;
        background-color: $grey-light28;
    }
    .tab-link.active.new,
    .tab-link.new {
        display: inline-flex;
        color: $white;
        background: $gradient-1;
        border-radius: 13px;
        padding: rem(7px) rem(10px) rem(7px) rem(7px);
    }
    .tab-link__name {
        font-size: rem(14px);
        font-weight: 500;
        line-height: rem(17px);
        letter-spacing: 0em;
    }
    .tab-link__name,
    .tab-link__icon {
        color: currentColor;
    }
    .tab-link__icon {
        display: flex;
    }
    .tab-link__icon img{
        height: auto;
        max-height: 17px;
        width: rem(17px);
        color: currentColor;
    }
    .tab-link__icon + .tab-link__name {
        margin-left: rem(7px);
    }

    @include mobile_or_tablet {
        &::v-deep {
            .vm--modal {
                height: 100% !important;
            }

            .app-modal__container {
                width: 100vw;
            }
        }

        header {
            padding: 1rem 2rem;
        }

        main {
            flex-direction: column;

            height: initial;
            max-height: initial;
        }

        aside {
            flex-direction: row;

            width: 100%;
            padding-bottom: 0;
            overflow: auto;

            a {
                height: 3.5rem;
                padding: 0 0.75rem;

                font-size: 1.25rem;
                white-space: nowrap;

                &:before {
                    display: none;
                }
            }
        }

        .tab + .tab {
            margin-top: 0px;
            margin-left: rem(5px);
        }

        .tab-link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0.75rem;
        }
        .tab-link__name {
            font-size: 1.25rem;
            white-space: nowrap;
        }
    }
}
</style>
