<template lang="pug">
    .editor-header-rename-input
        input(
            v-model="state"
            @change="renameDoc"
        )
</template>

<script>
import { updateDocument } from '@/js/api/requests/document';

export default {
    name: 'editor-header-rename-input',

    props: {
        value: {
            type: String,
            required: true,
        },

        document: {
            type: Object,
            required: true,
        },
    },

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        async renameDoc() {
            try {
                await updateDocument(this.document.id, { name: this.state });
            } catch (e) {
                if (e.response.status === 422) {
                    this.state = this.document.name;
                    this.$awn.alert('The name has already been taken');
                } else {
                    this.$awn.alert('Something went wrong, please try again later');
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.editor-header-rename-input {
    width: 100%;

    input {
        width: 100%;
        padding: rem(4px);

        font-size: rem(20px);

        border: 1px solid transparent;
        border-radius: rem(4px);

        transition: border-color .2s;

        &:hover {
            border-color: $hover-background;
        }

        &:focus {
            border-color: $blue;
        }
    }

    @include mobile_or_tablet {
        input {
            font-size: rem(18px);
            font-weight: 500;
        }
    }
}
</style>
