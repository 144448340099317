<template lang="pug">
    .list-children-move(v-show="show" :style = "styles")
</template>

<script>
export default {
    name: 'list-children-move',

    props: {
        counter: {
            type: Number,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            mouseCoordinates: null,
        };
    },

    computed: {
        styles() {
            if (this.mouseCoordinates) {
                return { 'top': this.mouseCoordinates.top + 'px', 'left': this.mouseCoordinates.left + 'px' };
            }
            return {};
        },
    },

    created() {
        window.addEventListener('mousemove', this.onmousemoveHandler);
    },

    destroyed() {
        window.removeEventListener('mousemove', this.onmousemoveHandler);
    },

    methods: {
        onmousemoveHandler(e) {
            const top = e.clientY - 55;
            const left = e.clientX + 155;
            this.mouseCoordinates = { top, left };
        },
    },
};
</script>

<style scoped lang="scss">
.list-children-move {
    position: fixed;
    z-index: 4;
    border-radius: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12px);

    font-weight: bold;

    background: $red;
    color: $white;
    box-shadow: 0 0 rem(9.8px) rgba(0, 0, 0, 0.25);
}
</style>
