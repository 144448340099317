import { isEmpty } from 'lodash';
import { getEntityLinkUrl } from '@/js/utils/breadcrump';

export default {
    methods: {
        pageRedirect(entity, preredirectAction = null) {
            const entityUrlLocation = getEntityLinkUrl(entity);
            const { href } = this.$router.resolve(entityUrlLocation);
            const isCurrentPath = href === this.$route.path;
            if (!isCurrentPath || (isCurrentPath && !isEmpty(this.$route.query))) {
                if (preredirectAction) {
                    preredirectAction();
                }
                this.$router.push(entityUrlLocation);
            }
        },
    },
};
