import { moveDocumentsToBin } from '@/js/api/requests/document';
import { isEmpty } from 'lodash';
import { moveFoldersToBin } from '@/js/api/requests/folder';

export default {
    methods: {
        deleteItem(doc, currentElemType) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async (confirm) => {
                    if (confirm) {
                        if (currentElemType === 'document' || currentElemType === undefined) {
                            try {
                                await moveDocumentsToBin(
                                    !isEmpty(this.selectedDocuments)
                                        ? this.selectedDocuments
                                        : doc.id,
                                );
                                this.$emit('update');
                                if (this.contentFromStore) {
                                    this.$store.dispatch('removeContentDocument', doc.id);
                                } else {
                                    this.$emit(
                                        'update:documents',
                                        this.documents.filter((d) => d.id !== doc.id),
                                    );
                                }
                                this.pageLoading = true;
                                this.$awn.success('The document was deleted successfully');
                                await this.fetchDashboard();
                            } catch (e) {
                                if (e.response.status === 403) {
                                    this.$awn.alert('You cannot delete this document');
                                } else {
                                    this.$awn.alert('Something went wrong, please try again later');
                                }
                            } finally {
                                this.pageLoading = false;
                            }
                        } else if (this.currentElemType === 'folder') {
                            await moveFoldersToBin(doc.id);
                        }
                    }
                },
            });
        },
    },
};
