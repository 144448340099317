import { restoreSnapshot } from '@/js/api/requests/editor';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import { mapMutations } from 'vuex';

export default {
    mixins: [CreateDocumentMixin],

    methods: {
        ...mapMutations(['setXAccessToken']),

        duplicate(doc) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async (confirm) => {
                    if (!confirm) return;

                    try {
                        const docId = await this.createDocument(
                            {},
                            false,
                            doc.name,
                            doc.image || null,
                            doc.icon,
                        );

                        await restoreSnapshot(doc.id, null, docId);

                        this.setXAccessToken(null);

                        this.$router.push({ name: 'Editor', params: { id: docId } });
                    } catch (e) {
                        this.$awn.alert('Something went wrong, please try again later');
                    }
                },
            });
        },
    },
};
