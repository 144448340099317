<template lang="pug">
    .folder-detail-settings.default-layout-block
        app-text-field(
            v-model="name"
            label="Folder Name:"
            :error="$v.$dirty && !$v.name.required ? 'Required field' : ''"
        )
        app-button(
            :loading="loading"
            @click="saveFolder"
        ) Save
        app-button.app-button--red(@click="deleteFolder") Delete Folder
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { updateFolder, moveFoldersToBin } from '@/js/api/requests/folder';

export default {
    name: 'folder-detail-settings',

    props: {
        folder: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        name: '',

        loading: false,
    }),

    validations: {
        name: { required },
    },

    created() {
        this.name = this.folder.name;
    },

    methods: {
        async saveFolder() {
            if (this.loading) return;

            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.loading = true;

            const fd = new FormData;
            fd.append('name', this.name);

            try {
                await updateFolder(this.folder.id, fd);
                this.folder.name = this.name;
                this.$awn.success('Folder settings was updated successfully');
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            } finally {
                this.loading = false;
            }
        },

        deleteFolder() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await moveFoldersToBin(this.folder.id);
                            this.$router.push({ name: 'RoomDetail', params: { roomId: this.folder.entity_id } });
                        } catch (e) {
                            this.$awn.alert('Something went wrong, please try again later');
                        }
                    }
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.folder-detail-settings {
    .app-button {
        margin-top: 3rem;
        margin-right: 2rem;
    }
}
</style>
