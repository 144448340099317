<template lang="pug">
    .favourite
        .default-layout-head
            app-breadcrumbs(
                :items="$route.meta.breadcrumb"
                @mouseoverBreadcrumb="mouseoverEntityHandler"
                @mouseupBreadcrumb="mouseupEntityHandler"
                @clickBreadcrumb="clickEntityHandler"
                class="app-breadcrumbs_mb app-breadcrumbs--theme-thin"
            )
        caption.forDesktop Files
        caption.forMobile_or_p Favorites
        table
            thead
                tr
                    th
                        span Name
                    th
                        span Owner
                    th
                        span Last Modified
                    th
                        span File size
            tbody
                tr(v-for="folder in favorites.folders")
                    td
                        favorite-icon(@click="deleteFromFavorites('folders', folder.id)")
                        span(class="favourite__content-name") {{ contentName('Folder', folder.name) }}
                    td Me
                    td {{ moment(folder.updated_at).format('DD.MM.YYYY') }}
                    td 300kb
                tr(v-for="document in favorites.documents")
                    td
                        favorite-icon(@click="deleteFromFavorites('documents', document.id)")
                        span(class="favourite__content-name") {{ contentName('Document', document.document.name) }}
                    td Me
                    td {{ moment(document.updated_at).format('DD.MM.YYYY') }}
                    td 300kb
</template>

<script>
import favoriteIcon from '@/assets/images/icons/favorite.svg?inline';
import moment from 'moment';
import { getFavorites, deleteFromFavorites } from '@/js/api/requests/favorites';

export default {
    name: 'favourite',

    components: {
        favoriteIcon,
    },

    data: () => ({
        favorites: {},
        pageLoading: true,
    }),

    async created() {
        this.favorites = await getFavorites();
        this.pageLoading = false;
    },

    methods: {
        contentName(contentName, name) {
            return contentName + ' | ' + name;
        },
        deleteFromFavorites(type, id) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        const types = {
                            folders: 'folder_id',
                            documents: 'document_id',
                        };
                        const fd = {
                            folder_id: [],
                            document_id: [],
                        };
                        fd[types[type]].push(id);
                        try {
                            await deleteFromFavorites(fd);
                            this.favorites[type] = this.favorites[type].filter(f => f.id !== id);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
            });
        },

        moment,
    },
};
</script>

<style scoped lang="scss">
caption {
    margin-bottom: rem(30px);

    font-size: rem(20px);
    font-weight: 500;
}

table {
    width: 100%;

    th, td {
        &:last-of-type {
            width: 6rem;
        }
    }

    th  {
        padding-bottom: 1rem;

        span {
            display: block;

            width: 100%;
            padding-bottom: 0.5rem;

            text-align: left;

            border-bottom: 1px solid #A8A6A6;
        }
    }

    td {
        padding: rem(14px) 0;

        svg, span {
            vertical-align: middle;
        }

        svg {
            width: rem(19px);
            margin-right: rem(6px);

            cursor: pointer;
            transition: fill .2s;

            fill: $black;

            &:hover {
                fill: transparent;
            }
        }
    }
}

.favourite__content-name {
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: rem(500px);
    display: inline-block;
}

@include mobile_or_tablet {
    .favourite__content-name {
        max-width: rem(250px);
    }

    .favourite {
        padding: rem(22px);
    }

    caption {
        margin-bottom: 1.25rem;
    }

    table {
        thead {
            display: none;
        }

        td {
            padding: 0.75rem 0;

            &:not(:first-of-type) {
                display: none;
            }
        }

    }
}
</style>
