<template>
    <div
        v-click-outside="close"
        class="library-filters-menu"
    >
        <div
            class="title"
            @click="openFilter"
        >
            <p>{{ currentRoom.name }}</p>
            <arrow-icon />
        </div>
        <div
            v-if="isOpen"
            class="filters"
        >
            <div
                v-for="(filter, i ) in filters"
                :key="i"
                class="item"
                @click="selectFilter(filter)"
            >
                <div class="icon">
                    <checked-icon v-if="currentRoom.id === filter.id" />
                </div>
                <p> {{ filter.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import libraryFilterMenuMixin from '@/mixins/libraryFilterMenuMixin';


export default {
    name: 'library-filters-menu',
    mixins: [ libraryFilterMenuMixin],

};
</script>

<style scoped lang="scss">
.library-filters-menu {
    position: relative;
    right: 13px;

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 7px;

        p {
            font-size: 13px;
            font-weight: 500;
            line-height: 15px;
            color: $grey-light3;
        }

        svg {
            margin-left: 5px;
        }

    }

    .filters {
        position: absolute;
        margin-top: 15px;
        z-index: 1000;
        top: 30px;
        padding: 11px;
        background-color: $white;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
        border-radius: 9px;

        .item {
            display: flex;
            align-items: center;
            cursor: pointer;

            p {
                font-size: 11px;
                font-weight: 500;
            }

            .icon {
                width: 15px;
                height: 10px;
                margin-right: 9px;
            }

            &:not(:first-child) {
                margin-top: 17px;
            }
        }
    }
}

</style>
