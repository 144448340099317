<template lang="pug">
    app-modal-redesign(
        name="rename-popup"
        closer
        @beforeOpen="beforeOpen"
    )
        form(@submit.prevent="submit").folder-form
            h3.folder-form__title {{ title }}
            app-text-field.folder-form__input(
                v-model="name"
                placeholder="Personal"
                :class="{ 'has-error': nameError }"
                :error="nameError"
            )
            button.folder-form__input.folder-form__emoji(
                type="button"
                @click="toggleEmoji"
                v-if="editIcon"
            )
                span Icon
                span.folder-form__icon {{ emoji }}
                arrow-icon
            button.folder-form__button(
                type="submit"
                :disabled="!getChangedStatus"
                :class="{active: getChangedStatus}"
            ) Update
            emoji-modal(
                ref="emojiModal"
                @insertEmoji="changeEmoji"
            )
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { updateDocument } from '@/js/api/requests/document';
import { updateFolder } from '@/js/api/requests/folder';
import { updateRoom } from '@/js/api/requests/room';
import ArrowIcon from '@/assets/images/icons/arrow-left.svg?inline';
import EmojiModal from '@/components/other/EmojiModal';
import { getRandomEmoji } from './EmojiModal.vue';

export default {
    name: 'rename-popup',

    components: {
        ArrowIcon,
        EmojiModal,
    },

    data: () => ({
        entity: null,
        entityType: '',
        successCallback: null,

        name: '',
        emoji: '',

        loading: false,
        changedName: '',
        changedEmoji: null,
    }),

    validations: {
        name: {
            required,
        },
    },
    computed: {
        nameError() {
            return !this.$v.name.required ? 'Required field' : '';
        },

        title() {
            if (this.entityType === 'document' || this.entityType === undefined) {
                return 'Edit document';
            } else if (this.entityType === 'folder') {
                return 'Edit folder';
            } else if (this.entityType === 'project') {
                return 'Edit project';
            }
            return 'Edit room';
        },

        editIcon() {
            return !(this.entityType === 'room' || this.entityType === 'project');
        },

        getChangedStatus() {
            return this.changedName !== this.name || this.changedEmoji !== this.emoji;
        },

    },

    methods: {
        toggleEmoji() {
            if (this.$refs.emojiModal.isOpen) {
                this.$refs.emojiModal.close();
            } else {
                this.$refs.emojiModal.open({
                    top: 105, left: 0, styles: {
                        position: 'absolute',
                    },
                });
            }
        },


        changeEmoji(emoji) {
            this.emoji = emoji.native;
            this.$refs.emojiModal.close();
        },
        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.loading) return;

            this.loading = true;

            try {
                if (this.entityType === 'document') {
                    await updateDocument(this.entity.id, { name: this.name, icon: this.emoji });
                    this.$awn.success('The document was edit successfully');
                } else if (this.entityType === 'folder') {
                    await updateFolder(this.entity.id, { name: this.name, icon: this.emoji });
                    this.$awn.success('The folder was edited successfully');
                } else if (this.entityType === 'room') {
                    await updateRoom(this.entity.id, { name: this.name });
                    this.$awn.success('The room was edit successfully');
                }
                this.$modal.hide('rename-popup');
                if (this.successCallback) {
                    this.successCallback(this.name, this.emoji);
                }
            } catch (e) {
                if (e.response.status === 422) {
                    this.$awn.alert('The name has already been taken');
                } else {
                    this.$awn.alert('Something went wrong, please try again later');
                }
            } finally {
                this.loading = false;
            }
        },

        beforeOpen(e) {
            this.entity = e.params?.entity;
            this.entityType = e.params?.entityType;
            this.successCallback = e.params?.successCallback;
            this.name = this.entity.name;
            this.changedName = this.entity.name;
            this.emoji = this.entity.icon ? this.entity.icon : getRandomEmoji();
            this.changedEmoji = this.entity.icon;
        },
    },
};
</script>

<style lang="scss" scoped>
.folder-form {
    position: relative;

    ::v-deep .emoji-modal {
        .emoji-mart {
            height: 350px;
        }
    }

    &__title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: normal;
        border-bottom: 1px solid #DADADA;
    }

    &__input {
        width: 100%;
        margin-bottom: 10px;

        &.has-error {
            margin-bottom: 20px;
        }

        &:not(.has-error) {
            &::v-deep {
                input {
                    border: none;
                }
            }
        }

        &::v-deep {
            input {
                padding: 6px 15px;
                height: auto;
                font-size: 12px;
                border-radius: 6px;
                background: #F1F0F0;

                &::placeholder {
                    color: #616161;
                }
            }
        }
    }

    &__emoji {
        display: flex;
        align-items: center;
        padding: 6px 15px;
        font-size: 12px;
        border-radius: 6px;
        background: #F1F0F0;
        text-align: left;
        color: #616161;
        cursor: pointer;
    }

    &__icon {
        font-size: 12px;
        margin-left: auto;
        margin-right: 9px;
    }

    &__button {
        padding: 6px;
        width: 100%;
        font-size: 12px;
        font-weight: 600;
        color: #8A8A8A;
        background: #E0E0E0;
        border-radius: 6px;
        cursor: pointer;
    }

    .active {
        background: $blue1;
        color: $white;
    }
}
</style>
