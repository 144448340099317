<template lang="pug">
    .settings-general
        .row
            .left Storage
            .right
                .memory
                    .memory__drag
                        .memory__drag-indicator(style="width: 40%")
                    .memory__used 1 GB used
                    .memory__total 2 GB
                .app-link Buy Storage
                .app-link View Storage
        .row
            .left Language
            .right
                .app-link Change Language
        .row
            .left Convert Uploads
            .right
                app-checkbox(
                    v-model="convertToDoc"
                    label="Convert to doc formats"
                )
</template>

<script>
export default {
    name: 'settings-general',

    data: () => ({
        convertToDoc: false,
    }),
};
</script>

<style scoped lang="scss">
.settings-general {
    padding: 0.5rem 1.5rem 1.5rem;
}

.row {
    display: flex;

    padding: 1.25rem 0;

    &:not(:last-child) {
        border-bottom: 1px solid rgba($grey, 0.25)
    }
}

.left, .right {
    width: 50%;
}

.left {
    font-size: rem(18px);
    font-weight: bold;
}

.memory {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    width: 100%;
    margin-bottom: 1rem;
    padding-top: 0.5rem;

    &__drag {
        width: 100%;
        height: rem(5px);
        margin-bottom: 0.7rem;

        background: $grey-light;

        &-indicator {
            height: 100%;

            background: $blue;
        }
    }
}

.app-link {
    margin-right: 0.5rem;
}

.app-checkbox {
    color: $blue;
}

@include mobile_or_tablet {
    .settings-general {
        padding-top: 0.25rem;
    }

    .row {
        flex-direction: column;
    }

    .left, .right {
        width: 100%;
    }

    .left {
        margin-bottom: 1rem;

        font-size: 1.5rem;
    }

    .memory {
        padding-top: 0;

        font-size: 1.125rem;

        &__drag {
            height: 0.6rem;
        }
    }
}
</style>
