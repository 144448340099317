<template lang="pug">
    .profile-menu
        .profile-menu__block
            .profile-menu__avatar
                img(v-if="userAvatar" :src="userAvatar")
                span(v-else) {{ userFirstLetters }}
            .profile-menu__info
                b {{ user.username }} {{ user.surname }}
                small {{ user.email }}

        .profile-menu__block
            a.profile-menu__link(@click="$modal.show('settings', { tab: 'profile' })")
                profile-icon
                | Profile
            a.profile-menu__link(@click="$modal.show('settings', { tab: 'general' })")
                settings-icon
                | Settings
            a.profile-menu__link
                task-icon
                | Task

        .profile-menu__block
            a.profile-menu__link(@click="$modal.show('settings', { tab: 'team-management' })")
                team-icon
                | Team management
        .profile-menu__block
            a.profile-menu__link
                upgrade-icon
                | Upgrade account
        .profile-menu__block(@click="logout")
            a.profile-menu__link
                signout-icon
                | Sign out
        .profile-menu__block
            a.profile-menu__link(@click="showHelp=true")
                help-icon
                | Help

        transition
            profile-menu-help(
                v-if="showHelp"
                @close="showHelp=false"
            )
</template>

<script>
import settingsIcon from '@/assets/images/icons/settings.svg?inline';
import profileIcon from '@/assets/images/icons/profile.svg?inline';
import taskIcon from '@/assets/images/icons/task.svg?inline';
import teamIcon from '@/assets/images/icons/team.svg?inline';
import upgradeIcon from '@/assets/images/icons/upgrade.svg?inline';
import signoutIcon from '@/assets/images/icons/signout.svg?inline';
import helpIcon from '@/assets/images/icons/help.svg?inline';
import ProfileMenuHelp from '@/components/layouts/ProfileMenuHelp';

export default {
    name: 'profile-menu',

    components: {
        settingsIcon,
        profileIcon,
        taskIcon,
        teamIcon,
        upgradeIcon,
        signoutIcon,
        helpIcon,
        ProfileMenuHelp,
    },

    data: () => ({
        showHelp: false,
    }),

    computed: {
        user() {
            return this.$store.getters.user;
        },

        userFirstLetters() {
            return `${this.user?.username?.[0] || ''}${this.user?.surname?.[0] || ''}`;
        },

        userAvatar() {
            return this.user?.image;
        },
    },

    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.replace('/login');
        },
    },
};
</script>

<style lang="scss" scoped>
.profile-menu {
    width: rem(300px);

    background: #fff;
    border: 1px solid $grey-light;
    border-radius: rem(3px);
    box-shadow: 0 0 10px 0 rgba(6, 7, 7, 0.15);

    &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:not(:last-of-type) {
            border-bottom: 2px solid #e9e9e9;
        }

        &:nth-of-type(1) {
            flex-direction: row;
            align-items: center;

            padding: 0.8rem;
        }
    }

    &__avatar {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;

        width: rem(45px);
        height: rem(45px);
        margin-right: rem(12px);
        overflow: hidden;

        font-size: rem(21px);
        font-weight: 500;
        color: #fff;

        background: #bfbfbf;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__info {
        width: calc(100% - #{rem(45px)});
        padding-right: rem(10px);

        b, small {
            display: block;

            width: 100%;
        }

        b {
            margin-bottom: 0.4rem;

            font-size: rem(18px);
        }

        small {
            font-size: rem(14px);
            @include name_reduction_simple();
        }
    }

    &__link {
        display: flex;
        align-items: center;

        width: 100%;
        padding: rem(12px) 1.6rem;
        stroke: #221B38;

        cursor: pointer;

        svg {
            width: rem(22px);
            margin-right: 1rem;
        }

        @media (hover: hover) {
            &:hover {
                background-color: rgba($grey, 0.1);
            }
        }
    }
}

</style>
