<template>
    <div class="settings-team-management">
        <template v-if="user.tariff.name === 'personal' && user.my_team_owner == null">
            <div class="no-content">
                To create a team, you need to buy a Pro
            </div>
        </template>
        <template v-else>
            <div class="header">
                <div class="header__title">
                    Team Management
                </div>
                <div class="header__controls">
                    <div class="search-field">
                        <app-text-field
                            v-model="searchField"
                            class="app-text-field--theme-solid"
                            placeholder="Search by name or email"
                        >
                            <template #icon>
                                <search-icon class="search-field-icon" />
                            </template>
                        </app-text-field>
                    </div>
                    <app-button
                        v-if="!user.my_team_owner "
                        block="block"
                        middle="middle"
                        @click="$modal.show('invite-members-popup', {members: getMembers})"
                    >
                        Invite Member
                    </app-button>
                    <app-button
                        v-else
                        block="block"
                        middle="middle"
                        outline="outline"
                        red="red"
                        @click="$modal.show('leave-team-popup')"
                    >
                        Leave Team
                    </app-button>
                </div>
                <div class="content">
                    <table class="table">
                        <tr class="table__header">
                            <th class="table__cell">
                                Name
                            </th>
                            <th class="table__cell">
                                Type
                            </th>
                            <th class="table__cell">
                                Last activity
                            </th>
                            <th class="table__cell"></th>
                        </tr>
                        <tr
                            v-for="(member, index) in getMembers"
                            :key="index"
                            class="table__row"
                        >
                            <td class="table__cell">
                                <div class="user-info">
                                    <div class="user-info__avatar">
                                        <img
                                            v-if="member.user_avatar"
                                            :alt="getFullName(member)"
                                            :src="member.user_avatar"
                                        /><span
                                            v-else
                                        >{{ getInitials(member) }}</span>
                                    </div>
                                    <div class="user-info__descr">
                                        <div class="user-info__title">
                                            {{ getFullName(member) }}
                                        </div>
                                        <div class="user-info__subtitle">
                                            {{ member.email }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="table__cell">
                                {{ !member.user_id ? 'Invite Sent' : 'Active' }}
                            </td>
                            <td class="table__cell">
                                {{
                                    getFormattedTime(member.user_logged_in_at !== null ? member.invited_at : member.user_logged_in_at)
                                }}
                            </td>
                            <td class="table__cell">
                                <app-context v-if="!user.my_team_owner">
                                    <template #activator>
                                        <div class="actions">
                                            <three-dots-horizontal-icon
                                                height="3"
                                            />
                                        </div>
                                    </template>
                                    <template #content>
                                        <ul class="member-context-menu">
                                            <li
                                                v-if="!member.user_id"
                                                class="member-context-menu__item"
                                                @click="sendInvite({email: member.email})"
                                            >
                                                Resend
                                                Invite
                                            </li>
                                            <li
                                                class="member-context-menu__item member-context-menu__item--warning"
                                                @click="removeMember(member.id)"
                                            >
                                                Remove Member
                                            </li>
                                        </ul>
                                    </template>
                                </app-context>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import searchIcon from '@/assets/images/icons/search.svg?inline';
import { mapActions } from 'vuex';
import threeDotsHorizontalIcon from '@/assets/images/icons/threeDotsHorizontal.svg?inline';
import moment from 'moment';

export default {
    name: 'settings-team-management',

    components: {
        searchIcon,
        threeDotsHorizontalIcon,
    },

    data: () => ({
        searchField: '',
        filteredItems: [],
    }),

    computed: {
        getMembers() {
            const members = this.$store.getters.getMembers;

            return members.filter(el => el.email.toLowerCase().includes(this.searchField.toLowerCase()) || el.user_name !== null && el.user_name.toLowerCase().includes(this.searchField.toLowerCase()));
        },

        user() {
            return this.$store.getters.user;
        },

    },

    mounted() {
        this.init();
    },

    methods: {
        ...mapActions(['sendInvite', 'leaveTeam', 'removeMember', 'updateMembers']),
        getInitials(user) {
            return `${user?.user_name?.[0] || ''}${user?.user_surname?.[0] || ''}`;
        },
        getFullName(user) {
            if (user.user_name == null || user.user_surname == null) return '';
            else {
                return (user?.user_name.trim() + ' ' + user?.user_surname.trim()).trim();
            }
        },
        getFormattedTime(time) {
            let formatted = '';
            if (moment().format('YYYY') !== moment(time).format('YYYY')) {
                formatted = moment(time).format('MMM Do YY');
            } else {
                formatted = moment(time).format('D MMM');
            }
            return formatted;
        },

        init() {
            this.updateMembers();
        },


    },
};
</script>

<style lang="scss" scoped>
.settings-team-management {
    position: relative;
    padding: rem(15px) rem(16px);

    .member-context-menu {
        width: rem(130px);
        background: $white;
        color: $black0;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
        border-radius: 9px;
        margin-top: rem(4px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: rem(12px);

        &__item {
            font-weight: 500;
            font-size: 9px;
            line-height: rem(15px);

            &--warning {
                color: $red2;
            }
        }

        .member-context-menu__item + .member-context-menu__item {
            margin-top: rem(10px);
        }
    }
}

.header {
    &__title {
        font-weight: 500;
        font-size: rem(18px);
        line-height: rem(22px);
        margin-bottom: rem(27px);
        color: #000000;
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .title + .controls {
        margin-top: rem(27px);
    }

    .app-button {
        width: rem(141px);
    }
}

.header + .content {
    margin-top: rem(21px);
}

.tabs {
    display: flex;

    margin-bottom: rem(20px);
    padding-bottom: 0.5rem;

    border-bottom: 1px solid rgba($grey, 0.5);

    .tab {
        margin-right: rem(35px);

        font-weight: 600;

        cursor: pointer;
    }
}

.search-field {
    width: rem(331px);

    .app-text-field {
        margin-bottom: unset;

        &::v-deep .app-text-field__input {
            padding: rem(8px) rem(8px) rem(8px) rem(48px);
            width: 100%;
            height: auto;
        }

        &::v-deep .app-text-field__icon {
            display: flex;
            width: auto;
            height: auto;
            left: rem(13px);
        }
    }

    &-icon {
        width: rem(16px);
        height: rem(16px);
        stroke: $grey2;

        pointer-events: none;
    }
}

.table {
    width: 100%;
    margin-bottom: rem(20px);

    &__header {
        border-bottom: 0.5px solid #cbcbcb;
        border-top: 0.5px solid #cbcbcb;

        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(15px);
        text-align: left;

        .table__cell {
            color: #787878;
            padding: rem(5px) 0;
        }
    }

    &__cell {
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(15px);
        padding: rem(16px) 0 0;
        color: #000000;

        &:nth-of-type(1) {
            width: 49%;
        }

        &:nth-of-type(2) {
            width: 30.5%;
        }

        &:nth-of-type(4) {
            text-align: right;
        }

    }

    &__row:nth-child(2) {
        .table__cell {
            padding: rem(12px) 0 0;
        }
    }

    .actions {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        cursor: pointer;
    }
}

.user-info {
    display: flex;
    align-items: center;

    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        width: rem(30px);
        height: rem(30px);

        font-weight: 600;
        font-size: rem(10px);
        line-height: rem(12px);

        color: $white;

        background: $blue;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(15px);

        color: #000000;
    }

    &__subtitle {
        font-weight: 400;
        font-size: rem(10px);
        line-height: rem(12px);

        color: #787878;
    }

    &__avatar + &__descr {
        margin-left: rem(7px);
    }

    &__title + &__subtitle {
        margin-top: rem(3px);
    }
}

.no-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(22px);
    margin-bottom: rem(27px);
    color: #000000;
}

@include mobile_or_tablet {
    .header {
        flex-wrap: wrap;
        justify-content: space-between;

        .title {
            margin-bottom: 1.5rem;
            padding-top: 0;

            font-size: 1.5rem;
        }

        &__controls {
            flex-direction: column;
        }

        .app-button {
            margin: 0;
        }

        .search-field + .app-button {
            margin-top: rem(10px);
        }
    }

    .tabs {
        .tab {
            font-size: 1.25rem;
        }
    }

    .search-field {
        width: 100%;
    }
}
</style>
