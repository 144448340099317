<template lang="pug">
    .uploading-popup
        header Uploading
            b 2 files
            .closer
        .main-wrapper(ref="mainWrapper")
            main
                .document
                    doc-icon.icon
                    | Important_Document.doc
                    upload-loader.status.loader
                .document
                    doc-icon.icon
                    | Impo rtant_ Docrtant.doc
                    upload-success.status
                .document
                    doc-icon.icon
                    | Impo rtant_ Docrtant_ Dortant_ Dortant_ Doument.doc
                    upload-success.status
</template>

<script>
import docIcon from '@/assets/images/icons/doc.svg?inline';
import uploadLoader from '@/assets/images/icons/upload_loader.svg?inline';
import uploadSuccess from '@/assets/images/icons/upload_success.svg?inline';
import Scrollbar from 'smooth-scrollbar';

export default {
    name: 'uploading-popup',

    components: {
        docIcon,
        uploadLoader,
        uploadSuccess,
    },

    mounted() {
        Scrollbar.init(this.$refs.mainWrapper);
    },
};
</script>

<style scoped lang="scss">
.uploading-popup {
    position: fixed;
    right: 2.5rem;
    bottom: 2.4rem;

    width: 25.7rem;

    font-family: Moderat, sans-serif;

    background: #fff;
    border: 1px solid rgba($grey, 0.5);
    border-radius: rem(2px);
    box-shadow: 0 0 rem(10px) 0 rgba(6, 7, 7, 0.15);

    header {
        display: flex;
        align-items: center;

        padding: 1.25rem 1.35rem;

        text-transform: uppercase;

        border-bottom: 2px solid rgba($grey, 0.25);

        b {
            margin-left: 0.2rem;
        }
    }

    .closer {
        position: relative;

        width: rem(20px);
        height: 1rem;
        margin-left: auto;

        cursor: pointer;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;

            display: block;

            width: 1.25rem;
            height: 2px;

            background: $black;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    .main-wrapper {
        height: 13.9rem;
    }

    main {
        padding: 0.7rem 1.35rem;
    }

    .document {
        display: flex;
        align-items: center;

        margin-bottom: 0.75rem;

        .icon, .status {
            flex-shrink: 0;
        }

        .icon {
            width: rem(21px);
            margin-right: rem(10px);
        }

        .status {
            width: rem(20px);
            margin-left: auto;

            &.loader {
                animation: rotate 2s linear infinite;
                @keyframes rotate {
                    from {
                        transform: rotate(0);
                    }
                    to {
                        transform: rotate(-360deg);
                    }
                }
            }
        }
    }
}
</style>
