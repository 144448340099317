<template lang="pug">
    ul.link-embed(
      v-if="isOpen"
      ref="linkPopup"
      v-click-outside="close"
    )
      li.link-embed__item
        button.link-embed__button(
          @click="createEmbed"
        )
         | Create embed
      li.link-embed__item
        button.link-embed__button(
          @click="dismiss"
        )
         | Dismiss
        
</template>

<script>
import DomElementMixin from '@/mixins/DomElementMixin';

export default {
  name: 'editor-link-embed-popup',

  mixins: [DomElementMixin],

  data: () => ({
      isOpen: false,
      quill: null,
  }),

  methods: {
      open(params={}) {
          this.isOpen = true;

          this.$nextTick(() => {
              this.$refs.linkPopup.style.cssText = `top: ${params.top}px; left: ${params.left}px;`;
              this.$refs.linkPopup.style.cssText = `top: ${this.domElNotInViewportTopShift(params.top, this.$refs.linkPopup)}px; left: ${params.left}px;`;
          });
      },

      close() {
        this.isOpen = false;
      },

      dismiss() {
        this.isOpen = false;
        this.$emit('dismiss');
      },

      createEmbed() {
        this.isOpen = false;
        this.$emit('createEmbed');
      },
    },
};
</script>

<style scoped lang="scss">
.link-embed {
  position: absolute;
  z-index: 1;

  display: flex;
  flex-direction: column;
  padding: 8px 0;

  background: #fff;
  box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
  border-radius: 9px;

  &__item {
    display: flex;
  }

  &__button {
    width: 100%;
    padding: 8px 11px;
    text-align: left;
    cursor: pointer;

    &:hover {
      background: #F1F1F1;
    }
  }
}
</style>
