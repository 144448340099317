<template lang="pug">
    app-modal.editor-invite-popup(
        name="editor-invite-popup"
        @opened="opened"
        @closed="closed"
        @beforeOpen="beforeOpen"
    )
        .top(v-show="!isAddingPeople")
            .title
                span Share
                closer-icon.closer.forMobile_or_p(@click="close")

            .add-field-button(@click="isAddingPeople=true")
                span Add people or emails
                app-button.forDesktop Add People
                app-button.forMobile_or_tablet Add

            .users(ref="users")
                .user(v-for="invitedUser in invitedUsersFiltered")
                    .user-info
                        .user-info__avatar
                            img(
                                v-if="invitedUser.image"
                                :src="invitedUser.image"
                            )
                            span(v-else) {{ getUserFirstLetters(invitedUser) }}
                        div
                            .user-info__name {{ invitedUser.username }} {{ invitedUser.surname }}
                            .user-info__email {{ invitedUser.email }}
                                b(v-if="invitedUser.is_owner") &nbsp;&nbsp;&nbsp;(owner)

                    template(v-if="!invitedUser.is_owner")
                        editor-invite-popup-accesses-select(
                            :value="getUserAccesses(invitedUser)"
                            @input="changeUserAccesses(invitedUser, $event)"
                            :document="document"
                        )
                        closer-icon.dropper(v-if="isOwner" @click="dropUser(invitedUser)")

        .top(v-show="isAddingPeople")
            .title
                span Share document
                settings-icon.settings
                closer-icon.closer(@click="isAddingPeople=false")

            form.add-field-form(@submit.prevent="addEmail")
                span
                    input(
                        v-model="email"
                        placeholder="Enter email"
                    )
                    editor-invite-popup-accesses-select(
                        v-model="accesses"
                        preselect-first
                        :for-invite-inline="false"
                        :document="document"
                    )
                    .error(v-if="emailError") {{ emailError }}
                app-button(:disabled="emailInvalid") Add

            .emails(ref="emails")
                div
                    .email(v-for="(email, index) in emails") {{ email.email }}
                        closer-icon.dropper(@click="dropEmail(index)")

            app-button.invite-button(
                :disabled="!emails.length"
                @click="inviteUser"
            ) Invite

        .bottom
            .learn-link
                span ?
                | Learn how to share

            editor-invite-popup-copy-link(:document="document")
</template>

<script>
import EditorInvitePopupAccessesSelect
    from '@/components/editor/invitePopup/EditorInvitePopupAccessesSelect';
import EditorInvitePopupCopyLink from '@/components/editor/invitePopup/EditorInvitePopupCopyLink';
import settingsIcon from '@/assets/images/icons/settings.svg?inline';
import closerIcon from '@/assets/images/icons/closer_2.svg?inline';
import Scrollbar from 'smooth-scrollbar';

import { email, required } from 'vuelidate/lib/validators';
import {
    DOCUMENT_ACCESSES,
    notificationInviteStatus,
    websocketsEventsNotifications,
} from '@/js/const';
import {
    deleteUserFromDocument,
    getUsersDocumentAccess,
    updateUserAccesses,
} from '@/js/api/requests/document';
import { getUserMainData } from '@/js/utils/user';

import sendToDataLayer from '@/js/utils/sendToDataLayer';
import { shareInvite } from '@/js/api/requests/common';

export default {
    name: 'editor-invite-popup',

    components: {
        EditorInvitePopupCopyLink,
        EditorInvitePopupAccessesSelect,
        settingsIcon,
        closerIcon,
    },

    data: () => ({
        document: {},
        invitedUsers: [],
        email: '',
        accesses: null,
        emails: [],
        isAddingPeople: false,
        inviteLinks: {},
    }),

    validations: {
        email: {
            required,
            email,
            notOwner(value) {
                return !this.invitedUsers.some(u => u.email === value && u.id === this.user.id);
            },
            notAccessed(value) {
                return !this.invitedUsers.some(u => u.email === value);
            },
            notGoingToAccess(value) {
                return !this.emails.some(e => e.email === value);
            },
        },
    },

    computed: {
        isOwner() {
            return this.document.is_owner;
        },

        invitedUsersFiltered() {
            return this.invitedUsers.filter(invitedUser => invitedUser.id !== this.user.id && invitedUser.username);
        },

        userWS() {
            return this.$store.getters.userWS;
        },

        user() {
            return this.$store.getters.user;
        },

        emailInvalid() {
            return this.$v.email.$invalid;
        },

        emailError() {
            if (!this.$v.email.notOwner) {
                return 'You can\'t send an invitation to yourself';
            }
            if (!this.$v.email.notAccessed) {
                return 'This email already has access to this document';
            }
            if (!this.$v.email.notGoingToAccess) {
                return 'You are about to send an invitation already';
            }
            return '';
        },
    },

    methods: {
        opened() {
            if (!window.isMobileOrTablet) {
                Scrollbar.init(this.$refs.users);
                Scrollbar.init(this.$refs.emails);
            }
        },

        closed() {
            this.isAddingPeople = false;
        },

        addEmail() {
            if (this.emailInvalid) return;
            this.emails.push(({
                email: this.email,
                accesses: this.accesses,
            }));
            this.email = '';
        },

        dropEmail(index) {
            this.emails = this.emails.filter((d, i) => i !== index);
        },

        getUserFirstLetters(user) {
            return `${user.username?.[0] || ''}${user.surname?.[0] || ''}`;
        },

        getUserAccesses(user) {
            return DOCUMENT_ACCESSES.find(a => JSON.stringify(a.settings) === user.role);
        },

        async changeUserAccesses(user, accesses) {
            // TODO check after back is finished
            const settings = JSON.stringify(accesses.settings);
            if (user.role !== settings) {
                await updateUserAccesses(this.document.id, {
                    user_id: user.id,
                    role: settings,
                });
                user.role = settings;
            }
        },

        async inviteUser() {
            try {
                await this.createAndGetInviteLinks();

                const usersDocument = await this.getUsersDocument();

                if (usersDocument?.users?.length) {
                    const sender = getUserMainData(this.user);
                    let data = {
                        documentId: this.document.id,
                        documentName: this.document.name,
                        senderFirstLetters: sender.firstLetters,
                    };

                    usersDocument.users.forEach(user => {
                        const receiver = getUserMainData(user);

                        this.sendNotificationToReceivers(sender, data, receiver);
                        this.sendNotificationToSender(sender, data, receiver);
                    });
                }

                await this.$emit('refreshDocument');
                this.$awn.success('People were invited successfully');

                this.sendEventToDataLayer();

                this.emails = [];
                this.isAddingPeople = false;
            } catch (e) {
                console.log(e);
            }
        },

        async createAndGetInviteLinks() {
            await Promise.all(
                this.emails.map(e => {
                    return shareInvite({
                        entity_id: this.document.id,
                        entity_type: 'document',
                        invited_emails: [e.email],
                        inviter_name: this.user.username,
                        role: e.accesses.settings,
                    }).then(link => this.inviteLinks[e.email] = link);

                }),
            );
        },

        async getUsersDocument() {
            const payload = {
                documentId: this.document.id,
                users: {},
            };

            this.emails.forEach((e, i) => {
                payload.users[i] = {
                    email: e.email,
                    access: JSON.stringify(this.emails[0].accesses.settings),
                };
            });

            return await getUsersDocumentAccess(payload);
        },

        sendNotificationToReceivers(sender, data, receiver) {
            this.userWS.emit('send-notification', {
                sender: { id: sender.id },
                receivers: [{ id: receiver.id }],
                type: websocketsEventsNotifications.inviteInDocument,
                data: {
                    ...data,
                    senderFullname: sender.fullName,
                    senderId: sender.id,
                    status: notificationInviteStatus.normal,
                    invitedRights: JSON.stringify(this.emails[0].accesses.settings),
                    invitelink: this.inviteLinks[receiver.email].link,
                },
            });
        },

        sendNotificationToSender(sender, data, receiver) {
            this.userWS.emit('send-notification', {
                sender: { id: sender.id },
                receivers: [{ id: sender.id }],
                type: websocketsEventsNotifications.inviteInDocumentForInviter,
                data: {
                    ...data,
                    invitedFullname: receiver.fullName,
                },
            });
        },

        sendEventToDataLayer() {
            this.emails.forEach(() => {
                sendToDataLayer({
                    'event': 'event-sharing',
                    'eventCategory': 'form',
                    'eventAction': 'sharing',
                });
            });
        },

        dropUser(user) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await deleteUserFromDocument(this.document.id, user.id);
                            await this.$emit('refreshDocument');
                            this.$awn.success('The person was deleted successfully');
                        } catch (e) {
                            this.$awn.alert('Something went wrong, please try again later');
                        }
                    }
                },
            });
        },

        beforeOpen(e) {
            if (e.params !== undefined) {
                this.document = e.params?.document;
                this.invitedUsers = e.params?.invitedUsers;
                this.isAddingPeople = e.params?.isAddingPeople;
            } else {
                return null;
            }
        },

        close() {
            this.$modal.hide('editor-invite-popup');
        },
    },
};
</script>

<style lang="scss" scoped>
.editor-invite-popup {
    &::v-deep .app-modal__container {
        width: rem(700px);
        padding: 0;
    }

    .top {
        padding: 1rem;
    }

    .title {
        display: flex;
        align-items: center;

        margin-bottom: 1rem;

        font-size: rem(28px);
        font-weight: 600;

        span {
            margin-right: auto;
        }

        .settings, .closer {
            width: 1.5rem;

            cursor: pointer;

            stroke: $violet;

            @media (hover: hover) {
                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        .closer {
            margin-left: 1rem;
        }
    }

    .add-field-button, .add-field-form {
        display: flex;

        margin-bottom: rem(20px);

        span {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 0 1rem;

            background: #EFEFEF;
            border-radius: rem(6px) 0 0 rem(6px);
            box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0.2);
        }

        .app-button {
            margin: 0;

            border-radius: 0 rem(6px) rem(6px) 0;
        }
    }

    .add-field-button {
        cursor: pointer;
        transition: opacity .2s;

        & > * {
            pointer-events: none;
        }

        @media (hover: hover) {
            &:hover {
                opacity: 0.8;
            }
        }
    }

    .add-field-form {
        position: relative;

        input {
            width: 100%;
            margin-right: rem(8px);
        }

        .error {
            position: absolute;
            top: calc(100% + #{rem(4px)});
            right: 0;
            left: 0;

            font-size: rem(12px);
            color: $red;
        }
    }

    .users {
        height: rem(300px);
        overflow: auto;
    }

    .emails {
        max-height: rem(180px);
        margin-bottom: rem(16px);
    }

    .user, .email {
        display: flex;
        align-items: center;

        padding: 0.5rem 1rem 0.5rem 0.5rem;

        transition: box-shadow .2s;

        .dropper {
            flex-shrink: 0;

            width: rem(16px);
            margin-left: rem(24px);

            cursor: pointer;
            opacity: 0;
            transition: opacity .2s;

            stroke: $violet;

            @media (hover: hover) {
                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                box-shadow: inset 0 0 0 10rem $hover-background;

                .dropper {
                    opacity: 1;
                }
            }
        }
    }

    .user {
        &-info {
            display: flex;
            align-items: center;

            margin-right: auto;

            &__avatar {
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;

                width: rem(40px);
                height: rem(40px);
                margin-right: rem(11px);
                overflow: hidden;

                color: #fff;

                background: $blue;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__name {
                margin-bottom: rem(6px);
            }

            &__email {
                font-size: rem(12px);
                color: #6E6D6D;
                word-break: break-word;
            }
        }
    }

    .email {
        display: flex;
        justify-content: space-between;
    }

    .invite-button {
        display: flex;

        margin-left: auto;
    }

    .bottom {
        display: flex;
        justify-content: space-between;

        padding: 0.75rem 1rem;

        border-top: 1px solid #AAAAAA;
    }

    .learn-link {
        display: flex;
        align-items: center;

        font-size: rem(14px);
        color: #9F9E9E;

        cursor: pointer;

        span {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 1.125rem;
            height: 1.125rem;
            margin-right: rem(6px);

            border: 1px solid #9F9E9E;
            border-radius: 50%;
        }
    }

    @include mobile_or_tablet {
        &::v-deep {
            .vm--modal {
                position: absolute;
                bottom: 0 !important;
                top: auto !important;
                left: 0 !important;

                border-radius: rem(20px) rem(20px) 0 0;

                transform: initial;
                opacity: 1;
            }

            .vm--overlay {
                background-color: rgba($black, 0.6);
            }

            .vm-transition--overlay {
                &-enter-active,
                &-leave-active {
                    transition: opacity .5s;
                }

                &-enter,
                &-leave-to {
                    opacity: 0;
                }
            }

            .vm-transition--modal {
                &-enter-active,
                &-leave-active {
                    transition: transform .5s;
                }

                &-enter,
                &-leave-to {
                    transform: translateY(100%);
                }
            }

            .app-modal__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 100vw;
                height: 100%;
            }
        }

        .title {
            margin-bottom: rem(32px);
        }

        .add-field-button, .add-field-form {
            margin-bottom: rem(32px);
        }

        .add-field-form {
            flex-wrap: wrap;

            span {
                height: rem(44px);
                margin-bottom: rem(8px);

                border-radius: rem(6px);
            }

            .app-button {
                margin-left: auto;

                border-radius: rem(4px);
            }

            .error {
                top: calc(100% + 0.5rem);

                text-align: center;
            }
        }

        .users {
            height: rem(140px);
        }

        .emails {
            max-height: initial;
        }

        .user, .email {
            padding-right: 0;
            padding-left: 0;

            .dropper {
                opacity: 1;
            }
        }

        .bottom {
            padding: 1rem;
        }
    }
}
</style>
