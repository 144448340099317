<template>
    <app-modal
        name="move-to-folder-popup"
        class="move-to-folder-popup"
        @closed="close"
        @beforeOpen="beforeOpen"
    >
        <div
            ref="list"
            class="list"
        >
            <div
                v-for="(folder, i) in folders"
                :key="i"
                class="list__item"
                @click="moveTo(folder.id)"
            >
                {{ folder.name }}
            </div>
            <div
                v-if="!folders.length"
                class="list__empty"
            ></div>
        </div>
    </app-modal>
</template>

<script>

import moveToFolderForComponentMixin from '@/js/mainContextMenu/moveToFolderForComponentMixin';
import { getAllFolders } from '@/js/api/requests/folder';


export default {
    name: 'move-to-folders-popup',

    mixins: [moveToFolderForComponentMixin],

    methods: {
        close() {
            this.$modal.hide('move-to-folder');
        },

        async beforeOpen(e) {
            this.folders = await getAllFolders();
            this.entity = e.params.entity;
        },
    },
};
</script>

<style scoped lang="scss">
.move-to-folder-popup {
    padding: 11px 15px;


    @include mobile_or_tablet {
        &::v-deep .app-modal__container {
            width: rem(700px);
            padding: 0;
        }

        &::v-deep {
            .vm--modal {
                position: absolute;
                bottom: 0 !important;
                top: auto !important;
                left: 0 !important;

                border-radius: rem(20px) rem(20px) 0 0;

                transform: initial;
                opacity: 1;
            }

            .vm--overlay {
                background-color: rgba($black, 0.6);
            }

            .vm-transition--overlay {
                &-enter-active,
                &-leave-active {
                    transition: opacity .5s;
                }

                &-enter,
                &-leave-to {
                    opacity: 0;
                }
            }

            .vm-transition--modal {
                &-enter-active,
                &-leave-active {
                    transition: transform .5s;
                }

                &-enter,
                &-leave-to {
                    transform: translateY(100%);
                }
            }

            .app-modal__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 100vw;
                height: 100%;
            }
        }

        .list {
            padding: 38px;
            background-color: $white;

            &__item {
                display: flex;
                align-items: center;

                p {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    margin-left: 25px;
                }

                &:not(:first-child) {
                    margin-top: 31px;
                }
            }
        }
    }
}
</style>
