<template lang="pug">
    modal.app-modal-redesign(
        :name="name"
        @before-open="beforeOpen"
        @opened="opened"
        @before-close="beforeClose"
        @closed="closed"
        height="auto"
    )
        .app-modal-redesign__container
            slot

            .app-modal-redesign__closer(
                v-if="closer"
                @click="close"
            )
                closer-icon

            .app-modal-redesign__closer.forMobile_or_p(
                v-else-if="mobileCloser"
                @click="close"
            )
                closer-icon
</template>

<script>

import closerIcon from '@/assets/images/icons/closer_2.svg?inline';

export default {
    name: 'app-modal-redesign',

    components: {
        closerIcon,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        closer: {
            type: Boolean,
            default: false,
        },

        mobileCloser: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        beforeOpen(e) {
            this.$emit('beforeOpen', e);
            window.disableScroll();
        },

        opened() {
            this.$emit('opened');
        },

        beforeClose() {
            this.$emit('beforeClose');
            window.enableScroll();
        },

        closed() {
            this.$emit('closed');
        },

        close() {
            this.$modal.hide(this.name);
        },
    },
};
</script>

<style lang="scss" scoped>
.app-modal-redesign {
    &::v-deep {
        .vm--container {
            bottom: 0;
            z-index: 10000 !important;

            width: 100vw;
            height: initial;
        }

        .vm--overlay {
            background: rgba(#000, 0.4);
        }

        .vm--modal {
            position: absolute;
            top: 50% !important;
            left: 50% !important;

            width: initial !important;
            max-width: 100%;
            max-height: 100%;
            overflow: visible;
            box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
            border-radius: 9px;

            transform: translate3d(-50%, -50%, 0);
            transition: opacity .3s;
        }
    }

    &__container {
        position: relative;

        width: rem(500px);
        max-width: 100vw;
        padding: 12px;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;

        .app-button {
            width: 100%;
            margin: 0 4%;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__closer {
        position: absolute;
        top: 1rem;
        right: 1rem;

        width: 1rem;

        cursor: pointer;

        svg {
            stroke: $violet;
        }
    }
}

@include mobile_or_P {
    .app-modal {
        &__container {
            width: 100vw;
        }

        &__closer {
            width: 2rem;
        }
    }
}

</style>
