<template>
    <app-modal
        class="searcher"
        name="searcher"
        @beforeOpen="beforeOpen"
    >
        <header>
            <search-icon class="icon" />
            <input
                v-model="value"
                placeholder="Find anything"
                type="text"
                @input="search"
            />
            <searcher-filters
                :filters="defaultFilters"
                @onFiltersChanged="onFiltersChanged"
            />
            <closer-icon
                class="close"
                @click="close"
            />
        </header>
        <div class="content">
            <search-item
                v-for="item of items"
                :id="item.id"
                :key="item.id"
                :icon="item.icon"
                :text="item.text"
                :title="splitText(item.name, isMobileOrTablet ? 22 : 33)"
                :type="item.type"
                :value="value"
            />
        </div>
    </app-modal>
</template>

<script>
import searchIcon from '@/assets/images/icons/search_2.svg?inline';
import closerIcon from '@/assets/images/icons/closer_2.svg?inline';
import SearcherFilters from './SearcherFilters.vue';
import SearchItem from './SearcherItem.vue';

import { getLastOpenedDocuments } from '@/js/api/requests/document';
import { SEARCH_FILTERS } from '@/js/const';
import { splitText } from '@/js/utils/text';

export default {
    name: 'searcher',

    components: {
        searchIcon,
        closerIcon,

        SearcherFilters,
        SearchItem,
    },

    data() {
        const defaultFilters = { ...SEARCH_FILTERS };

        return {
            defaultFilters,
            items: [],
            lastOpenedDocuments: [],
            value: '',
            cb: null,
            filters: {},
            payload: {},
            splitText,

        };
    },

    computed: {
        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },
    },

    methods: {
        close() {
            this.$modal.hide('searcher');
        },

        async beforeOpen() {
            try {
                this.value = '';
                this.items = [];
                const result = await getLastOpenedDocuments(9);
                this.items = [...result];
                this.lastOpenedDocuments = [...result];
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (!this.value || this.value.length < 3) {
                this.items = [...this.lastOpenedDocuments];
                return;
            }


            if (this.cb) {
                clearTimeout(this.cb);
                this.cb = null;
            }
            if (Object.keys(this.filters).length === 0) {
                this.filters = this.defaultFilters;
            }

            this.payload = {
                on_page: 9,
                string: this.value,
                in_body: this.filters.in_body.value,
                with_deleted: this.filters.with_deleted.value,
                only_shared: this.filters.only_shared.value,
                only_projects: this.filters.only_projects.value,
            };

            for (const item of Object.entries(this.payload)) {
                if (typeof item[1] !== 'string' && item[1] === false) {
                    delete this.payload[item[0]];
                }
            }


            this.cb = setTimeout(async () => {
                if (this.payload.string.length < 1) {
                    this.items = [...this.lastOpenedDocuments];
                } else {
                    await this.$store.dispatch('search', this.payload).then((value => this.items = value));
                }
            }, 500);


        },

        onFiltersChanged(value) {
            this.filters = { ...value };
            this.search();
        },
    },
};
</script>


<style lang="scss" scoped>
.searcher {


    &::v-deep .app-modal__container {
        width: 37.25rem;
        height: rem(703px);
        padding: 0;

    }

    &::v-deep .vm--modal {
        border-radius: 9px;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
    }

    @include mobile_or_tablet {
        &::v-deep {
            .vm--modal {
                height: 100% !important;
                border-radius: 0;
            }

            .app-modal__container {
                width: 100vw;
                height: calc(100% - 10px);
            }
        }
    }


    header {
        display: flex;
        align-items: center;
        padding: 10px;
        margin: 10px 10px 30px 10px;;
        border-radius: 4px;

        h2 {
            font-size: rem(28px);
            font-weight: 700;
        }

        .icon {
            stroke: #A7A7A9;
            width: 20px;
            height: 20px;
            stroke-width: 2;
            margin-right: 12px;
        }

        input {
            width: 70%;
            font-weight: 600;
            margin-right: 24px;

            &::placeholder {
                color: #A7A7A9;
            }
        }

        .close {
            width: 15px;
            height: 15px;
            margin-left: 30px;
            stroke: #A7A7A9;
            cursor: pointer;
        }

        @include mobile_or_tablet {
            background: #ECECEC;

            input {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
            }

            .close {
                margin-left: rem(17px);
            }
        }
    }

    .content {
        padding: 0 10px 10px 10px;
    }

}
</style>
