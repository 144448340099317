<template lang="pug">
    app-modal(
        name="create-room-popup"
        @beforeOpen="beforeOpen"
        @closed="closed"
    )
        form(@submit.prevent="submit")
            h3 Create new room
            app-text-field(
                v-model="name"
                label="Room name"
                :error="nameError"
            )
            .app-modal__bottom
                app-button(:loading="loading") Save
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createRoom } from '@/js/api/requests/room';

export default {
    name: 'create-room-popup',

    data: () => ({
        name: '',

        project: null,
        successCallback: null, // например, обновить список комнат

        loading: false,
    }),

    validations: {
        name: { required },
    },

    computed: {
        nameError() {
            if (this.$v.$dirty && !this.$v.name.required) {
                return 'Required field';
            }
            return null;
        },
    },

    methods: {
        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.loading) return;

            this.loading = true;

            const fd = new FormData;
            fd.append('name', this.name);
            fd.append('project_id', this.project.id);
            fd.append('settings', JSON.stringify({}));

            try {
                await createRoom(fd);
                this.successCallback?.();
                this.$modal.hide('create-room-popup');
            } catch (e) {
                if (e.response.status === 422) {
                    this.$awn.alert('The name has already been taken');
                } else {
                    this.$awn.alert('Something went wrong, please try again later');
                }
            } finally {
                this.loading = false;
            }
        },

        beforeOpen(e) {
            this.project = e.params?.project;
            this.successCallback = e.params?.successCallback;
        },

        closed() {
            this.name = '';
            this.$v.$reset();
        },
    },
};
</script>
