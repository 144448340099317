<template lang="pug">
    .app-card-document
        .app-card-document__header
            .app-card-document__header-icon(v-if="icon") {{icon}}
            .app-card-document__header-title {{name}}
        .app-card-document__content
            img(:src='contentPreview' :alt='name' :style="{width: 100 + 'px'}")
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: '',
            },

            name: {
                type: String,
                default: '',
            },

            contentPreview: {
                type: String,
                default: '',
            },
       },
    };
</script>
