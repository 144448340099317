import { createDocument } from '@/js/api/requests/document';
import { getRandomEmoji } from '@/components/other/EmojiModal';
import GetRouteParamsMixin from '@/mixins/GetRouteParamsMixin';

export default {
    mixins: [GetRouteParamsMixin],

    methods: {
        async createDocument(
            entity,
            pushToDocument= true,
            documentName = null,
            documentImage = null,
            documentIcon = null,
        ) {
            const fd = new FormData;

            if (documentName) {
                fd.append('name', documentName + ' (copy)');
            }
            if (documentImage) {
                fd.append('image', documentImage);
            }
            fd.append('icon', (documentIcon ? documentIcon : getRandomEmoji()));

            const { project_id, room_id, folder_id, document_id } = entity || {};

            if (project_id) {
                fd.append('project_id', project_id);
            }
            if (room_id) {
                fd.append('room_id', room_id);
            }
            if (folder_id) {
                fd.append('folder_id', folder_id);
            }
            if (document_id) {
                fd.append('document_id', document_id);
            }

            try {
                const documentId = await createDocument(fd);

                if (pushToDocument) {
                    this.$router.push({ name: 'Editor', params: { id: documentId } });
                }

                return documentId;
            } catch (e) {
                console.log(e);
            }
        },
    },
};
