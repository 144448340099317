<template lang="pug">
    .register
        privacy-agreement(
            v-if="step==='privacy'"
            @agree="step='email'"
        )

        form.register-form(
            v-else-if="step==='email'"
            @submit.prevent="sendCode"
        )
            .register-form__title Please enter your Email:
            app-text-field(
                v-model="email.data"
                placeholder="Enter your email"
                :error="emailError"
            )
            vue-recaptcha.recaptcha(
                sitekey="6LcyWE4fAAAAAOehX_USjt6pnwQ9JCMJkj1uFCpX"
                ref="recaptcha"
                @verify="onCaptchaVerify"
                @expired="onCaptchaExpired"
                :class="{'captchaError' : captcha.captchaError}"

            )
            app-button(
                :loading="email.loading"
            ) Continue

        form.register-form(
            v-else-if="step==='code'"
            @submit.prevent="checkCode"
        )
            .register-form__title Enter the Code you received:
            app-text-field(
                v-model="code.data"
                :error="codeError"
            )
            app-button(
                :loading="code.loading"
            ) Continue

        choose-plan(
            v-else-if="step==='choosePlan'"
            @nextStep="step='infoForm'"
        )

        //invite-form(
        //    v-else-if="step==='inviteForm'"
        //    @back="step='choosePlan'"
        //    @skip="finishInvite"
        //    @continue="finishInvite"
        //)

        info-form(
            v-else-if="step==='infoForm'"
            @back="step='choosePlan'"
            @continue="saveInfoData"
        )

        choose-department(
            v-else-if="step==='chooseDepartment'"
            @back="step='infoForm'"
            @continue="saveDepartment"
        )

</template>

<script>
import PrivacyAgreement from '@/components/pages/auth/register/PrivacyAgreement';
import ChoosePlan from '@/components/pages/auth/register/СhoosePlan';
import InfoForm from '@/components/pages/auth/register/InfoForm';
import InviteForm from '@/components/pages/auth/register/InviteForm';
import ChooseDepartment from '@/components/pages/auth/register/ChooseDepartment';
import sendToDataLayer from '@/js/utils/sendToDataLayer';
import { VueRecaptcha } from 'vue-recaptcha';
import { authorization, updateUserPassword } from '@/js/api/requests/auth';
import { required, email } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
    name: 'register',

    components: {
        PrivacyAgreement,
        InviteForm,
        ChoosePlan,
        InfoForm,
        ChooseDepartment,
        VueRecaptcha,
    },

    data: () => ({
        step: 'privacy', // privacy, email, code, choosePlan, infoForm, inviteForm, chooseDepartment

        email: {
            data: '',
            loading: false,
            error: null,
        },
        code: {
            data: '',
            loading: false,
            error: null,
        },

        infoData: {},
        department: '',

        loading: false,

        captcha: {
            statusCapchi: true,
            tokenCapchi: '',
            captchaError: false,
        },

    }),

    validations: {
        email: {
            data: {
                required, email,
            },
        },

        code: {
            data: {
                required,
            },
        },
    },

    computed: {
        emailError() {
            if (this.$v.email.data.$dirty) {
                if (!this.$v.email.data.required) {
                    return 'Required field';
                } else if (!this.$v.email.data.email) {
                    return 'Invalid Email Format';
                }
            }
            return this.email.error;
        },

        codeError() {
            if (this.$v.code.data.$dirty) {
                if (!this.$v.code.data.required) {
                    return 'Required field';
                }
            }
            return this.code.error;
        },
    },

    created() {
        if (this.$route.query.complete && this.$store.state.auth.token) {
            this.step = 'infoForm';
        }
    },

    methods: {

        ...mapActions(['updateNewUserStatus']),
        async sendCode() {
            this.email.error = null;
            let errorArray = [];
            if (this.email.loading ) return;

            if (this.$v.email.data.$invalid || this.captcha.statusCapchi) {
                this.$v.email.data.$touch();
                errorArray.push('error');
            }
            if (this.captcha.statusCapchi) {
                this.captcha.captchaError = true;
                errorArray.push('error');
            }
            if (errorArray.length) return;

            const fd = new FormData;
            fd.append('email', this.email.data);
            fd.append('is_register', true);
            fd.append('token_captcha', this.captcha.tokenCapchi);

            this.email.loading = true;

            try {
                await authorization(fd);
                this.step = 'code';
            } catch (e) {
                if (e.response.data.email?.[0]) {
                    this.email.error = e.response.data.email[0];
                } else {
                    this.email.error = 'Something went wrong, please try again later';
                }
            } finally {
                this.email.loading = false;
            }
        },

        async checkCode() {
            this.code.error = null;

            if (this.code.loading) return;

            if (this.$v.code.data.$invalid) {
                this.$v.code.data.$touch();
                return;
            }

            const fd = new FormData;
            fd.append('email', this.email.data);
            fd.append('email_code', this.code.data);

            this.code.loading = true;
            try {
                const token = await authorization(fd);
                if (token.is_registered) {
                    await this.$store.dispatch('fetchUser', { redirectPath: '/' });
                } else {
                    this.step = 'choosePlan';
                }
            } catch (e) {
                if (e?.response?.status === 401) {
                    this.code.error = 'Incorrect code';
                } else {
                    this.code.error = 'Something went wrong, please try again later';
                }
            } finally {
                this.code.loading = false;
            }
        },

        finishInvite() {
            this.step = 'chooseDepartment';
        },

        saveInfoData(data) {
            this.infoData = data;
            this.step = 'chooseDepartment';
        },

        saveDepartment(department) {
            this.department = department;
            this.register();
        },

        async register() {
            try {
                const fd = new FormData;
                fd.append('email', this.email.data);
                fd.append('email_code', this.code.data);
                const token = await authorization(fd);
                this.$store.commit('setToken', token);
                this.$cookie.set('token', JSON.stringify(token));

                await Promise.all([
                    this.$store.dispatch('updateUser', {
                        username: this.infoData.firstName,
                        surname: this.infoData.lastName,
                        country: this.infoData.country.key,
                    }),
                    updateUserPassword({
                        new_password: this.infoData.password,
                    }),
                ]);
                await this.$store.dispatch('updateNewUserStatus', true);
                this.$router.replace(this.$route.query.redirectPath || '/');



                sendToDataLayer({
                    'event': 'event-registration',
                    'eventCategory' : 'form',
                    'eventAction' : 'registration',
                });

            } catch (e) {
                console.log(e);
            }
        },
        onCaptchaVerify(res) {
            this.captcha.captchaError = false;
            this.captcha.tokenCapchi = res;
            this.captcha.statusCapchi = false;
        },

        onCaptchaExpired() {
            this.$refs.recaptcha.reset();
            this.captcha.captchaError = true;
            this.captcha.tokenCapchi = '';
        },


    },
};
</script>

<style scoped lang="scss">
.register {
    &::v-deep {
        .plan {
            margin-right: rem(115px);
            padding: rem(10px) rem(7px);
            min-width: rem(186px);

            &__title {
                margin-bottom: rem(10px);
                .title {
                    font-size: rem(14px);
                }
                .img {
                    width: rem(17px);
                    height: rem(17px);
                    margin-left: rem(11px);
                }
            }
            &__price {
                margin-bottom: rem(8px);
                .price {
                    font-size: rem(24px);
                    min-width: rem(46px);
                }
                .info{
                    margin-left: rem(30px);
                    font-size: rem(9px);
                }
            }
            &__options {
                .options-title {
                    font-size: rem(12px);
                    margin-bottom: rem(12px);
                }
                .options {
                    margin-bottom: rem(15px);
                    .option{
                        margin-bottom: rem(10px);
                        .icon{
                            width: rem(12px);
                            height: rem(9px);
                        }
                        .title{
                            font-size: rem(12px);
                            margin-left: rem(12px);
                        }
                    }
                }
            }
            &__btn {
                font-size: rem(12px);
                padding: rem(6px) 0px;
            }
            &__end-period {
                font-size: rem(12px);
            }
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: rem(570px);
        max-width: 100%;
        padding: 2rem;

        background: #fff;
        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

        &__title {
            margin-bottom: 2rem;

            font-size: rem(28px);
            font-weight: 600;
        }
    }
    .captchaError {
        border: 1px solid red;
        border-radius: 4px;
        width: 304px;
        height: 78px;
    }

    @include mobile_or_tablet {
        &-form {
            width: rem(350px);
            margin-top: 20vh;
            padding: 1rem;

            @include landscape {
                margin-top: 0;
            }

            &__title {
                margin-bottom: 1rem;

                font-size: 1.125rem;
            }
        }
    }
}
.recaptcha {
    margin-bottom: 1.25rem;
    @include mobile {
        transform: scale(0.828);
    }
}
</style>
