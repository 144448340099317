import Quill from 'quill';
const ImageBlotParent = Quill.import('formats/image');

class ImageBlot extends ImageBlotParent {
    static create({ url, alt= '', width, height, dataAlign, style }) {
        let node = super.create();
        node.setAttribute('src', url);
        node.setAttribute('alt', alt);
        if (width) node.setAttribute('width', width);
        if (height) node.setAttribute('height', height);
        if (dataAlign) node.setAttribute('data-align', dataAlign);
        if (style) node.setAttribute('style', style);

        return node;
    }

    static value(node) {
        return {
            url: node.getAttribute('src'),
            alt: node.getAttribute('alt'),
            width: node.width,
            height: node.height,
            dataAlign: node.getAttribute('data-align'),
            style: node.getAttribute('style'),
        };
    }
}

ImageBlot.blotName = 'custom-image';
ImageBlot.className = 'ql-custom-image';
ImageBlot.tagName = 'img';

Quill.register(ImageBlot);
