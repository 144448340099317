<template lang="pug">
    .how-to
        .default-layout-head
            app-breadcrumbs(
                :items="$route.meta.breadcrumb"
                @mouseoverBreadcrumb="mouseoverEntityHandler"
                @mouseupBreadcrumb="mouseupEntityHandler"
                @clickBreadcrumb="clickEntityHandler"
                class="app-breadcrumbs_mb app-breadcrumbs--theme-thin"
            )

        app-loader(v-if="!pageLoaded")
        .content(v-else)
            .links.default-layout-block
                .link(
                    v-for="(item, index) in howTo"
                    v-html="item.video_title"
                    @click="scrollTo(`.how-to .step[data-step-id=\"${index}\"]`, -10)"
                )
            .steps
                .step.default-layout-block(
                    v-for="(item, index) in howTo"
                    :data-step-id="index"
                )
                    .step__number Video {{ index + 1 }} / {{ howTo.length }}
                    h3(v-html="item.video_title")
                    .step__description(v-html="item.video_description")
                    iframe.step__video(
                        :src="item.video_link"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    )
</template>

<script>
import scrollTo from '@/js/utils/scrollTo';
import { getHowTo } from '@/js/api/requests/common';

export default {
    name: 'how-to',

    data: () => ({
        howTo: [],

        pageLoaded: false,
    }),

    created() {
        this.getHowTo();
    },

    methods: {
        async getHowTo() {
            try {
                this.howTo = await getHowTo();
            } catch (e) {
                this.$awn.alert('An error occurred while loading how to, please try again later');
            } finally {
                this.pageLoaded = true;
            }
        },

        scrollTo,
    },
};
</script>

<style scoped lang="scss">
.how-to {
    .content {
        display: flex;
        align-items: flex-start;
    }

    .links, .steps {
        width: 100%;
        max-width: 32rem;
    }

    .links {
        position: sticky;
        top: 2rem;

        margin-right: 7%;
    }

    .link {
        padding: 0.5rem;

        font-size: rem(22px);

        cursor: pointer;
        transition: box-shadow .2s;

        @media (hover: hover) {
            &:hover {
                box-shadow: 0 0 0 rem(2px) $hover-background, inset 0 0 0 10rem $hover-background;
            }
        }
    }

    .step {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__number {
            margin-bottom: 1rem;

            font-weight: 500;
            color: $blue;
        }

        h3 {
            margin-bottom: 1rem;
        }

        &__description {
            margin-bottom: 1rem;

            line-height: 1.3;
        }

        &__video {
            width: 100%;
            height: 16rem;
        }
    }

    @include mobile_or_tablet {
        .content {
            flex-direction: column;
            align-items: center;
        }

        .links {
            position: static;

            margin-right: 0;
        }

        .step {
            &__number {
                font-size: rem(20px);
            }
        }
    }
}
</style>
