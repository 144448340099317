<template lang="pug">
    .editor(@click = "mainClick" ref="editor" )
        app-loader(v-if="!pageLoaded")

        template(v-if="pageLoaded && document")
            editor-mobile-header(
                v-if="isMobileOrTablet"
                :document="document"
                :format="format"
                @formatting="formatting"
                @focusEditor="focusEditor"
                @blurEditor="blurEditor"
                @undo="undo"
                @redo="redo"
            )
            editor-header(
                v-else
                :document="document"
                :format="format"
                :mode="mode"
                :users="users"
                @openSnapshots="snapshotsIsOpen=true"
                @formatting="formatting"
                @updateMode="updateMode"
                @insertLink="insertLink"
                @insertTable="insertTable"
                @insertComment="insertComment"
                @undo="undo"
                @redo="redo"
                @cut="cut"
                @copy="copy"
                @paste="paste"
                @insertDivider="insertDivider"
                @openFormulaPopup="openFormulaPopup"
                @insertImage="insertImage"
                @insertEmoji="insertEmoji"
                @saveVersion="saveVersion"
                @insertMention="insertMention"
            )

            app-loader(v-if="!documentLoaded")

            .editor-wrapper.editor-wrapper--parent(v-show="documentLoaded")
                editor-title(
                    v-model="documentName"
                    :document="document"
                    :icon="documentIcon"
                    @update:icon="(icon) => documentIcon = icon"
                    @update:document-name="(name) => document.name = name"
                    @update:document-icon="(icon) => document.icon = icon"
                )

                editor-author-document(
                    v-if="isPublicLink && publicLinkPermissions.includes('view_author')"
                    :document="document"
                )

                .editor-wrapper.editor-wrapper--child
                    editor-side-actions(
                        v-if="!isPublicLink"
                        @openPopup="openActionPopup($event)"
                        :currentActionPopup="currentSideActionPopup"
                    )
                    editor-actions-popup-layout(
                        v-if="!isPublicLink"
                        ref="actionPopup"
                        :document="document"
                        :format="format"
                        :callout='calloutId'
                        @insert-block-callout="insertBlockCallout"
                        @formatting="formatting"
                        @closed="clearAction"
                    )
                    editor-header-table-size-selector(
                        v-show="isTableSizeSelector"
                        v-click-outside="closeTableSizeHandler"
                        :style="tableSizeSelectorStyles"
                        @click="changeTableSize"
                    )
                    emoji-modal(
                        ref="emojiModal"
                        @insertEmoji="insertEmoji"
                    )
                    .editor-container(
                        ref="editorContainer"
                        :class="'ql-'+mode+'-mode'"
                    )
                        #editor(
                            v-click-outside="onClickOutsideEditor"
                            @click.prevent="onClickInsideEditor"
                            @dragover.prevent
                            @drop="onDropBlock"
                        )
                        editor-comments-popup(
                            ref="commentPopup"
                            :invited-users="documentUsers"
                        )
                        editor-comments-try-add-by-anonymous(
                            v-if="isPublicLink && !emittingUserInfo.username"
                            ref="tryAddCommentByAnonymous"
                            @enteredName="setNameToAnonymousForAddComment"
                        )
                        editor-link-popup(ref="linkPopup")
                        editor-link-embed-popup(ref="linkEmbedPopup")
                        editor-link-menu(ref="linkMenu" @openEditLink="openEditLinkFromLinkPopup" @removeLink="removeLink")
                        editor-selection-text-popup(
                            v-if="!isPublicLink || publicLinkPermissions.includes('edit_content') || publicLinkPermissions.includes('comment')"
                            ref="selectionTextPopup"
                            :format="format"
                            @formatting="formatting"
                            @insertComment="insertComment"
                            @insertLink="insertLink"
                            @copy="copy"
                            @deleteText="deleteText"
                        )
                        editor-slash-popup(
                            ref="slashPopup"
                            :format="format"
                            @formatting="formatting"
                            @insertTable="insertTable"
                            @insertLink="insertLink"
                            @insertImage="insertImage"
                            @insertMention="insertMention"
                            @deleteText="deleteText"
                            @insertDocument="insertDocument($event)"
                        )

                        editor-at-popup(
                            v-if="!isPublicLink"
                            ref="atPopup"
                            @insertMentor="insertMentor"
                            :users="documentUsers"
                            :documents="workInProgress"
                            @insertDocument="insertDocument($event)"
                        )
                        editor-formula-popup(ref="formulaPopup")
                        editor-slash-helper(ref="slashHelper")
                        change-background-modal

            publish-to-web-settings(
                v-if="!isPublicLink"
                ref="publishToWebSettings"
            )

            editor-invite-popup(

                @refreshDocument="refreshDocument"
            )

            transition
                editor-snapshots(
                    v-if="snapshotsIsOpen"
                    @restoreSnapshot="restoreSnapshot"
                    @duplicateSnapshot="duplicateSnapshot"
                    @close="snapshotsIsOpen=false"
                )

        editor-not-found-popup

        input(id = "fileReaderInput" ref="fileReaderInput" type="file" class="link-input-hidden" @change="changeImage")
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import EditorHeader from '@/components/editor/header/EditorHeader';
import EditorMobileHeader from '@/components/editor/header/EditorMobileHeader';
import EditorSnapshots from '@/components/editor/snapshots/EditorSnapshots';
import EditorInvitePopup from '@/components/editor/invitePopup/EditorInvitePopup';
import EditorCommentsPopup from '@/components/editor/commentsPopup/EditorCommentsPopup';
import EditorCommentsTryAddByAnonymous from '@/components/editor/commentsPopup/EditorCommentsTryAddByAnonymous';
import EditorFormulaPopup from '@/components/editor/EditorFormulaPopup';
import { initEditor } from '@/js/editor/editor';
import connection from '@/js/editor/connection';
import userSocket from '@/js/editor/user_connection';
import EditorLinkPopup from '@/components/editor/EditorLinkPopup';
import EditorLinkEmbedPopup from '@/components/editor/EditorLinkEmbedPopup';
import EditorLinkMenu from '@/components/editor/EditorLinkMenu';
import EditorSelectionTextPopup from '@/components/editor/EditorSelectionTextPopup';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import { ENTITY_TYPES, websocketsEventsNotifications } from '@/js/const';
import PageRedirectMixin from '@/mixins/PageRedirectMixin';
import { keyboards } from '@/js/editor/const';
import { getUserMainData } from '@/js/utils/user';
import { copyInputText, hasClass } from '@/js/utils/dom';
import Quill from 'quill';
import EditorNotFoundPopup from '@/components/editor/EditorNotFoundPopup';
import EditorSlashPopup from '@/components/editor/slashPopup/EditorSlashPopup';
import EditorAtPopup from '@/components/editor/atPopup/EditorAtPopup';
import PublishToWebSettings from '@/components/editor/actionsPopup/PublishToWebSettings';
import EditorSlashHelper from '@/components/editor/EditorSlashHelper';
import { getDocumentById, updateOpenedMe } from '@/js/api/requests/document';
import {
    createSnapshot,
    duplicateSnapshot,
    getDocumentInfo,
    restoreSnapshot,
} from '@/js/api/requests/editor';
import EditorSideActions from '@/components/editor/EditorSideActions';
import EditorActionsPopupLayout from '@/components/editor/actionsPopup/EditorActionsPopupLayout';
import EmojiModal from '@/components/other/EmojiModal';
import EditorHeaderTableSizeSelector
    from '@/components/editor/header/EditorHeaderTableSizeSelector';
import EditorTitle from '@/components/editor/EditorTitle';
import EditorAuthorDocument from '@/components/editor/EditorAuthorDocument';
import { BLOCK_NAMES } from '@/components/editor/actionsPopup/EditorActionsBlocksPopup.vue';
import ChangeBackgroundModal from '@/components/other/ChangeBackgroundModal';
import Comment from '@/js/editor/comments';
import Popups from '@/js/editor/popups';
import PreviewDocument from '@/js/editor/previewDocument';

import _ from 'lodash';
import { load as nsfwjsLoad } from 'nsfwjs';
import { getHtmlByUrl } from '@/js/api/requests/other';
import { getDashboard } from '@/js/api/requests/common';
import moment from 'moment/moment';

const Delta = Quill.import('delta');

export default {
    name: 'editor',

    components: {
        ChangeBackgroundModal,
        EditorActionsPopupLayout,
        EditorSideActions,
        EditorMobileHeader,
        EditorSnapshots,
        EditorLinkPopup,
        EditorLinkEmbedPopup,
        EditorLinkMenu,
        EditorSelectionTextPopup,
        EditorHeader,
        EditorInvitePopup,
        EditorCommentsPopup,
        EditorCommentsTryAddByAnonymous,
        EditorNotFoundPopup,
        EditorSlashPopup,
        EditorAtPopup,
        EditorSlashHelper,
        EditorFormulaPopup,
        EmojiModal,
        EditorHeaderTableSizeSelector,
        EditorTitle,
        EditorAuthorDocument,
        PublishToWebSettings,
    },

    mixins: [CreateDocumentMixin, PageRedirectMixin],

    data() {
        return {
            calloutId: '',
            currentSideActionPopup: '',
            document: null,
            documentInfo: null,
            editor: null,
            format: {},
            mode: 'edit', // edit, view, suggest
            users: [],
            documentName: '',
            documentIcon: '',
            snapshotsIsOpen: false,
            snapshotRestoreStarted: false,
            pageLoaded: false,
            documentLoaded: false,
            scrollerY: 0,
            offsetImage: null,
            isTableSizeSelector: false,
            comment: null,
            workInProgress: [],
            popup: null,
            previewDocument: null,
            emittingUserInfo: {},
            selectedTextForAddComment: null,
            wasClickOutsideEditor: false,
        };
    },

    computed: {
        ...mapState({
            documentId: state => state.editor.documentId,
            isPublicLink: state => state.editor.isPublicLink,
            publicLinkPermissions: state => state.editor.permissions,
        }),

        tableSizeSelectorStyles() {
            return {
                position: 'fixed',
                top: '500px',
                right: '90px',
                zIndex: 2,
            };
        },

        user() {
            return this.$store.getters.user;
        },

        userWS() {
            return this.$store.getters.userWS;
        },

        settings() {
            return this.document?.is_owner ? 'full_access' : this.document?.settings;
        },

        documentUsers() {
            return this.document?.users || [];
        },

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },
    },

    watch: {
        // eslint-disable-next-line no-unused-vars
        $route(to, from) {
            this.pageLoaded = false;
            this.loadDocData();
        },
    },

    mounted() {
        this.loadDocData();
        this.checkImageForContent();
    },

    beforeCreate() {
        userSocket.connect();
    },

    beforeDestroy() {
        this.makePreview();
        userSocket.disconnect();
        userSocket.removeAllListeners();
    },

    provide() {
        return {
            copyLink: this.copyLink,
        };
    },

    methods: {
        ...mapActions(['getDocumentId']),

        ...mapMutations(['setPermissions']),

        onClickOutsideEditor() {
            this.wasClickOutsideEditor = true;
        },

        onClickInsideEditor() {
            this.wasClickOutsideEditor = false;
        },

        onDropBlock(event) {
            const blockName = event.dataTransfer.getData('block-name');
            switch (blockName) {
                case BLOCK_NAMES.CHECKLIST:
                    this.formatting({ list: this.format.list !== 'unchecked' && this.format.list !== 'checked' ? 'unchecked' : false });
                    break;
                case BLOCK_NAMES.LINK:
                    this.insertLink();
                    break;
                case BLOCK_NAMES.IMAGE:
                    this.insertImage();
                    break;
                case BLOCK_NAMES.MENTION:
                    this.insertMention();
                    break;
                case BLOCK_NAMES.EMOJI:
                    this.toggleEmojiModal();
                    break;
                case BLOCK_NAMES.TABLE:
                    this.openTableSizeHandler();
                    break;
                case BLOCK_NAMES.FORMULA:
                    this.openFormulaPopup();
                    break;
                case BLOCK_NAMES.CODE:
                    this.formatting({ code: true });
                    break;
                case BLOCK_NAMES.PAGE:
                    this.insertDocument({
                        meta: {
                            createBy: 'dropBlock',
                            typeDocument: 'newDocument',
                            popup: 'slashPopup',
                        },
                    });
                    break;
                default:
                    break;
            }
        },

        changeTableSize(e) {
            if (e.target.matches('[data-size]')) {
                const size = e.target.getAttribute('data-size')
                    .split('X')
                    .map(s => Number(s));
                this.editor.getSelection(true);
                this.insertTable(size);
            }
            this.closeTableSizeHandler();
        },

        openTableSizeHandler() {
            this.isTableSizeSelector = true;
        },

        closeTableSizeHandler() {
            this.isTableSizeSelector = false;
        },

        async loadDocData() {
            try {
                await this.getDocumentId(this.$route);
            } catch (e) {
                this.$router.push({ name: '404' });
            }

            try {
                const document = await getDocumentById(this.documentId);


                if (document.deleted_at) {
                    throw new Error;
                }

                // при публичной ссылке это не нужно
                // this.user в этом случае аноним (this.$store.getters.user)
                if (!this.isPublicLink) {
                    try {
                        this.documentInfo = await getDocumentInfo({ documentId: document.id });
                    } catch (e) {
                        throw new Error;
                    }
                }

                this.setPermissions(document.user.permissions);

                if (!this.isPublicLink) {
                    // при публичной ссылке не надо обновлять дату открытия документа
                    await updateOpenedMe(document.id);
                    // при публичной ссылке у нас анонимный юзер и у него нет доков, которые он может
                    // вставить в текущий документы
                    const docs = await getDashboard();
                    this.workInProgress = docs.work_in_progress;
                    this.workInProgress.sort((d1, d2) => +moment(d1.updated_at) - +moment(d2.updated_at));
                    this.workInProgress = this.workInProgress.reverse().slice(0, 5);
                }

                this.document = document;
                this.documentName = document?.name || '';
                this.documentIcon = document?.icon || '';

                setTimeout(this.init);
            } catch (e) {
                this.$modal.show('editor-not-found-popup');
            } finally {
                this.pageLoaded = true;
            }
        },

        toggleEmojiModal() {
            if (this.$refs.emojiModal.isOpen) {
                this.$refs.emojiModal.close();
            } else {
                this.$refs.emojiModal.open({
                    top: 200,
                    styles: {
                        position: 'fixed',
                        zIndex: 2,
                        right: '100px',
                    },
                });
            }
        },

        copyLink(access) {
            let el = null;

            if (access.name === 'Full Access') {
                el = this.$refs.inputAccessFull;
            }
            if (access.name === 'Can Edit') {
                el = this.$refs.inputCanEdit;
            }
            if (access.name === 'Can Comment') {
                el = this.$refs.inputCanComment;
            }
            if (access.name === 'Can View') {
                el = this.$refs.inputCanView;
            }

            copyInputText(this.$awn, el);
        },

        mainClick() {
            document.addEventListener('changeBackgroundFromFormatter', (event) => {
                this.calloutId = event.detail.id;
            });
            if (this.editor) {
                const range = this.editor.getSelection();

                if (range) {
                    const blot = this.editor.getLeaf(range.index)[0];
                    const blotName = blot.parent.constructor.name;

                    if (blotName === 'Link') {
                        const blotRange = {
                            index: this.editor.getIndex(blot),
                            length: blot.length(),
                        };

                        const blotDelta = this.editor.getContents(blotRange, blot.length());
                        const domNode = blot.parent.domNode;
                        const bounds = this.editor.getBounds(blotRange.index, blot.length());

                        const ops = blotDelta.ops;
                        const attributes = ops[0] && ops[0].attributes ? blotDelta.ops[0].attributes : null;
                        if (attributes && attributes.link) {
                            const linkHref = attributes.link;

                            if (domNode !== this.$refs.linkMenu.domNode) {
                                this.$refs.linkMenu.open({
                                    top: bounds.top + bounds.height + 5,
                                    left: bounds.left,
                                    link: linkHref,
                                    textLength: blot.length(),
                                    indexPosition: this.editor.getIndex(blot),
                                    domNode,
                                });
                            }
                        }
                    }
                }
            }
        },

        async refreshDocument() {
            this.document = await getDocumentById(this.document.id);
            this.documentName = this.document?.name || '';
            this.documentIcon = this.document?.icon || '';
        },

        async init() {
            setTimeout(() => {
                const toolbar = document.getElementById('header-toolbar');
                if (toolbar) {
                    toolbar.style.opacity = 1;
                }
            }, 700);

            this.editor = initEditor('#editor', this.pressKey);

            if (!this.publicLinkPermissions.includes('edit_content')) {
                this.editor.disable();
            }

            let docConnection = this.startDoc(this.editor, this.document.id);

            const cursors = this.editor.getModule('cursors');

            userSocket.on('docUsers', ({ users }) => {
                this.users = users;
                cursors.clearCursors();
                users.forEach(user => {
                    if (user.user_info.uid !== this.user.id) {
                        cursors.createCursor(user.user_info.uid, user.user_info.name, `#${user.user_info.color}`);
                    }
                });
            });

            if (!this.isPublicLink || this.publicLinkPermissions.includes('comment')) {
                userSocket.emit('joindoc', {
                    user_info: {
                        uid: this.user.id,
                        color: this.documentInfo?.color,
                        name: this.user.username,
                        image: this.user.image,
                    },
                    doc_id: this.document.id,
                });
            }

            userSocket.on('processRestoreSnapshot', () => {
                docConnection.unsubscribe();
                docConnection.destroy();
                this.editor.disable();
            });

            userSocket.on('processedRestoreSnapshot', () => {
                this.editor.enable();
                docConnection = this.startDoc(this.editor, this.document.id);
                if (this.snapshotRestoreStarted) {
                    this.$awn.success('Version restored successfully');
                    this.snapshotRestoreStarted = false;
                    this.snapshotsIsOpen = false;
                } else {
                    this.$awn.tip('Version was restored');
                }
            });

            userSocket.on('updateUserCursor', data => {
                cursors.moveCursor(data.uid, data.range);
            });

            this.editor.on('selection-change', range => {
                userSocket.emit('changeUserCursor', {
                    cursor: {
                        uid: this.user.id,
                        range,
                    },
                    documentId: this.document.id,
                });
            });

            this.editor.on('selection-change', (range) => {
                if (!range) {
                    this.format = {};
                    return;
                }
                this.format = this.editor.getFormat();
            });

            this.editor.on('selection-change', range => {
                if (
                    this.isPublicLink &&
                    !(this.publicLinkPermissions.includes('edit_content') || this.publicLinkPermissions.includes('comment'))
                ) {
                    return;
                }

                if (!range) return;
                if (range.length === 0) {
                    this.$refs.selectionTextPopup.close();
                    return;
                }

                const bounds = this.editor.getBounds(range.index, range.length);
                setTimeout(() => {
                    this.$refs.selectionTextPopup.open({
                        top: bounds.top + bounds.height,
                        left: bounds.left,
                    });
                });
            });

            this.initSlashHelper();
            this.getLinkFromPaste();
            this.getLinkInline();

            this.popup = new Popups({ editor: this.editor, refs: this.$refs });
            this.popup.initPopup('atPopup', '@');
            this.popup.initPopup('slashPopup', '/');

            this.previewDocument = new PreviewDocument({ document: this.document });

            this.editor.on('text-change', _.debounce(this.makePreview, 1000, { 'trailing': true }));

            this.$refs.editor.addEventListener('visibilitychange', this.makePreview);
        },

        makePreview() {
            if (!this.isPublicLink) {
                this.previewDocument.makePreview(this.$html2canvas);
            }
        },

        /**
         * Возращение false блокирует дефолтное действие нажатой кнопки,
         * например переход на строку ниже при нажатии стрелки вниз
         * */
        pressKey(range, context, key) {
            const { keyName } = key;
            const pressArrow = keyName === keyboards.up.keyName || keyName === keyboards.down.keyName;
            const pressEnter = keyName === keyboards.enter.keyName;

            const openedPopup = () => {
                if (this.$refs.slashPopup.isOpen) {
                    return 'slashPopup';
                }
                if (this.$refs.atPopup.isOpen) {
                    return 'atPopup';
                }
                return null;
            };

            if (openedPopup()) {
                if (pressArrow) {
                    this.$refs[openedPopup()].onArrow(keyName);
                    return false;
                }
                if (pressEnter) {
                    this.$refs[openedPopup()].onEnter();
                    return false;
                }
            }

            if (key.keyNameIsValue) {
                this.editor.insertText(range.index, key.keyCode, Quill.sources.USER);
                setTimeout(() => {
                    this.editor.setSelection(range.index + 1);
                });
                return false;
            }

            return true;
        },

        initSlashHelper() {
            setTimeout(() => {
                this.editor.on('selection-change', range => {
                    if (this.$refs.slashHelper) {
                        let isClose = true;
                        if (range) {
                            let [line] = this.editor.getLine(range.index);
                            if (line.domNode.localName !== 'td')
                                if (line.children.length === 1 && line.children.tail.domNode.tagName === 'BR') {
                                    const bounds = this.editor.getBounds(range.index, range.length);
                                    this.$refs.slashHelper.open({
                                        top: bounds.top,
                                        left: bounds.left,
                                    });
                                    isClose = false;
                                }
                        }

                        if (isClose) {
                            this.$refs.slashHelper.close();
                        }
                    }
                });
            });
        },

        setMentorClasses() {
            const $this = this;
            const $document = document;
            setTimeout(() => {
                const mentors = $document.getElementsByClassName(`ql-mentor`);
                if (mentors && mentors.length > 0) {
                    for (let i = 0; i < mentors.length; i++) {
                        if (!hasClass(mentors[i], 'ql-mentor_owner') && Number(mentors[i].getAttribute('data-mentor-id')) === $this.user.id) {
                            mentors[i].classList.add('ql-mentor_owner');
                        }
                    }
                }
            });
        },

        setBGText() {
            const $document = document;
            setTimeout(() => {
                const stylesBlocks = $document.querySelectorAll('*[style]');
                if (stylesBlocks && stylesBlocks.length > 0) {
                    let el;
                    for (let i = 0; i < stylesBlocks.length; i++) {
                        el = stylesBlocks[i];
                        if (el.style && el.style['background-color'] && el.tagName === 'SPAN') {
                            el.style['border-radius'] = '5px';
                            el.style['padding'] = '1px 2px';
                        }
                    }
                }
            });
        },

        setStylesAndClasses() {
            this.setMentorClasses();
            this.setBGText();
        },

        insertMention() {
            let range = this.editor.getSelection(true);
            if (!range) return;

            const offset = range.index + range.length;

            this.editor.insertText(offset, '@', Quill.sources.USER);
            this.editor.setSelection(offset + 1, Quill.sources.SILENT);

            setTimeout(() => this.popup.openPopup('atPopup'));
        },

        startDoc(editor, docId) {
            const docConnection = connection().get('documents', String(docId));

            docConnection.subscribe((err) => {
                if (err) throw err;

                editor.setContents(docConnection.data);
                this.setStylesAndClasses();

                this.documentLoaded = true;

                this.emittingUserInfo.username = this.user?.username;
                this.emittingUserInfo.surname = this.user?.surname;
                this.emittingUserInfo.image = this.user?.image;
                this.emittingUserInfo.color = this.documentInfo?.color;

                this.comment = new Comment({
                    editor: this.editor,
                    document: this.document,
                    editorContainer: this.$refs.editorContainer,
                    commentPopup: this.$refs.commentPopup,
                    userSocket,
                    emittingUserInfo: this.emittingUserInfo,
                    awn: this.$awn,
                    quil: Quill,
                    viewComments: !this.isPublicLink || this.publicLinkPermissions.includes('view_comments'),
                });

                this.comment.initComments();
                this.comment.addUserSocketListeners();

                editor.on('text-change', (delta, oldDelta, source) => {
                    this.comment.updateCommentsPositionsAndExistence();
                    /*
                    Возможные source:
                    user - действие самого пользователя, тправляются на бэк и другим пользователям,в режиме просмотра не работают
                    application - отправляются даже в режиме просмотра, например как комментарии в режиме suggest
                    api - действия с помощью апи, не отправляются на бэк и другим пользователям
                    silent - видны только у самого пользователя в редакторе, например смена позиции курсора, не отправляются на бэк и другим пользователям
                    */

                    if (source !== 'user' && source !== 'application') return;
                    let authorDelta = new Delta();
                    let commentId;
                    let domIndicator;
                    let domText;

                    delta.ops.forEach(op => {
                        if (op.delete) {
                            oldDelta.ops.map((el) => {
                                commentId = el?.attributes?.['comment-text']?.id;
                                if (commentId) {
                                    domIndicator = document.querySelector(`.ql-comment-indicator[data-comment-id="${commentId}"]`);
                                    domText = document.querySelector(`.ql-comment-text[data-comment-id="${commentId}"]`);
                                    if (domIndicator && !domText) {
                                        domIndicator.remove();
                                    }
                                }
                            });
                            return;
                        }

                        if (op.insert || (op.retain && op.attributes)) {
                            op.attributes = op.attributes || {};
                            op.attributes.author = {
                                id: this.user.id,
                                color: `#${this.documentInfo?.color}`,
                                name: `${this.user.username} ${this.user.surname}`,
                            };

                            authorDelta.retain(
                                op.retain || op.insert.length || 1,
                            );
                        } else {
                            authorDelta.retain(op.retain);
                        }
                    });

                    editor.updateContents(authorDelta, 'silent');
                    docConnection.submitOp(delta, { source: editor });

                    this.setStylesAndClasses();
                });

                docConnection.on('op', (op, source) => {
                    if (source === editor) return;
                    editor.updateContents(op);
                    this.setStylesAndClasses();
                });
            });
            return docConnection;
        },

        //после изменения формата скролит вверх, этот код нужен, что бы не терять позицию
        returnScrollerY(action) {
            this.scrollerY = window.scrollY;
            action();
            window.scroll(0, this.scrollerY);
            this.scrollerY = 0;
        },

        formatting(changes) {
            this.returnScrollerY(() => {
                // свойство background format сам переводит с backfournd-color и градиент оно не видит
                // backfournd-image не видит вообще
                Object.keys(changes).forEach(key => {
                    this.editor.format(key, changes[key], Quill.sources.USER);
                });
                this.format = this.editor.getFormat();
            });
        },

        focusEditor() {
            this.editor.focus();
        },

        blurEditor() {
            this.editor.blur();
        },

        updateMode(mode) {
            this.mode = mode;
            if (mode === 'view' || mode === 'suggest') {
                this.editor.disable();
            } else {
                this.editor.enable();
            }
        },

        openEditLink(range, data = false) {
            const bounds = this.editor.getBounds(range.index, range.length);
            const options = {
                top: bounds.top + bounds.height + 5,
                left: bounds.left,
                withName: data ? true : range.length === 0,
                ...data,
            };

            this.$refs.linkPopup.open(options);
            this.$refs.linkPopup.$off('save');
            this.$refs.linkPopup.$off('update');
            this.$refs.linkPopup.$on('save', e => {
                if (range.length > 0) {
                    this.formatting({ link: e.link });
                } else {
                    const linkName = e.name || e.link;
                    this.editor.insertText(
                        range.index,
                        linkName,
                        { link: e.link },
                        Quill.sources.USER,
                    );
                }
                setTimeout(() => {
                    this.openLinkEmbedPopup(range, bounds, {
                        link: e.link,
                        name: e.name,
                    });
                });
            });
            this.$refs.linkPopup.$on('update', e => {
                this.editor.deleteText(range.index, e.nameOldLength, Quill.sources.USER);
                this.editor.insertText(
                    range.index,
                    e.name ? e.name : e.link,
                    { link: e.link },
                    Quill.sources.USER,
                );
            });
        },

        openLinkEmbedPopup(range, bounds, { name, link }) {
            let description = null;
            let title = null;
            let miniature = null;

            const textLength = range.length > 0 ? range.length : (name || link).length;
            this.$refs.linkEmbedPopup.$off('dismiss');
            this.$refs.linkEmbedPopup.$off('createEmbed');
            this.$refs.linkEmbedPopup.open({
                top: bounds.top + bounds.height + 5,
                left: bounds.left,
            });
            this.$refs.linkEmbedPopup.$on('dismiss', () => {

            });
            this.$refs.linkEmbedPopup.$on('createEmbed', async () => {
                this.editor.deleteText(range.index, textLength, Quill.sources.USER);

                const urlData = await getHtmlByUrl({ url: link });

                const el = document.createElement('html');
                el.innerHTML = urlData;

                miniature = el.querySelectorAll('head > meta[property="og:image"]');
                if (miniature && miniature[0] && miniature[0].content) {
                    miniature = miniature[0].content;
                } else {
                    miniature = null;
                }

                let meta = el.querySelectorAll('meta');
                for (let i of meta) {
                    if (i.name === 'description') {
                        description = i.content;
                        break;
                    }
                }

                let titleBlock = el.querySelector('title');
                title = titleBlock.text;
                let favicon = `http://www.google.com/s2/favicons?domain=${link}`;


                this.editor.insertEmbed(range.index, 'link-embed', {
                    link,
                    title,
                    miniature,
                    description,
                    favicon,
                }, Quill.sources.USER);
            });
        },

        insertLink(customRange) {
            const range = customRange || this.editor.getSelection(true);

            if (!range) return;

            if (this.format.link) {
                this.formatting({ link: false });
                return;
            }

            this.openEditLink(range);
        },

        insertMentor(user) {
            const range = this.editor.getSelection(true);
            const sender = getUserMainData(this.user);
            const receiver = getUserMainData(user);
            if (sender.firstName && receiver.firstName) {
                const data = {
                    documentId: this.document.id,
                    documentName: this.document.name,
                    senderFirstLetters: sender.firstLetters,
                    senderFullname: sender.fullName,
                    haveLink: true,
                };

                this.userWS.emit('send-notification', {
                    sender: {
                        id: sender.id,
                        username: sender.fullName,
                    },
                    receivers: [
                        {
                            id: receiver.id,
                            username: receiver.fullName,
                        },
                    ],
                    type: websocketsEventsNotifications.notedInDocument,
                    data,
                });

                // eslint-disable-next-line no-unused-vars
                const { id, fullName: name } = receiver;
                this.editor.insertEmbed(range.index, 'mentor', { id, name }, Quill.sources.USER);
                this.editor.deleteText(range.index - 1, 1, Quill.sources.USER);
                const offset = this.$refs.atPopup.indexPosition + 1;
                this.editor.setSelection(offset, Quill.sources.SILENT);

            }
        },

        insertTable(size) {
            let tableModule = this.editor.getModule('table');
            tableModule.insertTable(...size);
        },

        insertBlockCallout() {
            const { index } = this.editor.getSelection(true);
            this.editor.insertEmbed(index, 'callout', Quill.sources.USER);
        },

        async insertDocument(options) {
            const range = this.editor.getSelection(true);
            let index = range.index;

            if (options.meta.createBy === 'popupSymbol') {
                index = range.index - 1;

                if (options.meta.popup === 'atPopup') {
                    this.editor.deleteText(index, 1, Quill.sources.USER);
                }

                if (options.meta.searchText) {
                    const countDeleteSymbol = options.meta.searchText.length;

                    index = index - countDeleteSymbol;

                    if (options.meta.popup === 'atPopup') {
                        this.editor.deleteText(index, countDeleteSymbol, Quill.sources.USER);
                    }
                }
            }

            const paramsCreateDocument = { id: null, name: null };
            let createdDocumentId = null;

            if (options.meta.typeDocument === 'newDocument') {
                createdDocumentId = await this.createDocument({
                        document_id: this.document.id,
                    },
                    false,
                );
                paramsCreateDocument.id = createdDocumentId;
                paramsCreateDocument.name = `document ${createdDocumentId}`;
                paramsCreateDocument.parent = 'true';
            }

            if (options.meta.typeDocument === 'existingDocument') {
                paramsCreateDocument.id = options.document.id;
                paramsCreateDocument.name = options.document.name;
            }

            this.editor.insertEmbed(index, 'sub-document', paramsCreateDocument, Quill.sources.USER);

            const offset = this.$refs[options.meta.popup].indexPosition + 1;
            this.editor.setSelection(offset, Quill.sources.SILENT);

            if (options.meta.typeDocument === 'newDocument') {
                if (createdDocumentId) {
                    this.$router.push(`/editor/${createdDocumentId}`);
                }
            }
        },

        undo() {
            this.editor.history.undo();
        },

        redo() {
            this.editor.history.redo();
        },

        cut() {
            document.execCommand('cut');
        },

        copy() {
            document.execCommand('copy');
        },

        deleteText(customRange) {
            const range = customRange || this.editor.getSelection();

            if (!range) return;

            this.editor.deleteText(range.index, range.length, Quill.sources.USER);
        },

        openEditLinkFromLinkPopup(customRange) {
            const range = customRange || this.editor.getSelection();

            if (!range) return;

            const format = this.editor.getFormat(range);
            const link = format.link;
            const name = this.editor.getText(range);

            if (link && name) {
                setTimeout(() => {
                    this.openEditLink(range, {
                        name,
                        link,
                        updateMode: true,
                    });
                });
            }
        },

        removeLink(customRange) {
            const range = customRange || this.editor.getSelection();

            if (!range) return;

            const format = { ...this.editor.getFormat(range) };
            format.link = false;
            this.editor.formatText(range.index, range.length, format, Quill.sources.USER);
        },

        async paste() {
            navigator.clipboard.readText().then(clipText => {
                const range = this.editor.getSelection();
                if (!range) return;
                this.editor.insertText(range.index, clipText);
            });
        },

        insertDivider() {
            let range = this.editor.getSelection();
            if (!range) return;

            this.editor.insertText(range.index, '\n', Quill.sources.USER);
            this.editor.insertEmbed(range.index + 1, 'divider', true, Quill.sources.USER);
            this.editor.setSelection(range.index + 2, Quill.sources.SILENT);
        },

        openFormulaPopup() {
            const range = this.editor.getSelection(true);
            const bounds = this.editor.getBounds(range.index, range.length);
            const options = {
                top: bounds.top + bounds.height + 5,
                left: bounds.left,
            };
            this.$refs.formulaPopup.open(options);
            this.$refs.formulaPopup.$off('save');
            this.$refs.formulaPopup.$on('save', e => {
                this.returnScrollerY(() => {
                    //TO DO по факту это клон блота квила, но блот квила никак не хочет видеть katex и повтроить его попап
                    this.editor.insertEmbed(range.index, 'formula2', e.value, Quill.sources.USER);
                    this.editor.setSelection(range.index + 1, Quill.sources.SILENT);
                });
            });
        },

        insertImage(customRange) {
            const range = customRange || this.editor.getSelection(true);
            if (!range) return;
            this.offsetImage = range.index + range.length;
            this.$refs.fileReaderInput.click();
        },

        changeImage(e) {
            if (this.offsetImage) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.predictPornContent(reader);
                };
                reader.onerror = (error) => {
                    console.log('Error READ FIle: ', error);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        },

        insertEmoji(emoji) {
            let range = this.editor.getSelection(true);
            if (!range) return;

            const offset = range.index + range.length;

            this.editor.insertText(offset, emoji.native, Quill.sources.USER);
            this.editor.setSelection(offset + 2, Quill.sources.SILENT);
        },

        setNameToAnonymousForAddComment(anonymousName) {
            this.emittingUserInfo.username = anonymousName;

            this.insertComment();
        },

        insertComment() {
            if (!this.selectedTextForAddComment) {
                this.selectedTextForAddComment = this.editor.getSelection();
            }

            if (!this.isPublicLink || this.publicLinkPermissions.includes('comment')) {
                if (this.emittingUserInfo.username) {
                    this.comment.insertComment(this.selectedTextForAddComment);
                    this.selectedTextForAddComment = null;
                    return;
                }

                this.$refs.tryAddCommentByAnonymous.isOpen = true;
            }
        },

        async saveVersion() {
            const fullName = `${this.user.username || ''} ${this.user.surname || ''}`;
            this.$awn.async(createSnapshot(this.document.id, fullName, this.documentInfo?.color),
                'Version saved successfully',
                'Something went wrong, please try again later',
            );
        },

        async restoreSnapshot(sn) {
            this.snapshotRestoreStarted = true;
            userSocket.emit('startRestoreSnapshot', this.document.id);
            try {
                await restoreSnapshot(this.document.id, sn._id);
                userSocket.emit('endRestoreSnapshot', this.document.id);
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            }
        },

        async duplicateSnapshot(sn) {
            const entitiesTypes = {
                project: 'project_id',
                room: 'room_id',
                folder: 'folder_id',
            };
            const entityType = ENTITY_TYPES[this.document.entity_type];
            const docId = await this.createDocument({ [entitiesTypes[entityType]]: this.document.entity_id }, false);
            try {
                await duplicateSnapshot(this.document.id, docId, sn.current ? false : sn._id);
                this.$router.push({ name: 'Editor', params: { id: docId } });
                await this.init();
                this.snapshotsIsOpen = false;
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            }
        },

        getLinkInline() {
            const urlR = /^(https?:\/\/|(www\.))([^\s\\/$.?#:].[^\s]*$)/;
            const editor = this.editor;
            editor.on('text-change', function (delta, oldDelta, source) {
                const blanks = [' ', '\n'];
                if (source !== 'user') return;
                let ops = JSON.parse(JSON.stringify(delta?.ops)) || [];
                let ins = ops.pop()?.insert; // получаем объект последней вставки
                if (!ins) return;
                if (typeof ins !== 'string' || blanks.includes(ins)) { // если это не строка или строка-разделитель слова
                    let pos = ops.pop()?.retain || 0; // получаем позицию курсора перед вставкой пробельного символа
                    if (!pos) return;
                    let old = JSON.parse(JSON.stringify(oldDelta.slice(0, pos)));
                    let op = old.ops?.pop(); //получаем последнюю операцию из контента документа перед курсором
                    let text = op.insert; //берем последний  текст из контента
                    if (typeof text !== 'string') return; // проверка для случая когда мы переходим на новую строку после вставки блока
                    if (!text.trim()) return;
                    //начиная с конца текста, найдем позицию разделителя слов
                    let i = text.length - 1;
                    // eslint-disable-next-line no-empty
                    while (i >= 0 && !blanks.includes(text[i]) && i--) {
                    }
                    i++;
                    let word = text.substring(i); // последнее слово в тексте
                    if (!word.match(urlR)) return;
                    let href = word.replace(urlR, 'https://$2$3');

                    editor.updateContents(new Delta()
                            .retain(pos - word.length)
                            .retain(word.length, { name: href, link: href }),
                        Quill.sources.USER);
                }
            });
        },

        checkImageForContent() {
            setTimeout(() => {
                this.$refs.editor.addEventListener('paste', (event) => {
                    let img = event.srcElement.currentSrc;
                    if (img === undefined) return;
                    this.predictPornContent(img, true);
                });
            }, 0);
        },

        getLinkFromPaste() {
            this.$refs.editor.addEventListener('paste', (event) => {
                const range = this.editor.getSelection();
                const offset = range.index + range.length;
                let text = event.clipboardData.getData('text');
                try {
                    new URL(text);
                    this.editor.insertText(
                        range.index,
                        text,
                        { name: text, link: text },
                        Quill.sources.USER,
                    );
                    this.editor.deleteText(range.index - text.length, text.length, Quill.sources.USER);
                    this.editor.setSelection(offset + 1, Quill.sources.SILENT);
                    const bounds = this.editor.getBounds(range.index, range.length);
                    setTimeout(() => {
                        this.openLinkEmbedPopup({
                                index: range.index - text.length,
                                length: text.length,
                            },
                            bounds,
                            {
                                link: text,
                            });
                    });
                } catch {
                    return null;
                }
            });
        },

        openActionPopup(val) {
            if (this.$refs.actionPopup.action === val) {
                this.$refs.actionPopup.isOpen = false;
                this.$refs.actionPopup.action = '';
                this.currentSideActionPopup = '';
            } else {
                this.$refs.actionPopup.isOpen = true;
                this.$refs.actionPopup.action = val;
                this.currentSideActionPopup = val;
            }
            if (!this.wasClickOutsideEditor) {
                this.editor.getSelection(true);
            }
            this.$root.$emit('close-publish-to-web-settings');
        },

        async predictPornContent(image, fromPaste = false) {
            const img = document.createElement('img');
            img.src = !fromPaste ? image.result : image;

            const model = await nsfwjsLoad();
            const predictions = await model.classify(img, 2);
            img.remove();

            const preparedPredictions = predictions
                .map((el) => ({ [el.className]: el.probability }))
                .filter(el => el.Sexy > 0.75 || el.Hentai > 0.75 || el.Porn > 0.75);

            if (preparedPredictions.length) {
                if (fromPaste) {
                    const range = this.editor.getSelection();
                    this.editor.deleteText(range.index - 1, 1, Quill.sources.USER);
                }
                this.$awn.alert('This image is not allowed to be uploaded');
            } else {
                this.editor.insertEmbed(this.offsetImage, 'custom-image', { url: image.result }, Quill.sources.USER);
                this.editor.setSelection(this.offsetImage + 2, Quill.sources.SILENT);
                this.offsetImage = null;
            }

            this.$refs.fileReaderInput.value = '';
        },

        clearAction() {
            this.currentSideActionPopup = '';
        },
    },
};
</script>

<style lang="scss" scoped>
.editor {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    ::v-deep .emoji-modal {
        .emoji-mart {
            height: 400px;
        }
    }

    &-wrapper {
        display: flex;
        padding: 1rem 0;
        flex-grow: 1;

        &--parent {
            flex-direction: column;
        }

        &--child {
            padding: 0;
            flex-grow: 1;
        }
    }

    &-container {
        position: relative;

        @include editor_container();
    }

    @include mobile_or_tablet {
        &-wrapper {
            padding: rem(75px) 0 0;
        }
    }

    &::v-deep {
        @include doc-list();
    }
}
</style>
