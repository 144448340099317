<template lang="pug">
    .files-table(:class="classes")
        .files
            //- files-table__link используется для drag and drop
            draggable(v-for="doc in sortedDocuments" :key="doc.id" @start="onStart(doc.id)" :style="{width: isMobileOrTablet ? '48%' : '186px'}")
                document-item.files__item.files-table__link(
                    :id="doc.id"
                    :class = "moveActions.entityClasses(doc, 'files__item--selected')"
                    :item="doc"
                    width="auto"
                    :height="'195px'"
                    @contextmenu="openDocContext($event, doc)"
                    @mouseover="moveActions.mouseoverEntityHandler(doc)"
                    @mouseup="moveActions.mouseupEntityHandler(doc)"
                    @click="clickFile(doc)"
                    @dblclick="moveActions.dbclickEntityHandler(doc)"

                )

            EditorInvitePopup
            context-menu(
                ref="contextMenu"
                :items="['share', 'download', 'rename', 'moveTo', 'duplicate', 'delete']"
            )
            move-to-folders-context(ref="moveToFoldersContext")
            export-document-context(ref="exportDocumentContext")


</template>

<script>
import docIcon from '@/assets/images/icons/doc.svg?inline';
import viewGridIcon from '@/assets/images/icons/view_grid.svg?inline';
import viewListIcon from '@/assets/images/icons/view_list.svg?inline';
import arrowBottomIcon from '@/assets/images/icons/arrow_bottom.svg?inline';
import MoveToFoldersContext from '@/components/other/MoveToFoldersContext';
import PageRedirectMixin from '@/mixins/PageRedirectMixin';
import ExportDocumentContext from '@/components/other/ExportDocumentContext';
import DocumentItem from '@/components/common/documentItem';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { ENTITY_TYPES_NAMES } from '@/js/const';
import dashboardContextMenuGeneratorMixin from '@/mixins/ContextMenuGeneratorMixin';
import EditorInvitePopup from '@/components/editor/invitePopup/EditorInvitePopup';

import draggable from 'vuedraggable';

export default {
    name: 'files-table',

    components: {
        EditorInvitePopup,
        MoveToFoldersContext,
        ExportDocumentContext,
        docIcon,
        viewGridIcon,
        viewListIcon,
        arrowBottomIcon,
        PageRedirectMixin,
        DocumentItem,
        draggable,
    },

    mixins: [PageRedirectMixin, dashboardContextMenuGeneratorMixin],

    props: {
        contentFromStore: {
            type: Boolean,
            default: false,
        },

        documents: {
            type: Array,
            required: true,
        },

        selectable: {
            type: Boolean,
            required: false,
            default: false,
        },

        // удалить документ из таблицы после переноса в другое место
        deleteDocumentAfterMove: {
            type: Boolean,
            required: false,
            default: true,
        },

        showFilters: {
            type: Boolean,
            required: false,
            default: false,
        },

        moveActions: {
            type: Object,
            default: () => {
                return {
                    entityClasses: () => {
                    },
                    mouseoverEntityHandler: () => {
                    },
                    mouseupEntityHandler: () => {
                    },
                    clickEntityHandler: () => {
                    },
                    dbclickEntityHandler: () => {
                    },
                };
            },
        },
    },


    data: () => ({
        selectedDocuments: [],

        sortBy: 'updated_at',
        order: 'descend', // ascend, descend

        view: 'list',
        actions: ['share', 'download', 'rename', 'moveTo', 'duplicate', 'delete'],

        searchString: '',
        contentType: null,
        contentTypeList: [
            {
                name: 'All Content',
                key: 'all',
            },
            {
                name: 'Files',
                key: 'files',
            },
            {
                name: 'Images',
                key: 'images',
            },
        ],
    }),

    computed: {
        clickFile() {
            return this.contentFromStore ? this.moveActions.clickEntityHandler : (entity) => {
                // TO DO если появится ещё контент, то надо на беке попросить чтоб везде у всех сущностейй вставляли тип
                this.pageRedirect({ ...entity, type: ENTITY_TYPES_NAMES.document });
            };
        },

        classes() {
            return {
                'files-table--grid': this.view === 'grid',
            };
        },
        sortedDocuments() {
            //поскольку переменные сортировки локальны у компонента, то
            //либо документы из стора, а там они уже будут остортированы(смотри changeSortBy ниже)
            if (this.contentFromStore) {
                return this.$store.getters.getDocuments;
            } else {
                //либо документы из родителя и мы их сортируем здесь
                return this.sortDocuments(this.documents);
            }
        },

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },

    },

    mounted() {
        this.sortStoreDocuments();
    },

    methods: {

        onStart(id) {
            this.$emit('setDocumentId', id);
        },

        sortDocuments(documents) {
            documents = [...documents];

            if (this.searchString) {
                documents = documents.filter(d => d.name.includes(this.searchString));
            }

            if (this.contentType?.key === 'images') {
                documents = [];
            }

            if (this.sortBy === 'updated_at') {
                documents.sort((d1, d2) => +moment(d1.updated_at) - +moment(d2.updated_at));
            } else if (this.sortBy === 'name') {
                documents.sort((d1, d2) => d1.name > d2.name ? 1 : d1.name < d2.name ? -1 : 0);
            }

            if (this.order === 'descend') {
                documents = documents.reverse();
            }

            return documents;
        },

        sortStoreDocuments() {
            if (this.contentFromStore) {
                this.$store.dispatch('setContentDocuments', this.sortDocuments(this.sortedDocuments));
            }
        },

        moment,
        isEmpty,
    },
};
</script>

<style lang="scss" scoped>
.files {
    display: flex;
    flex-wrap: wrap;
    gap: 33px;
    margin-bottom: 40px;

    &__item {
        display: flex;
        flex-direction: column;
        padding: 1px;
        background: #FFFFFF;
        overflow: hidden;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.1);
        border-radius: 9px;

        &--selected {
            padding: 0;
            background: rgba(106, 112, 255, 0.08);
            border: 1px solid #161FFE;
            box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.1);
        }
    }

    &__link {
        padding: 13px;
        flex-grow: 1;
    }

    &__title {
        padding-bottom: 7px;
        margin-bottom: 15px;
        font-size: 12px;
        border-bottom: 1px solid #CECDCD;
    }

    &__icon {
        margin-right: 4px;
    }

    @media screen and (max-width: 500px) {
        gap: 13px;
    }
}

</style>
