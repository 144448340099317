<template lang="pug">
    .settings-team-management
        header
            .title Team management
            app-button(@click="$modal.show('invite-members-popup')") Invite Members
            app-button(disabled) Invite Guests

        .tabs
            .tab Members
            .tab Guests
            .tab Invites
        .search-field
            app-text-field(
                v-model="searchField"
                placeholder="Search User"
            )
            search-icon.search-field-icon

        table
            tr
                th Name
                th Role
                th Joined
            tr(v-for="n in 0")
                td
                    .user-info
                        .user-info__photo PB
                        div
                            .user-info__name Petr Brown
                            .user-info__email petr.brown@reskript.com
                td Owned
                td 3 months ago


</template>

<script>
import searchIcon from '@/assets/images/icons/search.svg?inline';
export default {
    name: 'settings-invite-friends',

    components: {
        searchIcon,
    },

    data: () => ({
        searchField: '',
    }),
};
</script>

<style scoped lang="scss">
.settings-team-management {
    padding: 1.5rem;
}

header {
    display: flex;

    margin-bottom: rem(30px);

    .title {
        margin-right: auto;
        padding-top: 0.5rem;

        font-size: rem(20px);
        font-weight: bold;
    }

    .app-button {
        margin-left: rem(20px);
    }
}

.tabs {
    display: flex;

    margin-bottom: rem(20px);
    padding-bottom: 0.5rem;

    border-bottom: 1px solid rgba($grey, 0.5);

    .tab {
        margin-right: rem(35px);

        font-weight: 600;

        cursor: pointer;
    }
}

.search-field {
    position: relative;

    width: rem(300px);

    .app-text-field {
        &::v-deep .app-text-field__input {
            height: rem(40px);

            font-size: 1rem;
        }
    }

    &-icon {
        position: absolute;
        top: rem(10px);
        right: rem(12px);

        width: rem(18px);

        pointer-events: none;
    }
}

table {
    width: 100%;

    tr {
        border-bottom: 1px solid rgba($grey, 0.25);
    }

    th {
        padding: 0.25rem 0 0.5rem;

        font-weight: 600;
        text-align: left;
    }

    td {
        padding: rem(12px) 0;

        &:nth-of-type(1) {
            width: 50%;
        }

        &:nth-of-type(2) {
            width: 25%;
        }
    }
}

.user-info {
    display: flex;
    align-items: center;
    &__photo {
        display: flex;
        justify-content: center;
        align-items: center;

        width: rem(40px);
        height: rem(40px);
        margin-right: rem(10px);

        font-weight: 500;
        color: #fff;

        background: $blue;
        border-radius: 50%;
    }

    &__name {
        margin-bottom: 0.25rem;
    }

    &__email {
        font-size: rem(12px);
    }
}

@include mobile_or_tablet {
    header {
        flex-wrap: wrap;
        justify-content: space-between;

        .title {
            margin-bottom: 1.5rem;
            padding-top: 0;

            font-size: 1.5rem;
        }

        .app-button {
            margin: 0;
        }
    }

    .tabs {
        .tab {
            font-size: 1.25rem;
        }
    }

    .search-field {
        width: 100%;

        .app-text-field {
            &::v-deep .app-text-field__input {
                height: 3rem;

                font-size: 1.25rem;
            }
        }

        &-icon {
            top: 0.8rem;
            right: 1rem;

            width: 1.4rem;
        }
    }
}
</style>
