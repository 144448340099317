<template>
    <div
        class="folder-detail"
        @mousedown="mousedownHandler"
        @mousemove="mousemoveHandler"
        @mouseup="mouseupHandler"
    >
        <app-loader v-if="pageLoading" />
        <template v-else>
            <div class="default-layout-head">
                <app-breadcrumbs
                    :items="breadcrumbs"
                    class="app-breadcrumbs_mb app-breadcrumbs--theme-thin"
                    @clickBreadcrumb="clickEntityHandler"
                    @mouseoverBreadcrumb="mouseoverEntityHandler"
                    @mouseupBreadcrumb="mouseupEntityHandler"
                />
            </div>
            <project-header
                :background="background"
                :emoji="emoji"
                :project="project"
                @open="openModal($event)"
            />
            <div class="default-layout-row">
                <div class="folder-detail__left">
                    <folders
                        :click-entity-handler="clickEntityHandler"
                        :create-folder-params-when-empty="createFolderOptions"
                        :create-folder-params-when-non-empty="createFolderOptions"
                        :dbclick-entity-handler="dbclickEntityHandler"
                        :entity-classes="entityClasses"
                        :folders="folders"
                        :mouseover-entity-handler="mouseoverEntityHandler"
                        :mouseup-entity-handler="mouseupEntityHandler"
                        :open-folder-context="openFolderContext"
                    />
                    <documents
                        :create-document-options="createDocumentOptions"
                        :documents="documents"
                        :move-actions="moveActions"
                    />
                </div>
                <div
                    v-if="!isRootLibrary"
                    class="folder-detail__right"
                >
                    <div class="default-layout-block">
                        <chat
                            ref="chat"
                            :chat-type="ENTITY_TYPES_NAMES.project"
                            :entity-id="project.id"
                            always-open
                        />
                    </div>
                </div>
            </div>
            <context-menu
                ref="folderContext"
                :is-folders="true"
                :items="['moveTo', 'rename', 'delete']"
            />
            <list-children-move
                :counter="childrenLength"
                :show="isChildrenHaveAndMove"
            />
            <list-child-move
                v-for="(selectedChild, index) in selectedChildrenAnimations"
                :key="index"
                :child="selectedChild"
            />
            <move-to-folders-context ref="moveToFoldersContext" />
            <project-edit-popup
                @updateBackground="updateBackground($event)"
                @updateEmoji="updateEmoji($event)"
            />
        </template>
    </div>
</template>

<script>
import FolderContextMenuGeneratorMixin from '@/mixins/FolderContextMenuGeneratorMixin';
import MoveToFoldersContext from '@/components/other/MoveToFoldersContext';
import ChildrenMixin from '@/mixins/ChildrenMixin';
import { getFolderById } from '@/js/api/requests/folder';
import { ENTITY_TYPES_NAMES } from '@/js/const';
import { getProjectById } from '@/js/api/requests/project';
import TabsMixin from '@/mixins/TabsMixin';
import projectEditPopup from '@/components/pages/projects/detail/popup/projectEditPopup';
import projectHeader from '@/components/pages/projects/detail/projectHeader';
import reactiveUpdateProjectMixin from '@/mixins/reactiveUpdateProjectMixin';
import Chat from '@/components/other/chat';


export default {
    name: 'folder-detail',

    components: {
        Chat,
        MoveToFoldersContext,
        projectEditPopup,
        projectHeader,
    },

    mixins: [reactiveUpdateProjectMixin, ChildrenMixin, FolderContextMenuGeneratorMixin, TabsMixin],

    data: () => ({
        folder: null,
        pageLoading: true,
        project: null,

        tabs: [
            {
                id: 0,
                name: 'Project Library',
                tab: 'library',
            },
            {
                id: 1,
                name: 'Team Management',
                tab: 'manage-team',
            },
            {
                id: 2,
                name: 'Tasks',
                tab: 'calendar',
            },
            {
                id: 3,
                name: 'Settings',
                tab: 'settings',
            },
        ],
        popupTabsBg: [
            {
                title: 'Gradients',
                value: 'gradients',
            },
            {
                title: 'Unsplash',
                value: 'unsplash',
            },
            {
                title: 'Upload an image',
                value: 'upload',
            },
        ],
        popupTabsIcon: [
            {
                title: 'Emoji',
                value: 'emoji',
            },
        ],
    }),

    computed: {
        breadcrumbs() {
            return this.getBreadcrumps(this.folder);
        },
        createFolderOptions() {
            return this.folder ? { folder: { ...this.folder }, goToFolder: true } : null;
        },
        root() {
            return this.breadcrumbs[0];
        },
        isRootLibrary() {
            return this.root.type === this.ENTITY_TYPES_NAMES.library;
        },

        createDocumentOptions() {
            return { folder_id: this.folder.id };
        },

        setBackground() {
            if (this.project.background === null) {
                return this.defaultBackground;
            } else return this.project.background.includes('https') ? `background-image: url(${this.project.background}) ` : `background: ${this.project.background}`;
        },
    },

    watch: {
        $route() {
            this.fetchData();
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        async fetchData() {
            this.pageLoading = true;
            await this.fetchFolder();
            await this.fetchProject();

            this.pageLoading = false;
        },

        async fetchFolder() {
            this.folder = await getFolderById(this.$route.params.folderId);
            this.folder = { ...this.folder, type: ENTITY_TYPES_NAMES.folder };
            this.$store.dispatch('setContentFolders', this.addTypeForChild(this.folder.folder_childFolders, this.ENTITY_TYPES_NAMES.folder));
            this.$store.dispatch('setContentDocuments', this.addTypeForChild(this.folder.folder_documents, this.ENTITY_TYPES_NAMES.document));
        },
        async fetchProject() {
            if (this.isRootLibrary) return;
            let parent = this.folder.parents.reverse()[0];
            this.project = await getProjectById(parent.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.folder-detail {
    padding-bottom: rem(72px);
    padding-left: 13px;

    &__left {
        width: 100%;
    }

    &__right {
        flex-shrink: 0;

        width: rem(430px);
        margin-left: rem(20px);
        @include mobile_or_tablet {
            width: 100%;
            margin-left: 0;
        }
    }

    &-files {
        min-height: 50vh;
    }
}

.project-detail-head {
    height: 170px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 7px;
    background-size: cover;

    &__popup {
        cursor: pointer;
        padding: 6px 5px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 6px;
        right: 10px;
    }

    &__tabs {
        position: absolute;
        bottom: 0;
        right: 29px;
    }

    &__info {
        position: absolute;
        display: flex;
        align-items: center;
        margin-left: 11px;
        gap: 0 17px;
        bottom: 31px;

        &--img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 65px;
            width: 65px;
            background-color: $white;
            border-radius: 9px;

            p {
                font-size: 24px;
            }
        }

        &--title {
            color: $white;
            font-size: 36px;
            font-weight: 600;
            line-height: 43px;
        }
    }
}

::v-deep {
    .default-layout-block {
        padding: 0;
        box-shadow: none;
    }
}

</style>
