<template>
    <div
        :class="classes"
        class="app-input app-text-field"
    >
        <label
            v-if="label"
            :for="uid"
            class="app-text-field__label"
            v-html="label"
        ></label>
        <div class="app-text-field__wrapper">
            <div class="app-text-field__icon">
                <slot name="icon"></slot>
            </div>
            <input
                :id="uid"
                ref="input"
                v-model="state"
                :class="customClass"
                :type="type"
                class="app-text-field__input"
                v-bind="attributes"
                @blur="$emit('blur', $event)"
            />
            <div
                v-if="isPassword"
                class="password-eye-icon"
                @click="hidePassword=!hidePassword"
            >
                <eye-icon />
            </div>
        </div>
        <div
            v-if="error"
            class="app-text-field__error"
            v-html="error"
        ></div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash';
import EyeIcon from '@/assets/images/projectIcons/26.svg?inline';

export default {
    name: 'app-text-field',

    components: {
        EyeIcon,
    },

    props: {
        value: {
            type: [Number, String, Object],
            required: true,
        },

        isPassword: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        customClass: {
            type: String,
            default: '',
        },

        error: {
            type: String,
            default: '',
        },

        typeInput: {
            type: String,
            default: '',
        },
    },

    data: () => ({
        uid: null,
        hidePassword: true,
    }),

    computed: {
        type() {
            if (this.isPassword && this.hidePassword) {
                return 'password';
            } else if (this.typeInput === 'email') {
                return 'email';
            } else {
                return 'text';
            }
        },

        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-text-field--invalid': this.error,
                'app-text-field--with-icon': this.$slots.icon,
            };
        },

        inputClasses() {
            return {
                'app-text-field__input_with-password': this.isPassword,
            };
        },

        attributes() {
            return this.$attrs;
        },
    },

    mounted() {
        this.uid = 'text-field-' + uniqueId();
    },

    methods: {
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>

