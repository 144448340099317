<template lang="pug">
    .editor-layout
        slot
</template>

<script>
export default {
    name: 'editor-layout',
};
</script>
