import { del, get, post } from '@/js/api/http-helper';

export async function getUsersDocumentAccess(fd) {
    const data = await post('/document/users-document-access', fd);
    return data.data;
}

export async function getAllDocuments() {
    const data = await get('/documents/get');
    return data.data[0].result;
}

export async function getSharedDocs() {
    const data = await get('/documents/shared-with-me');
    return data.data;
}

export async function getLastOpenedDocuments(on_page) {
    const data = await get('/documents/latest-list', { on_page });
    return data.data;
}

export async function getDocumentById(id) {
    const data = await get(`/document/get/by-document-id/${id}`);
    return data.data;
}

export async function createDocument(fd) {
    const data = await post(`/document/create`, fd);
    return data.data.id;
}

export function updateDocument(id, fd) {
    return post(`/document/update/${id}`, fd);
}

export function updateUserAccesses(documentId, fd) {
    return post(`/document/user/update/${documentId}`, fd);
}

export function moveDocumentsToBin(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/document/move/to/bin/${joinedIds}`);
}

export function deleteDocuments(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/document/delete/${joinedIds}`);
}

export function sendInviteToDocument(fd) {
    return post(`/document/send/invite`, fd);
}

export function deleteUserFromDocument(documentId, userId) {
    const fd = { user_id: userId };
    return post(`/document/user/delete/${documentId}`, fd);
}

export async function acceptDocumentInviteFromEmail(params) {
    const data = await get(`/accept/document/invite`, params);
    return data.data;
}

export async function getDocumentInviteToken(fd) {
    const data = await post(`/document/copy-link/invite`, fd);
    return data.data;
}

export async function acceptDocumentInviteByToken(fd) {
    const data = await post(`/document/accept/copy-link/invite`, fd);
    return data.data;
}

export async function getGoogleDocuments() {
    const data = await get(`/google-drive/files`);
    return data.data;
}

export async function importGoogleDocuments(fd) {
    const data = await post(`/google-drive/file/import`, fd);
    return data.data;
}

export async function exportGoogleDocuments(fd) {
    const data = await post(`/google-drive/file/export`, fd);
    return data.data;
}

export async function updateOpenedMe(documentId) {
    return await post(`documents/${documentId}/update-opened-at`);
}

export async function search(payload) {
    const data = await get(`/documents/search?`, payload);
    return data.data;
}

export async function createPublicDocumentLinkByDocumentId({ documentId, payload }) {
    return await post(`/documents/${documentId}/published`, payload);
}

export async function getPublicDocumentLinkSettingsByDocumentId(documentId) {
    return await get(`/documents/${documentId}/published`);
}

export async function getPublicDocumentLinkInfoByLinkHash(linkHash) {
    const { data } = await get(`/published-to-web/${linkHash}`);
    return data;
}

export async function getPublicDocumentLinkToken(linkHash) {
    return  await post(`published-link-login`, { link_id: linkHash });
}

export async function deletePublicDocumentLink(documentId) {
    return del(`/documents/${documentId}/published`);
}
