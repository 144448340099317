import { splitText } from '@/js/utils/text';
import MoveChildrenMixin from '@/mixins/MoveChildrenMixin';

export default {
    data: () => ({
        selectedTab: null,
        splitText,
    }),

    mixins: [MoveChildrenMixin],

    methods: {
        getSelectedTab(order) {
            this.selectedTab = null;
            return this.selectedTab = order;
        },

        documentDblclickHandler(item) {
            if (item.type !== 'folder') {
                this.$router.push(`/editor/${item.id}`);
            } else {
                this.pageRedirect(item, this.clearMouseVariables);
            }
        },
    },
};

