export const ENTITY_TYPES = {
    'App\\Entity\\ProjectPackages\\Project': 'project',
    'App\\Entity\\RoomPackages\\Room': 'room',
    'App\\Entity\\FolderPackages\\Folder': 'folder',
    'App\\Entity\\DocumentPackages\\Document': 'document',
};

export const ENTITY_TYPES_NAMES = {
    project: 'project',
    room: 'room',
    folder: 'folder',
    document: 'document',
    library: 'library',
};

export const ENTITY_TYPES_NAMES_MULTIPLE = {
    projects: 'projects',
    rooms: 'rooms',
    folders: 'folders',
    documents: 'documents',
};

export const PROJECT_ICONS_COLORS = [
    '#FE9E9E',
    '#FF6363',
    '#FBA85B',
    '#FFFA7C',
    '#5956E3',
    '#6372FF',
    '#C496FF',
    '#D874E9',
    '#72C284',
    '#AAE8BB',
    '#C3D0F2',
    '#4CBEE2',
    '#D946B9',
    '#54188F',
    '#CFFCFF',
    '#919191',
];

export const DOCUMENT_ACCESSES = [
    {
        name: 'Full Access',
        description: 'Can edit, share and download',
        settings: 'full_access',
    },
    {
        name: 'Can Edit',
        description: 'Can only edit',
        settings: 'edit',
    },
    {
        name: 'Can Comment',
        description: 'Can comment and suggest',
        settings: 'comment',
    },
    {
        name: 'Can View',
        description: 'Can only view the document',
        settings: 'view',
    },
];

export const SEARCH_FILTERS = {
    in_body: {
        name: 'in_body',
        toggled: false,
        value: false,
        text: 'Only docs texts',
        icon: require('@/assets/images/icons/a_letters.svg'),
    },
    with_deleted: {
        name: 'with_deleted',
        toggled: false,
        value: false,
        text: 'Include bin',
        icon: require('@/assets/images/icons/bin_2.svg'),
    },
    only_shared: {
        toggled: false,
        value: false,
        text: 'Only Shared with me',
        icon: require('@/assets/images/icons/shared2.svg'),
    },
    only_projects: {
        toggled: false,
        value: false,
        text: 'Only Projects',
        icon: require('@/assets/images/icons/project.svg'),
    },
};

export const websocketsEventsNotifications = {
    notedInDocument: 'notedInDocument',
    notedInProject: 'notedInProject',

    asParticipantInDocument: 'asParticipantInDocument',
    asParticipantInProject: 'asParticipantInProject',

    inviteInDocument: 'inviteInDocument',
    inviteInDocumentForInviter: 'inviteInDocumentForInviter',
    inviteInDocumentForInviterAccept: 'inviteInDocumentForInviterAccept',
    inviteInDocumentForInviterReject: 'inviteInDocumentForInviterReject',
};

export const notificationInviteStatus = {
    normal: 'normal',
    rejected: 'rejected',
    accepted: 'accepted',
};

export const PLAN_PERIOD = {
    MONTH: 'monthly',
    ANNUAL: 'annual',
};
export const PLANS_NAMES = {
    PERSONAL: 'personal',
    PRO: 'pro',
};
export const PLANS = {
    [PLANS_NAMES.PERSONAL]: {
        name: PLANS_NAMES.PERSONAL,
        icon: require('@/assets/images/icons/leaf.svg'),
        title: 'Personal',
        prices: {
            [PLAN_PERIOD.MONTH]: 0,
            [PLAN_PERIOD.ANNUAL]: 0,
        },
        optionsTitle: 'Best for Personal Use',
        options: [
            {
                icon: require('@/assets/images/icons/checked2.svg'),
                title: 'Unlimited Documents',
            },
            {
                icon: require('@/assets/images/icons/checked2.svg'),
                title: 'Export in DOCX, <br> PDF, HTML',
            },
            {
                icon: require('@/assets/images/icons/checked2.svg'),
                title: 'Unlimited collaborators',
            },
            {
                icon: require('@/assets/images/icons/checked2.svg'),
                title: '90 days version history',
            },
            {
                icon: require('@/assets/images/icons/checked2.svg'),
                title: 'Chat',
            },
        ],
    },
    [PLANS_NAMES.PRO]: {
        name: PLANS_NAMES.PRO,
        icon: require('@/assets/images/icons/tree.svg'),
        title: 'Pro',
        prices: {
            [PLAN_PERIOD.MONTH]: 10,
            [PLAN_PERIOD.ANNUAL]: 8,
        },
        priceText: 'per person / <br> per month',
        optionsTitle: 'Best for Teams',
        options: [
            {
                icon: require('@/assets/images/icons/arrow_left_3.svg'),
                title: 'All as in Personal',
            },
            {
                icon: require('@/assets/images/icons/checked2.svg'),
                title: 'Unlimited version <br> history',
            },
            {
                icon: require('@/assets/images/icons/checked2.svg'),
                title: 'Project Invitation',
            },
        ],
    },
};

export const CODE_CHILDREN_ANIMATION = {
    CREATED: 'CREATED',
    CREATED_FINISH: 'CREATED_FINISH',
    RETURN_POSITION_START: 'RETURN_POSITION_START',
    RETURN_POSITION: 'RETURN_POSITION',
    RETURN_POSITION_FINISH: 'RETURN_POSITION_FINISH',
};

export const MANAGE_TEAM_TYPE = {
    PROJECT: 'project',
    ROOM: 'room',
    FOLDER: 'folder',
};

export const SHARED_FILTERS = [
    {
        title: 'Shared Date',
        value: 'invited_at',
    },
    {
        title: 'Name',
        value: 'name',
    },
    {
        title: 'Last Modified',
        value: 'modified_at',
    },
    {
        title: 'Last Modified by Me',
        value: 'modified_by_me_at',
    },
    {
        title: 'Last Opened by Me',
        value: 'opened_by_me_at',
    },
];
export const SHARED_FILTERS_DATE = [
    {
        name: 'Today',
        value: 'today',
    },
    {
        name: 'Yesterday',
        value: 'yesterday',
    },
    {
        name: 'This week',
        value: 'this_week',
    },
    {
        name: 'This month',
        value: 'this_month',
    },
    {
        name: 'This year',
        value: 'this_year',
    },
    {
        name: 'Older',
        value: 'older',
    },
];

export const GRADIENTS = [
    {
        value: 'linear-gradient(100.24deg, #5B69FF 0%, #FF59F8 51.04%, #FFAC58 100%);',
        id: 0,
    },
    {
        value: 'linear-gradient(100.24deg, #F62626 0%, #B10D0D 100%);',
        id: 1,
    },
    {
        value: 'linear-gradient(100.24deg, #FFF50E 0%, #E72F2F 100%);',
        id: 2,
    },
    {
        value: 'linear-gradient(100.24deg, #971FA0 0%, #E626F6 100%);',
        id: 3,
    },
    {
        value: 'linear-gradient(100.24deg, #130EFF 0%, #A9A7FF 100%);',
        id: 4,
    },
    {
        value: 'linear-gradient(100.24deg, #24B0FF 0%, #1D8998 100%);',
        id: 5,
    },
    {
        value: 'linear-gradient(100.24deg, #32792B 0%, #37F626 100%);',
        id: 6,
    },
    {
        value: 'linear-gradient(100.24deg, #2FFFF3 0%, #01E017 100%);',
        id: 7,
    },
    {
        value: 'linear-gradient(100.24deg, #CFFF0E 0%, #0ED4FF 100%);',
        id: 8,
    },
    {
        value: 'linear-gradient(100.24deg, #0EFFD4 0%, #962FE7 100%);',
        id: 9,
    },
    {
        value: 'linear-gradient(100.24deg, #130EFF 0%, #CF0EFF 100%);',
        id: 10,
    },
    {
        value: 'linear-gradient(100.24deg, #4415FF 0%, #FF1414 100%);',
        id: 11,
    },
    {
        value: 'linear-gradient(100.24deg, #011999 0%, #8302D2 100%);',
        id: 12,
    },
    {
        value: 'linear-gradient(100.24deg, #F5A200 0%, #9C00E6 100%);',
        id: 13,
    },
    {
        value: 'linear-gradient(100.24deg, #FF1717 0%, #252EFF 100%);',
        id: 14,
    },
    {
        value: '#231FFF;',
        id: 15,
    },
    {
        value: '#9C1FFF;',
        id: 16,
    },
    {
        value: '#1FA1FF;',
        id: 17,
    },
];
