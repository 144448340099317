<template>
    <div v-click-outside="hideFilters">
        <button
            class="filters-toggler"
            @click="toggleFilters"
        >
            Filter
        </button>
        <div
            v-show="filtersOpened && !isMobileOrTablet"
            class="filters"
        >
            <h2>Search In:</h2>
            <div
                v-for="(filter, filterKey) in filters"
                :key="filterKey"
                class="filters_item"
            >
                <img
                    :src="filter.icon"
                    class="filters_item-icon"
                />
                <div class="filters_item-title">
                    {{ filter.text }}
                </div>
                <app-toggler
                    :size="filterSize"
                    :value="filter.value"
                    class="filters_item-switch"
                    @input="(value)=> setFilter(filterKey, value)"
                />
            </div>
        </div>
        <editor-mobile-header-menu
            v-if="isMobileOrTablet"
            ref="menu"
            :disable-scroll="false"
            class="mobileFilters"
            @close="hideFilters"
        >
            <h2>Search In:</h2>
            <div
                v-for="(filter, filterKey) in filters"
                :key="filterKey"
                class="filters_item"
            >
                <img
                    :src="filter.icon"
                    class="filters_item-icon"
                />
                <div class="filters_item-title">
                    {{ filter.text }}
                </div>
                <app-toggler
                    :size="filterSize"
                    :value="filter.value"
                    class="filters_item-switch"
                    @input="(value)=> setFilter(filterKey, value)"
                />
            </div>
        </editor-mobile-header-menu>
    </div>
</template>

<script>
import EditorMobileHeaderMenu from '@/components/editor/header/EditorMobileHeaderMenu';


export default {
    name: 'searcher-filters',

    components: {
        EditorMobileHeaderMenu,
    },
    props: {
        filters: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            filtersOpened: false,
        };
    },

    computed: {
        filterSize() {
            return window.isMobileOrTablet ? 'md' : 'sm';
        },

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },
    },

    methods: {
        openFilters() {
            this.filtersOpened = true;

            if (window.isMobileOrTablet) {
                this.$refs.menu.open();
            }

        },

        hideFilters() {
            this.filtersOpened = false;
        },

        toggleFilters() {
            if (!this.filtersOpened) {
                this.openFilters();
            } else {
                this.hideFilters();
            }
        },

        setFilter(type, value) {
            if (value) {
                if (type == 'with_deleted') {
                    this.filters.only_shared.value = false;
                    this.filters.only_projects.value = false;
                } else if (type == 'only_shared') {
                    this.filters.with_deleted.value = false;
                    this.filters.only_projects.value = false;
                } else if (type == 'only_projects') {
                    this.filters.only_shared.value = false;
                    this.filters.with_deleted.value = false;
                }
            }

            this.filters[type].value = value;

            this.$emit('onFiltersChanged', this.filters);
        },
    },
};
</script>

<style lang="scss" scoped>
.filters-toggler {
    color: #A7A7A9;

    cursor: pointer;

    font-size: rem(14px);
    font-weight: 500;
}

.filters {
    width: rem(250px);

    padding: 12px;

    background: $white;
    box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
    border-radius: 9px;

    position: absolute;
    right: 50px;
    top: 36px;
    z-index: 99999;

    color: $black;
    font-weight: 500;

    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: rem(18px);
    }

    .filters_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 15px;
        height: 15px;

        margin: 8px 0;

        &-icon {
            margin-right: 14px;
            height: 17px;
            width: 15px;

            svg {
                stroke: #221B38;
            }
        }

        &-switch {
            margin-left: auto;
            margin-bottom: 0;
        }

        &-title {
            font-size: rem(12px);
        }
    }
}

.mobileFilters {

    h2 {
        font-size: rem(24px);
        font-weight: 600;
        margin-bottom: 17px;
        display: inline-flex;
        justify-content: center;

        width: 100%;
    }

    .filters_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 15px;

        margin: 17px 0;

        &-title {
            font-size: rem(18px);
        }

        &-icon {
            width: 24px;
            height: 24px;
            margin-right: 14px;
        }

        &-switch {
            width: 45px;
            margin-left: auto;
        }
    }

}
</style>
