import axios from 'axios';
import store from '@/store/index';

const config = {
    baseURL: process.env.VUE_APP_API_URL,
};

const httpClient = axios.create(config);

httpClient.interceptors.request.use(
    config => {
        const token = store.state.auth.token;
        const XAccessToken = store.state.auth.XAccessToken;

        if (token) {
            config.headers.Authorization = `${token.token_type} ${token.access_token}`;
        }
        if (XAccessToken) {
            config.headers['X-Access-Token'] = XAccessToken;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

httpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    },
);

const getResource = async (url, data, method, headers) => {
    let requestObj;

    if (method === 'post' || method === 'put' || method === 'delete') {
        requestObj = { url, method, data, ...headers };
    } else {
        requestObj = { url, method, params: data, ...headers };
    }

    const response = await httpClient(requestObj);
    return response.data;
};

export const get = async (url, params, headers) => getResource(url, params, 'get', headers);
export const post = async (url, data, headers) => getResource(url, data, 'post', headers);
export const put = async (url, data, headers) => getResource(url, data, 'put', headers);
export const del = async (url, data, headers) => getResource(url, data, 'delete', headers);
