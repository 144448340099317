import { del, get, post } from '@/js/api/http-helper';

export async function getProjectById(id) {
    const data = await get(`/project/get/by-project-id/${id}`);
    return data.data;
}

export async function createProject(fd) {
    const data = await post(`/project/create`, fd);
    return data.data;
}

export function updateProject(id, fd) {
    return post(`/project/update/${id}`, fd);
}

export function moveProjectsToBin(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/project/move/to/bin/${joinedIds}`);
}

export function deleteProjects(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/project/delete/${joinedIds}`);
}

export function sendInviteToProject(id, fd) {
    return post(`/project/send/invite/${id}`, fd);
}

export async function deleteProjectMember(projectId, userId) {
    return del(`/projects/${projectId}/members/${userId}`);
}

export function leaveFromProject(projectId) {
    return post(`/projects/${projectId}/leave`);
}
