<template lang="pug">
    .documents.default-layout-block
        h3.documents__title Documents
        .documents__list(v-if="documents.length")
            files-table(
                :documents.sync="documents"
                :delete-document-after-move="deleteDocumentAfterMove"
                @updateDocumentName="updateDocumentName"
                :moveActions="moveActions"
                :contentFromStore="contentFromStore"
                @setDocumentId="$emit('setDocId', $event)"
            )
        no-docs(v-else)
</template>

<script>
import FilesTable from '@/components/other/FilesTable';
import noDocs from '@/components/other/noDocs/noDocs';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import noDocumentsIcon from '@/assets/images/icons/no_documents.svg?inline';
import { getEntityLinkUrl } from '@/js/utils/breadcrump';
import { isEmpty } from 'lodash';

export default {
    name: 'documents',

    components: {
        FilesTable,
        noDocumentsIcon,
        noDocs,
    },

    mixins: [CreateDocumentMixin],

    props: {
        contentFromStore: {
            type: Boolean,
            default: true,
        },

        showAddBtn: {
            type: Boolean,
            default: true,
        },

        title: {
            type: String,
            default: 'Folders',
        },
        documents: {
            type: Array,
            required: true,
        },
        deleteDocumentAfterMove: {
            type: Boolean,
            default: true,
        },
        updateDocumentName: {
            type: Function,
            required: false,
            default: () => {},
        },
        createDocumentOptions: {
            type: Object,
            required: true,
        },

        moveActions: {
            type: Object,
            default: () => {
                return {
                    entityClasses: () => {},
                    mouseoverEntityHandler: () => {},
                    mouseupEntityHandler: () => {},
                    clickEntityHandler: () => {},
                    dbclickEntityHandler: () => {},
                };
            },
        },
    },

    methods: {
        isEmpty,
        getEntityLinkUrl,
    },
};
</script>

<style scoped lang="scss">
.documents {
    min-height: 35vh;
    padding: 0;
    margin-top: 25px;
    box-shadow: none;

    &::v-deep {
        .files-table__link {
            user-select: none;
            cursor: pointer;
        }

        .router-link_selected {
            background: $hover-background;
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 18px;
    }
}
</style>
