<template lang="pug">
    app-modal.change-layouts(
    name="change-layout-popup"
    @closed="close"
    )
        .change-layout-container
            .title Layout
            .icon-container-popup
                .icon(@click="documentsType(true)" :class="{active: documentsViewTypeCards}" )
                    card-icon
                .icon(@click="documentsType(false)" :class="{active: !documentsViewTypeCards}")
                    list-icon
</template>

<script>
import listIcon from '@/assets/images/list-mobile.svg?inline';
import cardIcon from '@/assets/images/cards-mobile.svg?inline';

export default {
    name: 'toggle-layout-popup',

    components: {
        listIcon,
        cardIcon,
    },


    computed: {
        documentsViewTypeCards() {
            return this.$store.getters.documentsViewTypeCards;
        },
    },

    methods: {
        close() {
          this.$modal.hide('toggle-layout-popup');
        },

        documentsType(payload) {
            this.$store.commit('updateDocumentsViewTypeCards', payload);
        },
    },
};


</script>

<style scoped lang="scss">
.change-layouts {
    &::v-deep .app-modal__container {
         width: rem(700px);
         padding: 0;
    }

    &::v-deep {
        .vm--modal {
            position: absolute;
            bottom: 0 !important;
            top: auto !important;
            left: 0 !important;

            border-radius: rem(20px) rem(20px) 0 0;

            transform: initial;
            opacity: 1;
        }

        .vm--overlay {
            background-color: rgba($black, 0.6);
        }

        .vm-transition--overlay {
            &-enter-active,
            &-leave-active {
                transition: opacity .5s;
            }

            &-enter,
            &-leave-to {
                opacity: 0;
            }
        }

        .vm-transition--modal {
            &-enter-active,
            &-leave-active {
                transition: transform .5s;
            }

            &-enter,
            &-leave-to {
                transform: translateY(100%);
            }
        }

        .app-modal__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            width: 100vw;
            height: 100%;
        }
    }

    .change-layout-container {
        width: 100%;
        height: 198px;
        display: flex;
        flex-direction: column;
        padding: 38px 38px 0 38px;

        .title {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 17px;
        }

        .icon-container-popup {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 69px;
            width: rem(314px);
            border-radius: 6px;
            margin-bottom: 52px;
            background-color: $grey-light32;

            .icon {
                width: rem(154px);
                height: rem(63px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 0 3px 3px;
                transition: .2s ease-in-out;

                &.active {
                    background-color: $white;
                }
            }
        }
    }
}


</style>
