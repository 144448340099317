import Vue from 'vue';
import router from '../router';
import moment from 'moment';

import { getUser, loggedIn, updateUser } from '@/js/api/requests/auth';
import { acceptDocumentInviteByToken } from '@/js/api/requests/document';

import { initAndGetUserSocket } from '@/js/editor/user_ws';
import { PLAN_PERIOD, PLANS_NAMES } from '@/js/const';

const vue = new Vue;

const getPlan = (state) => {
    const tariff = state.user && state.user.tariff ? state.user.tariff : null;

    if (tariff) {
        const tariffResult = {
            name: '',
            period: '',
            endPeriod: '',
        };

        if (tariff.name) {
            const arr = tariff.name.split('_');
            tariffResult.name = arr[0];
            tariffResult.period = arr[1];
        }

        if (tariff.end_period) {
            tariffResult.endPeriod = tariff.end_period;
        }

        return tariffResult;
    }

    return null;
};
const getPlanName = (state) => {
    const tariff = getPlan(state);
    // TO DO потом бейзик можно убрать, просто пока у зарег юзеров он, а должен быть персонал
    if (tariff && tariff.name && tariff.name !== 'basic') {
        return tariff.name;
    }
    return PLANS_NAMES.PERSONAL;
};
const getPlanEndPeriod = (state) => {
    const tariff = getPlan(state);
    if (tariff && tariff.endPeriod) {
        return tariff.endPeriod;
    }
    return '';
};
const getPlanPeriod = (state) => {
    const tariff = getPlan(state);
    if (tariff && tariff.period) {
        return tariff.period;
    }
    return PLAN_PERIOD.MONTH;
};

const initSocketHandlers = (userSocket, commit, dispatch) => {
    userSocket.on('connect', () => userSocket.emit('login'));

    userSocket.on('reconnect', () => userSocket.emit('login'));

    userSocket.on('after-login', data => {
        if (data.login) {
            dispatch('getNotifications');
        }
    });

    userSocket.on('box-notification', notifications => {
        if (!notifications.meta.total_items) {
            commit('clearNotification');
            return;
        }

        if (!notifications.data?.length) {
            return;
        }

        if (notifications.meta.current_page === 1) {
            commit('clearNotification');
        }

        commit('setNotifications', notifications.data);
    });

    userSocket.on('new-notification', () => dispatch('getNotifications'));
};

export default {
    state: () => ({
        user: null,
        token: null,
        XAccessToken: null,
        userWS: null,
        notifications: [],
        planPeriodSwitcher: PLAN_PERIOD.MONTH,
        planSelectedForCheckout: null,
        hasUnreadChatMessages: false,
    }),

    mutations: {
        setUser(state, data) {
            state.user = data;
        },

        setToken(state, data) {
            state.token = data;
        },

        setPlanPeriodSwitcher(state, period = PLAN_PERIOD.MONTH) {
            state.planPeriodSwitcher = period;
        },

        setPlanSelectedForCheckout(state, value = null) {
            state.planSelectedForCheckout = value;
        },

        setXAccessToken(state, data) {
            state.XAccessToken = data;
        },

        setUserWS(state, data) {
            state.userWS = data;
        },

        setNotifications(state, notification) {
            const notifications = notification.map(data => {
                const id = data._id ?? data.id;
                let isRead = false;

                if (data.receiver) {
                    if (data.receiver.length > 0) {
                        const user = data.receiver.find(user => user.id === state.user.id);
                        if (user) {
                            isRead = user.is_read;
                        }
                    } else {
                        isRead = data.receiver.is_read;
                    }
                } else if ('isRead' in data) {
                    isRead = data.isRead;
                }

                return {
                    id,
                    data: { ...data.data },
                    isRead,
                    created_at: data.created_at,
                    senderFirstLetters: data.data.senderFirstLetters ? data.data.senderFirstLetters : '',
                    type: data.type,
                };
            });

            notifications.sort((a, b) => moment.utc(a.created_at).local() - moment.utc(b.created_at).local());
            notifications.reverse();

            state.notifications = [...state.notifications, ...notifications];
        },

        clearNotification(state) {
            state.notifications = [];
        },

        setHasUnreadChatMessages(state, payload) {
            state.hasUnreadChatMessages = payload;
        },
    },

    getters: {
        user: state => state.user,
        planName: state => getPlanName(state),
        planEndPeriod: state => getPlanEndPeriod(state),
        planSelectedForCheckout: state => state.planSelectedForCheckout,
        planPeriod: state => getPlanPeriod(state),
        planPeriodSwitcher: state => state.planPeriodSwitcher,
        userWS: state => state.userWS,
        userNotifications: state => state.notifications,
    },

    actions: {
        async fetchUserByXAccessToken({ state, commit }) {
            const { data } = await getUser(state.XAccessToken);

            commit('setUser', {
                ...data,
            });
        },

        async fetchUser({ state, commit, dispatch }, { token, redirectPath }) {
            if (!token && state.token) {
                token = state.token;
            }

            try {
                const { data } = await getUser(token);
                const user = data;

                commit('setUser', { ...user });
                commit('setToken', token);

                loggedIn();

                const userSocket = initAndGetUserSocket(user.id);
                commit('setUserWS', userSocket);

                initSocketHandlers(userSocket, commit, dispatch);

                if (Vue.cookie.get('inviteToken')) {
                    await dispatch('acceptInviteToken', JSON.parse(Vue.cookie.get('inviteToken')));
                    Vue.cookie.delete('inviteToken');
                    return;
                }

                if (redirectPath) {
                    router.replace(redirectPath);
                }
            } catch (e) {
                Vue.cookie.delete('token');
                vue.$awn.alert(`Failed to get/update your data`);
            }
        },

        async updateUser({ dispatch }, data) {
            try {
                await updateUser(data);
                await dispatch('fetchUser', {});
            } catch (e) {
                //
            }
        },

        logout({ commit }) {
            commit('setUser', null);
            commit('setToken', null);
            Vue.cookie.delete('token');
        },

        async acceptInviteToken({ state }, data) {
            try {
                if (data.entity_type === 'document') {
                    const data = await acceptDocumentInviteByToken({
                        token: data.token,
                        user_id: state.user.id,
                    });

                    router.replace({ name: 'Editor', params: { id: data.entity_id } });
                }
            } catch (e) {
                router.replace('/');
            }
        },

        getNotifications({ getters }, { page = 1, limit = 20, status = false } = {}) {
            getters.userWS.emit('get-notification', { page, limit, status });
        },
    },
};
