<template lang="pug">
    .wrapper.editor-container
        .content
            .avatar {{ initials }}
            .author
                .name {{ fullName }}
                .created Created on {{ created }}
</template>

<script>
import moment from 'moment';

export default {
    name: 'editor-author-document',

    props: {
        document: {
            type: Object,
            required: true,
        },
    },

    computed: {
        initials () {
            return `${this.document.owner.username?.[0] || ''}${this.document.owner.surname?.[0] || ''}`;
        },

        fullName () {
            return `${this.document.owner.username || ''} ${this.document.owner.surname || ''}`;
        },

        created () {
            return moment(this.document.created_at).format('D MMMM, YYYY');
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    padding: 0 100px 0;

    .content {
        border-bottom: 1px solid #DADADA;
        padding: 6px 0;
        display: flex;
        align-items: center;

        .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 29px;
            height: 29px;
            border-radius: 15px;
            background-color: #008CF6;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 9px;
        }

        .author {
            margin-left: 12px;

            .name {
                font-weight: 500;
                font-size: 11px;
                margin-bottom: 5px;
            }
            .created {
                color: #AAAAAA;
                font-weight: 500;
                font-size: 9px;
            }
        }
    }
}

@include mobile_or_P {

}
</style>
