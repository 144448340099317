import { del, get, post } from '@/js/api/http-helper';

export async function getAllFolders() {
    const data = await get('/folders/get');
    return data.data[0].result;
}

export async function getFolderById(id) {
    const data = await get(`/folder/get/by-folder-id/${id}`);
    return data.data?.[0];
}

export async function createFolder(fd) {
    const data = await post(`/folder/create`, fd);
    return data.data.id;
}

export function updateFolder(id, fd) {
    return post(`/folder/update/${id}`, fd);
}

export function moveFoldersToBin(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/folder/move/to/bin/${joinedIds}`);
}

export function deleteFolders(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/folder/delete/${joinedIds}`);
}
