<template>
    <div class="shared">
        <div class="default-layout-head">
            <app-breadcrumbs
                :items="$route.meta.breadcrumb"
                class="app-breadcrumbs_mb app-breadcrumbs--theme-thin"
                no-hover-effect
            />
        </div>
        <app-loader v-if="pageLoading" />
        <template v-else>
            <shared-filters-menu
                :class="{'inTable' : !documentsViewTypeCards}"
                @selectFilter="groupDocumentsByDate($event)"
            />
            <template v-if="documentsViewTypeCards">
                <div
                    v-if="filter !== 'name'"
                    class="shared-items"
                >
                    <div
                        v-for="(date, i) in SHARED_FILTERS_DATE"
                        :key="i"
                        class="shared-items__container"
                    >
                        <div class="shared-items__container__title">
                            {{ date.name }}
                        </div>
                        <div
                            :class="{'mobile': isMobileOrTablet}"
                            class="shared-items__container_items"
                        >
                            <document-item
                                v-for="(item, i) in groupedDocumentsByDate[date.value]"
                                :key="i"
                                :class="{'selected' : selectedTab === i}"
                                :height="'195px'"
                                :item="item"
                                :width="isMobileOrTablet ? '49%' : '189px'"
                                class="item"
                                @click="getSelectedTab(i)"
                                @dblclick="documentDblclickHandler(item)"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="shared-items-by-name"
                >
                    <document-item
                        v-for="(item, i) in sortDocumentsByName"
                        :key="i"
                        :class="{'selected' : selectedTab === i}"
                        :height="'195px'"
                        :item="item"
                        :order="i"
                        :width="isMobileOrTablet ? '49%' : '189px'"
                        class="item"
                        @click="getSelectedTab(i)"
                        @dblclick="documentDblclickHandler(item)"
                    />
                </div>
            </template>
            <template v-else>
                <documents-table
                    :group-items-by="SHARED_FILTERS_DATE"
                    :headers="headersForTable"
                    :items="filter === 'name' ? sortDocumentsByName : groupedDocumentsByDate"
                    :without-groups="filter === 'name'"
                    is-shared
                />
            </template>
        </template>
        <sharedFiltersMenuPopup @selectFilter="currentFilter" />
    </div>
</template>

<script>
import SharedFiltersMenu from '@/components/shared/sharedFiltersMenu';
import { SHARED_FILTERS_DATE } from '@/js/const';
import { getSharedDocs } from '@/js/api/requests/document';
import DocumentItem from '@/components/common/documentItem';
import moment from 'moment';
import DocumentsTable from '@/components/common/documentsTable';
import sharedFiltersMenuPopup from '@/components/shared/sharedFiltersMenuPopup';
import documentsAndTableMixin from '@/mixins/documentsAndTableMixin';

export default {
    name: 'shared',
    components: { DocumentsTable, DocumentItem, SharedFiltersMenu, sharedFiltersMenuPopup },
    mixins: [documentsAndTableMixin],
    data: () => ({
        pageLoading: true,
        filter: 'shared_date',
        SHARED_FILTERS_DATE,
        allDocuments: [],
        groupedDocumentsByDate: {},
        sortedDocumentsByName: [],
        headersForTable: ['Name', 'Shared by', 'Shared date'],
    }),

    computed: {
        documentsViewTypeCards() {
            return this.$store.getters.documentsViewTypeCards;
        },

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },

        sortDocumentsByName() {
            let sortedDocuments = [...this.allDocuments];
            return sortedDocuments.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
        },
    },

    watch: {
        filter(val) {
            this.sortDocuments(val);
        },
    },

    mounted() {
        this.getDocument();
    },

    methods: {
        async getDocument() {
            try {
                const shared = await getSharedDocs();
                this.allDocuments = shared || [];
                this.sortDocuments(this.filter);
                this.groupDocumentsByDate();

            } catch (e) {
                this.$awn.alert('An error occurred while loading data, please try again later');
            } finally {
                this.pageLoading = false;
            }
        },


        currentFilter(filter) {
            this.filter = filter;
        },

        sortDocuments() {
            switch (this.filter) {
                case 'shared_date':
                    this.allDocuments.sort((d1, d2) => +moment(d1.invited_at) - +moment(d2.invited_at));
                    break;
                case 'last_modified':
                    this.allDocuments.sort((d1, d2) => +moment(d1.modified_at) - +moment(d2.modified_at));
                    break;
                case 'last_modified_by_me':
                    this.allDocuments.sort((d1, d2) => +moment(d1.modified_by_me_at) - +moment(d2.modified_by_me_at));
                    break;
                case 'last_opened_by_me':
                    this.allDocuments.sort((d1, d2) => +moment(d1.opened_by_me_at) - +moment(d2.opened_by_me_at));
            }
        },

        groupDocumentsByDate(val) {
            this.filter = val === undefined ? 'invited_at' : val;
            if (val === 'name') return;
            this.groupedDocumentsByDate = {
                today: [],
                yesterday: [],
                this_week: [],
                this_month: [],
                this_year: [],
                older: [],
            };
            let date = null;
            let today = +moment();
            let yesterday = +moment().add(-1, 'days');
            let week = +moment().add(-7, 'days');
            let month = +moment().add(-30, 'days');
            let year = +moment().add(-365, 'days');

            for (let doc of this.allDocuments) {
                date = +moment(doc[this.filter]);

                if (date < year) {
                    this.groupedDocumentsByDate.older.push(doc);
                } else if (date < month) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.groupedDocumentsByDate.this_year.push(doc);
                } else if (date < week) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.groupedDocumentsByDate.this_month.push(doc);
                } else if (date < yesterday) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.groupedDocumentsByDate.this_week.push(doc);
                } else if (date > today) {
                    this.groupedDocumentsByDate.yesterday.push(doc);
                } else this.groupedDocumentsByDate.today.push(doc);
            }
        },


        openFilter() {
            if (!this.isMobileOrTablet) return;
            this.$modal.show('shared-filters-menu-popup');
        },
    },
};
</script>

<style lang="scss" scoped>
.default-layout-head {
    padding-left: 8px;
}

.shared {
    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #4A4A4A;
    }

    .shared-items {
        gap: 33px;
        display: flex;
        flex-direction: column;
        padding: 0 13px;

        &__container__title {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
        }

        &__container_items {
            display: flex;
            flex-wrap: wrap;
            column-gap: 33px;

            &.mobile {
                justify-content: space-between;
                column-gap: 0;
            }

            .item {
                margin: 17px 0;

                &.selected {
                    background: rgba(106, 112, 255, 0.08);
                }
            }
        }
    }

    .shared-items-by-name {
        margin-top: 17px;
        padding: 17px 13px;
        display: flex;
        flex-wrap: wrap;
        gap: 33px;
        margin-bottom: 40px;
    }

    .inTable {
        margin-top: 10px;
        right: 26px;
        z-index: 10;
    }
}
</style>
