<template lang="pug">
    .editor-snapshots
        .header
            .header__back(@click="close")
                img(src="@/assets/images/arrow_back.svg" alt="")
            .header__name {{ selectedSnapshotName }}
        .main
            .editor-wrapper(ref="editorWrapper")
                .editor-container
                    #editor-snapshot
                    editor-snapshots-author(ref="author")
            editor-snapshots-sidebar(
                :snapshots="snapshots"
                :selected-snapshot="selectedSnapshot"
                @selectSnapshot="selectSnapshot"
                @restoreSnapshot="restoreSnapshot"
                @duplicateSnapshot="duplicateSnapshot"
            )

</template>

<script>
import EditorSnapshotsSidebar from '@/components/editor/snapshots/EditorSnapshotsSidebar';
import Scrollbar from 'smooth-scrollbar';
import Quill from 'quill';
import moment from 'moment';
import hex2rgb from '@/js/utils/hex2rgb';
import EditorSnapshotsAuthor from '@/components/editor/snapshots/EditorSnapshotsAuthor';
import { getSnapshots, getSnapshotById } from '@/js/api/requests/editor';

export default {
    name: 'editor-snapshots',

    components: {
        EditorSnapshotsAuthor,
        EditorSnapshotsSidebar,
    },

    data: () => ({
        snapshots: [],
        selectedSnapshot: {},
        snapshotOps: [],
        editor: null,
    }),

    computed: {
        selectedSnapshotName() {
            return this.selectedSnapshot.title || moment(this.selectedSnapshot.created_at).format('LLL');
        },
    },

    created() {
        window.disableScroll();
    },

    async mounted() {
        this.snapshots = await getSnapshots(this.$route.params.id);

        this.editor = new Quill('#editor-snapshot', {
            theme: 'snow',
            readOnly: true,
            modules: {
                toolbar: false,
            },
        });
        await this.selectSnapshot(this.snapshots[0]);
        Scrollbar.init(this.$refs.editorWrapper);

        this.initMouseMoveEvents();
    },

    beforeDestroy() {
        window.enableScroll();
    },

    methods: {
        async selectSnapshot(sn) {
            if (this.selectedSnapshot._id === sn._id) return;

            this.selectedSnapshot = sn;
            const data = await getSnapshotById(sn._id);

            const snapshotOps = [];
            data[0].ops.forEach(o => {
                const color = o.attributes?.author?.color;
                if (color) {
                    const id = o.attributes.author.id;
                    let hex = hex2rgb(color);
                    let background = `rgba(${hex.r}, ${hex.g}, ${hex.b}, 0.25)`;

                    // Если будут одинаковые цвета, немного меняем цвет
                    while (snapshotOps.some(o => o.attributes.background === background && o.attributes.author.id !== id)) {
                        hex.r-=1;
                        background = `rgba(${hex.r}, ${hex.g}, ${hex.b}, 0.25)`;
                    }

                    o.attributes.background = background;
                }
                snapshotOps.push(o);
            });

            this.snapshotOps = snapshotOps;
            this.editor.setContents(snapshotOps);
        },

        initMouseMoveEvents() {
            this.$refs.editorWrapper.onmousemove = ({ target }) => {
                const background = target.style.backgroundColor; // bg элемента на котором курсор
                if (background) {
                    const currentOp = this.snapshotOps.find(o => o.attributes?.background === background);
                    if (currentOp) {
                        const blot = Quill.find(target);
                        const bounds = this.editor.getBounds(this.editor.getIndex(blot));
                        this.$refs.author.open({
                            author: currentOp.attributes.author.name,
                            background: currentOp.attributes.author.color,
                            top: bounds.top,
                            left: bounds.left,
                        });
                    }
                } else {
                    this.$refs.author.close();
                }
            };
        },

        restoreSnapshot(sn) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: confirm => {
                    if (confirm) {
                        this.$emit('restoreSnapshot', sn);
                    }
                },
            });
        },

        duplicateSnapshot(sn) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: confirm => {
                    if (confirm) {
                        this.$emit('duplicateSnapshot', sn);
                    }
                },
            });
        },

        close() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped lang="scss">
.editor-snapshots {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;

    width: 100vw;

    background: #E5E5E5;
}

.header {
    display: flex;
    align-items: center;

    height: rem(77px);
    padding: 2rem;

    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &__back {
        margin-right: rem(40px);
        padding: 0.5rem;

        cursor: pointer;

        img {
            width: rem(40px);
        }

        &:hover {
            background-color: $hover-background;
        }
    }
}

.main {
    display: flex;
}

.editor {
    &-wrapper {
        width: 100%;
        height: calc(100vh - #{rem(77px)});
        padding: 1rem 0;
    }

    &-container {
        position: relative;

        @include editor_container();
    }
}
</style>
