<template lang="pug">
    app-modal(name="cancel-tariff-modal" class="cancel-tariff-modal")
        .cancel-tariff-modal__text Are you sure you want to cancel a paid subscription?
        .cancel-tariff-modal__btns
            app-button(@click="btnClick" :loading="loading" class="cancel-tariff-modal__btn") Yes
            app-button.app-button--grey2(@click.prevent="$modal.hide('cancel-tariff-modal')") No
</template>

<script>
import { tariffCancel } from '@/js/api/requests/plans';

export default {
    name: 'cancel-tariff-modal',

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        async btnClick() {
            this.loading = true;
            try {
                await tariffCancel();
                this.$awn.success(`You changed the tariff to personal`);
                this.$store.dispatch('fetchUser', {});
                this.$modal.hide('cancel-tariff-modal');
            } catch (e) {
                this.$awn.alert('An error has occurred');
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.cancel-tariff-modal {
    &__text {

    }
    &__btns {
        margin-top: rem(20px);
    }
    &__btn {
        margin-right: rem(20px);
    }
}
</style>
