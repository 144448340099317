<template>
    <app-modal
        name="export-document-popup"
        class="export-document-popup"
        @closed="close"
        @beforeOpen="beforeOpen"
    >
        <div class="list">
            <div
                class="list__item"
                @click="exportDocumentDocx(doc)"
            >
                Microsoft word (docx)
            </div>
            <div
                class="list__item"
                @click="exportDocumentPdf(doc)"
            >
                PDF Document (pdf)
            </div>
            <div
                class="list__item"
                @click="exportDocumentHtml(doc)"
            >
                HTML
            </div>
        </div>
    </app-modal>
</template>


<script>

import DownloadDocumentMixin from '@/mixins/DownloadDocumentMixin';

export default {
    name: 'export-document-popup',
    mixins: [DownloadDocumentMixin],


    data: () => ({
        doc: null,
    }),

    methods: {
        close() {
            this.$modal.hide('export-document-popup');
        },

        beforeOpen(e) {
            this.doc = e.params.doc;
        },
    },
};
</script>

<style lang="scss" scoped>
.export-document-popup {
    padding: 11px 15px;


    @include mobile_or_tablet {
        &::v-deep .app-modal__container {
            width: rem(700px);
            padding: 0;
        }

        &::v-deep {
            .vm--modal {
                position: absolute;
                bottom: 0 !important;
                top: auto !important;
                left: 0 !important;

                border-radius: rem(20px) rem(20px) 0 0;

                transform: initial;
                opacity: 1;
            }

            .vm--overlay {
                background-color: rgba($black, 0.6);
            }

            .vm-transition--overlay {
                &-enter-active,
                &-leave-active {
                    transition: opacity .5s;
                }

                &-enter,
                &-leave-to {
                    opacity: 0;
                }
            }

            .vm-transition--modal {
                &-enter-active,
                &-leave-active {
                    transition: transform .5s;
                }

                &-enter,
                &-leave-to {
                    transform: translateY(100%);
                }
            }

            .app-modal__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 100vw;
                height: 100%;
            }
        }

        .list {
            padding: 38px;
            background-color: $white;

            &__item {
                display: flex;
                align-items: center;

                p {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    margin-left: 25px;
                }

                &:not(:first-child) {
                    margin-top: 31px;
                }
            }
        }
    }
}
</style>
