import { ENTITY_TYPES_NAMES } from '@/js/const';

//у каждой сущностей 2-составная урл(rooms/1,folders/1), но хлеб крошки вложенные(папка в папке папки проекта итд итп)
export const getEntityLink = (entity) => {
    let url = { ...entity, link: { name: '', params: '' } } ;
    if (entity.type === ENTITY_TYPES_NAMES.library) {
        url.link = { name: 'Library' };
    } else if (entity.type === ENTITY_TYPES_NAMES.folder) {
        url.link = { name: 'FolderDetail', params: { folderId: entity.id } };
        // entity_id это айди родителя, а не самой сущности!
        // без родителя сущность точно в либрали
        if (entity.entity_id === null) {
            url.link.name = 'LibraryFolder';
        }
    } else if (entity.type === ENTITY_TYPES_NAMES.project) {
        url.link = { name: 'ProjectDetail', params: { id: entity.id } };
        url.icon = entity.image;
    } else if (entity.type === ENTITY_TYPES_NAMES.room) {
        url.link = { name: 'RoomDetail', params: { roomId: entity.id } };
    } else if (entity.type === ENTITY_TYPES_NAMES.document) {
        url.link = { name: 'Editor', params: { id: entity.id } };
    }
    return url;
};

export const getEntityLinkUrl = (entity) => {
    return getEntityLink(entity).link;
};

export const getBreadcrumps = (entity) => {
    let breadcrumps = [];
    const library = getEntityLink({
        name: 'Library',
        type: ENTITY_TYPES_NAMES.library,
        id: 0,
    });
    const project = getEntityLink( {
        name: 'Project',
        type: ENTITY_TYPES_NAMES.project,
        id: 0,
    });
    if (entity) {
        if (entity.parents.length > 0) {
            breadcrumps = entity.parents.map((entityParent) => getEntityLink(entityParent));
            const rootParent = breadcrumps[0];
            if (rootParent.type === ENTITY_TYPES_NAMES.folder && !rootParent.entity_id) {
                breadcrumps = [library, ...breadcrumps];
            }
        } else if (entity.type !== ENTITY_TYPES_NAMES.project) {
            breadcrumps = [
                library,
                ...breadcrumps,
            ];
        } else if (entity.type === ENTITY_TYPES_NAMES.project) {
            breadcrumps = [
                project,
                ...breadcrumps,
            ];
        }
    }
    return [...breadcrumps, getEntityLink(entity)];
};

export const getEditorBreadcrumbs = (entity) => {
    let breadcrumbs = [];
    if (entity) {
        if (entity.parents.length > 0) {
            breadcrumbs = entity.parents.map((entityParent) => getEntityLink(entityParent)).reverse();
            const rootParent = breadcrumbs[0];
            if (rootParent.type === ENTITY_TYPES_NAMES.folder && !rootParent.entity_id) {
                breadcrumbs = [...breadcrumbs];
            }
        } else if (entity.type !== ENTITY_TYPES_NAMES.project) {
            breadcrumbs = [
                ...breadcrumbs,
            ];
        }
    }
    return [...breadcrumbs, getEntityLink(entity)];
};

