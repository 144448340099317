import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'regenerator-runtime/runtime';


import '@/plugins/disableScroll.js';
import '@/plugins/deviceDetecter.js';
import '@/plugins/awn';


import '@/assets/scss/main.scss';

import TextareaAutosize from 'vue-textarea-autosize';
Vue.use(TextareaAutosize);

import { mask } from 'vue-the-mask';
Vue.directive('mask', mask);

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
Vue.use(PerfectScrollbar);

import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

import * as nsfwjs from 'nsfwjs';
Vue.use(nsfwjs);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);


import Gapi from 'vue-googleapis';
Vue.use(Gapi, {
    clientId: '687991799131-6ar3ufthlik2lo88mm7u6qbh04h6mcn7.apps.googleusercontent.com',
    developerKey: 'AIzaSyAP1RwaQ79mxOKNxR651pc9NGMOPBaZBqs',
    scope: 'https://www.googleapis.com/auth/drive.file ' +
        'https://www.googleapis.com/auth/drive.metadata ' +
        'https://www.googleapis.com/auth/drive.readonly',
});

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

import VModal from 'vue-js-modal';
Vue.use(VModal);

import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import VueConfirmDialog from 'vue-confirm-dialog';
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

import VueContext from 'vue-context';
Vue.component('vue-context', VueContext);

import ClickOutside from 'vue-click-outside';
Vue.directive('ClickOutside', ClickOutside);

import ContextMenu from '@/components/other/ContextMenu';
Vue.component('context-menu', ContextMenu);


// layouts
import DefaultLayout from '@/layouts/Default';
Vue.component('default-layout', DefaultLayout);
import EditorLayout from '@/layouts/Editor';
Vue.component('editor-layout', EditorLayout);
import WelcomeLayout from '@/layouts/Welcome';
Vue.component('welcome-layout', WelcomeLayout);
import LoadingLayout from '@/layouts/Loading';
Vue.component('loading-layout', LoadingLayout);
import CheckoutLayout from '@/layouts/Checkout';
Vue.component('checkout-layout', CheckoutLayout);

// app components
import AppButton from '@/components/app/app-button/index';
Vue.component('app-button', AppButton);
import AppTextField from '@/components/app/app-text-field/index';
Vue.component('app-text-field', AppTextField);
import AppSelect from '@/components/app/app-select/index';
Vue.component('app-select', AppSelect);
import AppDatepicker from '@/components/app/app-datepicker/index';
Vue.component('app-datepicker', AppDatepicker);
import AppCheckbox from '@/components/app/app-checkbox/index';
Vue.component('app-checkbox', AppCheckbox);
import AppToggler from '@/components/app/app-toggler/index';
Vue.component('app-toggler', AppToggler);
import AppLoader from '@/components/app/app-loader/index';
Vue.component('app-loader', AppLoader);
import AppModal from '@/components/app/app-modal';
Vue.component('app-modal', AppModal);
import AppModalRedesign from '@/components/app/app-modal/redesign';
Vue.component('app-modal-redesign', AppModalRedesign);
import AppContext from '@/components/app/app-context';
Vue.component('app-context', AppContext);
import AppBreadcrumbs from '@/components/app/app-breadcrumbs/index';
Vue.component('app-breadcrumbs', AppBreadcrumbs);
import AppCardDocument from '@/components/app/app-card-document/index';
Vue.component('app-card-document', AppCardDocument);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
