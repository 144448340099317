import MoveToMixin from '@/js/mainContextMenu/moveToMixin';
import renameMixin from '@/js/mainContextMenu/renameMixin';
import downloadMixin from '@/js/mainContextMenu/downloadMixin';
import shareMixin from '@/js/mainContextMenu/shareMixin';
import deleteMixin from '@/js/mainContextMenu/deleteMixin';
import duplicateMixin from '@/js/mainContextMenu/duplicateMixin';

export default {
    data: () => ({
        currentElemType: null,
    }),

    mixins: [MoveToMixin, renameMixin, downloadMixin, shareMixin, deleteMixin, duplicateMixin],

    methods: {
        openDocContext: function (e, doc) {
            this.currentElemType = doc.type;
            const menu = this.$refs.contextMenu;
            menu.open(e);

            menu.$off(['share', 'moveTo', 'rename', 'download', 'duplicate', 'delete']);

            menu.$on('moveTo', () => {
                this.moveTo(e, doc, this.currentElemType);
            });

            menu.$on('rename', () => {
                this.rename(doc);
            });

            menu.$on('download', () => {
                this.download(e, doc);
            });

            menu.$on('share', async () => {
                this.share(doc);
            });

            menu.$on('duplicate', async () => {
                this.duplicate(doc);
            });

            menu.$on('delete', () => {
                this.deleteItem(doc);
            });
        },
    },
};
