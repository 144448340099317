<template lang="pug">
    .icon(v-click-outside="close")
        switcher-icon.icon(@click="isOpen = !isOpen")
        .toggle-layout(
            v-if="isOpen"
        )
            .toggle-layout-container
                .title Layout
                .icon-container
                    .icon(@click="documentsType(true)" :class="{active: documentsViewTypeCards}" )
                        card-icon
                    .icon(@click="documentsType(false)" :class="{active: !documentsViewTypeCards}")
                        list-icon


</template>

<script>

import listIcon from '@/assets/images/list.svg?inline';
import cardIcon from '@/assets/images/cards.svg?inline';
import switcherIcon from '@/assets/images/icons/switcher.svg?inline';

export default {
    name: 'toggle-layout',

    components: {
        listIcon,
        cardIcon,
        switcherIcon,
    },

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        documentsViewTypeCards() {
            return this.$store.getters.documentsViewTypeCards;
        },
    },

    methods: {
        close() {
            this.isOpen = false;
        },

        documentsType(payload) {
            this.$store.commit('updateDocumentsViewTypeCards', payload);
        },
    },
};
</script>

<style lang="scss">

.icon {
    position: relative;
}
.toggle-layout {
    position: absolute;
    top: rem(40px);
    right: rem(0px);
    background-color: $white;
    border-radius: 9px;
    width: 147px;
    height: 88px;
    box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);

    @include mobile_or_tablet {
        &::v-deep .app-modal__container {
            width: rem(700px);
            padding: 0;
        }
        &::v-deep {
            .vm--modal {
                position: absolute;
                bottom: 0 !important;
                top: auto !important;
                left: 0 !important;

                border-radius: rem(20px) rem(20px) 0 0;

                transform: initial;
                opacity: 1;
            }

            .vm--overlay {
                background-color: rgba($black, 0.6);
            }

            .vm-transition--overlay {
                &-enter-active,
                &-leave-active {
                    transition: opacity .5s;
                }

                &-enter,
                &-leave-to {
                    opacity: 0;
                }
            }

            .vm-transition--modal {
                &-enter-active,
                &-leave-active {
                    transition: transform .5s;
                }

                &-enter,
                &-leave-to {
                    transform: translateY(100%);
                }
            }

            .app-modal__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 100vw;
                height: 100%;
            }

            .toggle-layout-container {
                .title {
                    font-size: 10px;
                }

                .icon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 69px;
                    width: rem(314px);
                    border-radius: 6px;
                    background-color: $grey-light32;

                    .icon {
                        width: rem(154px);
                        height: rem(63px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 2px 0 3px 3px;
                        transition: .2s ease-in-out;

                        &.active {
                            background-color: red;
                        }
                    }
                }

            }
        }
    }
}

.toggle-layout-container {
    display: flex;
    flex-direction: column;
    padding: 4px 8px;

    .title {
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
        margin-bottom: 15px;
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 100%;
        border-radius: 6px;
        background-color: $grey-light32;

        .icon {
            width: 62px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 0 2px 2px;
            transition: .2s ease-in-out;

            &.active {
                background-color: $white;
            }
        }
    }
}
</style>
