<template>
    <div
        :class="{grey: greyBackground}"
        class="no-docs"
        @click="createDoc"
    >
        <div class="no-docs-ellipse">
            <div class="no-docs-ellipse--icon">
                <no-docs-icon />
            </div>
        </div>
    </div>
</template>

<script>

import noDocsIcon from '@/assets/images/no-docs.svg?inline';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import GetRouteParamsMixin from '@/mixins/GetRouteParamsMixin';

export default {
    name: 'no-docs',
    components: { noDocsIcon },

    mixins: [CreateDocumentMixin, GetRouteParamsMixin],

    props: {
        greyBackground: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        createDoc() {
            this.createDocument(this.getRouteParams());
        },
    },
};
</script>

<style lang="scss" scoped>
.no-docs {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 480px;
    cursor: pointer;

    &-ellipse {
        background: $grey-dark8;
        border-radius: 50%;
        height: 370px;
        width: 370px;
        display: flex;
        justify-content: center;

        &--text {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
        }
    }
}

.grey {
    background-color: $grey-dark8;
}
</style>
