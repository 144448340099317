<template lang="pug">
    .settings.default-layout-block

        app-text-field.app-text-field-custom(
            v-model="name"
            label="Title:"
            :disabled="!isUserProjectOwner"
            placeholder="Set room title"
            :loading="loading"
            @blur="saveRoom"
        )
        app-button( v-if="isUserProjectOwner" @click="deleteRoom") Delete Room
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { updateRoom, moveRoomsToBin } from '@/js/api/requests/room';
import ManageTeam from '@/mixins/ManageTeamMixin';

export default {
    name: 'room-detail-settings',

    mixins: [ManageTeam],

    props: {
        room: {
            type: Object,
            required: true,
        },

        project: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        name: '',

        loading: false,
    }),

    validations: {
        name: { required },
    },

    created() {
        this.name = this.room.name;
    },

    methods: {
        async saveRoom() {
            if (this.loading) return;

            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.loading = true;

            const fd = new FormData;
            fd.append('name', this.name);

            try {
                await updateRoom(this.room.id, fd);
                this.room.name = this.name;
                this.$awn.success('Room settings was updated successfully');
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            } finally {
                this.loading = false;
            }
        },

        deleteRoom() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await moveRoomsToBin(this.room.id);
                            this.$router.push({
                                name: 'ProjectDetail',
                                params: { id: this.room.project_id },
                            });
                        } catch (e) {
                            this.$awn.alert('Something went wrong, please try again later');
                        }
                    }
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@include Settings();
</style>
