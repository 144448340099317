<template lang="pug">
    .main-header
        toggle-layout.icon.switcher-button
        settings-icon.icon.settings-link(@click="$modal.show('settings', { tab: 'general' })")
        .notifications-button
            notification-icon.icon(@click="toggleDropdown('notifications')")
            .notifications-button__circle(v-if="haveUnreadNotifications")

            transition
                notifications.dropdown(
                    v-if="dropdowns.notifications"
                    v-click-outside="toggleDropdown.bind(null, 'notifications')"
                )

        .avatar-button
            .avatar-button__circle(@click="toggleDropdown('profileMenu')")
                img(v-if="userAvatar" :src="userAvatar")
                span(v-else) {{ userFirstLetters }}

            arrow-bottom-icon.arrow(:class="{opened : dropdowns.profileMenu}")

            transition
                profile-menu.dropdown(
                    v-if="dropdowns.profileMenu"
                    v-click-outside="toggleDropdown.bind(null, 'profileMenu')"
                )
</template>

<script>
import settingsIcon from '@/assets/images/icons/settings.svg?inline';
import arrowBottomIcon from '@/assets/images/icons/arrow_bottom.svg?inline';
import viewToggleIcon from '@/assets/images/icons/view_toggle.svg?inline';
import notificationIcon from '@/assets/images/icons/notification.svg?inline';
import switcherIcon from '@/assets/images/icons/switcher.svg?inline';
import SearchField from '@/components/layouts/SearchField';
import Notifications from '@/components/layouts/notifications/Notifications';
import ProfileMenu from '@/components/layouts/ProfileMenu';
import NotifficationMixin from '@/mixins/NotifficationMixin';
import ToggleLayout from '@/components/layouts/toggle/toggleLayout';

export default {
    name: 'main-header',

    components: {
        ToggleLayout,
        switcherIcon,
        viewToggleIcon,
        settingsIcon,
        notificationIcon,
        SearchField,
        Notifications,
        ProfileMenu,
        arrowBottomIcon,
    },

    mixins: [NotifficationMixin],

    data: () => ({
        isOpen: false,
        dropdowns: {
            notifications: false,
            profileMenu: false,
        },
        listView: false,
    }),

    computed: {
        user() {
            return this.$store.getters.user;
        },

        userFirstLetters() {
            return `${this.user?.username?.[0] || ''}${this.user?.surname?.[0] || ''}`;
        },

        userAvatar() {
            return this.user?.image;
        },
    },

    methods: {
        toggleDropdown(name) {
            if (this.dropdowns[name]) {
                this.dropdowns[name] = false;
            } else {
                setTimeout(() => {
                    this.dropdowns[name] = true;
                });
            }
        },

        close() {
            this.isOpen = false;
        },
    },
};
</script>

<style scoped lang="scss">
.main-header {
    display: flex;
    justify-content: flex-end;
    z-index: 11;
    height: 22px;

    .search-field {
        margin-right: rem(48px);
    }

    .settings-link {
        width: rem(22px);
        margin-right: rem(14px);
        cursor: pointer;
    }

    .controls-item + .controls-item {
        margin-left: rem(14px);
    }

    .notifications-button {
        position: relative;

        width: rem(22px);
        margin-right: rem(14px);

        svg {
            width: 100%;

            cursor: pointer;
        }

        &__circle {
            position: absolute;
            top: 0.20rem;
            right: 0rem;

            width: rem(10px);
            height: rem(10px);

            background: $red;
            border-radius: 50%;

            pointer-events: none;
        }
    }

    .switcher-button {
        margin-right: rem(14px);

        cursor: pointer;

        &.active {
            background-color: #f5f5fa;
        }
    }

    .avatar-button {
        position: relative;

        display: flex;
        align-items: center;

        transition: .4s ease-in-out;
        margin-right: 24px;

        &__circle {
            display: flex;
            justify-content: center;
            align-items: center;

            width: rem(27px);
            height: rem(27px);
            overflow: hidden;

            font-size: rem(14px);
            font-weight: 500;
            color: #fff;

            background: $blue;
            border-radius: 50%;

            cursor: pointer;

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }
    }

    .arrow {
        width: 7px;
        height: 3px;
        margin-left: 3px;

        &.opened {
            transform: rotate(180deg);
        }
    }

    .avatar-select {
        margin-left: rem(3px);

        svg {
            stroke: $violet;
        }
    }

    .dropdown {
        position: absolute;
        top: calc(100% + 1.2rem);
        z-index: 100;

        &:before {
            content: '';
            position: absolute;
            right: 1.5rem;
            bottom: 100%;

            display: block;

            width: 1rem;
            height: 1rem;

            background: #fff;
            border: 1px solid $grey-light;
            border-right-color: transparent;
            border-bottom-color: transparent;

            transform: rotate(45deg);
            transform-origin: left bottom;
        }
    }

    .notifications {
        right: -1.3rem;
    }

    .profile-menu {
        right: -0.2rem;
    }
    }
</style>
