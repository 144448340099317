<template lang="pug">
    .help-button
        img.button(
            src="@/assets/images/help-button.svg"
            @click="toggle"
        )
        transition
            .modal(
                v-if="isOpened"
                v-click-outside="close"
            )
                .link
                    .link__icon
                        img(src="@/assets/images/icons/contact_support.svg")
                    | Contact Support
                a.link(
                    href="https://reskript.typeform.com/to/mIuXFYwz"
                    target="_blank"
                )
                    .link__icon
                        img(src="@/assets/images/icons/report_bug.svg")
                    | Report Bug
                a.link(
                    href="https://reskript.typeform.com/to/UjtPPfVz"
                    target="_blank"
                )
                    .link__icon
                        img(src="@/assets/images/icons/request_feature.svg")
                    | Request feature
                hr
                a.link.grey Terms & Privacy
                hr
                a.link.grey Twitter - @_reskript
</template>

<script>
export default {
    name: 'help-button',

    data: () => ({
        isOpened: false,
    }),

    methods: {
        toggle() {
            if (this.isOpened) {
                this.close();
            } else {
                setTimeout(() => {
                    this.isOpened = true;
                });
            }
        },

        close() {
            this.isOpened = false;
        },
    },
};
</script>

<style scoped lang="scss">
.help-button {
    position: fixed;
    bottom: rem(20px);
    left: calc(100vw - #{rem(70px)});
    z-index: 100;

    .button {
        width: rem(35px);
        height: rem(35px);

        cursor: pointer;
        filter: drop-shadow(0 0 rem(10px) rgba(0, 0, 0, 0.2));
        transition: transform .1s;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .modal {
        position: absolute;
        right: 0;
        bottom: calc(100% + #{rem(16px)});

        background: #fff;
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.1);
    }

    .link {
        display: flex;
        align-items: center;

        padding: rem(12px);

        white-space: nowrap;

        cursor: pointer;
        transition: background-color .2s;

        @media (hover: hover) {
            &:hover {
                background-color: $hover-background;
            }
        }

        &__icon {
            display: flex;
            justify-content: center;
            align-self: center;

            width: rem(31px);
            height: rem(24px);
            margin-right: rem(8px);

            img {
                width: 100%;
                height: 100%;

                object-fit: contain;
            }
        }

        &.grey {
            color: #676767;
        }
    }

    hr {
        height: 1px;
        margin: 0;

        background-color: #C6C6C6;
        border: none;
    }
}
</style>
