<template lang="pug">
    .app-to-top-link.forMobile_or_p
        .app-to-top-link__arrow(@click="scrollTo('body')")
            img(src="@/assets/images/icons/arrow_bottom.svg" alt="")
</template>

<script>
import scrollTo from '@/js/utils/scrollTo';

export default {
    name: 'app-to-top-link',

    methods: {
        scrollTo,
    },
};
</script>

<style scoped lang="scss">
.app-to-top-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    width: 100%;
    padding-bottom: 1rem;

    &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;

        width: rem(64px);
        height: rem(64px);

        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 rem(24px) 0 rgba(6, 7, 7, 0.08);

        img {
            width: rem(28px);

            transform: rotate(180deg);
        }
    }
}
</style>
