<template lang="pug">
    #app
        component(:is="layout")
            router-view

        help-button(
            v-if="!isPublicLink"
        )
        made-in-reskript(
            v-if="isPublicLink && publicLinkPermissions.includes('view_rescript_badge')"
        )

        settings
        searcher
        create-project-popup
        create-room-popup
        create-folder-popup
        rename-popup
        invite-members-popup
        leave-team-popup
        register-completed-popup
        upload-document-popup
        cancel-tariff-modal
        vue-confirm-dialog
        modal-loading-google-docs
        payment-method-modal
        on-boarding-view
</template>

<script>
import { mapState } from 'vuex';
import HelpButton from '@/components/other/HelpButton';
import MadeInReskript from '@/components/other/MadeInReskript';
import Settings from '@/components/other/settings/index';
import Searcher from '@/components/other/searcher/Searcher';
import CreateProjectPopup from '@/components/other/CreateProjectPopup';
import CreateRoomPopup from '@/components/other/CreateRoomPopup';
import CreateFolderPopup from '@/components/other/CreateFolderPopup';
import RenamePopup from '@/components/other/RenamePopup';
import CancelTariffModal from '@/components/other/plans/CancelTariffModal';
import InviteMembersPopup from '@/components/other/InviteMembersPopup';
import LeaveTeamPopup from '@/components/other/LeaveTeamPopup';
import RegisterCompletedPopup
    from '@/components/pages/auth/register/RegisterCompletedPopupDeprecated';
import UploadDocumentPopup from '@/components/other/UploadDocumentPopup';
import ModalLoadingGoogleDocs from '@/components/other/ModalLoadingGoogleDocs';
import PaymentMethodModal from '@/components/other/plans/PaymentMethodModal';

import sendToDataLayer from '@/js/utils/sendToDataLayer';
import OnBoardingView from '@/components/onboarding/onBoardingView';

export default {
    components: {
        OnBoardingView,
        HelpButton,
        MadeInReskript,

        Settings,
        Searcher,
        CreateProjectPopup,
        CreateRoomPopup,
        CreateFolderPopup,
        RenamePopup,
        InviteMembersPopup,
        LeaveTeamPopup,
        RegisterCompletedPopup,
        UploadDocumentPopup,
        ModalLoadingGoogleDocs,
        CancelTariffModal,
        PaymentMethodModal,
    },

    computed: {
        ...mapState({
            isPublicLink: state => state.editor.isPublicLink,
            publicLinkPermissions: state => state.editor.permissions,
        }),

        layout() {
            return this.$route.meta.layout || 'default-layout';
        },

        pageLoaded() {
            return this.$store.state.pageLoaded;
        },

        // loadingGoogleDocs() {
        //     return this.$store.getters.getLoadingGoogleDocs;
        // },
    },

    watch: {
        loadingGoogleDocs(visible) {
            const modalName = 'modal-loading-google-docs';
            if (visible) {
                this.$modal.show(modalName);
            } else {
                this.$modal.hide(modalName);
            }
        },
    },

    created() {
        this.detectIsMobileOrTablet();

        let unwatch = this.$watch('pageLoaded', () => {
            this.addAnalytics();
            unwatch();
        });
    },

    methods: {
        detectIsMobileOrTablet() {
            let flag = false;
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (!(!/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|android|playbook|silk|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) && !/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(userAgent.substr(0, 4))))
                flag = true;
            if (userAgent.match(/Macintosh/i) !== null && document.documentElement.offsetWidth <= 1024) {
                // Lying iOS13 iPad
                const canvas = document.createElement('canvas'); // need to distinguish between Macbook and iPad
                if (canvas !== null) {
                    const context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
                    if (context) {
                        const info = context.getExtension('WEBGL_debug_renderer_info');
                        if (info) {
                            const renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
                            if (renderer.includes('Apple')) flag = true;
                        }
                    }
                }
            }
            window.isMobileOrTablet = flag;
        },

        addAnalytics() {
            let sendUser = () => {
                const user = this.$store.getters.user;
                if (user) {
                    sendToDataLayer({
                        'event': 'userId',
                        'user_id': user.email,
                    });
                }
            };

            sendUser();

            window.timeOfEnteringThePage = +new Date();
            window.timeOfEnteringTheSite = +new Date();

            // время нахождения на странице
            this.$watch('$route.path', (newPath, oldPath) => {
                sendUser();

                const time = Math.ceil((+new Date() - window.timeOfEnteringThePage) / 1000);

                sendToDataLayer({
                    'event': 'GAEvent',
                    'eventCategory': oldPath,
                    'eventAction': 'active',
                    'eventLabel': time,
                });
                window.timeOfEnteringThePage = +new Date();
            });

            // время нахождения на сайте
            window.addEventListener('beforeunload', () => {
                const time = Math.ceil((+new Date() - window.timeOfEnteringTheSite) / 1000);
                sendToDataLayer({
                    'event': 'GAEvent',
                    'eventCategory': 'time',
                    'eventAction': 'all',
                    'eventLabel': time,
                    'eventNonInteraction': 'true',
                });
            });
        },
    },
};
</script>
