<template lang="pug">
    div(:class="classes")
        .app-toggler__label(
            v-if="labelLeft"
            v-html="labelLeft"
            @click="toggle(false)"
        )
        div(:class="indicatorClasses" @click="toggle")
        .app-toggler__label(
            v-if="labelRight"
            v-html="labelRight"
            @click="toggle(true)"
        )
</template>

<script>
export default {
    name: 'app-toggler',

    props: {
        value: {
            type: Boolean,
            required: true,
        },

        labelLeft: {
            type: String,
            default: '',
        },

        labelRight: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
    },

    computed: {
        classes() {
          const classes = [];

          classes.push(`app-toggler${this.size? '-' + this.size : ''}`);

          if (this.value) classes.push(`app-toggler${this.size? '-' + this.size : ''}--active`);

          return classes;
        },
        indicatorClasses() {
          return {
                'app-toggler__indicator': this.size === 'default',
                'app-toggler-sm__indicator': this.size === 'sm',
                'app-toggler-md__indicator': this.size === 'md',
            };
        },
    },

    methods: {
        toggle(value) {
            if (typeof value === 'boolean') {
                this.$emit('input', value);
            } else {
                this.$emit('input', !this.value);
            }
        },
    },
};
</script>
