import { importGoogleDocuments, search } from '@/js/api/requests/document';
import { ENTITY_TYPES_NAMES, ENTITY_TYPES_NAMES_MULTIPLE } from '@/js/const';
import Vue from 'vue';

const vue = new Vue;

//это дочерний контент страниц(папки, документы, итд итп) и связанное с ним
export default {
    state: () => ({
        folders: [],
        documents: [],
        documentsShared: [],

        //импорт документов(для появления модалки во время загрузки)
        loadingGoogleDocs: false,
    }),

    mutations: {
        setLoadingGoogleDocs(state, value) {
            state.loadingGoogleDocs = value;
        },

        //
        setContent(state, { data, type }) {
            state[type] = data;
        },
        setContentName(state, { type, name, id, icon }) {
            const cloneArr = [...state[type]];
            const obj = cloneArr.find(d => d.id === id);
            if (obj) {
                obj.name = name;
                obj.icon = icon;
            }
            state[type] = cloneArr;
        },
        setContentObject(state, { entity, params }) {
            let { type, id } = entity;

            if (type === ENTITY_TYPES_NAMES.document) {
                type = ENTITY_TYPES_NAMES_MULTIPLE.documents;
            } else if (type === ENTITY_TYPES_NAMES.folder) {
                type = ENTITY_TYPES_NAMES_MULTIPLE.folders;
            }

            if (type) {
                const cloneArr = [...state[type]];
                const objIndex = cloneArr.findIndex(d => d.id === id);
                if (objIndex >= 0) {
                    cloneArr[objIndex] = { ...cloneArr[objIndex], ...params };
                }
                state[type] = cloneArr;
            }
        },
        removeContent(state, { type, id }) {
            let cloneArr = [...state[type]];
            cloneArr = cloneArr.filter(d => d.id !== id);
            state[type] = cloneArr;
        },
    },

    getters: {
        getLoadingGoogleDocs: state => state.loadingGoogleDocs,
        getFoldersAndDocuments: state => [...state.folders, ...state.documents],
        getFolders: state => state.folders,
        getDocuments: state => state.documents,
    },

    actions: {
        setContentDocuments({ commit }, data) {
            commit('setContent', { data, type: ENTITY_TYPES_NAMES_MULTIPLE.documents });
        },
        setContentNameDocument({ commit }, { name, id, icon }) {
            commit('setContentName', { name, id, icon, type: ENTITY_TYPES_NAMES_MULTIPLE.documents });
        },
        removeContentDocument({ commit }, id) {
            commit('removeContent', { id, type: ENTITY_TYPES_NAMES_MULTIPLE.documents });
        },

        setContentFolders({ commit }, data) {
            commit('setContent', { data, type: ENTITY_TYPES_NAMES_MULTIPLE.folders });
        },
        setContentNameFolder({ commit }, { name, id, icon }) {
            commit('setContentName', { name, id, icon, type: ENTITY_TYPES_NAMES_MULTIPLE.folders });
        },
        removeContentFolder({ commit }, id) {
            commit('removeContent', { id, type: ENTITY_TYPES_NAMES_MULTIPLE.folders });
        },

        async search(_, payload) {
          let result;

          try {
            result = await search(payload);
          } catch (error) {
            console.log(error);
          }

          return result;
        },

        async importGoogleDocuments({ commit, state, dispatch }, { data, isRootLibraryPage }) {
            commit('setLoadingGoogleDocs', true);
            try {
                let savedDocs = await importGoogleDocuments(data);
                if (isRootLibraryPage && savedDocs && savedDocs.length > 0) {
                    savedDocs = savedDocs.map((obj) => {
                        return { ...obj, type: ENTITY_TYPES_NAMES.document };
                    });
                    dispatch('setContentDocuments', [...savedDocs, ...state.documents]);
                    vue.$awn.success(`Google documents uploaded successfully`);
                }
            } catch (e) {
                vue.$awn.alert(`Google docs failed to load`);
            } finally {
                commit('setLoadingGoogleDocs', false);
            }
        },
    },
};
