<template lang="pug">
    .refresh-password
        form.refresh-password-form(@submit.prevent="submit")
            app-text-field(
                v-model="password"
                label="Password"
                :error="passwordError"
                :isPassword="true"
            )
            app-text-field(
                v-model="passwordConfirm"
                label="Confirm Password"
                :error="passwordConfirmError"
                :isPassword="true"
            )
            app-button.app-button--full-width(
                :disabled="!password || !passwordConfirm"
                :loading="loading"
            ) Confirm
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { refreshPassword } from '@/js/api/requests/auth';

export default {
    name: 'refresh-password',

    data: () => ({
        password: '',
        passwordConfirm: '',

        loading: false,
        apiError: null,
    }),

    validations: {
        password: {
            required,
            minLength: minLength(8),
        },

        passwordConfirm: {
            required,
            minLength: minLength(8),
            matches(value) {
                return this.password === value;
            },
        },
    },

    computed: {
        passwordError() {
            if (this.$v.$dirty) {
                if (!this.$v.password.required) {
                    return 'Required field';
                } else if (!this.$v.password.minLength) {
                    return 'The minimum password length is 8 characters';
                }
            }
            return this.apiError;
        },

        passwordConfirmError() {
            if (this.$v.$dirty) {
                if (!this.$v.passwordConfirm.required) {
                    return 'Required field';
                } else if (!this.$v.passwordConfirm.minLength) {
                    return 'The minimum password length is 8 characters';
                } else if (!this.$v.passwordConfirm.matches) {
                    return 'Password mismatch';
                }
            }
            return null;
        },
    },

    methods: {
        async submit() {
            this.apiError = null;
            if (this.loading) return;
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.loading = true;

            const fd = new FormData;
            fd.append('email', this.$route.query.email);
            fd.append('signature', this.$route.query.signature);
            fd.append('new_password', this.password);

            try {
                const data = await refreshPassword(fd);
                this.apiError = data.data.error;
                if (data.data.error) {
                    this.apiError = data.data.error;
                } else {
                    this.$router.replace({ name: 'Login' });
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.refresh-password {
    &-form {
        width: rem(355px);
        padding: 1rem;

        background: #FFFFFF;
        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

        .app-button {
            margin-top: 1rem;
        }
    }
    .visible-password {
        cursor: pointer;
        font-size: rem(13px);
        text-decoration: underline;
    }
}
</style>
