<template>
    <div class="gradients">
        <card-popup
            v-for="(item) in GRADIENTS"
            :key="item.id"
            :project-id="projectId"
            :styles="getBackground(item.value)"
            @updateBackground="updateBackground($event)"
        />
    </div>
</template>

<script>

import cardPopup from '@/components/pages/projects/detail/popup/cardPopup';
import { GRADIENTS } from '@/js/const';

export default {
    name: 'gradients',
    components: { cardPopup },

    props: {
        projectId: {
            type: Number,
            default: 0,
        },
    },

    data: () => ({
        GRADIENTS,
    }),

    methods: {
        getBackground(value) {
            return value;
        },

        updateBackground(background) {
            this.$emit('updateBackground', background);
        },
    },

};
</script>

<style lang="scss" scoped>
.gradients {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 20px;
}

</style>
