<template lang="pug">
    .notifications-page
        notifications
</template>

<script>
import Notifications from '@/components/layouts/notifications/Notifications';

export default {
    name: 'notifications-page',

    components: {
        Notifications,
    },

    created() {
        if (!window.isMobileOrTablet) {
            this.$router.push({ name: 'Dashboard' });
        }
    },
};
</script>


