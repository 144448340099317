<template lang="pug">
	div
		.shareBlock
			.publishToWeb
				earthIcon.itemIcon
				p.itemText Publish to Web
				app-toggler.publishToWebToggle(v-model="toggledPublishToWebAsModel" size="sm")

			p.note Publish and share link with anyone. Anybody who has the link can access the document

			.publishToWebSettings(v-if="toggledPublishToWebAsModel")
				div
					app-button(@click="openPublishToWebSettings" middle grey block)
						img(width="15" src="@/assets/images/icons/settings.svg")
				div
					app-button(
						@click="copyPublicLink" middle block :loading="isLoadingCopyLinkButton"
					) Copy Link

			app-button(@click="openShare" middle grey block)
				shareIcon.itemIcon(width="15")
				span.itemText Share

		.exportBlock
			p.titleBlock Export

			button.itemButton(
				@click="downloadWord"
			)
				wordIcon.itemIcon
				span.itemText MS Word (docx)

			button.itemButton(
				@click="downloadPdf"
			)
				pdfIcon.itemIcon
				span.itemText PDF

			button.itemButton(
				@click="downloadHtml"
			)
				htmlIcon.itemIcon
				span.itemText HTML

			button.itemButton(
				@click="documentPrint"
			)
				printIcon.itemIcon
				span.itemText Print
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import DownloadDocumentMixin from '@/mixins/DownloadDocumentMixin';
import shareIcon from '@/assets/images/icons/share.svg?inline';
import wordIcon from '@/assets/images/icons/word.svg?inline';
import pdfIcon from '@/assets/images/icons/pdf.svg?inline';
import htmlIcon from '@/assets/images/icons/code.svg?inline';
import printIcon from '@/assets/images/icons/print.svg?inline';
import earthIcon from '@/assets/images/icons/earth.svg?inline';

export default {
    name: 'editor-actions-share-popup',

    components: {
        shareIcon,
        wordIcon,
        pdfIcon,
        htmlIcon,
        printIcon,
        earthIcon,
    },

    mixins: [DownloadDocumentMixin],

	props: {
        document: {
            type: Object,
            default: () => ({}),
            required: true,
        },
	},

    data () {
      return {
          isLoadingCopyLinkButton: false,
      };
    },

    computed: {
        ...mapState({
            toggledPublishToWeb: state => state.editor.toggledPublishToWeb,
        }),
    },

    watch: {
        toggledPublishToWebAsModel (value) {
            if (!value) {
                this.$root.$emit('close-publish-to-web-settings');
            }
        },
    },

    beforeCreate() {
        this.$options.computed.toggledPublishToWebAsModel = {
            get () {
                return this.toggledPublishToWeb;
            },
            set (value) {
                this.setToggledPublishToWeb(value);
            },
        };
    },

	methods: {
        ...mapActions(['createPublicDocumentLink']),

        ...mapMutations(['setToggledPublishToWeb']),

		downloadWord() {
			this.exportDocumentDocx(this.document);
		},

		downloadPdf() {
			this.exportDocumentPdf(this.document);
		},

		downloadHtml() {
			this.exportDocumentHtml(this.document);
		},

		documentPrint() {
			this.$emit('close');
			setTimeout(() => print());
		},

        openPublishToWebSettings () {
            this.$root.$emit('open-publish-to-web-settings');
        },

        async copyPublicLink () {
            this.isLoadingCopyLinkButton = true;

            try {
                const data = await this.createPublicDocumentLink(this.document.id);
                navigator.clipboard.writeText(data.link);
                this.$awn.success('link successfully copied');
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            }

            this.isLoadingCopyLinkButton = false;
            this.$root.$emit('close-publish-to-web-settings');
        },

        openShare () {
            this.$modal.show('editor-invite-popup');
        },
	},
};
</script>

<style lang="scss" scoped>
.shareBlock {
    margin-bottom: 17px;
}

.titleBlock {
    margin-bottom: 9px;
    font-size: 14px;
    font-weight: 500;
}

.publishToWeb {
    width: 100%;
    padding: 8px 6px;
    display: flex;
    align-items: center;
}

.publishToWebSettings {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 9px 0;

    div:first-child {
        min-width: 59px;
    }
    div:last-child {
        width: 100%;
    }
    div + div {
        margin-left: rem(11px);
    }
}

.itemButton {
    width: 100%;
    padding: 8px 6px;
    display: flex;
    cursor: pointer;
    border-radius: 4px;
}

.itemIcon {
    margin-right: 20px;
}

.itemText {
    flex-grow: 1;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    color: black;
}

.publishToWebToggle {
    margin: 0;
}

.note {
    margin-bottom: 8px;
    line-height: 12px;
    font-size: 10px;
    color: #AEAEAE;
}
</style>
