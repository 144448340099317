import arrowIcon from '@/assets/images/icons/long_arrow_bottom.svg?inline';
import checkedIcon from '@/assets/images/icons/checked_3.svg?inline';

import { SHARED_FILTERS } from '@/js/const';

export default {
    name: 'shared-filters-menu',
    components: { arrowIcon, checkedIcon },

    data: () => ({
        currentFilter: SHARED_FILTERS[0].title,
        isOpen: false,
        SHARED_FILTERS,
    }),

    computed: {
        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },
    },

    methods: {
        selectFilter(filter) {
            this.currentFilter = filter.title;
            this.$emit('selectFilter', filter.value);
        },

        close() {
            this.isOpen = false;
        },
    },
};


