<template lang="pug">
    .app-datepicker
        datepicker(
            v-model="state"
            :inline="inline"
        )
</template>

<script>
import Datepicker from 'vue2-datepicker';

export default {
    name: 'app-datepicker',

    components: {
        Datepicker,
    },

    props: {
        value: {
            type: Date,
            default: new Date(),
        },

        inline: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
