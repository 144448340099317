<template>
    <div class="project-detail-library default-layout-block">
        <template>
            <div class="filter">
                <div class="search-field">
                    <app-text-field
                        v-model="searchField"
                        class="app-text-field--theme-solid"
                        placeholder="Search"
                    >
                        <template #icon>
                            <search-icon class="search-field-icon" />
                        </template>
                    </app-text-field>
                </div>
                <library-filters-menu
                    :filters="filters"
                    class="filter-menu"
                    @changeFilter="changeFilter"
                />
            </div>
            <documents-table
                v-if="docs.length"
                :headers="headersForTable"
                :items="docs"
                background
                bolder="bolder"
                without-groups="without-groups"
            />
            <template v-else>
                <no-docs grey-background />
            </template>

            <shared-filters-menu-popup :filters="project.project_rooms" />
        </template>
    </div>
</template>

<script>
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import DocumentsTable from '@/components/common/documentsTable';
import searchIcon from '@/assets/images/icons/search.svg?inline';
import noDocs from '@/components/other/noDocs/noDocs';
import SharedFiltersMenuPopup from '@/components/shared/sharedFiltersMenuPopup';
import LibraryFiltersMenu from '@/components/pages/projects/detail/popup/libraryFiltersMenu';
import { getRoomById } from '@/js/api/requests/room';
import { reactive } from '@vue/composition-api';


export default {
    name: 'project-detail-library',
    components: { LibraryFiltersMenu, SharedFiltersMenuPopup, DocumentsTable, searchIcon, noDocs },
    mixins: [CreateDocumentMixin],

    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        headersForTable: ['Name', 'Updated', 'Created'],
        searchField: '',
        documents: reactive([]),
    }),

    computed: {
        filters() {
            let filters = [...this.project.project_rooms];
            filters.unshift({ name: 'All documents', id: 'allDocuments' });
            return filters;
        },

        docs() {
            return this.documents.filter(el => el.name.includes(this.searchField));
        },
    },

    mounted() {
        this.documents = this.project.project_library;
    },

    methods: {
        async changeFilter(val) {
            if (val.id === 'allDocuments') {
                this.documents = this.project.project_library;
            } else {
                const room = await getRoomById(val.id);
                this.documents = room.room_documents;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.default-layout-block {
    ::v-deep {
        padding: 0;
    }
}

.filter {
    background-color: $grey-dark8;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .search-field {
        width: rem(331px);
        background: #ECECEC;

        .app-text-field {
            margin-bottom: unset;
            margin-left: 13px;

            &::v-deep .app-text-field__input {
                padding: rem(8px) rem(8px) rem(8px) rem(48px);
                width: 100%;
                height: auto;
            }

            &::v-deep .app-text-field__icon {
                display: flex;
                width: auto;
                height: auto;
                left: rem(13px);
            }
        }

        &-icon {
            width: rem(16px);
            height: rem(16px);
            stroke: $grey2;

            pointer-events: none;
        }
    }

    .filter-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 127px;
        background: #ECECEC;
        border-radius: 4px;
        height: 32px;
    }
}
</style>
