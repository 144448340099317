<template lang="pug">
    .sidebar-creation-list(v-if="isOpen")
        .link(@click="createDoc")
            img.icon(src="@/assets/images/icons/create_document.svg" alt="")
            | Create Document
        .link(
            v-if="showCreateFolderLink"
            @click="createFolder"
        )
            img.icon(src="@/assets/images/icons/create_folder.svg" alt="")
            | Create Folder
        .link(
            v-if="showCreateProjectLink"
            @click="$modal.show('create-project-popup')"
        )
            img.icon(src="@/assets/images/icons/create_project.svg" alt="")
            | Create Project

        .link(@click="$modal.show('upload-document-popup')" class="link_mobile")
            img.icon(src="@/assets/images/icons/upload_document.svg" alt="")
            | Upload Document
        .link(@click="loadGoogleDocuments" class="link_mobile")
            img.icon(src="@/assets/images/icons/google_drive.svg" alt="")
            | Google drive

        .link(class="upload-docs link_pc")
            img.icon(src="@/assets/images/icons/upload_document.svg" alt="")
            | Upload Document
            .upload-docs__popup
                .link(v-for="link in uploadDocsList" :key = "link.title" @click="link.click()" class="upload-docs__link")
                    img.icon(:src="link.src" alt="")
                    | {{ link.title }}
</template>

<script>
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import GetRouteParamsMixin from '@/mixins/GetRouteParamsMixin';

export default {
    name: 'sidebar-creation-list',

    mixins: [CreateDocumentMixin, GetRouteParamsMixin],

    data() {
        return {
            isOpen: false,
            uploadDocsList: [
                { title: 'From computer', src: require('@/assets/images/icons/from_computer.svg'), click: () => this.$modal.show('upload-document-popup') },
                { title: 'Google drive', src: require('@/assets/images/icons/google_drive.svg'), click: () => this.loadGoogleDocuments() },
            ],
        };
    },

    computed: {
        showCreateProjectLink() {
            return this.$route.name !== 'ProjectDetail';
        },

        showCreateFolderLink() {
            return this.$route.name !== 'ProjectDetail';
        },
    },


    mounted() {
        let gDrive = document.createElement('script');
        gDrive.setAttribute('type', 'text/javascript');
        gDrive.setAttribute('src', 'https://apis.google.com/js/api.js');
        document.head.appendChild(gDrive);
    },

    methods: {
        createFolder() {
            this.$modal.show('create-folder-popup');
        },

        createDoc() {
            this.createDocument(this.getRouteParams());
        },

        toggle() {
            if (this.isOpen) {
                this.isOpen = false;
                document.removeEventListener('click', this.clickOutsideHandler);
            } else {
                this.isOpen = true;
                if (!window.isMobileOrTablet) {
                    setTimeout(() => {
                        document.addEventListener('click', this.clickOutsideHandler);
                    });
                }
            }
        },

        clickOutsideHandler(e) {
            if (this.isOpen && (!e.target.closest('.sidebar-creation-list') || e.target.closest('.sidebar-creation-list .link'))) {
                this.toggle();
            }
        },

        googleAuthSignOut(googleAuth) {
            this.$awn.alert('You didn\'t grant access to google docs during login. Repeat authorization and grant access.');
            googleAuth.signOut();
        },
        loadGoogleDocuments() {
            const $this = this;
            const checkGALoad = setTimeout(async function () {
                const googleAuth = await $this.$google.api.auth2.getAuthInstance();

                googleAuth.grantOfflineAccess({
                    prompt: 'select_account', //select_account, consent
                    scope: 'https://www.googleapis.com/auth/drive.file ' +
                        'https://www.googleapis.com/auth/drive.metadata ' +
                        'https://www.googleapis.com/auth/drive.readonly',
                }).then(
                    // eslint-disable-next-line no-unused-vars
                    ev => {
                        $this.reloadUserAuth();
                    },
                    // eslint-disable-next-line no-unused-vars
                ).catch(er => {
                    $this.googleAuthSignOut(googleAuth);
                });
                clearInterval(checkGALoad);
            }, 1);
        },
        createPicker(authResult) {
            const { access_token } = authResult;
            if (authResult && !authResult.error) {
                this.$google.api.load('picker', () => {
                    // eslint-disable-next-line no-undef
                    const view = new google.picker.View(google.picker.ViewId.DOCUMENTS).setMimeTypes('application/vnd.google-apps.document');
                    // eslint-disable-next-line no-undef
                    const picker = new google.picker.PickerBuilder().
                        // eslint-disable-next-line no-undef
                        enableFeature(google.picker.Feature.MULTISELECT_ENABLED).
                        addView(view).
                        setOAuthToken(access_token).
                        setAppId('rescriptdev').
                        setDeveloperKey('AIzaSyAP1RwaQ79mxOKNxR651pc9NGMOPBaZBqs').
                        setCallback((data) => this.pickerCallback(data, access_token)).
                        build();
                    picker.setVisible(true);
                });
            }
        },
        pickerCallback(data, access_token) {
            const files = data.docs && data.docs.length > 0 ? data.docs.map(element => ({ id: element.id,  name: element.name })) : [];
            if (files.length > 0) {
                this.$store.dispatch('importGoogleDocuments', {
                    data: {
                        files,
                        access_token,
                    },
                    isRootLibraryPage: this.$route.name === 'Library',
                });
            }
        },
        async reloadUserAuth() {
            try {
                const googleAuth = await this.$google.api.auth2.getAuthInstance();
                const googleUser = await googleAuth.currentUser.get();

                googleUser.reloadAuthResponse().then(
                    async (authResponse) => {
                        const googleAccess = await googleUser.getGrantedScopes();
                        if (googleAccess) {
                            this.createPicker(authResponse);
                        } else {
                            this.googleAuthSignOut(googleAuth);
                        }
                    },
                );
            } catch (error) {
                this.$awn.alert('An unknown error has occurred. Try to log in and get google files later.');
            }
        },
    },
};
</script>

<style scoped lang="scss">
.sidebar-creation-list {
    padding: rem(8px) 0;
    overflow: visible;

    background: #fff;
    border-radius: rem(14px);
    box-shadow: 0 0 rem(10px) 0 rgba(6, 7, 7, 0.15);

    .link {
        display: flex;
        align-items: center;

        padding: rem(8px) rem(16px);

        font-weight: 500;
        white-space: nowrap;

        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                background-color: $hover-background;
            }
        }

        &_pc {
            display: flex;
        }
        &_mobile {
            display: none;
        }
    }

    .icon {
        width: rem(32px);
        margin-right: rem(10px);
    }

    @include mobile_or_tablet {
        border-radius: 0;
        box-shadow: none;

        .link {
            font-size: rem(19px);

            &_pc {
                display: none;
            }
            &_mobile {
                display: flex;
            }
        }

        .icon {
            margin: 0 rem(20px) 0 rem(14px);
        }
    }
}
.upload-docs {
    position: relative;
    &__popup {
        display: none;
        position: absolute;
        top: 0;
        right: rem(-200px);
        padding: rem(8px) 0;
        background: #fff;
        border-radius: rem(14px);
        box-shadow: 0 0 rem(10px) 0 rgba(6, 7, 7, 0.15);
    }
    &:hover &__popup {
        display: block;
    }
    &__link {

    }
}
</style>
