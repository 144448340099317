import Quill from 'quill';

let Inline = Quill.import('blots/embed');

class Mentor extends Inline {
    static create({ id, name }){
        let node = super.create();
        node.setAttribute('data-mentor-id', id);
        node.setAttribute('data-mentor-name', name);
        node.setAttribute('spellcheck', false);
        node.innerHTML = '@' + name;
        return node;
    }

    static value(node) {
        return {
            id: node.getAttribute('data-mentor-id'),
            name: node.getAttribute('data-mentor-name'),
            spellcheck: node.getAttribute('spellcheck'),
        };
    }
}

Mentor.blotName = 'mentor';
Mentor.className = 'ql-mentor';
Mentor.tagName = 'div';
Quill.register(Mentor);
