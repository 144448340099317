import Quill from 'quill';
import 'emoji-picker-element';

const Embed = Quill.import('blots/embed');
const randomEmoji = ['😀', '😃', '😄', '😁', '😅', '😆', '😍', '🥰', '😇', '💬'];
// how use
// const { index } = this.editor.getSelection();
// this.editor.insertEmbed(index, 'callout', true);
function getRandomEmoji() {
    const idx = Math.round(Math.random() * 10);
    return randomEmoji[idx];
}

function getModuleId() {
    return Math.random().toString();
}

class Callout extends Embed {
    constructor(...args) {
        super(...args);
        this.modal = null;
        this.button = null;
        this.setBg = null;
    }

    static create(value = {}) {
        const icon = value.icon || getRandomEmoji();
        const node = super.create();
        const paragraph = this.createParagraph(node, value.text);
        const background = value.background || 'background-color: #EBEBEB';
        const dropdown = this.createDropdown(node);


        this.button = this.createButton(node, icon);
        this.setBg = this.createButtonBackground(node, '•••');

        node.setAttribute('data-callout-icon', icon);
        node.setAttribute('data-callout-text', '');
        node.setAttribute('data-callout-background', background);
        node.setAttribute('id', getModuleId());

        node.addEventListener('click', () => {
            const event = new CustomEvent('changeBackgroundFromFormatter', {
                bubbles: true,
                detail: { id: node.getAttribute('id'), top: node.offsetTop },
            });
            document.dispatchEvent(event);
        });

        setTimeout(() => {
            node.querySelector('span').addEventListener('contextmenu', () => {

                dropdown.classList.toggle('ql-callout__dropdown--opened');
            });

            node.querySelector('p').addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        }, 0);

        node.append(this.button, paragraph, this.setBg, dropdown);
        setTimeout(() => {
            paragraph.focus();
        }, 0);

        return node;

    }

    static dropdownItems = [

        {
            text: 'Delete',
            customEvent: (close, node) => {
                return () => {
                    node.remove();
                    close();
                };
            },
        },
    ];

    static createDropdown(mainNode) {
        const node = document.createElement('div');
        node.className = 'ql-callout__dropdown';
        node.setAttribute('data-not-link', true);

        this.dropdownItems.forEach(({ text, customEvent }) => {
            const item = this.createDropdownItem(
                text,
                customEvent(() => node.classList.remove('ql-callout__dropdown--opened'), mainNode),
            );
            node.append(item);
        });

        return node;
    }

    static createDropdownItem(content = 'default text', customEvent) {
        const node = document.createElement('div');
        node.className = 'ql-callout__dropdown-item';

        const button = document.createElement('button');
        button.setAttribute('type', 'button');
        button.setAttribute('data-not-link', true);
        button.className = 'ql-callout__dropdown-button';
        button.textContent = content;
        if (customEvent) {
            button.addEventListener('click', customEvent);
        }

        node.append(button);

        return node;
    }

    static createButton(parent, content = '') {
        const node = document.createElement('button');

        node.setAttribute('type', 'button');
        node.className = 'ql-callout__icon';
        node.textContent = content;
        node.addEventListener('click', this.toggleModal.bind(this, parent));

        return node;
    }

    static createParagraph(parent, content = '') {
        const node = document.createElement('p');

        if (content) node.textContent = content;

        node.setAttribute('contenteditable', true);
        node.className = 'ql-callout__text';
        node.addEventListener('input', (e) => {
            e.stopPropagation();
            parent.setAttribute('data-callout-text', e.target.textContent);
        });
        node.addEventListener('keydown', (e) => {
            e.stopPropagation();
            if (e.code === 'Backspace' && e.target.textContent === '') {
                parent.remove();
            }
        });

        return node;
    }

    static createButtonBackground(parent, content = '') {
        const node = document.createElement('button');

        node.setAttribute('type', 'button');
        node.className = 'ql-callout__background';
        node.id = 'setBgBtn';
        node.textContent = content;
        node.addEventListener('click', this.changeBackgroundCallout.bind(this, parent));

        return node;
    }

    static toggleModal(node) {
        if (this.modal) {
            this.removeModal();
        } else {
            this.showModal(node);
        }
    }

    static showModal(node) {
        this.modal = true;
        node.insertAdjacentHTML('beforeend', `
        <emoji-picker class="ql-callout__emoji"></emoji-picker>
    `);
        this.modal = document.querySelector('emoji-picker');
        this.modal.addEventListener('emoji-click', (event) => {
            const emoji = event.detail.unicode;
            node.setAttribute('data-callout-icon', emoji);
            this.button.textContent = emoji;
            this.removeModal();
        });
    }

    static changeBackgroundCallout(node) {
        document.addEventListener('changeBackgroundCallout', function (event) {
            if (node.id === event.detail.id) {
                node.setAttribute('data-callout-background', event.detail.color);
            }
        });

        const event = new CustomEvent('changeBackground', {
            bubbles: true,
            detail: { id: node.getAttribute('id'), top: node.offsetTop },
        });

        document.dispatchEvent(event);
    }

    static removeModal() {
        this.modal.remove();
        this.modal = null;
    }

    static value(node) {
        const span = node.querySelector('span');
        span.style.backgroundColor = node.getAttribute('data-callout-background');
        span.style.padding = '10px 10px 19px';
        span.style.borderRadius = '6px';
        return {
            icon: node.getAttribute('data-callout-icon'),
            text: node.getAttribute('data-callout-text'),
            background: node.getAttribute('data-callout-background'),
            id: node.getAttribute('id'),
        };
    }
}

Callout.blotName = 'callout';
Callout.className = 'ql-callout';
Callout.tagName = 'div';


Quill.register(Callout);
