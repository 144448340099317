<template lang="pug">
    .choose-plan
        .left
            .choose-plan__pc
                .choose-plan__title.forDesktop Choose a price plan:
                period-switch
            .choose-plan__title.forMobile_or_p Subscription:
            plans-for-registration(:nextStep="() => $emit('nextStep')")
        .right
</template>

<script>
import PeriodSwitch from '@/components/other/plans/PeriodSwitch';
import PlansForRegistration from '@/components/other/plans/PlansForRegistration';
export default {
    name: 'choose-plan',
    components: {
        PlansForRegistration,
        PeriodSwitch,
    },
};
</script>

<style scoped lang="scss">
.choose-plan {
    display: flex;

    width: 100vw;
    height: 100vh;

    background: #fff;

    &__pc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;
    }

    .left, .right {
        width: 50%;
    }

    .left {
        padding: rem(64px) rem(76px);

        background: #F8FBFF;
    }

    .right {
        background: url(../../../../assets/images/choose_plan_bg.svg) no-repeat center bottom / 100% auto;
    }

    &__title {
        font-size: rem(36px);
    }

    table {
        width: 100%;

        th {
            font-size: rem(24px);
        }

        td {
            padding: 3vh 0;

            font-size: rem(18px);

            &:not(:nth-of-type(1)) {
                text-align: center;
            }
        }

        .choose-plan__prices td{
            font-size: 3rem;
        }
        .choose-plan__buttons td:nth-of-type(1) {
            font-size: rem(24px);
            color: #777676;
        }
    }

    @include mobile_or_tablet {
        height: initial;

        background: transparent;

        .left {
            width: 95%;
            margin: 0 auto;
            padding: 1rem;
            overflow-x: auto;

            background: #fff;
            border-radius: rem(6px);
            box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);
        }

        .right {
            display: none;
        }

        &__title {
            margin-bottom: 2rem;
        }

        table {
            td {
                padding: 1rem 0.5rem 1rem 0;
            }
        }
    }
}
</style>
