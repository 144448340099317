<template lang="pug">
    .editor-header-table-size-selector
        .size {{ activeCell[0] }} X {{ activeCell[1] }}
        table(@mouseleave="activeCell=[0, 0]")
            tr(v-for="i in 10")
                td(
                    v-for="j in 12"
                    @mouseenter="activeCell=[i, j]"
                    :class="{ active: i <= activeCell[0] && j <= activeCell[1] }"
                    :data-size="i+'X'+j"
                    @click="(e) => $emit('click', e)"
                )
</template>

<script>
export default {
    name: 'editor-header-table-size-selector',

    data: () => ({
        activeCell: [0, 0],
    }),
};
</script>

<style scoped lang="scss">
.editor-header-table-size-selector {
    padding: rem(10px) rem(14px);

    cursor: initial;

    .size {
        margin-bottom: rem(8px);

        font-size: rem(14px);
        text-align: center;
    }

    table {
        width: rem(300px);
    }

    td {
        width: rem(15px);
        height: rem(15px);

        background-color: #fbfbfb;
        border: 1px solid #d7d7d7;

        cursor: pointer;

        &.active {
            background-color: #edf7fe;
            border-color: $grey;
        }
    }
}
</style>
