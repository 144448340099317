<template lang="pug">
    .app-horizontal-tabs
        .app-horizontal-tabs__item(
            v-for="tab in tabs"
            :class="{active: tab.id === value && !inactive}"
            @click="$emit('change', tab.id)")
            p(v-html="tab.name")
</template>

<script>

export default {
    name: 'app-horizontal-tabs',

    props: {
        value: {
            validator: () => {
                return true;
            },
            required: true,
        },

        tabs: {
            type: Array,
            required: true,
        },

        inactive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
