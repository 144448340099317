import { render, staticRenderFns } from "./SidebarCreationList.vue?vue&type=template&id=119177d0&scoped=true&lang=pug&"
import script from "./SidebarCreationList.vue?vue&type=script&lang=js&"
export * from "./SidebarCreationList.vue?vue&type=script&lang=js&"
import style0 from "./SidebarCreationList.vue?vue&type=style&index=0&id=119177d0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119177d0",
  null
  
)

export default component.exports