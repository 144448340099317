<template lang="pug">
    .storage
        .default-layout-head
            .default-layout-head__title Storage

        .default-layout-block
            h3 Usage
            .usage__row
                .memory
                    .memory__drag
                        .memory__drag-indicator(style="width: 40%")
                    .memory__used 700 mb used
                    .memory__total 2 GB
                .usage__right
                    p.forDesktop If you haven’t been using big size files for a long time, ReSkript can delete them for you automatically.
                    app-button Optimize

                    p.forMobile_or_p Items selected: 2
                    app-button.app-button--grey.app-button--icon.forMobile_or_p
                        img(src="@/assets/images/icons/delete.png" alt="")

        .default-layout-block
            .tabel__top
                h3 Files
                p.forDesktop Items selected: 2
                app-button.app-button--red.forDesktop Delete
            files-table(v-model="selectedFiles")
</template>

<script>
import FilesTable from '@/components/other/FilesTable';
export default {
    name: 'storage',

    components: {
        FilesTable,
    },

    data: () => ({
        selectedFiles: [],
    }),
};
</script>

<style scoped lang="scss">
.usage {
    &__row {
        display: flex;
        justify-content: space-between;

    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        max-width: rem(284px);
        margin: -1.8rem 0 0.6rem;

        p {
            margin-bottom: 1.5rem;

            line-height: 1.2;
            text-align: center;
        }
    }
}

.memory {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    width: rem(570px);
    max-width: 100%;
    padding-top: 1.65rem;

    font-size: rem(18px);

    &__drag {
        width: 100%;
        height: rem(10px);
        margin-bottom: 0.7rem;

        background: $grey-light;

        &-indicator {
            height: 100%;

            background: $blue;
        }
    }

    &__used {
        color: $blue;
    }
}

.tabel {
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 1.6rem;

        h3 {
            margin: 0;
        }

        p {
            margin-right: rem(45px);
            margin-left: auto;
        }
    }
}

@include mobile_or_tablet {
    .storage {
        padding-top: 1rem;
    }

    .usage {
        &__row {
            flex-direction: column;
        }

        &__right {
            flex-direction: row;
            align-items: center;

            max-width: 100%;
            margin: 0;

            p {
                margin: 0 1rem 0 auto;
            }
        }
    }

    .memory {
        width: 100%;
        margin-bottom: 2rem;
        padding-top: 1rem;
    }
}
</style>
