<template lang="pug">
    .editor-formula-popup(
        v-if="isOpen"
        ref="formulaPopup"
        v-click-outside="close"
        class='ql-tooltip ql-editing'
    )
        app-text-field(
            ref="value"
            v-model="value"
            placeholder="e=mc^2"
        )
        .btn(@click="save") Save
</template>

<script>
export default {
    name: 'editor-formula-popup',

    data: () => ({
        value: '',
        isOpen: false,
    }),

    methods: {
        open(params={}) {
            this.isOpen = true;
            this.value = params.value ?? '';
            this.$nextTick(() => {
                if (this.$refs.formulaPopup) {
                    this.$refs.formulaPopup.style.cssText = `top: ${params.top}px; left: ${params.left}px;`;
                    this.$refs.value.focus();
                }
            });
        },

        close() {
            this.isOpen = false;
            this.value = '';
        },

        save() {
            this.$emit( 'save', {
                value: this.value,
            });
            this.close();
        },
    },
};
</script>

<style scoped lang="scss">
.editor-formula-popup {
    position: absolute;
    z-index: 1;

    display: flex;
    flex-direction: column;

    padding: 1.5rem 1rem 1rem;

    background: #fff;
    border-radius: rem(4px);
    box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

    &::v-deep {
        input {
            height: rem(20px);
            font-size: rem(14px);
            padding:  1rem;
        }

        .btn {
            cursor: pointer;
        }
    }
}
</style>
