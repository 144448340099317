<template>
    <div
        class="room-detail"
        @mousedown="mousedownHandler"
        @mousemove="mousemoveHandler"
        @mouseup="mouseupHandler"
    >
        <app-loader v-if="pageLoading" />
        <template v-else>
            <div class="default-layout-head">
                <app-breadcrumbs
                    :items="breadcrumbs"
                    class="app-breadcrumbs_mb app-breadcrumbs--theme-thin"
                    @clickBreadcrumb="clickEntityHandler"
                    @mouseoverBreadcrumb="mouseoverEntityHandler"
                    @mouseupBreadcrumb="mouseupEntityHandler"
                />
            </div>
            <project-header
                :background="background"
                :emoji="emoji"
                :project="project"
                is-project
                @open="openModal($event)"
            />
            <div class="default-layout-row">
                <div class="room-detail__left">
                    <transition mode="out-in">
                        <div
                            v-if="activeTabId === null"
                            key="null"
                        >
                            <folders
                                :click-entity-handler="clickEntityHandler"
                                :create-folder-params-when-empty="createFolderOptions"
                                :create-folder-params-when-non-empty="createFolderOptions"
                                :dbclick-entity-handler="dbclickEntityHandler"
                                :entity-classes="entityClasses"
                                :folders="folders"
                                :mouseover-entity-handler="mouseoverEntityHandler"
                                :mouseup-entity-handler="mouseupEntityHandler"
                                :open-folder-context="openFolderContext"
                                title="Room Folders"
                            />
                            <documents-table
                                v-if="documents.length"
                                :headers="headersForTable"
                                :items="documents"
                                background
                                bolder="bolder"
                                title="title"
                                without-groups="without-groups"
                            />
                            <no-docs v-else />
                        </div>
                        <room-detail-library
                            v-else-if="activeTabId === 0"
                            key="0"
                            :room="room"
                        />
                        <project-detail-manage-team
                            v-else-if="activeTabId === 1"
                            key="2"
                            :project="project"
                            :room="room"
                            :type="'room'"
                        />
                        <room-detail-settings
                            v-else-if="activeTabId === 3"
                            key="3"
                            :project="project"
                            :room="room"
                        />
                    </transition>
                </div>
                <div class="room-detail__right">
                    <chat
                        :entity-id="project.id"
                        always-open="always-open"
                        chat-type="project"
                    />
                </div>
            </div>
            <context-menu
                ref="folderContext"
                :is-move-to-for-folder="true"
                :items="['moveTo', 'rename', 'delete']"
            />
            <list-children-move
                :counter="childrenLength"
                :show="isChildrenHaveAndMove"
            />
            <list-child-move
                v-for="(selectedChild, index) in selectedChildrenAnimations"
                :key="index"
                :child="selectedChild"
            />
            <move-to-folders-context ref="moveToFoldersContext" />
            <project-edit-popup
                @updateBackground="updateBackground($event)"
                @updateEmoji="updateEmoji($event)"
            />
        </template>
    </div>
</template>

<script>
import FolderContextMenuGeneratorMixin from '@/mixins/FolderContextMenuGeneratorMixin';
import MoveToFoldersContext from '@/components/other/MoveToFoldersContext';
import Chat from '@/components/other/chat/index';
import TabsMixin from '@/mixins/TabsMixin';
import RoomDetailLibrary from '@/components/pages/rooms/detail/Library';
import RoomDetailSettings from '@/components/pages/rooms/detail/Settings';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import ChildrenMixin from '@/mixins/ChildrenMixin';
import { getProjectById } from '@/js/api/requests/project';
import { getRoomById } from '@/js/api/requests/room';
import { MANAGE_TEAM_TYPE } from '@/js/const';
import DocumentsTable from '@/components/common/documentsTable';
import projectEditPopup from '@/components/pages/projects/detail/popup/projectEditPopup';
import NoDocs from '@/components/other/noDocs/noDocs';
import projectHeader from '@/components/pages/projects/detail/projectHeader';
import ProjectDetailManageTeam from '@/components/other/ManageTeam';
import reactiveUpdateProjectMixin from '@/mixins/reactiveUpdateProjectMixin';


export default {
    name: 'room-detail',

    components: {
        NoDocs,
        DocumentsTable,
        Chat,
        RoomDetailLibrary,
        projectHeader,
        RoomDetailSettings,
        ProjectDetailManageTeam,
        MoveToFoldersContext,
        projectEditPopup,
    },

    mixins: [reactiveUpdateProjectMixin, TabsMixin, CreateDocumentMixin, ChildrenMixin, FolderContextMenuGeneratorMixin],

    data: () => ({
        MANAGE_TEAM_TYPE,
        tabs: [
            {
                id: 0,
                name: 'Project Library',
                tab: 'library',
            },
            {
                id: 1,
                name: 'Team Management',
                tab: 'manage-team',
            },
            {
                id: 2,
                name: 'Tasks',
                tab: 'calendar',
            },
            {
                id: 3,
                name: 'Settings',
                tab: 'settings',
            },
        ],
        popupTabsBg: [
            {
                title: 'Gradients',
                value: 'gradients',
            },
            {
                title: 'Unsplash',
                value: 'unsplash',
            },
            {
                title: 'Upload an image',
                value: 'upload',
            },
        ],
        popupTabsIcon: [
            {
                title: 'Emoji',
                value: 'emoji',
            },

        ],
        headersForTable: ['Name', 'Updated', 'Created'],


        project: null,
        room: null,
        defaultBackground: 'background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(91.48deg, #5B69FF 0%, #FF59F8 52.08%, #FFAC58 100%)',

        pageLoading: true,
    }),

    computed: {
        breadcrumbs() {
            return this.getBreadcrumps({ ...this.room });
        },

        createFolderOptions() {
            return this.room ? { room: { ...this.room }, successCallback: this.fetchRoom } : null;
        },

        createDocumentOptions() {
            return { room_id: this.room.id };
        },

        setBackground() {
            if (this.project.background === null) {
                return this.defaultBackground;
            } else return this.project.background.includes('https') ? `background-image: url(${this.project.background}) ` : `background: ${this.project.background}`;
        },
    },

    async created() {
        await this.fetchRoom();
        await this.fetchProject();
        this.pageLoading = false;
    },

    methods: {
        async fetchRoom() {
            try {
                this.room = await getRoomById(this.$route.params.roomId);
                this.room = { ...this.room, type: this.ENTITY_TYPES_NAMES.room };
                this.$store.dispatch('setContentFolders', this.addTypeForChild(this.room.room_folders, this.ENTITY_TYPES_NAMES.folder));
                this.$store.dispatch('setContentDocuments', this.addTypeForChild(this.room.room_documents, this.ENTITY_TYPES_NAMES.document));

            } catch (e) {
                console.log(e);
            }
        },

        async fetchProject() {
            this.project = await getProjectById(this.room?.project_id);
        },

        openModal(args) {
            this.$modal.show('change-bg-modal', args);
        },
    },
};
</script>

<style lang="scss" scoped>
.room-detail {
    margin-left: 13px;
    padding-bottom: rem(72px);

    &__left {
        width: 100%;
    }

    &__right {
        flex-shrink: 0;

        width: rem(430px);
        margin-left: rem(20px);
    }

    &-folders {
        min-height: rem(170px);
        padding-bottom: 0;

        &__item {
            display: inline-flex;

            width: 9.2rem;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;

            transition: background-color .2s;

            a {
                display: flex;
                flex-direction: column;
                align-items: center;

                width: 100%;
            }

            svg {
                width: rem(90px);
                margin-bottom: rem(5px);
            }

            span {
                width: 100%;

                font-family: Helvetica, sans-serif;
                font-size: rem(15px);
                text-align: center;
                overflow-wrap: break-word;
            }

            @media (hover: hover) {
                &:hover {
                    background: $hover-background;
                }
            }
        }
    }

    &-files {
        min-height: 35vh;
    }

    .project-detail-head {
        height: 170px;
        margin-bottom: 10px;
        position: relative;
        border-radius: 7px;
        background-size: cover;

        &__popup {
            cursor: pointer;
            padding: 6px 5px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.2);
            position: absolute;
            top: 6px;
            right: 10px;
        }

        &__tabs {
            position: absolute;
            bottom: 0;
            right: 29px;
        }

        &__info {
            position: absolute;
            display: flex;
            align-items: center;
            margin-left: 11px;
            gap: 0 17px;
            bottom: 31px;

            &--img {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 65px;
                width: 65px;
                background-color: $white;
                border-radius: 9px;

                p {
                    font-size: 24px;
                }
            }

            &--title {
                color: $white;
                font-size: 36px;
                font-weight: 600;
                line-height: 43px;
            }
        }
    }


    @include mobile_or_tablet {
        &__right {
            width: 100%;
            margin-left: 0;
        }

        &-folders {
            &__item {
                width: 33%;
                margin-bottom: 1rem;

                svg {
                    width: rem(80px);
                }
            }
        }
    }
}
</style>
