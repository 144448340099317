<template lang="pug">
    .three-dots-menu
        .three-dots-menu__dots(@click="toggle")
        .three-dots-menu__list(
            v-if="isOpen"
            v-click-outside="toggle"
            @click.stop="closeOnClick ? toggle() : false"
        )
            slot
</template>

<script>
export default {
    name: 'three-dots-menu',

    props: {
        closeOnClick: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isOpen: false,
    }),

    methods: {
        toggle() {
            if (this.isOpen) {
                this.isOpen = false;
            } else {
                setTimeout(() => {
                    this.isOpen = true;
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.three-dots-menu {
    position: relative;

    &__dots {
        width: rem(26px);
        height: rem(26px);

        background: url(../../assets/images/icons/threeDots.svg) no-repeat center center / rem(4px) auto;
        border-radius: rem(2px);

        cursor: pointer;
        transition: background-color .2s;

        &:hover {
            background-color: rgba($grey, 0.1);
        }
    }

    &__list {
        position: absolute;
        top: 0;
        right: 0;

        display: flex;
        flex-direction: column;

        background: #fff;
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.1);

        a {
            padding: rem(8px) rem(16px);

            font-size: rem(14px);
            white-space: nowrap;

            cursor: pointer;

            user-select: none;

            &:hover {
                background-color: rgba($grey, 0.1);
            }
        }
    }

    &--theme-horizontal {
        .three-dots-menu__dots {
            display: flex;
            height: rem(14px);
            width: rem(14px);
            background: url(../../assets/images/icons/threeDotsHorizontal.svg) no-repeat center center / auto rem(3px);
        }

        .three-dots-menu__list {
            background: $white;
            box-shadow: 0px 0px 9.8px rgba($black0, 0.1);
            border-radius: 9px;
            padding: rem(12px);

            z-index: 3;

            &::v-deep ul li + li {
                margin-top: rem(15px);
            }

            &::v-deep ul li {
                font-weight: 500;
                font-size: rem(13px);
                line-height: rem(16px);

                color: $black0;
            }
        }

        @include mobile_or_tablet {
            .three-dots-menu__dots {
                height: rem(32px);
                width: rem(32px);
                background: url(../../assets/images/icons/threeDotsHorizontal.svg) no-repeat center center / auto rem(7px);
            }
        }
    }
}
</style>
