<template lang="pug">
    .mobile-header(:class="classes")
        .container
            search-field.search-field(@openMenu="toggleMenu" :isMobile="true" @onList="openPopup")
        .container_open_header(:class="{'active': menuOpen}")
            .header-content-container(v-click-outside="close")
                .logo-header
                    router-link.logo(to="/"   )
                        img(src="@/assets/images/logo.svg" alt="ReSkript")
                mobile-menu(:class="{'mobile-menu--open': menuOpen}")
        changeLayoutPopup
</template>

<script>
import SearchField from '@/components/layouts/SearchField';
import MobileMenu from '@/components/layouts/MobileMenu';
import changeLayoutPopup from '@/components/layouts/toggle/changeLayoutPopup';

export default {
    name: 'mobile-header',

    components: {
        SearchField,
        MobileMenu,
        changeLayoutPopup,
    },

    data: () => ({
        searchOpen: false,
    }),

    computed: {
        menuOpen() {
          return this.$store.getters.getOpenMenu;
        },

        classes() {
            return {
                'menu-open': this.menuOpen,
                'search-open': this.searchOpen,
            };
        },
    },

    watch: {
        $route() {
            if (this.menuOpen) {
                this.toggleMenu();
            }
        },
    },

    methods: {
        toggleMenu() {
            this.setOpenMenu(!this.menuOpen);
            if (this.menuOpen) {
                window.disableScroll();
            } else {
                window.enableScroll();
            }
        },

        documentsType(payload) {
            this.$store.commit('updateDocumentsViewTypeCards', payload);
        },

        setOpenMenu(payload) {
          this.$store.commit('setOpenMenu', payload);
        },

        close() {
            this.setOpenMenu(false);
            window.enableScroll();
        },

        openPopup() {
          this.$modal.show('change-layout-popup');
        },
    },
};
</script>

<style scoped lang="scss">
.mobile-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: white;

    .mobile-menu {
        width: 290px;
        top: 8.5rem;
        height: 100vh;
    }

    .container {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .search-field {
            width: 100%;
            padding: 0 12px;
        }
    }

    .container_open_header {
        position: absolute;
        display: flex;
        align-items: flex-end;
        height: 100vh;
        width: 100%;
        top: 0;
        transform: translate3d(-100%, 0, 0);
        transition: transform .5s;

        &.active {
            transform: translate3d(0,0,0);
            background-color: rgba(191, 191, 191, .6);
        }
    }

    .burger, .logo {
        transition: width 0s .3s, opacity .3s .3s;
    }

    .burger {
        position: relative;

        width: 2rem;
        height: 2rem;

        span {
            position: absolute;
            right: 0;
            left: 0;

            height: 2px;

            background: $black;

            transform: translate3d(0, -50%, 0);
            transition: background-color .3s, transform .3s, top .2s .3s, opacity 0s .3s;

            &:nth-of-type(1) {
                top: calc(50% - 0.6rem);
            }

            &:nth-of-type(2) {
                top: 50%;
            }

            &:nth-of-type(3) {
                top: calc(50% + 0.6rem);
            }
        }
    }

    &.search-open {
        .burger, .logo {
            width: 0;

            opacity: 0;
            transition: 0s;
        }
    }

    &.menu-open {
        height: rem(140px);
        background-color: #fff;
        padding-bottom: 30px;
    }

    .header-content-container {
        width: 290px;
        height: 100vh;
        background-color: $white;
    }

    .logo-header {
        position: absolute;
        top: 83px;

        .logo {
            img {
                display: block;

                width: 146px;
                height: 1.5rem;
                margin-bottom: 30px;
            }
        }
    }
}
</style>
