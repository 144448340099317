<template lang="pug">
    .editor-title.editor-container
        emoji-modal(
            ref="emojiModal"
            @insertEmoji="insertEmoji"
        )
        button.editor-title__button(
            @click="toggleEmojiModal"
        ) {{ icon }}
        input.editor-title__input(
            v-model="state"
            :disabled="!publicLinkPermissions.includes('edit_content')"
            placeholder="Page Title"
            @change="renameDoc"
            :class="{defaultTitle: setTitleColor}"
        )
</template>

<script>
import { mapState } from 'vuex';
import EmojiModal from '@/components/other/EmojiModal';
import { updateDocument } from '@/js/api/requests/document';
import { getRandomEmoji } from '../other/EmojiModal.vue';

export default {
    name: 'editor-title',
    components: { EmojiModal },
    props: {
        value: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: '',
        },
        document: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            publicLinkPermissions: state => state.editor.permissions,
        }),

        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        setTitleColor() {
            return this.state.includes('New document');
        },
    },
    mounted() {
        this.checkIcon();
    },
    methods: {
        checkIcon() {
            if (!this.icon) {
                const emoji = getRandomEmoji();
                this.updateIcon(emoji);
            }
        },
        insertEmoji({ native }) {
            this.updateIcon(native);
            this.$refs.emojiModal.close();
        },
        async updateIcon(emoji) {
            let oldIcon = this.icon;
            this.$emit('update:icon', emoji);
            try {
                await updateDocument(this.document.id, { icon: emoji });
                this.$emit('update:document-icon', emoji);
            } catch {
                this.$emit('update:icon', oldIcon);
            }
        },
        async renameDoc() {
            try {
                await updateDocument(this.document.id, { name: this.state });
                this.$emit('update:document-name', this.state);
            } catch (e) {
                if (e.response.status === 422) {
                    this.state = this.document.name;
                    this.$awn.alert('The name has already been taken');
                } else {
                    this.$awn.alert('Something went wrong, please try again later');
                }
            }
        },
        toggleEmojiModal() {
            if (!this.publicLinkPermissions.includes('edit_content')) {
                return;
            }

            if (this.$refs.emojiModal.isOpen) {
                this.$refs.emojiModal.close();
            } else {
                this.$refs.emojiModal.open({
                    top: 100,
                    left: 100,
                    styles: {
                        position: 'absolute',
                        zIndex: 2,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.editor-title {
    position: relative;
    display: flex;
    padding: 35px 100px 0;
    grid-template-columns: 29px auto;
    font-size: 24px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 100px;
        right: 100px;
        height: 1px;
        background: #DADADA;
    }

    &__button {
        padding: 0;
        font-size: inherit;
        cursor: pointer;
    }

    &__input {
        padding: 12px;
        font-size: inherit;
        color: $black;
        font-weight: 600;
        width: 100%;

        &::placeholder {
            color: #B1B1B1;
        }
    }

    .defaultTitle {
        color: $grey-dark7;
    }
}

@include mobile_or_P {
    .editor-title {
        padding: 35px 40px 0;

        &::after {
            left: 40px;
            right: 40px;
        }
    }
}
</style>
