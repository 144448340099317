import Vue from 'vue';
import router from '../router';
import {
    deleteMemberReq,
    getMembersReq,
    leaveTeamReq,
    sendInviteReq,
} from '@/js/api/requests/team';
import { shareInvite } from '@/js/api/requests/common';

const vue = new Vue();

export default {
    state: () => ({
        members: [],
    }),

    mutations: {
        setMembers(state, data) {
            state.members = data;
        },
    },

    getters: {
        getMembers: (state) => state.members,
    },

    actions: {
        async leaveTeam(data) {
            try {
                await leaveTeamReq();
                vue.$modal.hide('leave-team-popup');
                if (data?.billing) {
                    router.replace('/checkout');
                }
                vue.$awn.success('You successful leave team');
            } catch (e) {
                console.log(e);
                vue.$modal.hide('leave-team-popup');
                vue.$modal.hide('invite-members-popup');
                vue.$awn.alert('Error leave team');
            }
        },

        async removeMember({ dispatch, getters }, data) {
            try {
                await deleteMemberReq(data);
                vue.$modal.hide('leave-team-popup');
                vue.$awn.success('You successful remove member');
            } catch (e) {
                console.log(e);
                vue.$awn.alert('Error remove member');
            } finally {
                dispatch('updateMembers', getters.user);
            }
        },

        async updateMembers({ commit }, data) {
            let response = [];
            try {
                response = await getMembersReq(data);
                commit('setMembers', response);
                vue.$awn.success('Your team list was updated successfully');
            } catch (e) {
                console.log(e);
                vue.$awn.alert('Error update list');
            }
        },

        async sendInvite({ dispatch, getters }, data) {
            if (data.type) {
                try {
                    await shareInvite({
                        entity_id: data.id,
                        entity_type: data.type,
                        invited_emails: [data.email],
                        inviter_name: data.user,
                        role: 'member',
                    });
                    vue.$awn.success('You have sent the invite successfully ');

                } catch (e) {
                    vue.$awn.alert('Error while sending invite');
                }
            } else {
                try {
                    await sendInviteReq(data);
                    vue.$awn.success('You have sent the invite successfully ');

                } catch (e) {
                    vue.$awn.alert('Error while sending invite');
                }
            }
            dispatch('updateMembers', getters.user);

        },
    },
};
