import { get, post, put } from '@/js/api/http-helper';

export function getUser(token) {
    return get('/user', {}, {
        headers: { Authorization: `${token.token_type} ${token.access_token}` },
    });
}

export async function authorization(fd) {
    const data = await post(`/authorization`, fd);
    return data.data;
}

export async function signIn(fd) {
    const data = await post(`/signIn`, fd);
    return data.data;
}

export function loggedIn() {
    return post('/user/logged');
}

export function updateUser(fd) {
    return post(`/user/update`, fd);
}

export function updateUserPassword(fd) {
    return put(`/user/update/password`, fd);
}

export function resetPassword(fd) {
    return post(`/send/email/for/reset-password`, fd);
}

export function refreshPassword(fd) {
    return post(`/refresh-password`, fd);
}

export async function cardLast() {
    const data = await get(`/card/last`);
    return data.data;
}

export async function cardLastUpdate(fd) {
    const data = await post(`/card/last/update`, fd);
    return data.data;
}
