<template>
    <div
        v-show="isOpen"
        class="changeBackgroundModal"
        :style="{ top: topOffset - 50 + 'px',right: -140 + 'px'}"
    >
        <div class="changeBackgroundModal__container">
            <div
                v-for="(color, i) in colors"
                :key="i"
                class="changeBackgroundModal__container--color"
                :style="{backgroundColor: color}"
                @click="changeColor(color)"
            >
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'change-background-modal',
    data: () => ({
        isOpen: false,
        id: null,
        topOffset: 0,
        colors: [
            '#F8F8F8',
            '#F4F5F6',
            '#F8F8F9',
            '#F6F6F6',
            '#ECECFD',
            '#EDF3FE',
            '#EEF9FD',
            '#EFFBF6',
            '#F8ECFE',
            '#FEECEC',
            '#FEF7EC',
            '#FEFCEC',
            '#FEECF7',
        ],
    }),

    mounted() {
        setTimeout(this.listenChangeBackground,1000);
    },

    methods: {
      listenChangeBackground() {
          document.addEventListener('changeBackground', (event) => {
              this.isOpen = true;
              this.id = event.detail.id;
              this.topOffset = event.detail.top;
          });
      },

      changeColor(color) {
          const event = new CustomEvent('changeBackgroundCallout', { bubbles: true, detail: { color: color, id: this.id } } );
          document.dispatchEvent(event);
          this.isOpen = false;
      },

    },
};
</script>

<style scoped lang="scss">
 .changeBackgroundModal {
     position: absolute;
     right: 20px;
     background: $white;
     box-shadow: 0 0 29px rgba(0, 0, 0, 0.15);
     border-radius: 6px;
     z-index: 10000;

     &__container {
         display: flex;
         flex-wrap: wrap;
         gap: 10px;
         max-width: 240px;
         padding: 20px;

         &--color {
             min-height: 20px;
             min-width: 20px;
             border-radius: 50%;
         }
     }
 }
</style>
