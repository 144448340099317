
export default {
  methods: {
       moveTo(e, doc, currentElemType ) {
          if (currentElemType === 'document' || currentElemType === undefined) {
              this.$refs.moveToFoldersContext.open(doc);
              this.$refs.moveToFoldersContext.$off('moveSuccess');
              this.$refs.moveToFoldersContext.open(e, doc);
              this.$refs.moveToFoldersContext.$on('moveSuccess', ({ entityType, entityId }) => {
                  if (this.deleteDocumentAfterMove) {
                      if (this.contentFromStore) {
                          this.$store.dispatch('removeContentDocument', doc.id);
                      } else {
                          this.$emit('update:documents', this.documents.filter(d => d.id !== doc.id));
                      }
                  } else {
                      doc.entity_type = entityType;
                      doc.entity_id = entityId;
                  }
              });
          }
          else if ( this.currentElemType === 'folder') {
              this.$refs.moveToFoldersContext.open(e, doc, false);
              this.$refs.moveToFoldersContext.$off('moveSuccess');
              this.$refs.moveToFoldersContext.$on('moveSuccess', () => {
                  this.removeFolderForContextMenu(doc);
              });
          }
      },
  },
};


