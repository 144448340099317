import { render, staticRenderFns } from "./EditorLinkMenu.vue?vue&type=template&id=080997f8&scoped=true&lang=pug&"
import script from "./EditorLinkMenu.vue?vue&type=script&lang=js&"
export * from "./EditorLinkMenu.vue?vue&type=script&lang=js&"
import style0 from "./EditorLinkMenu.vue?vue&type=style&index=0&id=080997f8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080997f8",
  null
  
)

export default component.exports