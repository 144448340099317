export default {
    methods: {
        domElNotInViewportTopShift(top, el) {
            let topShift = 0;
            if (el) {
                const rect = el.getBoundingClientRect();
                const windowH = (window.innerHeight || document.documentElement.clientHeight);
                const notInViewport = !(
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= windowH &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
                if (notInViewport) {
                    topShift = Math.abs(rect.bottom -  windowH);
                }
            }

            return top - topShift;
        },
    },
};
