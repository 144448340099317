<template lang="pug">
    app-modal(
        name="create-project-popup"
        @closed="closed"
    )
        form(@submit.prevent="submit")
            h3 Create new project
            app-text-field(
                v-model="name"
                label="Project name"
                :error="nameError"
            )
            .app-modal__bottom
                app-button(:loading="loading") Save
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createProject } from '@/js/api/requests/project';

export default {
    name: 'create-project-popup',

    data: () => ({
        name: '',
        loading: false,
    }),

    validations: {
        name: { required },
    },

    computed: {
        nameError() {
            if (this.$v.$dirty && !this.$v.name.required) {
                return 'Required field';
            }
            return null;
        },
    },

    methods: {
        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const randomEmoji = ['😀', '😃', '😄', '😁', '😅', '😆', '😍', '🥰', '😇', '💬'];

            function getRandomEmoji() {
                const idx = Math.round(Math.random() * 10);
                return randomEmoji[idx];
            }

            if (this.loading) return;

            this.loading = true;

            const fd = new FormData;
            fd.append('name', this.name);
            fd.append('background', 'linear-gradient(100.24deg, #5B69FF 0%, #FF59F8 51.04%, #FFAC58 100%);');
            fd.append('image', getRandomEmoji());

            try {
                createProject(fd).then(v => {
                    let project = v;
                    this.$modal.hide('create-project-popup');
                    this.$router.push(`/projects/${project.id}`);
                });
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            } finally {
                this.loading = false;
            }
        },

        closed() {
            this.name = '';
            this.$v.$reset();
        },
    },
};
</script>
