<template lang="pug">
    plans(:registrationPage="true" :btnClick="btnClick" :btnClasses="btnClasses" :btnDisabled="btnDisabled" :btnText="btnText")
</template>

<script>
import Plans from '@/components/other/plans/Plans';
import { PLANS_NAMES } from '@/js/const';

export default {
    name: 'plans-for-registration',

    components: {
        Plans,
    },

    props: {
        nextStep: {
            type: Function,
            required: true,
        },
    },

    computed: {
        planPeriodSwitcher() {
            return this.$store.getters.planPeriodSwitcher;
        },
    },

    methods: {
        btnClick(plan) {
            if (plan.name === PLANS_NAMES.PRO) {
                this.$store.commit('setPlanSelectedForCheckout', { name: plan.name + '_' + this.planPeriodSwitcher });
            } else {
                this.$store.commit('setPlanSelectedForCheckout', null);
            }
            this.nextStep();
        },
        // eslint-disable-next-line no-unused-vars
        btnClasses(plan) {
            return '';
        },
        // eslint-disable-next-line no-unused-vars
        btnDisabled(plan) {
            return false;
        },
        // eslint-disable-next-line no-unused-vars
        btnText(plan) {
            return 'Start';
        },
    },
};
</script>
