import Quill from 'quill';
import EllipsesIcon from '@/assets/images/icons/ellipses.svg';

const Embed = Quill.import('blots/embed');

// Как использовать: quill.insertEmbed(index, 'link-embed', { ...anyData }, Quill.sources.USER)
class LinkEmbed extends Embed {
    // Элементы дропдауна
    static dropdownItems = [
        {
            text: 'Open Link',
            customEvent: (close, node) => {
                return () => {
                    node.children[0].firstChild.click();
                    close();
                };
            },
        },
        {
            text: 'Transform to text',
            customEvent: (close, node) => {
                return () => {
                    const link = node.getAttribute('data-link');
                    const linkFromEmbed = document.createElement('a');
                    linkFromEmbed.href = link;
                    linkFromEmbed.innerText = link;
                    node.before(linkFromEmbed);
                    close();
                    node.remove();
                };
            },
        },
        {
            text: 'Delete',
            customEvent: (close, node) => {
                return () => {
                    node.remove();
                    close();
                };
            },
        },
    ];

    // Не используется
    constructor(...args) {
        super(...args);
    }

    // Метод, вызываемый при инициализации модуля
    static create(data = {}) {
        const node = super.create();
        const dropdown = this.createDropdown(node);
        const button = this.createButton(dropdown);
        const container = this.createMainContainer(data.link);

        this.createGrid(container, data);

        container.append(button);
        container.append(dropdown);
        node.append(container);

        this.saveData(node, data);
        node.addEventListener('click', () => {
            window.open(`${node.getAttribute('data-link')}`);
        });
        return node;
    }

    // Сохранение данных, чтобы потом получить их при перезагрузке страницы
    static saveData(node, data) {
        node.setAttribute('data-title', data.title);
        node.setAttribute('data-description', data.description);
        node.setAttribute('data-image', data.miniature);
        node.setAttribute('data-link', data.link);
        node.setAttribute('data-favicon', data.favicon);
    }

    // Создание главного родителя / Ссылки
    static createMainContainer(link) {
        const node = document.createElement('a');
        node.setAttribute('href', link);
        node.setAttribute('target', '_blank');
        node.className = 'ql-link-embed__container';
        node.addEventListener('click', (event) => {
            if (event.target.dataset.notLlink) {
                event.preventDefault();
            }
        });
        return node;
    }

    // Создание верстки всего блока
    static createGrid(parent, data) {
        const content = this.createContent(data);
        const preview = this.createPreview(data.miniature);

        parent.append(content, preview);
    }

    // Создание блока с заголовком и основной информацией
    static createContent(data) {
        const node = document.createElement('div');
        node.className = 'ql-link-embed__content';
        const title = this.createTitle(data.title);
        const description = this.createDescription(data.description);
        const footer = this.createFooter(data);
        node.append(title, description, footer);
        return node;
    }

    static createPreview(image = '') {
        const node = document.createElement('div');
        node.className = 'ql-link-embed__preview';
        if (image) {
            node.style.background = `url(${image}) center no-repeat`;
            node.style.backgroundSize = 'cover';
        } else {
            node.classList.add('ql-link-embed__preview--plug');
        }
        return node;
    }

    static createTitle(content = 'Title of the website goes here...') {
        const node = document.createElement('p');
        node.textContent = content;
        node.className = 'ql-link-embed__title';
        return node;
    }

    static createDescription(
        content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec libero a tellus lacinia mattis. Vestibulum dignissim, nisl vitae dictum cursus, mi erat egestas neque, sit amet imperdiet lacus orci vel est. Morbi...',
    ) {
        const node = document.createElement('p');
        node.textContent = content;
        node.className = 'ql-link-embed__description';
        return node;
    }

    static createFooter(data) {
        const container = document.createElement('div');
        container.className = 'ql-link-embed__footer';
        const favicon = this.createFavicon(data.favicon);
        const link = this.createFooterLink(data.link);
        container.append(favicon, link);
        return container;
    }

    static createFavicon(image = '') {
        const node = document.createElement('div');
        node.className = 'ql-link-embed__favicon';
        if (image) {
            node.style.background = `url(${image}) center no-repeat`;
            node.style.backgroundSize = 'cover';
        } else {
            node.classList.add('ql-link-embed__favicon--plug');
        }
        return node;
    }

    static createFooterLink(content = 'website link...') {
        const node = document.createElement('p');
        node.className = 'ql-link-embed__link';
        node.textContent = content;
        return node;
    }

    static createButton(dropdown) {
        const node = document.createElement('button');
        node.setAttribute('type', 'button');
        node.setAttribute('data-not-llink', true);
        node.style.background = `rgba(0, 0, 0, 0.5) url(${EllipsesIcon}) center no-repeat`;
        node.className = 'ql-link-embed__button';
        node.addEventListener('click', () =>
            dropdown.classList.toggle('ql-link-embed__dropdown--opened'),
        );
        return node;
    }

    static createDropdown(mainNode) {
        const node = document.createElement('ul');
        node.className = 'ql-link-embed__dropdown';
        node.setAttribute('data-not-llink', true);

        this.dropdownItems.forEach(({ text, customEvent }) => {
            const item = this.createDropdownItem(
                text,
                customEvent(
                    () => node.classList.remove('ql-link-embed__dropdown--opened'),
                    mainNode,
                ),
            );
            node.append(item);
        });

        return node;
    }

    static createDropdownItem(content = 'default text', customEvent) {
        const node = document.createElement('li');
        node.className = 'ql-link-embed__dropdown-item';

        const button = document.createElement('button');
        button.setAttribute('type', 'button');
        button.setAttribute('data-not-llink', true);
        button.className = 'ql-link-embed__dropdown-button';
        button.textContent = content;
        if (customEvent) {
            button.addEventListener('click', customEvent);
        }

        node.append(button);

        return node;
    }

    // При перезагрузке страницы, метод create получит эти данные аргументом
    static value(node) {
        return {
            title: node.getAttribute('data-title'),
            description: node.getAttribute('data-description'),
            miniature: node.getAttribute('data-image'),
            link: node.getAttribute('data-link'),
            favicon: node.getAttribute('data-favicon'),
        };
    }
}

LinkEmbed.blotName = 'link-embed';
LinkEmbed.className = 'ql-link-embed';
LinkEmbed.tagName = 'div';

Quill.register(LinkEmbed);
