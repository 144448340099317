import {
    createPublicDocumentLinkByDocumentId,
    getPublicDocumentLinkInfoByLinkHash,
    getPublicDocumentLinkSettingsByDocumentId,
    getPublicDocumentLinkToken,
} from '@/js/api/requests/document';

export default {
    state: () => ({
        documentId: null,
        isPublicLink: false,
        toggledPublishToWeb: false,
        publishToWebSettings: {
            accessNested: false, // 'access_nested' permission
            allowEdit: false, // 'edit_content' permission
            allowDuplicate: false, // 'duplicate' permission
            allowSearchEngineIndexing: false,
            allowComments: false, // 'comment' permission
            showComments: false, // 'view_comments' permission
            showTitle: true, // 'view_title' permission
            showAuthor: false, // 'view_author' permission
            showBadge: false, // 'view_badge' permission
        },
        permissions: [
            // 'access_nested',
            // 'edit_content',
            // 'duplicate',
            // 'view_comments',
            // 'comment',
            // 'view_author',
            // 'view_title',
            // 'view_badge',
        ],
    }),

    actions: {
        async createPublicDocumentLink({ state }, documentId) {
            return await createPublicDocumentLinkByDocumentId({
                documentId,
                payload: state.publishToWebSettings,
            });
        },

        async getPublicDocumentLinkSettings({ commit }, payload) {
            const { data: { options } } = await getPublicDocumentLinkSettingsByDocumentId(payload);

            for (const [key, value] of Object.entries(options)) {
                commit('setPublicDocumentLinkSetting', { key, value });
            }
        },

        async getPublicDocumentLinkToken({ commit }, payload) {
            const token = await getPublicDocumentLinkToken(payload);
            commit('setXAccessToken', token.access_token);
        },

        async getDocumentId({ commit }, payload) {
            if (payload.params.idSubDoc) {
                commit('setDocumentId', payload.params.idSubDoc);
                commit('setIsPublicLink', true);

                return;
            }

            if (payload.params.linkHash) {
                const documentInfo = await getPublicDocumentLinkInfoByLinkHash(payload.params.linkHash);

                commit('setDocumentId', documentInfo.entity_id);
                commit('setIsPublicLink', true);

                return;
            }

            commit('setDocumentId', payload.params.id);
        },
    },

    mutations: {
        setDocumentId(state, value) {
            state.documentId = value;
        },

        setIsPublicLink(state, value) {
            state.isPublicLink = value;
        },

        setToggledPublishToWeb(state, value) {
            state.toggledPublishToWeb = value;
        },

        setPublicDocumentLinkSetting(state, { key, value }) {
            state.publishToWebSettings[key] = value;
        },

        setPermissions(state, value) {
            state.permissions = value;
        },
    },
};
