import { updateDocument } from '@/js/api/requests/document';

export default {
    methods: {
        rename(doc, currentElemType) {
            this.$modal.show('rename-popup', {
                entity: doc,
                entityType: currentElemType,
                successCallback: async (name, icon) => {
                    if (currentElemType === 'document' || currentElemType === undefined) {
                        const params = { id: doc.id, name, icon };
                        if (this.contentFromStore) {
                            this.$store.dispatch('setContentNameDocument', params);
                            await updateDocument(doc.id, { name: name, icon: icon });
                        } else {
                            const cloneArr = [...this.documents];
                            const obj = cloneArr.find((d) => d.id === doc.id);
                            if (obj) {
                                obj.name = name;
                            }
                            this.$emit('update:documents', cloneArr);
                            await updateDocument(doc.id, { name: name, icon: icon });
                            this.pageLoading = true;

                            if (this.isDashboard) {
                                try {
                                    await this.fetchDashboard();
                                } catch (e) {
                                    this.$emit('update');
                                }
                            } else {
                                await this.fetchFoldersAndDocument();
                            }
                        }
                    } else if (this.currentElemType === 'folder') {
                        await this.updateFolder(doc.id, { name: name, icon: icon });
                    }
                },
            });
        },
    },
};
