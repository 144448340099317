<template lang="pug">
    .checkout(v-if="user")
        .checkout__header
            logo
            router-link(to="/")
                img(src="@/assets/images/icons/closer_2.svg" class="checkout__close")
        .checkout__container
            .checkout__main
                app-button.plan-checkout__btn.plan-checkout__btn-mobile(@click="submit" :loading="loading" :disabled="!subscriptionData.validPaymentForm")
                    credit-icon(class="credit-icon")
                    span Pay and upgrade
                .checkout__lb
                    .block-standart.block-standart-billing-form
                        billing-form(:formData="subscriptionData")
                    .block-standart
                        payment-method(:formData="subscriptionData")
                .checkout__rb
                    plan-checkout(:subscriptionData="subscriptionData" :loading="loading")
                    coupon(:subscriptionData="subscriptionData" @updateDataForm="updateDataForm")
</template>

<script>
import Logo from '@/components/layouts/Logo';
import BillingForm from '@/components/other/settings/BillingForm';
import PaymentMethod from '@/components/other/plans/PaymentMethod';
import Coupon from '@/components/other/plans/Coupon';
import PlanCheckout from '@/components/other/plans/PlanCheckout';
import CreditIcon from '@/assets/images/icons/credit-card2.svg?inline';
// eslint-disable-next-line no-unused-vars
import { tariffSubscription, tariffDiscount } from '@/js/api/requests/plans';

export default {
    name: 'checkout',

    components: {
        Logo,
        BillingForm,
        PaymentMethod,
        PlanCheckout,
        CreditIcon,
        Coupon,
    },

    data() {
        return {
            subscriptionData: {
                couponId: '',
                couponCode: '',
                price: '',
                discount_price: '',
                price_difference: '',
                price_difference_percent: '',

                nameOnCard: '',
                cardNumber: '',
                monthAndYear: '',
                cvc: '',

                validPaymentForm: false,

                nameOfCompany: '',
                address: '',
                country: '',
                city: '',
                postalCode: '',
                vatNumber: '',
                validBillingForm: false,
            },

            loading: false,
        };
    },

    provide() {
        return {
            submit: this.submit,
        };
    },

    computed: {
        tariff() {
            return this.$store.getters.planSelectedForCheckout;
        },
        user() {
            return this.$store.getters.user;
        },
    },

    created() {
        if (!this.tariff || !this.user) {
            this.$router.push({ name: 'Dashboard' });
        } else {
            //TO DO paymant в юзере не храним, но мб потом введём какие-то поля
            this.subscriptionData.nameOnCard = this.user.name_on_card ?? '';
            this.subscriptionData.cardNumber = this.user.card_number ?? '';
            if (this.user.exp_month && this.user.exp_year) {
                this.subscriptionData.monthAndYear = this.user.exp_month + '/' + this.user.exp_year;
            }
            this.subscriptionData.monthAndYear = '';
            this.subscriptionData.cvc = this.user.cvc ?? '';
            //

            this.subscriptionData.nameOfCompany = this.user.company ?? '';
            this.subscriptionData.address = this.user.address ?? '';
            this.subscriptionData.country = this.user.country;
            this.subscriptionData.city = this.user.city ?? '';
            // this.phone = this.user.phone ?? '';
            this.subscriptionData.postalCode = this.user.zip ?? '';
            this.subscriptionData.vatNumber = this.user.eu_vat ?? '';
        }
    },

    methods: {
        async updateDataForm(data) {
            this.subscriptionData = { ...this.subscriptionData, ...data };
        },
        async submit() {
            if (this.loading) return;

            this.loading = true;

            try {
                const card_number = Number(this.subscriptionData.cardNumber.replace(/\s+/g, ''));
                const monthAndYearArr = this.subscriptionData.monthAndYear.split('/');
                const exp_month = Number(monthAndYearArr[0]);
                const exp_year = Number(monthAndYearArr[1]);
                const tariff = { ...this.tariff };

                const data = {
                    card_number,
                    exp_month,
                    exp_year,
                    cvc: Number(this.subscriptionData.cvc),
                    name_on_card: this.subscriptionData.nameOnCard,

                    company: this.subscriptionData.nameOfCompany,
                    country: this.subscriptionData.country,
                    city: this.subscriptionData.city,
                    address: this.subscriptionData.address,
                    zip: this.subscriptionData.postalCode,
                    eu_vat: this.subscriptionData.vatNumber,

                    tariff,
                };

                if (this.subscriptionData.couponId) {
                    data.coupon_id = this.subscriptionData.couponId;
                }

                await tariffSubscription(data);

                this.$store.dispatch('fetchUser', {});

                this.$store.commit('setPlanSelectedForCheckout');
                this.$awn.success(`Pay tariff success`);
                this.$router.push({ name: 'Dashboard' });

            } catch (e) {
                this.$awn.alert(e.response?.data?.data ? e.response.data.data : 'Problems on the server, or incorrect data or insufficient funds');
            } finally {
                this.loading = false;
            }
        },
    },

};
</script>

<style lang="scss">
.checkout {
    height: 100%;
    background: $grey-light14;
    @media (max-width: 1200px) {
        background: $white;
    }
    .save-billing {
        margin-top: rem(49px);
        @media (max-width: 1200px) {
            margin-top: rem(25px);
        }
    }
    .billing {
        .title {
            margin-bottom: rem(21px);
            font-size: rem(18px);
            @media (max-width: 1200px) {
                margin-bottom: rem(24px);
            }
        }
        .app-text-field__label {
            font-size: rem(14px) !important;
        }
        .app-text-field__input {
            font-size: rem(12px) !important;
        }
        .multiselect__single {
            font-size: rem(12px) !important;
        }
    }
    &__close {
        width: rem(15px);
        height: rem(15px);
    }
    &__header {
        padding: rem(13px) rem(23px) rem(15px) rem(14px);
        border-bottom: 1px $grey-light13 solid;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $white;
        @media (max-width: 1200px) {
            border-bottom: none;
            padding-top: rem(60px);
            padding-bottom: rem(20px);
        }
    }
    .header-logo {
        @media (max-width: 1200px) {
            height: rem(21px);
        }
    }
    &__container {
        padding-top: rem(22px);
        padding-bottom: rem(11px);
        @media (max-width: 1200px) {
            padding-top: rem(0px);
            margin: rem(0px) rem(7px);
        }
    }
    &__main {
        width: rem(1262px);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1200px) {
            width: 100%;
            flex-direction: column-reverse;
        }
    }
    &__lb {
        width: 64%;
        @media (max-width: 1200px) {
            width: 100%;
        }
        .block-standart {
            width: 100%;
            @media (max-width: 1200px) {
                max-width: rem(600px);
                display: block;
                margin: 0 auto;
                background: $grey-light20;
                box-shadow: none;
                padding: rem(24px) rem(10px);
            }
        }
        .block-standart-billing-form {
            margin-bottom: rem(25px);
        }
    }
    &__rb {
        width: 32%;
        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    .checkout__container {
        .name-of-company, .phone {
            width: rem(391px) !important;
            @media (max-width: 1200px) {
                width: 100% !important;
            }
        }
        .address {
            width: 100% !important;
            max-width: rem(680px);
            @media (max-width: 1200px) {
                width: 100% !important;
            }
        }
        .country {
            width: rem(153px) !important;
            @media (max-width: 1200px) {
                width: 46% !important;
            }
        }
        .app-input.city {
            width: rem(160px) !important;
            @media (max-width: 1200px) {
                width: 48% !important;
            }
        }
        .post-code {
            width: rem(160px) !important;
            @media (max-width: 1200px) {
                width: 100% !important;
            }
        }
        .have-vat {
            width: 100% !important;
            margin-bottom: rem(14px);
        }
        .vat-number {
            width: rem(244px) !important;
            @media (max-width: 1200px) {
                width: 100% !important;
            }
        }
    }
}
.plan-checkout__btn {
    font-weight: 500;
    display: flex !important;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
    margin-top: rem(11px);
    min-height: rem(52px) !important;

    span {
        font-size: rem(18px);
    }

    svg {
        width: rem(20px);
        height: rem(15px);
        margin-right: rem(7px);
        display: inline-block;
        path {
            fill: $white;
        }
    }

    &-pc {
        margin-top: rem(88px);
        min-width: auto;
        max-width: rem(342px);
        width: 100%;
        @media (max-width: 1200px) {
            display: none;
        }
    }

    &-mobile {
        display: none !important;
        width: 100%;
        @media (max-width: 1200px) {
            display: block !important;
            margin: 0 auto;
            width: 100%;
            max-width: rem(400px);
            margin-top: rem(47px);
            margin-bottom: rem(104px);
        }
    }

    &.app-button--disabled {
        color: $grey-light17;
        background: $grey-light18;
        svg {
            path {
                fill: $grey-light19;
            }
        }
    }

    &.app-button--loading {
        color: transparent;

        svg {
            width: rem(30px);
            height: rem(30px);
        }

        .credit-icon {
            display: none;
        }
    }
}
</style>
