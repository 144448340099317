<template lang="pug">
    app-modal.actions-popup(
        name="dashboard-actions-popup"
        @closed="close"
        @beforeOpen="beforeOpen"
    )
        .container
            .action(@click="share(doc)")
                add-members-icon.icon
                p Share
            .action(@click="openExportPopup()")
                export-icon.icon
                p Export
                arrow-right-icon.arrow
            .action(@click="rename(doc)")
                edit-icon.icon
                p Edit
            .action(@click="openMoveToPopup()")
                move-to-document-icon.icon
                p Move To
            .action(@click="duplicate(doc)")
                duplicate-icon.icon
                p Duplicate
            .action(@click="deleteItem(doc, currentElemType)")
                delete-icon.icon
                p Delete {{ ref }}


</template>

<script>

import addMembersIcon from '@/assets/images/icons/add_members.svg?inline';
import exportIcon from '@/assets/images/icons/export.svg?inline';
import editIcon from '@/assets/images/icons/edit_2.svg?inline';
import moveToDocumentIcon from '@/assets/images/icons/move_to_document.svg?inline';
import duplicateIcon from '@/assets/images/icons/dublicate.svg?inline';
import deleteIcon from '@/assets/images/icons/delete_2.svg?inline';
import arrowRightIcon from '@/assets/images/icons/arrow_right.svg?inline';

import MoveToMixin from '@/js/mainContextMenu/moveToMixin';
import renameMixin from '@/js/mainContextMenu/renameMixin';
import downloadMixin from '@/js/mainContextMenu/downloadMixin';
import shareMixin from '@/js/mainContextMenu/shareMixin';
import deleteMixin from '@/js/mainContextMenu/deleteMixin';
import duplicateMixin from '@/js/mainContextMenu/duplicateMixin';

export default {
    name: 'dashboard-actions-popup',

    components: {
        addMembersIcon,
        exportIcon,
        editIcon,
        moveToDocumentIcon,
        duplicateIcon,
        deleteIcon,
        arrowRightIcon,
    },

    mixins: [ MoveToMixin, renameMixin,downloadMixin, shareMixin, deleteMixin, duplicateMixin],

    data: () => ({
        id: null,
        doc: null,
        currentElemType: null,
        ref: null,
    }),

    methods: {


        close() {
            this.$modal.hide('dashboard-actions-popup');
        },

        openExportPopup() {
            this.$modal.show('export-document-popup', { doc: this.doc });
        },
        openMoveToPopup() {
            this.$modal.show('move-to-folder-popup', { entity: this.doc });
        },

        async beforeOpen(e) {
            this.id = e.params?.id;
            this.doc = e.params?.doc;
            this.ref = e.params?.ref;
            this.currentElemType = e.params?.doc.type;
        },

    },
};
</script>

<style scoped lang="scss">
.actions-popup {
    padding: 11px 15px;


    @include mobile_or_tablet {
        &::v-deep .app-modal__container {
            width: rem(700px);
            padding: 0;
        }

        &::v-deep {
            .vm--modal {
                position: absolute;
                bottom: 0 !important;
                top: auto !important;
                left: 0 !important;

                border-radius: rem(20px) rem(20px) 0 0;

                transform: initial;
                opacity: 1;
            }

            .vm--overlay {
                background-color: rgba($black, 0.6);
            }

            .vm-transition--overlay {
                &-enter-active,
                &-leave-active {
                    transition: opacity .5s;
                }

                &-enter,
                &-leave-to {
                    opacity: 0;
                }
            }

            .vm-transition--modal {
                &-enter-active,
                &-leave-active {
                    transition: transform .5s;
                }

                &-enter,
                &-leave-to {
                    transform: translateY(100%);
                }
            }

            .app-modal__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 100vw;
                height: 100%;
            }
        }

        .container {
            padding: 38px;
            background-color: $white;

            .action {
                display: flex;
                align-items: center;

                .icon {
                    width: 26px !important;
                    height: 26px !important;
                }

                .arrow {
                    width: 8px;
                    height: 18px;
                    margin-left: auto;
                }

                p {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    margin-left: 25px;
                }

                &:not(:first-child) {
                    margin-top: 31px;
                }
            }
        }
    }
}
</style>
