import { get, post, put, del } from '@/js/api/http-helper';

const backendUrl = process.env.VUE_APP_EDITOR_BACKEND_URL;
export function getDocumentInfo({ documentId }) {
    return get(`${backendUrl}/api/doc/getDocumentInfo?documentId=${documentId}`);
}

export function getSnapshots(documentId) {
    return get(`${backendUrl}/api/snapshot/findByDoc?documentId=${documentId}`);
}

export function getSnapshotById(snapshotId) {
    return get(`${backendUrl}/api/snapshot/${ snapshotId }`);
}

export function restoreSnapshot(documentId, snapshotId = null, newDocumentid) {
    const fd = {
        documentId: documentId,
        snapshotId: snapshotId,
        createNew: newDocumentid,
    };
    return post(`${backendUrl}/api/snapshot/restore`, fd);
}

export function duplicateSnapshot(documentId, createNew, snapshotId) {
    const fd = {
        documentId,
        createNew,
    };
    if (snapshotId) {
        fd.snapshotId = snapshotId;
    }
    return post(`${backendUrl}/api/snapshot/restore`, fd);
}

export function createSnapshot(documentId, fullName, color) {
    const fd = {
        documentId,
        author: {
            fullName,
            color,
        },
    };
    return post(`${backendUrl}/api/snapshot`, fd);
}

export function renameSnapshot(id, name) {
    const fd = { title: name };
    return put(`${backendUrl}/api/snapshot/${id}`, fd);
}

export function getComments(documentId) {
    return get(`${backendUrl}/api/comment?documentId=${documentId}`);
}

export async function getCommentMessages(id) {
    const data = await get(`${backendUrl}/api/comment/${id}`);
    return data[0];
}

export async function deleteMessage(id) {
    const data = await del(`${backendUrl}/api/message/${id}`);
    return data[0];
}

export function exportDocumentAsDocx(documentId) {
    return get(`${backendUrl}/api/files/export/${documentId}`, {},{ responseType: 'blob' });
}

export function exportDocumentAsPdf(documentId) {
    return get(`${backendUrl}/api/files/export/pdf/${documentId}`, {},{ responseType: 'blob' });
}

export function exportDocumentAsHTML(documentId) {
    return get(`${backendUrl}/api/files/export/html/${documentId}`, {},{ responseType: 'blob' });
}

export function importDocument(userId, file, name, entity_type, entity_id) {
    const fd = new FormData;
    fd.append('file', file);
    fd.append('name', name);
    if (entity_type) {
        fd.append(entity_type, entity_id);
    }
    return post(`${backendUrl}/api/files/import/document/${userId}`, fd);
}
