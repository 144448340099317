<template>
    <div
        :style="setBackground"
        class="project-detail-head"
    >
        <div
            class="project-detail-head__popup"
            @click="openModal({projectId: project.id, tabs: popupTabsBg, activeTab: 'gradients', height: '58px'})"
        >
            <change-bg-icon />
        </div>
        <div class="project-detail-head__info">
            <div
                class="project-detail-head__info--img"
                @click="openModal({projectId: project.id, tabs: popupTabsIcon, activeTab: 'emoji'})"
            >
                <p>{{ setEmoji }}</p>
            </div>
            <div class="project-detail-head__info--title">
                <input
                    v-model="projectName"
                    @blur="setNewTitle(projectName)"
                />
            </div>
        </div>
        <app-horizontal-tabs
            :tabs="tabs"
            :value="activeTabId"
            class="project-detail-head__tabs"
            inactive
            @change="changeTab"
        />
    </div>
</template>

<script>
import changeBgIcon from '@/assets/images/icons/changeBg.svg?inline';
import appHorizontalTabs from '@/components/app/app-horizontal-tabs';
import tabsMixin from '@/mixins/TabsMixin';
import { updateProject } from '@/js/api/requests/project';

export default {
    name: 'project-header',
    components: { changeBgIcon, appHorizontalTabs },
    mixins: [tabsMixin],

    props: {
        project: {
            type: Object,
            required: true,
        },
        background: {
            type: String,
            default: '',
        },

        emoji: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        projectName: '',
        popupTabsBg: [
            {
                title: 'Gradients',
                value: 'gradients',
            },
            {
                title: 'Unsplash',
                value: 'unsplash',
            },
            {
                title: 'Upload an image',
                value: 'upload',
            },
        ],
        popupTabsIcon: [
            {
                title: 'Emoji',
                value: 'emoji',
            },
        ],
        tabs: [
            {
                id: 0,
                name: 'Project Library',
                tab: 'library',
            },
            {
                id: 1,
                name: 'Team Management',
                tab: 'manage-team',
            },
            {
                id: 2,
                name: 'Tasks',
                tab: 'calendar',
            },
            {
                id: 3,
                name: 'Settings',
                tab: 'settings',
            },
        ],
        pageLoading: true,
    }),

    computed: {
        setBackground() {
            if (typeof this.background === 'string') {
                return this.background.includes('https') ? `background-image: url(${this.background}) ` : `background: ${this.background}`;
            } else if (this.project.background === null) {
                return this.defaultBackground;
            } else return this.project.background.includes('https') ? `background-image: url(${this.project.background}) ` : `background: ${this.project.background}`;
        },

        setEmoji() {
            if (typeof this.emoji === 'string') {
                return this.emoji;
            } else {
                return this.project.image;
            }
        },


    },


    mounted() {
        this.setTabs();
        this.projectName = this.project.name;
    },

    methods: {
        setTabs() {
            this.tabs[0].name = this.$route.path.split(/\//)[1].slice(0, -1) + ' Library';
        },

        openModal(args) {
            this.$emit('open', args);
        },

        async setNewTitle(title) {
            if (title === this.project.name) return;
            await updateProject(this.project.id, { name: title });
        },
    },

};
</script>

<style lang="scss" scoped>
.project-detail-head {
    height: 170px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 7px;
    background-repeat: no-repeat;
    background-size: cover;

    &__popup {
        cursor: pointer;
        padding: 6px 5px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 6px;
        right: 10px;
    }

    &__tabs {
        position: absolute;
        bottom: 0;
        right: 29px;
    }

    &__info {
        position: absolute;
        display: flex;
        align-items: center;
        margin-left: 11px;
        gap: 0 17px;
        bottom: 31px;

        &--img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 65px;
            width: 65px;
            background-color: $white;
            border-radius: 9px;

            p {
                font-size: 24px;
            }
        }

        &--title {
            input {
                color: $white;
                font-size: 36px;
                font-weight: 600;
                line-height: 43px;
            }
        }
    }
}
</style>
