(function() {
    const app = document.body;
    app.style['overflow-x'] = 'hidden';

    window.disableScroll = () => {
        app.style.overflow = 'hidden';
    };

    window.enableScroll = () => {
        app.style.overflow = 'initial';
        app.style['overflow-x'] = 'hidden';
    };
})();
