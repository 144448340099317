<template lang="pug">
    .editor-link-popup(
        v-if="isOpen"
        ref="linkPopup"
        v-click-outside="close"
    )
        closer-icon.closer(@click="close")
        app-text-field.app-text-field--small(
            v-if="withName"
            ref="name"
            v-model="name"
            placeholder="Name"
        )
        app-text-field.app-text-field--small(
            v-model="link"
            ref="link"
            placeholder="Link"
        )
        a.save-link(
            @click="save"
            :class="{ disabled: !link }"
        ) Save
</template>

<script>
import DomElementMixin from '@/mixins/DomElementMixin';
import closerIcon from '@/assets/images/icons/closer_2.svg?inline';

export default {
    name: 'editor-link-popup',

    components: {
        closerIcon,
    },

    mixins: [DomElementMixin],

    data: () => ({
        nameOld: '',
        name: '',
        link: '',
        haveLinkAndLinkParams: false,

        withName: false,
        isOpen: false,
    }),

    methods: {
        open(params={}) {
            this.isOpen = true;
            this.withName = params.withName ?? false;
            if (params.updateMode) {
                this.haveLinkAndLinkParams = true;
                this.name = params.name ?? '';
                this.link = params.link ?? '';
                this.nameOld = this.name;
            } else {
                this.haveLinkAndLinkParams = false;
                this.name = '';
                this.link = '';
                this.nameOld = this.name;
            }

            this.$nextTick(() => {
                this.$refs.linkPopup.style.cssText = `top: ${params.top}px; left: ${params.left}px;`;
                this.$refs.linkPopup.style.cssText = `top: ${this.domElNotInViewportTopShift(params.top, this.$refs.linkPopup)}px; left: ${params.left}px;`;

                if (this.withName) {
                    this.$refs.name.focus();
                } else {
                    this.$refs.link.focus();
                }
            });
        },

        close() {
            this.isOpen = false;
            this.name = this.link = '';
            this.withName = false;
        },

        save() {

            try {
                new URL(this.link);
                if (this.haveLinkAndLinkParams) {
                    this.$emit('update', {
                        name: this.name,
                        link: this.link,
                        nameOldLength: this.nameOld.length,
                    });
                } else {
                    this.$emit( 'save', {
                        name: this.name,
                        link: this.link,
                    });
                }
                this.isOpen = false;
            } catch {
                this.$awn.alert('Please enter a valid link');
            }

        },
    },
};
</script>

<style scoped lang="scss">
.editor-link-popup {
    position: absolute;
    z-index: 1;

    display: flex;
    flex-direction: column;

    padding: 1.5rem 1rem 1rem;

    background: #fff;
    border-radius: rem(4px);
    box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);
}

.closer {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1;

    width: 1rem;

    cursor: pointer;

    stroke: $violet;

    &:hover {
        transform: scale(1.05);
    }
}

.save-link {
    align-self: flex-end;

    cursor: pointer;

    &.disabled {
        color: $grey;

        pointer-events: none;
    }

    &:hover {
        color: $blue;
    }
}
</style>
