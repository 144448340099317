import { keyboards } from '@/js/editor/const';

import Quill from 'quill';

import BlotFormatter from 'quill-blot-formatter-mobile';
Quill.register('modules/blotFormatter', BlotFormatter);

import Autoformat from 'quill-autoformat';
Quill.register('modules/Autoformat', Autoformat);

import * as QuillTableUI from 'quill-table-ui';
Quill.register({ 'modules/tableUI': QuillTableUI.default }, true);

import Searcher from '@/js/editor/modules/searcher';
Quill.register('modules/Searcher', Searcher);

import QuillCursors from 'quill-cursors';
Quill.register('modules/cursors', QuillCursors);

require('@/js/editor/modules/dividerBlot');
require('@/js/editor/modules/imageBlot');
require('@/js/editor/modules/commentBlot');
require('@/js/editor/modules/mentorBlot');
require('@/js/editor/modules/spaceBlot');
require('@/js/editor/modules/formulaBlot');
require('@/js/editor/modules/subDocBlot');
require('@/js/editor/modules/callout');
require('@/js/editor/modules/linkEmbed');

import { fontList, sizeList } from '@/js/editor/const';

const createBindingKey = (key, pressKey) => {
    const { keyCode } = key;
    const options = key.options ? key.options : {};
    return {
        ...options,
        key: keyCode,
        handler: (range, context) => pressKey(range, context, key),
    };
};

const initEditor = (selector, pressKey = () => {}) => {
    const bindings = {};
    for (const key in keyboards) {
        bindings[keyboards[key].keyName] = createBindingKey(
            keyboards[key],
            pressKey,
        );
    }

    const editor = new Quill(selector, {
        theme: 'snow',
        modules: {
            keyboard: {
                bindings,
            },
            toolbar: false,
            blotFormatter: {},
            table: true,
            tableUI: true,
            Searcher: true,
            cursors: {
                transformOnTextChange: true,
                hideDelayMs: 500,
                hideSpeedMs: 300,
            },
        },
    });

    // Регистрируем шрифты и ставим шрифт по умолчанию
    let FontAttributor = Quill.import('attributors/class/font');
    FontAttributor.whitelist = fontList.map(f => f.value);
    Quill.register(FontAttributor, true);
    editor.format('font', fontList[0].value);

    // Регистрируем размеры шрифта и ставим размер по умолчанию
    let Size = Quill.import('attributors/style/size');
    Size.whitelist = sizeList.map(s => s.value);
    Quill.register(Size, true);
    editor.format('size', sizeList[0].value);

    return editor;
};

export { initEditor };
