import { del, get, post } from '@/js/api/http-helper';

export async function getRoomById(id) {
    const data = await get(`/room/get/by-room-id/${id}`);
    return data.data;
}

export async function createRoom(fd) {
    const data = await post(`/room/create`, fd);
    return data.data.id;
}

export async function inviteRoom(id, fd) {
    return await post(`/room/send/invite/${id}`, fd);
}

export function updateRoom(id, fd) {
    return post(`/room/update/${id}`, fd);
}

export function moveRoomsToBin(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/room/move/to/bin/${joinedIds}`);
}

export function deleteRooms(ids) {
    const joinedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return del(`/room/delete/${joinedIds}`);
}
