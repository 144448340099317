<template lang="pug">
    .invite-form
        .left
            arrow-back.invite-form__arrow-back(@click="$emit('back')")
            .invite-form__title Invite people to join your team
            .invite-form__top
                app-text-field(
                    v-model="email"
                )
                app-button(@click="invite") Invite
            .invite-form-emails
                .invite-form-emails__item acme1@email.com
                .invite-form-emails__item acme1@email.com
                .invite-form-emails__item acme1@email.com
                .invite-form-emails__item acme1@email.com
                .invite-form-emails__item acme1@email.com
                .invite-form-emails__item acme1@email.com

            .invite-form__bottom
                app-button(@click="$emit('continue')") Continue
                app-button.app-button--grey(@click="$emit('skip')") Skip this step
        .right

</template>

<script>
import arrowBack from '@/assets/images/arrow_back.svg?inline';

export default {
    name: 'invite-form',

    components: {
        arrowBack,
    },

    data: () => ({
        email: '',
    }),

    methods: {
        invite() {

        },
    },
};
</script>

<style scoped lang="scss">
.invite-form {
    display: flex;

    width: 100vw;
    height: 100vh;

    background: #fff;

    .left, .right {
        width: 50%;
    }

    .left {
        padding: rem(64px) rem(76px);

        background: #F8FBFF;
    }

    .right {
        background: url(../../../../assets/images/invite_form_bg.svg) no-repeat center bottom / 100% auto;
    }

    &__arrow-back {
        width: rem(40px);
        margin-bottom: 2rem;

        cursor: pointer;
    }

    &__title {
        margin-bottom: 3rem;

        font-size: rem(36px);
    }

    &__top {
        display: flex;
        align-items: center;

        margin-bottom: rem(60px);

        .app-text-field {
            width: 80%;
            margin: 0 2rem 0 0;
        }
    }

    &-emails {
        &__item {
            margin-bottom: rem(50px);

            font-size: rem(24px);
        }
    }

    &__bottom {
        display: flex;

        .app-button:first-child {
            margin-right: 1rem;
        }
    }

    @include mobile_or_tablet {
        height: initial;

        background: transparent;

        .left {
            width: 90%;
            max-width: 100vmin;
            margin: 0 auto;
            padding: 1rem;

            background: #fff;
            border-radius: rem(6px);
            box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);
        }

        .right {
            display: none;
        }

        &__title {
            margin-bottom: 2rem;

            font-size: 1.25rem;
        }

        &__top {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: rem(30px);

            .app-text-field {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        &-emails {
            &__item {
                margin-bottom: rem(30px);

                font-size: 1.25rem;
            }
        }
    }
}
</style>
