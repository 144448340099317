<template lang="pug">
    form(@submit.prevent="submit" class='plan-buy-form')
        h3 Payment Method
        app-text-field(
            class="name-on-card"
            v-model="$v.formData.nameOnCard.$model"
            label="Name on card*"
            :error="validateNameOnCard"
            @input="validForParent"
        )
        app-text-field(
            class="card-number"
            v-model="$v.formData.cardNumber.$model"
            v-mask="'#### #### #### ####'"
            label="Card number*"
            placeholder="1234 1234 1234 1234"
            :error="validateCardNumber"
            @input="validForParent"
        )
            template(v-slot:icon)
                img(:src="require('@/assets/images/icons/credit-card.svg')")
        app-text-field(
            class="month-and-year"
            v-model="$v.formData.monthAndYear.$model"
            v-mask="'##/####'"
            label="Expiry date*"
            placeholder="MM/YYYY"
            :error="validateMonthAndYear"
            @input="validForParent"
        )
        app-text-field(
            class="cvc"
            v-model="$v.formData.cvc.$model"
            v-mask="'###'"
            label="CVC/Security code*"
            placeholder="CVC"
            :error="validateCvc"
            @input="validForParent"
        )
        .app-modal__bottom
            app-button.pay-btn(:loading="loading" :disabled="$v.$invalid") Save
</template>

<script>
import { required, helpers } from 'vuelidate/lib/validators';
const cartValidate = helpers.regex('cartValidate',  /[\d]{4}\s[\d]{4}\s[\d]{4}\s[\d]{4}$/);
const monthAndYearValidate = helpers.regex('monthAndYearValidate', /[\d]{2}\/[\d]{4}$/);
const cvcValidate = helpers.regex('cvcValidate', /^\d{3}$/);
import { cardLastUpdate } from '@/js/api/requests/auth';

export default {
    name: 'payment-method',

    props: {
        formData: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    computed: {
        validateNameOnCard() {
            return this.$v.formData.nameOnCard.$error && !this.$v.formData.nameOnCard.required? 'Required field' : '';
        },
        validateCardNumber() {
            if (this.$v.formData.cardNumber.$error) {
                if (!this.$v.formData.cardNumber.required) {
                    return 'Required field';
                }
                if (!this.$v.formData.cardNumber.cartValidate) {
                    return 'Need 16 numbers';
                }
            }
            return '';
        },
        validateMonthAndYear() {
            if (this.$v.formData.monthAndYear.$error) {
                if (!this.$v.formData.monthAndYear.required) {
                    return 'Required field';
                }
                if (!this.$v.formData.monthAndYear.monthAndYearValidate) {
                    return 'Need month and year';
                }
            }
            return '';
        },
        validateCvc() {
            if (this.$v.formData.cvc.$error) {
                if (!this.$v.formData.cvc.required) {
                    return 'Required field';
                }
                if (!this.$v.formData.cvc.cvcValidate) {
                    return 'Need 3 numbers';
                }
            }
            return '';
        },
    },

    created() {
        this.validForParent();
    },

    validations: {
        formData: {
            nameOnCard: {
                required,
            },
            cardNumber: {
                required,
                cartValidate,
            },
            monthAndYear: {
                required,
                monthAndYearValidate,
            },
            cvc: {
                required,
                cvcValidate,
            },
        },
    },

    methods: {
        validForParent() {
            if ('validPaymentForm' in this.formData) {
                this.formData.validPaymentForm = !this.$v.$invalid;
            }
        },

        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            if (this.loading) return;

            this.loading = true;

            const card_number = Number(this.formData.cardNumber.replace(/\s+/g, ''));
            const monthAndYearArr = this.formData.monthAndYear.split('/');
            const exp_month = Number(monthAndYearArr[0]);
            const exp_year = Number(monthAndYearArr[1]);
            const cvc = Number(this.formData.cvc);
            const name_on_card = this.formData.nameOnCard;

            const data = {
                card_number,
                exp_month,
                exp_year,
                cvc,
                name_on_card,
            };

            try {
                const newCard = await cardLastUpdate(data);
                this.$awn.success(`Update card success`);
                //for paymant method modal
                this.$emit('updateCard', newCard);
                this.$modal.hide('payment-method-modal');
            } catch (e) {
                this.$awn.alert('Update card fail');
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@include plan-buy-form();
</style>
