export default {
    methods: {
        // это отбор парамсов самых важных роутов
        getRouteParams() {
            let data = null;
            const { name, params } = this.$route;

            // по дефолту попадёт в Library
            if ((name === 'FolderDetail' || name === 'LibraryFolder') && params.folderId) {
                data = { folder_id: params.folderId };
            } else if (name === 'ProjectDetail' && params.id) {
                data = { project_id: params.id };
            } else if (name === 'RoomDetail' && params.roomId) {
                data = { room_id: params.roomId };
            }
            return data;
        },
    },
};
