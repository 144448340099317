import { mimeTypes } from '@/js/editor/const';

export default (content, fileName, type) => {
    const a = document.createElement('a');

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(new Blob([content], {
            type: mimeTypes[type],
        }), fileName);
        return;
    }

    if (URL && 'download' in a) {
        a.href = URL.createObjectURL(new Blob([content], {
            type: mimeTypes[type],
        }));

        a.setAttribute('download', `${fileName}.${type}`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return;
    }

    // only this mime type is supported
    location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
};
