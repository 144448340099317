<template>
    <div class="upload-popup">
        <div class="upload-popup__container">
            <div class="upload-popup__container--upload">
                <label for="file">
                    <input
                        id="file"
                        ref="fileInput"
                        accept="image/jpeg, image/png, image/jpg"
                        type="file"
                    />
                    Choose an image
                </label>
            </div>
            <div class="upload-popup__container--settings">
                <div class="resolution">
                    Recommended size is {{ recommendResolution }}pixels
                </div>
                <div class="size">
                    Maximum upload size is 2MB
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProjectById, updateProject } from '@/js/api/requests/project';

export default {
    name: 'upload-popup',

    props: {
        projectId: {
            type: Number,
            default: 0,
        },
        recommendResolution: {
            type: String,
            default: '',
        },

    },

    data: () => ({
        projectName: '',
    }),

    mounted() {
        this.fileInput();
        getProjectById(this.projectId).then(v => this.projectName = v.name);
    },

    methods: {
        fileInput() {
            const BYTES_IN_MB = 1048576;
            this.$refs.fileInput.addEventListener('change', async (e) => {
                let file = e.target.files[0];

                if (file.size > 2 * BYTES_IN_MB) {
                    this.$awn.alert('Maximum file size 2mb');
                    file = null;
                } else {
                    try {
                        let fd = new FormData;
                        fd.append('background', file);
                        updateProject(this.projectId, fd).then((v) => {
                            this.$awn.success('Project background was updated successfully');
                            this.$modal.hide('change-bg-modal');
                            this.$emit('updateBackground', v.data.background);
                        });
                    } catch (e) {
                        this.$awn.alert('Something went wrong, please try again later');
                        this.$modal.hide('change-bg-modal');
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-popup {
    height: 100%;
    width: 100%;

    &__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 51px);

        &--upload {
            input {
                display: none;
            }

            label {
                padding: 8px 63px;
                background-color: $blue4;
                border-radius: 6px;
                color: $white;
                font-size: 12px;
                font-weight: 600;
                cursor: pointer;
            }

        }

        &--settings {

            color: $grey-light31;
            font-size: 12px;
            font-weight: 500;

            .resolution {
                margin-bottom: 10px;
                margin-top: 30px;
            }

            .size {
                text-align: center;
            }
        }
    }
}


</style>
