<template lang="pug">
    app-toggler(
        v-model="planPeriod"
        label-left="Monthly"
        label-right="Annually"
        size="sm"
    )
</template>

<script>
import { PLAN_PERIOD } from '@/js/const';

export default {
    name: 'period-switch',

    computed: {
        planPeriodSwitcher() {
            return this.$store.getters.planPeriodSwitcher;
        },
        planPeriod: {
            get() {
                return this.planPeriodSwitcher === PLAN_PERIOD.ANNUAL;
            },
            set(value) {
                this.$store.commit('setPlanPeriodSwitcher', value ? PLAN_PERIOD.ANNUAL : PLAN_PERIOD.MONTH);
            },
        },
    },
};
</script>
