<template lang="pug">
    .notes
        h3 Notes
        .notes-add

        .notes-list
            .notes-list__item
</template>

<script>
export default {
    name: 'notes',
};
</script>

<style scoped lang="scss">
.notes {
    position: relative;

    height: 100%;
    padding: 1.2rem 1.8rem;
    overflow: hidden;

    background: #fcefd2;
    &-add {
        position: absolute;
        top: 0;
        right: 0;

        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;

            display: block;

            width: 5.25rem;
            height: 8rem;

            background: #fee4a9;

            transform: rotate(-46deg);
            transform-origin: 0 0;
        }

        &:after {
            content: '+';
            position: absolute;
            top: 1rem;
            right: 0.8rem;

            font-size: 2rem;
            font-weight: 600;
        }
    }
}
</style>
