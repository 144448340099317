<template lang="pug">
    .chat-reactions-select(ref="chatReactionsSelect")
        emoji-icon.button(@click="toggle")

        .list(
            v-if="isOpen"
            ref="list"
            v-click-outside="toggle"
        )
            .item(
                v-for="emoji in emojiList"
                @click="addReaction(emoji)"
            ) {{ emoji }}
</template>

<script>
import emojiIcon from '@/assets/images/icons/emoji.svg?inline';

export default {
    name: 'chat-reactions-select',

    components: {
        emojiIcon,
    },

    data: () => ({
        isOpen: false,
        emojiList: ['⭐','🤯','❤️','💩','😂','👺','💯','👍','🧠','👀'],
    }),

    methods: {
        addReaction(emoji) {
            this.$emit('addReaction', emoji);
            this.toggle();
        },

        toggle() {
            if (this.isOpen) {
                this.isOpen = false;
            } else {
                setTimeout(() => {
                    this.isOpen = true;
                    // чтобы не вылезал а левую границу
                    this.$nextTick(() => {
                        const selectOffsetLeft = this.$refs.chatReactionsSelect.offsetLeft;
                        const listWidth = this.$refs.list.offsetWidth;
                        if (selectOffsetLeft - listWidth < 0) {
                            this.$refs.list.style.right = selectOffsetLeft - listWidth + 'px';
                        }
                    });
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.chat-reactions-select {
    position: relative;

    .button {
        display: block;

        width: rem(18px);

        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                path {
                    stroke: $blue;
                }

                circle {
                    fill: $blue;
                }
            }
        }
    }

    .list {
        position: absolute;
        right: 0;
        bottom: calc(100% + #{rem(4px)});

        width: rem(170px);
        padding: rem(5px);

        background-color: #fff;
        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(#000, 0.1);

        .item {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            width: rem(32px);
            height: rem(30px);

            border-radius: rem(3px);

            cursor: pointer;

            user-select: none;

            @media (hover: hover) {
                &:hover {
                    background-color: $hover-background;
                }
            }
        }
    }
}
</style>
