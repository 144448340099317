<template lang="pug">
    app-modal.modal-loading-google-docs(name="modal-loading-google-docs")
        .title Uploading document:
        app-loader
        .uploading Uploading...
</template>

<script>
export default {
    name: 'modal-loading-google-docs',
};
</script>

<style lang="scss">
.modal-loading-google-docs {
    .app-modal__container {
        width: rem(300px) !important;
    }
    .title {
        text-align: center !important;
        color: $grey-light28;
        font-weight: 500;
    }
    .app-loader {
        padding: 0px !important;
    }
    .uploading {
        text-align: center;
        color: $grey-dark3;
        font-weight: 500;
    }
}
</style>
