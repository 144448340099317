<template lang="pug">
    .search-field(:class="classes")
        burger-icon.icon(@click="openMenu" v-if="isMobile")
        search-icon.icon(@click="toggle" v-else)
        input.input(placeholder="Search" @click="openSearcher")
        list-mobile-icon.icon.list-icon(@click="$emit('onList')")

</template>

<script>
import searchIcon from '@/assets/images/icons/search_2.svg?inline';
import burgerIcon from '@/assets/images/icons/burger.svg?inline';
import listMobileIcon from '@/assets/images/icons/list-mobile.svg?inline';



export default {
    name: 'search-field',

    components: {
        searchIcon,
        burgerIcon,
        listMobileIcon,
    },

    props: {
        hiddenable: {
            type: Boolean,
            required: false,
            default: false,
        },

        isMobile: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        opened: false,
    }),

    computed: {

        classes() {
            return {
                'hiddenable': this.hiddenable,
                'opened': this.opened,
            };
        },
    },

    methods: {
        toggle(value) {
            if (this.hiddenable) {
                if (value === 'close') {
                    this.opened = false;
                } else {
                    this.opened = !this.opened;
                }

                if (this.opened) {
                    this.$emit('open');
                } else {
                    this.$emit('close');
                }
            }
        },
        openMenu () {
            this.$emit('openMenu');
        },
        openSearcher() {
          this.$modal.show('searcher');
        },
    },
};
</script>

<style scoped lang="scss">
.search-field {
    position: relative;

    display: flex;
    align-items: center;
    padding-right: rem(2px);

    width: rem(173px);

    border-radius: 4px;

    background-color: #ECECEC;

    .icon {
        flex-shrink: 0;

        margin-right: rem(20px);
        margin-left: rem(13px);

        stroke: #A7A7A9;
        stroke-width: 2px;
    }

    .list-icon {
        display: none;

        @include mobile_or_tablet {
            display: flex;
        }
    }

    .input {
        width: 100%;
        padding: 0.7rem 0;
        font-weight: 500;
        font-size: 13px;
    }

    .mobile {
        width: 100px;
    }

    .closer {
        position: absolute;
        top: 50%;
        right: rem(8px);

        width: rem(20px);

        transform: translateY(-50%);
        cursor: pointer;
        transition: opacity .4s;

        stroke: $red;
    }

    &.hiddenable {
        overflow: hidden;

        transition: width .5s, border-color .1s;

        .icon {
            cursor: pointer;
        }

        .input {
            padding-right: rem(36px);
        }

        &:not(.opened) {
            width: rem(42px);

            border-color: transparent;

            transition: width .5s, border-color .1s .4s;

            .icon {
                margin-left: rem(9px);
            }

            .closer {
                opacity: 0;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_tablet {
        width: 100%;
        height: 40px;

        .input {
            font-size: 14px;
        }
    }
}
</style>
