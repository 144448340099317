import moment from 'moment';

export const getDiffTime = (date1, date2) => {
    let dateResultText = '';
    const seconds = Math.abs(date1.diff(date2, 'seconds'));
    const minutes = Math.abs(date1.diff(date2, 'minutes'));
    const hours = Math.abs(date1.diff(date2, 'hours'));
    const days = Math.abs(date1.diff(date2, 'days'));
    if (days > 0) {
        dateResultText = 'Date: ' + date2.format('MM-DD-YYYY');
    } else if (hours > 0) {
        dateResultText = hours + ' hours ago';
    } else if (minutes > 0) {
        dateResultText = minutes + ' minutes ago';
    } else if (seconds > 0) {
        dateResultText = seconds + ' seconds ago';
    } else if (seconds <= 0) {
        dateResultText = 'now';
    }
    return dateResultText;
};

export const getDiffCurrentTime = (date) => {
    return getDiffTime(moment() , date);
};
