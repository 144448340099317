<template lang="pug">
    .editor-snapshots-author(
        v-if="isOpen"
        :style="styles"
    ) {{ params.author }}
</template>

<script>
export default {
    name: 'editor-snapshots-author',

    data: () => ({
        isOpen: false,

        params: {},
    }),

    computed: {
        styles() {
            return {
                top: this.params.top + 'px',
                left: this.params.left + 'px',
                backgroundColor: this.params.background,
            };
        },
    },

    methods: {
        open(params) {
            this.isOpen = true;
            this.params = params;
        },

        close() {
            this.isOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.editor-snapshots-author {
    position: absolute;

    padding: rem(2px) rem(4px);

    font-size: rem(14px);
    color: #000;

    border-radius: rem(2px);

    transform: translateY(calc(-100% - #{rem(2px)}));
}
</style>
