<template lang="pug">
    router-link(to="/")
        img(src="@/assets/images/logo.svg" alt="ReSkript" class='header-logo')
</template>

<script>
export default {
    name: 'logo',
};
</script>
