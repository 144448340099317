<template lang="pug">
    .reset-password
        form.reset-password-form(@submit.prevent="submit")
            template(v-if="!success")
                .reset-password-form__title Forgot your password?
                app-text-field(
                    v-model="email"
                    label="Email"
                    :error="emailError"
                )
                app-button(
                    :disabled="!email"
                    :loading="loading"
                ) Send me instructions
            template(v-else)
                .reset-password-form__title Instructions sent!
                p Instructions for resetting your password have been sent to
                    br
                    b {{ email }}
                p You will receive the email within 5 minutes. Be sure to check your spam folder too.

</template>

<script>

import { required, email } from 'vuelidate/lib/validators';
import { resetPassword } from '@/js/api/requests/auth';

export default {
    name: 'reset-password',

    data: () => ({
        email: '',

        loading: false,
        apiError: null,
        success: false,
    }),

    validations: {
        email: {
            required, email,
        },
    },

    computed: {
        emailError() {
            if (this.$v.$dirty) {
                if (!this.$v.email.required) {
                    return 'Required field';
                } else if (!this.$v.email.email) {
                    return 'Invalid Email Format';
                }
            }
            return this.apiError;
        },
    },

    methods: {
        async submit() {
            this.apiError = null;
            if (this.loading) return;
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.loading = true;

            const fd = new FormData;
            fd.append('email', this.email);

            try {
                await resetPassword(fd);
                this.success = true;
            } catch (e) {
                if (e.response.status === 404) {
                    this.success = true;
                } else {
                    this.apiError = 'Server error';
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.reset-password {
    &-form {
        display: flex;
        flex-direction: column;

        width: rem(646px);
        padding: rem(14px) rem(116px) rem(17px);

        background: #FFFFFF;
        border-radius: rem(6px);
        box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.25);

        &__title {
            margin-bottom: rem(29px);

            font-size: rem(36px);
            text-align: center;
        }

        .app-button {
            align-self: flex-end;
        }

        p {
            margin-bottom: rem(20px);

            font-size: rem(14px);
            line-height: rem(17px);
            text-align: center;
        }
    }

    @include mobile_or_tablet {
        &-form {
            width: rem(350px);
            padding: 1rem;

            &__title {
                font-size: 1.5rem;
            }
        }
    }
}
</style>
