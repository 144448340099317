class Popups {
    editor = null
    refs = null

    constructor ({ editor, refs }) {
        this.editor = editor;
        this.refs = refs;
    }

    initPopup (popup, symbol) {
        let lastLetter = '';

        this.editor.on('text-change', (delta, oldDelta, source) => {
            if (source !== 'user') {
                return;
            }

            lastLetter = delta.ops.find((element) => 'insert' in element)?.insert;

            setTimeout(() => {
                const range = this.editor.getSelection();

                if (!this.refs[popup].isOpen) {
                    if (lastLetter === symbol) {
                        this.refs[popup].setIndexPosition(range.index);
                        this.openPopup(popup);
                    }
                    return;
                }

                if (range.index === 0 || range.index <= this.refs[popup].indexPosition || delta.ops[1].insert === '\n') {
                    this.refs[popup].closeAndClear(false);
                    return;
                }

                if (delta.ops.find((element) => 'delete' in element)) {
                    this.refs[popup].removeLetter(range.index);
                } else {
                    this.refs[popup].addLetter(range.index, lastLetter);
                }
            });
        });
    }

    openPopup (namePopup) {
        const range = this.editor.getSelection();
        const popup = this.refs[namePopup];
        const cursorBounds = this.editor.getBounds(range.index, range.length);

        const top = () => {
            const clientHeight = document.documentElement.clientHeight;
            const editorTopPosition = 183;
            const cursorTopPosition = cursorBounds.top + editorTopPosition;
            const documentScrollPosition = document.documentElement.scrollTop;

            return clientHeight - cursorTopPosition + documentScrollPosition > popup.heightPopup
                ? cursorBounds.top + cursorBounds.height
                : cursorBounds.top - popup.heightPopup;
        };

        let left = cursorBounds.left;

        if (window.isMobileOrTablet) {
            const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            const systemZoom = width / window.screen.availWidth;
            left = (width - 315) / 2 / systemZoom + dualScreenLeft;
        }

        popup.open({ top: top(), left });
    }
}

export default Popups;
