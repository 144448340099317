let VueScrollTo = require('vue-scrollto');

export default (selector, addOffset=0) => {
    let offset = addOffset;
    if (window.isMobileOrTablet) {
        const header = document.querySelector('.mobile-header');
        offset -= header.offsetHeight;
    }
    VueScrollTo.scrollTo(selector, 500, { offset });
};
