<template lang="pug">
    .room-detail-library.default-layout-block
        files-table(:documents.sync="room.room_library" show-filters)
        .app-plus(@click="createDocument({room_id: room.id})")
</template>

<script>
import FilesTable from '@/components/other/FilesTable';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';

export default {
    name: 'room-detail-library',

    components: { FilesTable },

    mixins: [CreateDocumentMixin],

    props: {
        room: {
            type: Object,
            required: true,
        },
    },
};
</script>
