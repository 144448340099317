<template lang="pug">
    .bin
        .default-layout-head
            app-breadcrumbs(
                :items="$route.meta.breadcrumb"
                class="app-breadcrumbs_mb app-breadcrumbs--theme-thin"
            )
        app-loader(v-if="pageLoading")
        template(v-else)
            .top-panel.forDesktop
                p Items in bin will be deleted forever in 30 days
                template(v-if="selectedTotal > 0")
                    span {{ selectedTotal }} Item Selected
                    a(@click="restoreSelected") Restore selected
                    a(@click="emptySelected") Empty selected
                a(@click="restoreBin") Restore Bin
                a(@click="emptyBin") Empty Bin
            caption.forDesktop Files

            .top-panel.forMobile_or_p
                caption Bin
                span Item Selected: {{ selectedTotal }}
                app-button.app-button--grey.app-button--icon.forMobile_or_p(@click="emptySelected")
                    img(src="@/assets/images/icons/delete.png" alt="")

            table.forDesktop
                thead
                    tr
                        th Name
                        th Owner
                        th Delete date
                        th File size
            .bin-tab
                .bin-tab__name Projects
                    arrow-bottom-icon
                .bin-tab__content
                    table
                        tr(v-for="item in bin.projects")
                            td
                                app-checkbox(v-model="item.selected")
                                | {{ item.name }}
                            td Me
                            td {{ moment(item.deleted_at).format('DD.MM.YYYY') }}
                            td 300kb
            .bin-tab
                .bin-tab__name Rooms
                    arrow-bottom-icon
                .bin-tab__content
                    table
                        tr(v-for="item in bin.rooms")
                            td
                                app-checkbox(v-model="item.selected")
                                | {{ item.name }}
                            td Me
                            td {{ moment(item.deleted_at).format('DD.MM.YYYY') }}
                            td 300kb
            .bin-tab
                .bin-tab__name Folders
                    arrow-bottom-icon
                .bin-tab__content
                    table
                        tr(v-for="item in bin.folders")
                            td
                                app-checkbox(v-model="item.selected")
                                | {{ item.name }}
                            td Me
                            td {{ moment(item.deleted_at).format('DD.MM.YYYY') }}
                            td 300kb
            .bin-tab
                .bin-tab__name Files
                    arrow-bottom-icon
                .bin-tab__content
                    table
                        tr(v-for="item in bin.documents")
                            td
                                app-checkbox(v-model="item.selected")
                                | {{ item.name }}
                            td Me
                            td {{ moment(item.deleted_at).format('DD.MM.YYYY') }}
                            td 300kb

</template>

<script>
import arrowBottomIcon from '@/assets/images/icons/arrow_bottom.svg?inline';
import deleteIcon from '@/assets/images/icons/delete.svg?inline';
import moment from 'moment';
import { deleteDocuments } from '@/js/api/requests/document';
import { deleteFolders } from '@/js/api/requests/folder';
import { deleteProjects } from '@/js/api/requests/project';
import { deleteRooms } from '@/js/api/requests/room';
import { deleteAllBin, getBin, restoreAllBin, restoreFromBin } from '@/js/api/requests/bin';

export default {
    name: 'bin',

    components: {
        arrowBottomIcon,
        deleteIcon,
    },

    data: () => ({
        bin: {},

        pageLoading: true,
    }),

    computed: {
        selectedTotal() {
            return Object.values(this.bin).reduce((total, b) => {
                return total + b?.filter(i => i.selected).length || 0;
            }, 0);
        },
    },

    async created() {
        await this.fetchBin();
        this.pageLoading = false;

        setTimeout(this.initTabs);
    },

    methods: {
        async fetchBin() {
            const bin = await getBin();
            this.bin = {
                projects: bin.projects?.map(p => ({
                    ...p,
                    selected: false,
                })) || [],
                rooms: bin.rooms?.map(p => ({
                    ...p,
                    selected: false,
                })) || [],
                folders: bin.folders?.map(p => ({
                    ...p,
                    selected: false,
                })) || [],
                documents: bin.documents?.map(p => ({
                    ...p,
                    selected: false,
                })) || [],
            };
        },

        initTabs() {
            const tabs = document.querySelectorAll('.bin-tab');
            tabs.forEach(t => {
                const name = t.querySelector('.bin-tab__name');
                const content = t.querySelector('.bin-tab__content');
                name.addEventListener('click', () => {
                    if (t.classList.contains('opened')) {
                        t.classList.remove(('opened'));
                        content.style.height = 0;
                    } else {
                        t.classList.add(('opened'));
                        const contentHeight = content.querySelector('table').scrollHeight;
                        content.style.height = contentHeight + 'px';
                    }
                });
            });
        },

        updateTabs() {
            const tabs = document.querySelectorAll('.bin-tab');
            tabs.forEach(t => {
                const content = t.querySelector('.bin-tab__content');
                const contentHeight = content.querySelector('table').scrollHeight;
                if (t.classList.contains('opened')) {
                    content.style.height = contentHeight + 'px';
                    if (contentHeight === 0) {
                        t.classList.remove('opened');
                    }
                }
            });
        },

        emptyBin() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await deleteAllBin();
                            this.bin = {};
                            setTimeout(this.updateTabs);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
            });
        },

        emptySelected() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        const deleteRequests = Object.keys(this.bin).reduce((accum, key) => {
                            const selectedItems = this.bin[key].filter(p => p.selected).map(p => p.id);
                            if (selectedItems.length) {
                                if (key === 'documents') {
                                    accum.push(deleteDocuments(selectedItems));
                                } else if (key === 'folders') {
                                    accum.push(deleteFolders(selectedItems));
                                } else if (key === 'projects') {
                                    accum.push(deleteProjects(selectedItems));
                                } else if (key === 'rooms') {
                                    accum.push(deleteRooms(selectedItems));
                                }
                            }
                            return accum;
                        }, []);
                        try {
                            await Promise.all(deleteRequests);
                            Object.keys(this.bin).forEach(key => {
                                this.bin[key] = this.bin[key].filter(p => !p.selected);
                            });
                            setTimeout(this.updateTabs);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
            });
        },

        restoreBin() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await restoreAllBin();
                            this.bin = {};
                            setTimeout(this.updateTabs);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
            });
        },

        restoreSelected() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    const entitiesTypes = {
                        projects: 'project_id',
                        rooms: 'room_id',
                        folders: 'folder_id',
                        documents: 'document_id',
                    };
                    if (confirm) {
                        const restoreRequests = Object.keys(this.bin).reduce((accum, key) => {
                            const selectedItems = this.bin[key].filter(p => p.selected).map(p => p.id);
                            if (selectedItems.length) {
                                accum.push(restoreFromBin({ [entitiesTypes[key]]: selectedItems }));
                            }
                            return accum;
                        }, []);
                        try {
                            await Promise.all(restoreRequests);
                            Object.keys(this.bin).forEach(key => {
                                this.bin[key] = this.bin[key].filter(p => !p.selected);
                            });
                            setTimeout(this.updateTabs);
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
            });
        },

        moment,
    },
};
</script>

<style lang="scss" scoped>
.bin {
    padding: 0 rem(15px);
}

.top-panel {
    display: flex;

    margin: 0 -2.5rem rem(30px);
    padding: 1rem rem(40px);

    font-size: rem(18px);

    background: #EEEEF3;

    p {
        margin-right: auto;
    }

    span {
        font-weight: 300;
    }

    a {
        margin-left: rem(30px);

        cursor: pointer;
    }
}

caption {
    margin-bottom: rem(20px);

    font-size: rem(20px);
    font-weight: 500;
}

.bin-tab {
    margin: rem(10px) 0 rem(24px);

    &__name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 1rem 1rem 1rem 0.5rem;

        font-size: rem(18px);
        font-weight: 500;

        background-color: #EEEEF3;

        cursor: pointer;

        svg {
            width: 1rem;

            transition: transform .2s;

            stroke: $violet;
        }
    }

    &__content {
        height: 0;
        padding: 0 2.5rem;
        overflow: hidden;

        transition: height .4s;
    }

    &.opened &__name svg {
        transform: rotate(180deg);
    }
}

table {
    width: 100%;

    thead {
        box-shadow: 0 1px #A8A6A6;
    }

    tr.selected {
        background: #edf7fe;
    }

    th, td {
        &:nth-of-type(1) {
            width: 40%;
        }

        &:nth-of-type(4) {
            width: 6rem;
        }
    }

    th {
        padding-bottom: 0.5rem;

        text-align: left;
    }

    td {
        position: relative;

        padding: rem(14px) 0;

        .app-checkbox {
            position: absolute;
            top: 50%;
            right: 100%;

            transform: translateY(-50%);
        }
    }
}

@include mobile_or_tablet {
    .bin {
        padding: rem(22px);
    }

    .top-panel {
        align-items: center;

        margin: 0 0 1.5rem;
        padding: 0;

        background: none;

        caption {
            margin-right: auto;
            margin-bottom: 0;
        }

        .app-button {
            margin-left: rem(14px);
        }
    }
    .bin-tab {
        &__content {
            margin: 0 rem(-22px);
            padding: 0 rem(22px);
        }
    }
    table {
        th, td {
            &:nth-of-type(1) {
                width: 100%;
            }

            &:nth-of-type(2), &:nth-of-type(4) {
                display: none;
            }
        }

        td {
            .app-checkbox {
                position: static;

                display: inline;

                vertical-align: middle;
            }
        }
    }
}
</style>
