<template lang="pug">
    div
        .editor-comments-popup-mention
            .toggler(@click="toggle")
                template.myClass(v-if="isMobileOrTablet" ) @
        .list(
            v-if="isOpen"
            v-click-outside="toggle"
            ref="users"
            :class="{ 'list-in-desktop': !isMobileOrTablet }"
        )
            .item(
                v-for="user in usersFiltered"
                @click.stop="addMention(user)"
            )
                .avatar
                    img(
                        v-if="user.image"
                        :src="user.image"
                    )
                    span(v-else) {{ getUserFirstLetters(user) }}
                .name {{ user.username }} {{ user.surname }}
</template>

<script>
import Scrollbar from 'smooth-scrollbar';

export default {
    name: 'editor-comments-popup-mention',

    props: {
        invitedUsers: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        isOpen: false,
    }),

    computed: {
        usersFiltered() {
            return this.invitedUsers.filter((obj) => obj.username);
        },

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },
    },

    methods: {
        initSlick() {
            this.$nextTick(() => {
                Scrollbar.init( this.$refs.users);
            });
        },

        toggle() {
            if (this.isOpen) {
                this.isOpen = false;
            } else {
                setTimeout(() => {
                    this.isOpen = true;
                    this.initSlick();
                });
            }
        },

        getUserFirstLetters(user) {
            return `${user.username?.[0] || ''}${user.surname?.[0] || ''}`;
        },

        addMention(user) {
            const prefix = this.isMobileOrTablet ? '@' : '';
            const userMentioned = `${prefix}${[user.username, user.surname].filter(n => n).join('_')}`;

            this.$emit('addMention', userMentioned);
            this.toggle();
        },
    },
};
</script>

<style scoped lang="scss">
.list {
    position: absolute;
    bottom: 60vh;
    left: 0;
    min-width: rem(180px);
    max-width: rem(250px);
    max-height: rem(115px);
    background: #fff;
    box-shadow: 0 0 rem(10px) rgba(0, 0, 0, 0.1);
}

.item {
    display: flex;
    align-items: center;
    padding: rem(8px);
    font-size: rem(12px);
    cursor: pointer;

    .avatar {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: rem(24px);
        height: rem(24px);
        margin-right: rem(8px);
        overflow: hidden;
        color: #fff;
        background: #C4C4C4;
        border-radius: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .name {
        overflow: hidden;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @media (hover: hover) {
        &:hover {
            background-color: rgba($grey, 0.1);
        }
    }
}

@media screen and (min-width: 1025px) {
    .list {
        bottom: 100%;
        left: 0;
    }
}

.editor-comments-popup-mention{
    position: relative;
    margin-right: auto;

    .toggler {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(24px);
        height: rem(24px);
        margin-left: rem(4px);
        font-weight: 500;
        border-radius: rem(2px);
        cursor: pointer;
        transition: box-shadow .2s;
        user-select: none;

        @media (hover: hover) {
            &:hover {
                box-shadow: 0 0 0 rem(2px) $hover-background, inset 0 0 0 rem(10px) $hover-background;
            }
        }
    }

    @include mobile_or_tablet {
        .toggler {
            font-size: rem(18px);
        }

        .list {
            left: 0;
            min-width: rem(210px);
        }

        .item {
            font-size: rem(16px);
            .avatar {
                width: rem(36px);
                height: rem(36px);
            }
        }
    }
}
</style>
