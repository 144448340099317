<template lang="pug">
    transition(name="editor-comments-popup")
        .editor-comments-popup(
            v-if="isOpen"
            ref="commentsPopup"
            :style="styles"
            @click.self="closeOnMobile"
        )
            .content
                .top(v-if="messages.length")
                    .top__name.forMobile_or_tablet Comment:
                    .top__resolve(@click="resolveComment") Resolve
                    .top__more
                        three-dots-menu.three-dots-menu--theme-horizontal
                            ul.menu__list
                                li.menu__item(@click="deleteComment()")
                                    bin-icon
                                    span Delete comment

                .comments(v-if="messages.length")
                    .comment(v-for="message in messages")
                        .comment__avatar
                            img(
                                v-if="message.owner.image"
                                :src="message.owner.image"
                            )
                            span(v-else) {{ getUserFirstLetters(message.owner) }}
                        .comment__content
                            .comment__header
                                .comment__name {{ message.owner.username }} {{ message.owner.surname }}
                            .comment__text(v-html="commentText(message.text)")
                        .comment__more
                            three-dots-menu.three-dots-menu--theme-horizontal(close-on-click)
                                ul.menu__list
                                    li.menu__item(@click="editMessage(message)")
                                        editor-icon
                                        span Edit
                                    li.menu__item(@click="deleteMessage(message)")
                                        bin-icon
                                        span Delete

                .new-comment
                    .comment__avatar.forMobile_or_tablet
                        img(
                            v-if="user.image"
                            :src="user.image"
                        )
                        span(v-else) {{ getUserFirstLetters(user) }}

                    textarea-autosize(
                        ref="textarea"
                        v-model="message"
                        placeholder="Add a comment"
                        rows="1"
                    )

                    .new-comment__bottom
                        .info.forDesktop Type @ to ping someone
                        .controls
                            .controls__item--small
                                editor-comments-popup-mention(
                                    ref="commentsPopupMention"
                                    :invited-users="invitedUsers"
                                    @addMention="addMention"
                                )
                            .controls__item
                                app-button(
                                    @click="send"
                                    small
                                    block
                                ) Send
                            .controls__item.forDesktop
                                app-button.app-button--grey(@click="close" middle block) Cancel
</template>

<script>
import { mapState } from 'vuex';
import EditorCommentsPopupMention from '@/components/editor/commentsPopup/EditorCommentsPopupMention';
import threeDotsMenu from '@/components/other/ThreeDotsMenu';
import editorIcon from '@/assets/images/icons/edit.svg?inline';
import binIcon from '@/assets/images/icons/bin-2.svg?inline';
import { messageWithMentionUsers } from '@/js/utils/text';
import { isEmpty } from 'lodash';

export default {
    name: 'editor-comments-popup',

    components: {
        EditorCommentsPopupMention,
        editorIcon,
        threeDotsMenu,
        binIcon,
    },

    props: {
        invitedUsers: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        isOpen: false,
        message: '',
        commentId: null,
        fragment: '',
        messages: [],
        styles: {},
        editingMessage: {},
    }),

    computed: {
        ...mapState({
            isPublicLink: state => state.editor.isPublicLink,
            publicLinkPermissions: state => state.editor.permissions,
            isLoggedUser: state => state.auth.user.id !== 'anonymous',
        }),

        isAllowCommentsByPermission() {
            if (!this.isLoggedUser) {
                return false;
            }

            if (!this.isPublicLink) {
                return true;
            }

            return this.publicLinkPermissions.includes('comment');
        },

        user() {
            return this.$store.getters.user;
        },

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },
    },

    watch: {
        message() {
            this.$refs.commentsPopupMention.isOpen = this.message[this.message.length - 1] === '@';
        },
    },

    methods: {
        open(params = {}) {
            this.isOpen = true;
            this.fragment = params.fragment;

            if (params.commentId) {
                this.commentId = params.commentId;
            }
            if (params.messages) {
                this.messages = params.messages;
            }

            this.styles = { top: `${params.top}px` };

            setTimeout(() => {
                this.$refs.textarea.$el.focus();
                document.addEventListener('click', this.clickOutsideHandler);
            });

            if (window.isMobileOrTablet) {
                window.disableScroll();
            }
        },

        close() {
            this.isOpen = false;
            this.message = '';
            this.editingMessage = {};
            this.messages = [];
            document.removeEventListener('click', this.clickOutsideHandler);
            this.$emit('close');

            if (window.isMobileOrTablet) {
                window.enableScroll();
            }
        },

        closeOnMobile() {
            if (window.isMobileOrTablet) {
                this.close();
            }
        },

        getUserFirstLetters(user) {
            return `${user.username?.[0] || ''}${user.surname?.[0] || ''}`;
        },

        commentText(message) {
            return messageWithMentionUsers(message, this.invitedUsers);
        },

        addMessage(message) {
            this.messages.push(message);
        },

        updateMessage (message) {
            this.messages.find(v => v._id === message._id).text = message.text;
        },

        removeMessage (message) {
            const indexMessage = this.messages.findIndex(v => v._id === message._id);
            this.messages.splice(indexMessage, 1);
        },

        updateCommentId(id) {
            this.commentId = id;
        },

        addMention(mention) {
            if (!this.isAllowCommentsByPermission) return;

            this.message += mention + ' ';
            this.$refs.textarea.$el.focus();
        },

        clickOutsideHandler(e) {
            if (!e.target.closest('.editor-comments-popup')) {
                this.close();
            }
        },

        resolveComment() {
            if (!this.isAllowCommentsByPermission) return;

            this.$emit('resolveComment', this.commentId);
        },

        deleteComment() {
            if (!this.isAllowCommentsByPermission) return;

            this.$emit('deleteComment', this.commentId);
        },

        editMessage(message) {
            if (!this.isAllowCommentsByPermission) return;

            this.editingMessage = message;
            this.message = message.text;
        },

        deleteMessage(message) {
            if (!this.isAllowCommentsByPermission) return;

            this.$emit('deleteMessage', message);
        },

        send() {
            if (!this.isAllowCommentsByPermission) return;

            if (isEmpty(this.editingMessage)) {
                this.$emit('send', this.message);
            } else {
                const message = {
                    _id: this.editingMessage._id,
                    comment_id: this.editingMessage.comment_id,
                    text: this.message,
                };
                this.$emit('editMessage', message);
            }

            this.message = '';
            this.editingMessage = {};
        },
    },
};
</script>

<style scoped lang="scss">
.editor-comments-popup {
    position: absolute;
    right: rem(-305px);
    z-index: 1;

    width: rem(339px);

    .menu {
        &__item {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
                white-space: nowrap;
            }
            svg {
                min-height: rem(13px);
                height: rem(13px);
            }
            svg + span {
                margin-left: rem(13px);
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        width: 100%;
        // padding: rem(12px);

        background: $white;
        box-shadow: 0px 0px 9.8px rgba($black0, 0.1);
        border-radius: 9px;
        overflow: hidden;
    }

    .top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: rem(11px) rem(12px);

        &__resolve {
            font-weight: 500;
            font-size: rem(12px);
            color: $grey-dark5;
            padding: rem(3px) rem(7px);
            border-radius: 4px;
        }

        &__more {
            padding: rem(1px) rem(1px);
            border-radius: 4px;
        }

        &__resolve, &__more {
            display: flex;
            background: $white;
            cursor: pointer;
            transition: all 0.2s;

            @media (hover: hover) {
                &:hover {
                    color: $grey-dark5;
                    background-color: $grey-light28;
                }
            }
        }

        .top__resolve + .top__more {
            margin-left: rem(14px);
        }
    }

    .comments {
        padding: rem(10px) rem(12px);
    }

    .top + .comments {
        border-top: 1px solid $grey-light31;
    }

    .comment {
        display: flex;
        align-items: center;
        margin-bottom: rem(16px);

        &__avatar {
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            width: rem(30px);
            height: rem(30px);
            margin-right: rem(7px);
            overflow: hidden;

            font-weight: 500;
            color: $white;

            background: $blue1;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        &__content {
            width: 100%;
        }

        &__header {
            display: flex;
            justify-content: space-between;


        }

        &__name {
            max-width: rem(150px);
            margin-right: rem(8px);
            overflow: hidden;

            white-space: nowrap;
            text-overflow: ellipsis;


            font-weight: 500;
            font-size: rem(11px);
            line-height: rem(13px);

            color: $black0;
        }

        &__date {
            color: #888787;
        }

        &__text {
            font-size: rem(10px);
            line-height: rem(12px);
            font-weight: 400;
            word-break: break-word;

            color: $black0;

            &::v-deep {
                .mention {
                    margin-right: rem(4px);

                    color: $blue2;
                }
            }
        }

        .comment__header + .comment__text {
            margin-top: rem(7px);
        }

        .comment__more {
            cursor: pointer;
        }
    }

    .new-comment {
        display: flex;
        flex-wrap: wrap;
        padding: rem(12px) rem(6px) rem(6px) rem(12px);
        background-color: $grey-light33;
        position: relative;

        textarea {
            width: calc(100% - #{rem(40px)});
            margin-top: rem(5px);
            margin-bottom: rem(8px);

            font-weight: 500;
            font-size: rem(12px);
            line-height: rem(15px);

            color: $grey4;
        }

        &__bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            margin-top: rem(8px);
        }

        .info {
            font-weight: 500;
            font-size: rem(10px);
            line-height: rem(12px);
            color: $grey-light34;
        }

        .controls {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &__item {
                width: rem(76px);
            }
            .controls__item + .controls__item {
                margin-left: rem(5px);
            }
        }
    }

    @include little_pc_and_mobile {
        right: rem(-280px);
    }

    @media screen and (max-width: 1366px){
        right: rem(-220px);
    }

    @media screen and (max-width: 1300px){
        right: rem(-200px);
    }

    @media screen and (max-width: 1260px){
        right: rem(-180px);
    }

    @media screen and (max-width: 1180px){
        right: rem(-150px);
    }

    @media screen and (max-width: 1170px){
        right: rem(-130px);
    }

    @media screen and (max-width: 1110px){
        right: rem(-105px);
    }

    @include mobile_or_tablet {
        position: fixed;
        top: 0 !important;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;

        display: flex;
        align-items: flex-end;

        width: 100%;

        background-color: rgba($black, 0.6);

        .menu {
            padding: rem(15px);
            &__item {
                svg {
                    min-height: rem(20px);
                    height: rem(20px);
                }
                svg + span {
                    margin-left: rem(15px);
                }
                span {
                    font-size: rem(16px);
                    line-height: rem(20px);
                }
            }
        }

        .content {
            min-height: 60vh;
            max-height: 75vh;
            overflow: auto;

            border-radius: rem(20px) rem(20px) 0 0;
        }

        .top {
            flex-wrap: nowrap;

            &__name {
                width: calc(100% - #{rem(100px)});
                // margin-bottom: rem(16px);

                font-size: rem(24px);
                font-weight: 600;
                line-height: rem(29px);
            }

            &__fragment, &__resolve {
                font-size: rem(18px);
                line-height: rem(22px);
            }

            &__resolve {
                font-size: rem(18px);
                line-height: rem(22px);
                padding: rem(4px) rem(14px);
                border-radius: rem(3px);
            }

            &__more,
            &__resolve {
                background-color: $white;
            }
        }

        .top,
        .comments {
            padding: rem(20px) rem(21px);
        }

        .comment {
            &__avatar {
                width: 62px;
                height: 62px;
                margin-right: rem(12px);

                font-size: rem(24px);
            }

            &__name {
                font-size: rem(18px);
                line-height: rem(22px);
            }

            &__text {
                font-size: rem(18px);
                line-height: rem(22px);
            }

            .comment__header + .comment__text {
                margin-top: rem(11px);
            }
        }

        .new-comment {
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: rem(8px) rem(21px);
            background-color: $white;
            position: inherit;

            textarea {
                font-size: rem(16px);
                font-weight: 500;
            }

            &__bottom {
                width: auto;
                margin-top: 0;
            }

            .controls {
                &__item {
                    width: rem(69px);
                }

                .controls__item--small + .controls__item {
                    margin-left: rem(15px);
                }
            }

        }

        &-enter-active,
        &-leave-active {
            transition:  background-color .5s;

            .content {
                transition: transform .5s;
            }
        }

        &-enter,
        &-leave-to {
            background-color: transparent;

            .content {
                transform: translateY(100%);
            }
        }
    }
}
.app-button {
    max-height: 29px !important;
}
</style>
