<template lang="pug">
    vue-context.move-to-folders-context(
        ref="context"
        @open="openHandler"
    )
        .list(ref="list")
            div
                .list__item(
                    v-for="folder in folders"
                    @click="moveTo( document, folder.id)"
                ) {{ folder.name}}
                .list__empty(v-if="!folders.length") Nowhere to move
</template>

<script>
import Scrollbar from 'smooth-scrollbar';
import { getAllFolders } from '@/js/api/requests/folder';
import moveToFolderForComponentMixin from '@/js/mainContextMenu/moveToFolderForComponentMixin';

// TO DO надо сделать чтоб работало и для папок а не ток документов
export default {
    name: 'move-to-folders-context',
    mixins: [moveToFolderForComponentMixin],

    data: () => ({
        document: null,
    }),

    mounted() {
        Scrollbar.init(this.$refs.list);
    },

    methods: {
        // TO DO потом нужно будет переделать на передачу родителя,
        // для папки room_id, folder_id, project_id, library: true
        // для документа project, room, folder, надо будет добавить ещё либрари хотя нарно лучше вообще переделать и на беке
        open(e, entity, isDocOrFolder = true) {
           setTimeout( async () => {
               this.isDocOrFolder = isDocOrFolder;
               try {
                   this.folders = await getAllFolders();
                   this.$refs.context.open(e);
                   if (e.id ) {
                       this.document = e.id;
                   } else {
                       return;
                   }
                   e.id ? this.document = e.id : null;
               } catch (e) {
                   this.$awn.alert('Something went wrong, please try again later');
               }
           }, 500);
        },

        openHandler() {
            // Это все дабы закрыть меню при открытии другого меню
            if (window.openedContextMenu && window.openedContextMenu !== this.$refs.context) {
                window.openedContextMenu.close();
                delete window.openedContextMenu;
            }
            window.openedContextMenu = this.$refs.context;
        },
    },
};
</script>

<style scoped lang="scss">
.move-to-folders-context {
    width: 15rem;

    .list {
        max-height: 15rem;

        &__item {
            padding: rem(16px);

            overflow-wrap: break-word;

            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    background-color: rgba($grey, 0.1);
                }
            }
        }

        &__empty {
            padding: rem(16px);
        }
    }
}
</style>
