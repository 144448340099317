<template lang="pug">
    .dashboard
        .default-layout-head
            p Dashboard

        app-loader(v-if="pageLoading")
        template(v-else)
            .dashboard-item
                .documents(v-if="documentsViewTypeCards && workInProgress.length" :class="{'space-between': workInProgress.length % 5 === 0 }")
                    .documents-item(
                        v-for="(item, index) in workInProgress"
                        :key="item.id"
                        :class="{'selected' : selectedTab === index}"
                        @click="getSelectedTab(index)"
                        @dblclick="documentDblclickHandler(item)"
                        @contextmenu.prevent.stop="openDocContext($event, item)"

                    )

                        .documents-item__header
                            .documents-item__header-icon(v-if="item.icon") {{ item.icon }}
                            .documents-item__header-icon(v-else) ✌
                            .documents-item__header-title {{splitText(item.name, 20)}}
                            dot-icon.three-dots-menu(@click="openEditPopup(item.id )")



                        .documents-item__content
                            img(:src="item.image" :alt="item.name" v-if="item.image !== null")
                            p(v-else) Please edit the document for the preview to load
                        context-menu(
                            ref="dashboardContext"
                            :items="['moveTo', 'rename', 'addToFavorite', 'download', 'delete']"
                        )


                .list(v-else-if="!documentsViewTypeCards && workInProgress.length")
                    documents-table(
                        :items="workInProgress"
                        :headers="headersForTable"
                        without-groups
                        is-dashboard
                        @update="fetchDashboard"
                    )

                no-docs(v-else)
            .dashboard-item
                .projects
                    .projects__header Projects
                    horizontal-scroll.projects__content.horizontal-scroll(ref="projectsContent" v-if="projects.length" )
                        .projects-item(v-for="project in projects" @click="projectClickHandler(project)")
                            .projects-item__inner-wrapper
                                .projects-item__header
                                    .projects-item__icon
                                        p {{project.image ? project.image : '' }}
                                .projects-item__title {{project.name && splitText(project.name, 18)}}
                                .projects-item__content {{project.description}}
                    .noProjects(v-else @click="createProject")
                        noProjectsIcon
                        p Click here to create a Project

            app-to-top-link

            //uploading-popup
            EditorInvitePopup


            move-to-folders-context(ref="moveToFoldersContext")
            export-document-context(ref="exportDocumentContext")
            DashboardActionsPopup
            context-menu(
                ref="contextMenu"
                :items="['share', 'download', 'rename', 'moveTo', 'duplicate', 'delete']"
                @update="fetchDashboard"
            )
            export-document-context-popup
            move-to-folders-popup
</template>

<script>
import docIcon from '@/assets/images/icons/doc.svg?inline';
import arrowBottomIcon from '@/assets/images/icons/arrow_bottom.svg?inline';
import noDocumentsIcon from '@/assets/images/icons/no_documents.svg?inline';
import noSharedDocumentsIcon from '@/assets/images/icons/no_shared_documents.svg?inline';
import noProjectsIcon from '@/assets/images/icons/no_projects.svg?inline';
import HorizontalScroll from 'vue-horizontal-scroll';
import dotIcon from '@/assets/images/icons/dot.svg?inline';

import Notes from '@/components/other/notes/index';
import AppToTopLink from '@/components/app/app-to-top-link/index';
import UploadingPopup from '@/components/other/UploadingPopup';
import { isEmpty } from 'lodash';
import CreateDocumentMixin from '@/mixins/CreateDocumentMixin';
import { getDashboard } from '@/js/api/requests/common';
import ChildrenMixin from '@/mixins/ChildrenMixin';
import moment from 'moment';
import ThreeDotsMenu from '@/components/other/ThreeDotsMenu';
import CreateFolderPopup from '@/components/other/CreateFolderPopup';
import DashboardActionsPopup from '@/components/dashboard/DashboardActionsPopup';
import EditorInvitePopup from '@/components/editor/invitePopup/EditorInvitePopup';
import MoveToFoldersContext from '@/components/other/MoveToFoldersContext';
import ExportDocumentContext from '@/components/other/ExportDocumentContext';
import ContextMenuGeneratorMixin from '@/mixins/ContextMenuGeneratorMixin';
import DocumentsTable from '@/components/common/documentsTable';

import { splitText } from '@/js/utils/text';
import { getDocumentById } from '@/js/api/requests/document';
import ExportDocumentContextPopup from '@/components/other/ExportDocumentContextPopup';
import MoveToFoldersPopup from '@/components/other/moveToFoldersPopup';
import NoDocs from '@/components/other/noDocs/noDocs';
import { acceptMemberReq } from '@/js/api/requests/team';


export default {
    name: 'dashboard',

    components: {
        NoDocs,
        MoveToFoldersPopup,
        ExportDocumentContextPopup,
        MoveToFoldersContext,
        ExportDocumentContext,
        EditorInvitePopup,
        DashboardActionsPopup,
        CreateFolderPopup,
        ThreeDotsMenu,
        HorizontalScroll,
        UploadingPopup,
        AppToTopLink,
        Notes,
        docIcon,
        noDocumentsIcon,
        noSharedDocumentsIcon,
        noProjectsIcon,
        arrowBottomIcon,
        dotIcon,
        DocumentsTable,
    },

    mixins: [CreateDocumentMixin, ChildrenMixin, ContextMenuGeneratorMixin],

    data: () => ({
        date: null,
        workInProgress: [],
        projects: [],
        selectedTab: null,
        pageLoading: true,
        isDashboard: true,
        splitText,
        headersForTable: ['Name', 'Updated', 'Created'],
    }),

    provide() {
        return {
            copyLink: this.copyLink,
        };
    },

    computed: {

        isMobileOrTablet() {
            return window.isMobileOrTablet;
        },

        documentsViewTypeCards() {
            return this.$store.getters.documentsViewTypeCards;
        },

        user() {
            return this.$store.getters.user;
        },

        hasInvite() {
            return this.$store.getters.getTeamInviteId;
        },
    },

    mounted() {
        if (!this.user.onboarded) {
            this.$modal.show('onboarding-modal');
        }

        if (this.hasInvite) {
            acceptMemberReq({ id: this.hasInvite }).then(() => {
                this.$store.dispatch('updateTeamInviteId', null);
                this.$store.dispatch('fetchUser', {});
            });

        }
    },

    created() {
        this.fetchDashboard();
    },

    methods: {

        async fetchDashboard() {
            try {
                this.pageLoading = true;

                const dashboard = await getDashboard();

                this.projects = dashboard.projects[0].result.filter(el => el.length === undefined) || [];

                this.workInProgress = dashboard.work_in_progress || [];
                this.workInProgress = this.workInProgress
                    .sort((d1, d2) => +moment(d2.opened_at) - +moment(d1.opened_at))
                    .slice(0, 10);
            } catch (e) {
                this.$awn.alert('An error occurred while loading data, please try again later');
            } finally {
                this.pageLoading = false;
            }
        },

        getSelectedTab(index) {
            return this.selectedTab === index ? this.selectedTab = null : this.selectedTab = index;
        },

        documentDblclickHandler(item) {
            this.$router.push(`/editor/${item.id}`);
        },

        projectClickHandler(item) {
            this.$router.push(`/projects/${item.id}`);
        },

        formatedDate(date) {
            return moment(date).fromNow();
        },

        createProject() {
            this.$modal.show('create-project-popup');
        },

        async openEditPopup(id) {
            const document = await getDocumentById(id);
            this.$modal.show('dashboard-actions-popup', { id: id, doc: document });
        },

        isEmpty,
    },
};
</script>

<style lang="scss" scoped>
.dashboard {
    max-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;

    &-datepicker {
        background: transparent;
        height: 21.25rem;
        @media screen and (max-width: 767px) {
            height: auto;
        }
    }

    .dashboard-item {

        &:first-child {
            height: 100%;

            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                background-color: #4A4A4A;
            }
        }

        &:nth-child(2) {
            height: 100%;
            overflow-y: scroll;


            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2px;
                background-color: #4A4A4A;
            }
        }

        &:nth-child(3) {
            margin-top: auto;
            bottom: 0;
            padding: 0 12px;
        }
    }

    &-files {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-between;

        width: 100%;
        height: 21rem;
        margin-bottom: -1.85rem;

        &__item {
            display: flex;
            align-items: center;

            width: 45%;
            margin-bottom: 1.5rem;
            margin-left: 0.5rem;

            cursor: pointer;
            transition: box-shadow .1s;

            user-select: none;

            svg {
                flex-shrink: 0;

                width: 3rem;
                margin-right: 1.25rem;
            }

            span {
                max-width: 11rem;
                overflow: hidden;

                line-height: normal;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            @media (hover: hover) {
                &:hover {
                    box-shadow: 0 0 0 0.625rem $hover-background, inset 0 0 0 10rem $hover-background;
                }
            }

            &--has-notification {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: rem(10px);
                    height: rem(10px);

                    background: $red;
                    border-radius: 50%;

                    transform: translate(-35%, -35%);
                }
            }

        }
    }

    &-projects {
        display: flex;
        flex-wrap: wrap;

        width: 100%;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;

            position: relative;

            width: 9%;
            @media screen and (max-width: 1450px) {
                width: 15%;
            }
            @media screen and (max-width: 1200px) {
                width: 20%;
            }

            margin-bottom: 1rem;
            padding: 0 0.5rem;

            transition: box-shadow .1s;

            &__icon {
                width: rem(66px);
                height: rem(66px);

                margin-bottom: 1rem;
                padding: rem(14px);

                border-radius: rem(10px);
            }

            p {
                width: 100%;
                height: 100%;

                object-fit: contain;
            }

            span {
                font-size: rem(16px);
                line-height: 1.25;
                font-weight: 500;
                text-align: center;
                overflow-wrap: break-word;

                max-width: 5rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            @media (hover: hover) {
                &:hover {
                    box-shadow: 0 0 0 0.5rem $hover-background, inset 0 0 0 10rem $hover-background;
                }
            }
        }


    }

    .default-layout-block.dashboard-notes {
        padding: 0;
        margin-bottom: 1.125rem;
    }

    .default-layout-block.dashboard-datepicker {
        padding: 1rem 0;
        margin-bottom: 1.125rem;

        .app-datepicker {
            margin: 0 auto;
        }
    }

    .default-layout-head {
        padding-left: rem(15px);

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            margin-top: 2px;
        }
    }

    .default-layout-block:last-of-type {
        flex-shrink: 0;

        width: rem(290px);
    }


    &__more {
        @media screen and (min-width: 901px) and (max-width: 1384px) {
            display: none;
        }
    }

    @include mobile_or_tablet {
        &__more {
            display: inline-flex;
            align-items: center;

            float: right;

            margin: 0.65rem 0 0 auto;

            font-size: 1.2rem;
            color: $blue;

            svg {
                width: 0.9rem;
                margin-left: 0.4rem;

                transform: rotate(-90deg);

                stroke: $blue;
            }
        }

        &-files {
            height: auto;
            min-height: 12rem;
            margin-bottom: 0;

            &__item {
                width: 100%;
                margin-bottom: 0.75rem;
                margin-left: 0;
                padding-bottom: 0.75rem;

                font-size: 1.18rem;

                border-bottom: 1px solid #e9e9e9;

                svg {
                    width: 1.8rem;
                }

                span {
                    max-width: 100%;
                }

                &:active {
                    background: $hover-background;
                }
            }
        }

        &-projects {
            min-height: 12rem;

            &__item {
                width: 33%;
                margin-bottom: 1.5rem;
            }
        }

        .default-layout-block:last-of-type {
            width: 100%;
        }
    }
}

.documents {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    gap: 19px;
    padding: 6px rem(13px) 0;
    max-width: 100%;
    cursor: pointer;
    justify-content: unset;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #4A4A4A;
    }

    &-item {
        width: 19%;
        height: 439px;

        padding: rem(15px) 0;

        background: $white;
        box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.1);
        border-radius: 9px;
        overflow: hidden;

        &__header {
            width: 90%;
            display: flex;
            align-items: center;
            padding: 15px 0 7px 0;
            border-bottom: 1px solid $grey2;
            margin: auto;

            &-title {
                font-weight: 500;
                font-size: 14px;
                line-height: rem(17px);
                color: $black;
            }

            &-icon + &-title {
                margin-left: rem(7px);
            }
        }

        &.selected {
            background: rgba(106, 112, 255, 0.08);
            border: 1px solid $blue8;
        }

        &__content {
            height: 100%;

            img {
                width: 100%;
                padding: 13px 13px;
            }

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 8px;
                height: 100%;
                font-weight: 500;
                line-height: 10px;
                color: $grey-light3;

            }
        }

        &__header + &__content {
            margin-top: 12px;
        }

        .three-dots-menu {
            display: none;
        }

    }

    @include little_pc_and_mobile {
        &-item {
            width: 18.6%;
        }
    }

    @include mobile_or_tablet {
        justify-content: center;
        overflow-x: hidden;


        &-item {
            width: 100%;
            height: 215px;
            margin-bottom: 13px;
            padding: 0px 15px 0px 15px;
            overflow: hidden;
            box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.1);
            top: 4px;

            &__header {
                width: 100%;
            }

            &__content {
                img {
                    min-width: 100%;
                    padding: 0;
                }
            }

            .three-dots-menu {
                display: flex;
                margin-left: auto;
                top: 4px;
                z-index: 11;
            }
        }
    }
}

.projects {
    padding: rem(23px) rem(15px);
    height: rem(256px);
    border-radius: 9px;
    max-width: 100%;
    display: grid;
    background-color: $grey-dark8;
    margin: 34px 0 15px 0;


    &__header {
        font-weight: 500;
        font-size: rem(18px);
        line-height: rem(22px);

        color: $black;

        &._horizontal-scroll {
            scroll-behavior: smooth;
        }
    }

    &__content {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &::-webkit-scrollbar {
            height: 3px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #4A4A4A;
        }
    }

    &__header + &__content {
        margin-top: rem(35px);
    }

    &-item {
        cursor: pointer;
        flex: 0 0 auto;
        position: relative;
        padding: rem(15px);

        &__inner-wrapper {
            border-radius: 9px;
            box-shadow: 0px 0px 9.8px rgba(0, 0, 0, 0.15);
            background-color: $white;
            padding: rem(12px);
            width: rem(250px);
            height: rem(110px);
        }

        &__title {
            font-weight: 500;
            font-size: rem(18px);
            line-height: rem(22px);
            color: #000000;
        }

        &__content {
            font-weight: 500;
            font-size: 13px;
            line-height: rem(16px);
            margin-top: 7px;
            color: #575757;
            white-space: break-spaces;
        }

        &__icon {
            position: absolute;
            right: 0;
            top: 2px;
            width: rem(55px);
            height: rem(55px);

            p {
                font-size: 29px;
            }
        }

        &__header + &__content {
            margin-top: rem(10px);
        }
    }

    .noProjects {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
            width: 76px;
            height: 66px;
            margin-bottom: 17px;
        }

        p {
            color: $grey-dark7;
        }
    }

    @include mobile_or_tablet {
        &__header + &__content {
            margin-top: 0;
        }
    }
}

.list {
    margin-bottom: rem(34px);
    padding: 0 13px;

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 7px;

        tr {
            transition: .4s ease-in-out;

            &:first-child {
                th {
                    position: relative;
                    border-bottom: 1px solid $grey-light13;
                    border-top: 1px solid $grey-light13;

                    &:not(:first-child) {
                        &:before {
                            content: '';
                            position: absolute;
                            height: 70%;
                            left: -13px;
                            background-color: $grey-light13;
                            width: 1px;
                        }
                    }
                }
            }

            &:not(:first-child) {
                padding: rem(7px) 0;

                &.selectedTab {
                    td {
                        background-color: $grey-light28;
                    }
                }

                &:hover {
                    td {
                        background-color: $grey-light28;
                    }
                }
            }
        }

        th {
            color: #787878;
            padding: 5px 0 5px;

            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: left;

            &:first-child {
                padding-left: rem(13px);
            }
        }

        td {
            cursor: pointer;
            vertical-align: middle;

            padding: 7px 0;

            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #787878;

            transition: .4s ease-in-out;

            &:nth-of-type(1) {
                width: 50%;
                border-radius: 10px 0 0 10px;
            }

            &:nth-of-type(2) {
                width: 25%;
            }

            &:last-child {
                border-radius: 0 10px 10px 0;
            }
        }

        .list-elem-info {
            display: flex;
            align-items: center;
            padding-left: rem(13px);

            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;

                width: rem(20px);
                height: rem(26px);
                margin-right: rem(18px);
            }

            &__title {
                color: #000000;
            }
        }

        @include mobile {
            tr {
                &:first-child {
                    display: none;
                }

                &:not(:first-child) {
                    td:not(:first-child) {
                        display: none;
                    }
                }
            }
        }

    }

}

.link-input-hidden {
    @include hidden_input_for_copy();
}
</style>
