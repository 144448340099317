import Quill from 'quill';
const Inline = Quill.import('blots/inline');

class SearchedStringBlot extends Inline {}
SearchedStringBlot.blotName = 'searchedString';
SearchedStringBlot.className = 'ql-searched-string';
SearchedStringBlot.tagName = 'div';
Quill.register(SearchedStringBlot);

class SearchedActiveStringBlot extends Inline {}
SearchedActiveStringBlot.blotName = 'searchedActiveString';
SearchedActiveStringBlot.className = 'ql-searched-active-string';
SearchedActiveStringBlot.tagName = 'div';
Quill.register(SearchedActiveStringBlot);

class Searcher {
    constructor(quill) {
        this.quill = quill;
        this.results = [];
        this.currentResultIndex = 0;

        const input = document.getElementById('editor-search-input');
        if (!input) return;
        this.input = input;
        this.numberField = document.getElementById('editor-search-number');
        this.totalField = document.getElementById('editor-search-total');
        this.prevBtn = document.getElementById('editor-search-prev-btn');
        this.nextBtn = document.getElementById('editor-search-next-btn');


        this.input.addEventListener('input', this.search.bind(this));
        this.input.addEventListener('keyup', this.keyUp.bind(this));
        this.input.addEventListener('focus', this.getEditorText.bind(this));

        /*document
          .getElementById('replace')
          .addEventListener('click', this.replace.bind(this));
        document
          .getElementById('replace-all')
          .addEventListener('click', this.replaceAll.bind(this));*/
    }

    keyUp(e) {
        if (e.keyCode === 38) {
            this.changeActive.call(this, 'prev');
        } else if (e.keyCode === 40 || e.keyCode === 13) {
            this.changeActive.call(this, 'next');
        }
    }

    search() {
        this.removeStyle();
        Searcher.SearchedString = this.input.value;
        if (Searcher.SearchedString) {
            let re = new RegExp(Searcher.SearchedString, 'gi');
            this.currentResultIndex = 0;
            if (re.test(this.editorText)) {
                this.results = this.editorText.getIndicesOf(Searcher.SearchedString);
                let length = Searcher.SearchedString.length;
                this.numberField.innerHTML = 1;
                this.totalField.innerHTML = this.results.length;
                this.results.forEach(startIndex => {
                    this.quill.formatText(startIndex, length, 'searchedString', true);
                });
                this.quill.formatText(this.results[0], length, 'searchedActiveString', true);
                this.scrollToResult.call(this);
            } else {
                this.numberField.innerHTML = 0;
                this.totalField.innerHTML = 0;
            }
        }
    }

    changeActive(direction) {
        const searchLength = Searcher.SearchedString.length;
        if (direction === 'next' && this.currentResultIndex < this.results.length -1 || direction === 'prev' && this.currentResultIndex > 0) {
            this.quill.formatText(this.results[this.currentResultIndex], searchLength, 'searchedActiveString', false);
            this.currentResultIndex += direction === 'next' ? 1 : -1;
            this.quill.formatText(this.results[this.currentResultIndex], searchLength, 'searchedActiveString', true);
            this.numberField.innerHTML = this.currentResultIndex + 1;
            this.scrollToResult.call(this);
        }
    }

    scrollToResult() {
        const editorOffset = this.quill.root.getBoundingClientRect().top + window.pageYOffset;
        const resultOffset = editorOffset + this.quill.getBounds(this.results[this.currentResultIndex]).top;
        if (resultOffset > window.pageYOffset + window.innerHeight || resultOffset < window.pageYOffset + editorOffset) {
            window.scrollTo({
                top: resultOffset - window.innerHeight / 2,
            });
        }
    }

    replace() {
        if (!Searcher.SearchedString) return;

        // if no occurrences, then search first.
        if (!Searcher.occurrencesIndices) this.search();
        if (!Searcher.occurrencesIndices) return;

        let results = Searcher.occurrencesIndices;

        let oldString = this.input.value;
        let newString = document.getElementById('replace-input').value;

        this.quill.deleteText(results[Searcher.currentIndex], oldString.length);
        this.quill.insertText(results[Searcher.currentIndex], newString);
        this.quill.formatText(
            results[Searcher.currentIndex],
            newString.length,
            'searchedString',
            false,
        );
        // update the occurrencesIndices.
        this.search();
    }

    replaceAll() {
        if (!Searcher.SearchedString) return;
        let oldStringLen = this.input.value.length;
        let newString = document.getElementById('replace-input').value;

        // if no occurrences, then search first.
        if (!Searcher.occurrencesIndices) this.search();
        if (!Searcher.occurrencesIndices) return;

        if (Searcher.occurrencesIndices) {
            while (Searcher.occurrencesIndices) {
                this.quill.deleteText(Searcher.occurrencesIndices[0], oldStringLen);
                this.quill.insertText(Searcher.occurrencesIndices[0], newString);

                // update the occurrencesIndices.
                this.search();
            }
        }
        this.removeStyle();
    }

    getEditorText() {
        this.editorText = this.quill.getText();
    }

    removeStyle() {
        this.quill.formatText(0, this.quill.getText().length, 'searchedString', false);
        this.quill.formatText(0, this.quill.getText().length, 'searchedActiveString', false);
    }
}

// function for utility
String.prototype.getIndicesOf = function(searchStr) {
    let searchStrLen = searchStr.length;
    let startIndex = 0,
        index,
        results = [];
    while ((index = this.toLowerCase().indexOf(searchStr.toLowerCase(), startIndex)) > -1) {
        results.push(index);
        startIndex = index + searchStrLen;
    }
    return results;
};

export default Searcher;
