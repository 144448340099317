export default {
    data: () => ({
        background: null,
        emoji: null,
    }),


    methods: {
        openModal(args) {
            this.$modal.show('change-bg-modal', args);
        },

        updateBackground(background) {
            if (typeof background !== 'string') return;
            this.background = background;
        },

        updateEmoji(emoji) {
            if (typeof emoji !== 'string') return;
            this.emoji = emoji;
        },
    },
};
