<template lang="pug">
    .format-popup
        p.format-popup__label Font
        multiselect.multiselect-custom(
            :value="currentFont"
            :searchable="false"
            :options="fontList"
            label="name"
            :allow-empty="true"
            @select="changeFont"
        )
        p.format-popup__label Text Size
        .format-popup__wrapper.text-size
            button.text-size__button(
                :disabled="fontSizeIndex === 0"
                @click="reduceFont"
            )
                minus-icon
            p.text-size__text {{ currentFontSize.name }}
            button.text-size__button(
                :disabled="fontSizeIndex === fontSizeList.length - 1"
                @click="increaseFont"
            )
                plus-icon
        p.format-popup__label Format
        .format-popup__wrapper.format.format--4
            button.format__button(
                :class="{ active: isBold }"
                @click="toggleBold"
            )
                img(src="@/assets/images/editor/icons/bold.svg")
            button.format__button(
                :class="{ active: isItalic }"
                @click="toggleItalic"
            )
                img(src="@/assets/images/editor/icons/italic.svg")
            button.format__button(
                :class="{ active: isStrike }"
                @click="toggleStrike"
            )
                img(src="@/assets/images/editor/icons/strikethrough.svg")
            button.format__button(
                :class="{ active: isUnderline }"
                @click="toggleUnderline"
            )
                img(src="@/assets/images/editor/icons/underline.svg")
        .format-popup__wrapper.format.format--4
            button.format__button(
                :class="{ active: isAlignLeft }"
                @click="changeAlign(alignList[0])"
            )
                img(src="@/assets/images/editor/icons/align_left.svg")
            button.format__button(
                :class="{ active: isAlignCenter }"
                @click="changeAlign(alignList[1])"
            )
                img(src="@/assets/images/editor/icons/align_center.svg")
            button.format__button(
                :class="{ active: isAlignRight }"
                @click="changeAlign(alignList[2])"
            )
                img(src="@/assets/images/editor/icons/align_right.svg")
            button.format__button(
                :class="{ active: isAlignJustify }"
                @click="changeAlign(alignList[3])"
            )
                img(src="@/assets/images/editor/icons/align_all.svg")
        .format-popup__wrapper.format.format--4
            button.format__button(
                :class="{ active: isBulletList }"
                @click="toggleBulledList"
            )
                img(src="@/assets/images/editor/icons/bulleted_list.svg")
            button.format__button(
                :class="{ active: isNumberedList }"
                @click="toggleNumberedList"
            )
                img(src="@/assets/images/editor/icons/numbered_list.svg")
            button.format__button(
                :class="{ active: isSubscript }"
                @click="toggleSubscript"
            )
                img(src="@/assets/images/editor/icons/subscript.svg")
            button.format__button(
                :class="{ active: isSuperscript }"
                @click="toggleSuperscript"
            )
                img(src="@/assets/images/editor/icons/superscript.svg")
        .format-popup__wrapper.format.format--3
            button.format__button
                img(src="@/assets/images/editor/icons/column-1.svg")
            button.format__button
                img(
                    style="max-width: 35px"
                    src="@/assets/images/editor/icons/column-2.svg"
                )
            button.format__button
                img(
                    style="max-width: 35px"
                    src="@/assets/images/editor/icons/column-3.svg"
                )
        p.format-popup__label Decorations
        .format-decorations
            // блок callout пока на "Паузе"
            //button.format-popup__wrapper.format-decorations__button(
            //    @click="insertBlock"
            //)
            //    span.format-decorations__block.format-decorations__text Block
            button.format-popup__wrapper.format-decorations__button
                span.format-decorations__focus.format-decorations__text Focus
        p.format-popup__label Color
        .format-colors
            button.format-colors__button(
                v-for="color in colors"
                :key="color"
                :style="{ background: color }"
                @click="changeColor(color)"
                ref="format-colors__button"
            )
            button.format-colors__button.format-colors__button--add
                plusIcon
</template>

<script>
import Multiselect from 'vue-multiselect';
import { fontList, sizeList, alignList } from '@/js/editor/const';
import plusIcon from '@/assets/images/icons/just-plus.svg?inline';
import minusIcon from '@/assets/images/icons/just-minus.svg?inline';

export default {
    name: 'editor-actions-format-popup',
    components: {
        Multiselect,
        plusIcon,
        minusIcon,
    },

    props: {
        format: {
            type: Object,
            default: () => ({}),
        },
        document: {
            type: Object,
            default: () => ({}),
        },

        callout: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            id: null,
            font: null,
            fontSizeIndex: 0,
            fontList: fontList,
            fontSizeList: sizeList,
            alignList: alignList,
            colors: [
                '#000000',
                '#535353',
                '#bbbbbb',
                '#dadada',
                '#263cff',
                '#008cf6',
                '#a8d8fd',
                '#09d604',
                '#5903e7',
                '#ff2020',
                '#ffa41c',
                '#ffdc26',
                '#f600c0',
            ],
        };
    },

    computed: {
        isBold() { return this.format.bold; },
        isItalic() { return this.format.italic; },
        isStrike() { return this.format.strike; },
        isUnderline() { return this.format.underline; },
        isAlignLeft() { return this.format.align === this.alignList[0].value; },
        isAlignCenter() { return this.format.align === this.alignList[1].value; },
        isAlignRight() { return this.format.align === this.alignList[2].value; },
        isAlignJustify() { return this.format.align === this.alignList[3].value; },
        isBulletList() { return this.format.list === 'bullet'; },
        isNumberedList() { return this.format.list === 'ordered'; },
        isSubscript() { return this.format.script === 'sub'; },
        isSuperscript() { return this.format.script === 'super'; },
        currentFont() {
            return this.fontList.find(font => this.format.font === font.value) || fontList[0];
        },
        currentFontSize() {
            return this.fontSizeList[this.fontSizeIndex];
        },
        currentAlign() {
            return alignList.find(align => this.format.align === align.value);
        },
    },

    watch: {
        format() {
            this.initFormats();
        },
    },

    mounted() {
        this.initFormats();
    },

    methods: {
        initFormats() {
            this.initFontSizeIndex();
        },
        changeFont(font) {
            this.$emit('formatting', { font: font.value });
        },
        initFontSizeIndex() {
            let index = sizeList.findIndex(size => this.format.size === size.value) || 0;
            if (index === -1) index = 0;
            this.fontSizeIndex = index;
        },
        increaseFont() {
            if (this.fontSizeIndex >= this.fontSizeList.length - 1) return;
            this.fontSizeIndex++;
            this.$emit('formatting', { size: this.currentFontSize.value });
        },
        reduceFont() {
            if (this.fontSizeIndex <= 0) return;
            this.fontSizeIndex--;
            this.$emit('formatting', { size: this.currentFontSize.value });
        },
        insertBlock() {
            this.$emit('insert-block-callout');
        },
        toggleBold() {
            this.$emit('formatting', { bold: !this.format.bold });
        },
        toggleItalic() {
            this.$emit('formatting', { italic: !this.format.italic });
        },
        toggleStrike() {
            this.$emit('formatting', { strike: !this.format.strike });
        },
        toggleUnderline() {
            this.$emit('formatting', { underline: !this.format.underline });
        },
        changeAlign(align) {
            if (this.currentAlign.value === align.value) {
                this.$emit('formatting', { align: undefined });
            } else {
                this.$emit('formatting', { align: align.value });
            }
        },
        toggleBulledList() {
            this.$emit('formatting', { list: this.format.list !== 'bullet' ? 'bullet' : false });
        },
        toggleNumberedList() {
            this.$emit('formatting', { list: this.format.list !== 'ordered' ? 'ordered' : false });
        },
        toggleSubscript() {
            this.$emit('formatting', { script: this.format.script !== 'sub' ? 'sub' : false });
        },
        toggleSuperscript() {
            this.$emit('formatting', { script: this.format.script !== 'super' ? 'super' : false });
        },
        changeColor(color) {
            if (this.format.color === color) {

                this.$emit('formatting', { color: undefined });
                if (!this.callout) return;

                document.getElementById(`${this.callout}`).setAttribute('data-callout-background', color);

            } else {
                this.$emit('formatting', { color: color });

                if (!this.callout) return;
                document.getElementById(`${this.callout}`).setAttribute('data-callout-background', color);
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.format-popup {
    &__label {
        margin: 17px 0 11px;
        color: $grey-dark6;
        font-size: 12px;
        line-height: 14px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__wrapper {
        background: #FAFAFA;
        border-radius: 4px;
    }
};

.text-size {
    display: grid;
    grid-template-columns: auto 34px auto;
    width: 80px;
    height: 30px;

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;

        &:disabled {
            cursor: default;

            svg {
                stroke: #DADADA;
            }
        }
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        font-size: 14px;
        border-left: 1px solid #DADADA;
        border-right: 1px solid #DADADA;
    }
}

.format {
    display: grid;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    overflow: hidden;

    &--3 {
        grid-template-columns: repeat(3, 1fr);
    }

    &--4 {
        grid-template-columns: repeat(4, 1fr);
    }

    &__button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
            background: #E2ECFF;
        }

        &::after {
            content: '';
            position: absolute;
            top: 6px;
            right: 0;
            bottom: 6px;
            width: 1px;
            background: #DADADA;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        img {
            max-width: 20px;
        }
    }
}

.format-decorations {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;

    &__button {
        padding: 8px 16px;
        cursor: pointer;
    }

    &__text {
        display: block;
        width: 100%;
        padding: 5px 0;
        font-size: 12px;
    }

    &__block {
        color: #5C5C5C;
        background: #E1E1E1;
        border-radius: 4px;
    }

    &__focus {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 4px;
            background: #000000;
            border-radius: 2px;
        }
    }
}

.format-colors {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;

    &__button {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        cursor: pointer;

        &--add {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            background: #DADADA;

            svg {
                stroke: #898989;
            }
        }
    }
}
</style>

<style lang="scss">
.multiselect {
    &--active {
        .multiselect__single {
            &::after {
                content: '';
                position: absolute;
                right:8px;
                top: 6px;
                width: 6px;
                height: 1px;
                background: #000000;
                transition: .1s;
                transform: rotate(45deg);
            }

            &::before {
                content: '';
                position: absolute;
                right:12px;
                top: 6px;
                width: 6px;
                height: 1px;
                background: #000000;
                transition: .1s;
                transform: rotate(-45deg);
            }
        }
    }

    &__single {
        position: relative;
        width: 100%;
        display: inline-block;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            right:8px;
            top: 6px;
            width: 6px;
            height: 1px;
            background: #000000;
            transition: .1s;
            transform: rotate(-45deg);
        }

        &::before {
            content: '';
            position: absolute;
            right:12px;
            top: 6px;
            width: 6px;
            height: 1px;
            background: #000000;
            transition: .1s;
            transform: rotate(45deg);
        }
    }

    &__tags {
        padding: 9px 5px;
        background: #FAFAFA;
        border-radius: 4px;
    }

    &__content {
        width: 100%;
        margin-top: 5px;
        background: #FAFAFA;
        border-radius: 4px;
    }

    &__element {
        width: 100%;
        padding: 9px 5px;
        cursor: pointer;
    }
}

.multiselect-custom {
    position: relative;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    border: none !important;

    &:after {
        content: '';
        position: absolute;
        right: 0;
        background: url(../../../assets/images/icons/arrow_select.png);
    }
    span {
        font-size: 12px;
    }

    .multiselect__content-wrapper {
        .multiselect__content {
            max-height: 200px !important;
            overflow: hidden;
            overflow-y: scroll;
        }
    }
}
</style>
