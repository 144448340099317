import { get, post } from '@/js/api/http-helper';

export async function getFavorites() {
    const data = await get('/favorites/get');
    return data.data;
}

export function addToFavorites(fd) {
    return post(`/favorite/add`, fd);
}

export function deleteFromFavorites(fd) {
    return post(`/favorite/restore/by-entities`, fd);
}
