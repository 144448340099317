<template lang="pug">
    modal.app-modal(
        :name="name"
        @before-open="beforeOpen"
        @opened="opened"
        @before-close="beforeClose"
        @closed="closed"
        height="auto"
    )
        .app-modal__container
            slot

            .app-modal__closer(
                v-if="closer"
                @click="close"
            )
                closer-icon

            .app-modal__closer.forMobile_or_p(
                v-else-if="mobileCloser"
                @click="close"
            )
                closer-icon
</template>

<script>

import closerIcon from '@/assets/images/icons/closer_2.svg?inline';

export default {
    name: 'app-modal',

    components: {
        closerIcon,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        closer: {
            type: Boolean,
            default: false,
        },

        mobileCloser: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        beforeOpen(e) {
            this.$emit('beforeOpen', e);
            window.disableScroll();
        },

        opened() {
            this.$emit('opened');
        },

        beforeClose() {
            this.$emit('beforeClose');
            window.enableScroll();
        },

        closed() {
            this.$emit('closed');
        },

        close() {
            this.$modal.hide(this.name);
        },
    },
};
</script>
