<template>
    <div class="background">
        <div class="preview">
            <img
                :src="imageToRender"
                alt="preview_image"
            />
        </div>
    </div>
</template>

<script>

export default {
    name: 'editor-slash-popup-example',

    props: {
        previewTitle: {
          type: String,
          default: 'Task',
        },
    },

    computed: {
        //Поменять превью документа как оно появится
        imageToRender () {
            switch (this.previewTitle) {
                case 'Task': return  require('@/assets/images/editor/components-preview/task.svg');
                case 'Image': return require('@/assets/images/editor/components-preview/image.svg');
                case 'Heading 1': return require('@/assets/images/editor/components-preview/h1.svg');
                case 'Heading 2': return require('@/assets/images/editor/components-preview/h2.svg');
                case 'Heading 3': return require('@/assets/images/editor/components-preview/h3.svg');
                case 'Mention': return require('@/assets/images/editor/components-preview/mention.svg');
                case 'Table': return require('@/assets/images/editor/components-preview/table.svg');
                case 'Link': return require('@/assets/images/editor/components-preview/link.svg');
                case 'Bulleted List': return require('@/assets/images/editor/components-preview/bulleted-list.svg');
                case 'Numbered List': return require('@/assets/images/editor/components-preview/numbered-list.svg');
                case 'Page': return require('@/assets/images/editor/components-preview/sub-doc.svg');
                default: return require('@/assets/images/editor/components-preview/bulleted-list.svg');
            }
        },
    },

};
</script>

<style scoped lang="scss">
.background{
    background: #CCCCCC;
    width: rem(123px);
    height: rem(80px);
    padding: 5px;
    margin-left: rem(10px);

    border-radius: 9px;
}
.preview {
    position: relative;

    display: flex;

    width: rem(113px);
    height: rem(70px);

    box-shadow: 0 0 9.8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    background: white;

    pointer-events: none;


  &::v-deep .ql-container{
    padding: 0;
  }
}
</style>
