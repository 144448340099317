import { moveFoldersToBin } from '@/js/api/requests/folder';

export default {
    methods: {
        openFolderContextCreator(e, folder, moveToSuccess = null, renameSuccess = null, deleteSuccess = null) {
            const menu = this.$refs.folderContext;
            menu.open(e);

            menu.$off(['moveTo', 'rename', 'delete']);

            menu.$on('moveTo',  () => {
                this.$refs.moveToFoldersContext.open(e, folder, false);
                this.$refs.moveToFoldersContext.$off('moveSuccess');
                this.$refs.moveToFoldersContext.$on('moveSuccess', () => {
                    if (moveToSuccess) {
                        moveToSuccess();
                    }
                });
            });

            menu.$on('rename', () => {
                this.$modal.show('rename-popup', {
                    entity: folder,
                    entityType: 'folder',
                    successCallback: (name, icon) => {
                        if (renameSuccess) {
                            renameSuccess(name, icon);
                        }
                    },
                });
            });

            menu.$on('delete', () => {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: 'No',
                        yes: 'Yes',
                    },
                    callback: async confirm => {
                        if (confirm) {
                            try {
                                await moveFoldersToBin(folder.id);
                                if (deleteSuccess) {
                                    deleteSuccess();
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    },
                });
            });
        },
    },
};
