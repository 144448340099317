<template lang="pug">
    .settings-profile
        .title Profile
        .user
            .user__photo
                img(v-if="avatar" :src="avatar")
                span(v-else) {{ userFirstLetters }}
            .user__name {{ user.username }} {{ user.surname }}

            .links
                label.app-link
                    input(
                        type="file"
                        @change="loadPhoto"
                    )
                    | Upload Picture
                a.app-link Choose Avatar
        .fields
            app-text-field.app-text-field--small(
                v-model="firstName"
                label="First Name:"
            )
            app-text-field.app-text-field--small(
                v-model="lastName"
                label="Last Name:"
            )
            app-select.app-text-field--small(
                v-model="businessArea"
                :options="businessAreaList"
                label="Select your Business area:"
            )

        app-button(
            @click="save"
            :loading="loading"
        ) Save Changes

</template>

<script>
import { isFileNotImage } from '@/js/utils/file';
export default {
    name: 'settings-profile',

    data: () => ({
        firstName: '',
        lastName: '',
        businessArea: {
            name: 'Finance3',
            key: 'Finance3',
        },
        avatarFile: null,
        avatarFileBase64: null,

        businessAreaList: [
            {
                name: 'Finance',
                key: 'Finance',
            },
            {
                name: 'Finance2',
                key: 'Finance2',
            },
            {
                name: 'Finance3',
                key: 'Finance3',
            },
        ],

        loading: false,
    }),

    computed: {
        user() {
            return this.$store.getters.user;
        },

        avatar() {
            return this.avatarFileBase64 || this.user.image;
        },

        userFirstLetters() {
            return `${this.user.username?.[0] || ''}${this.user.surname?.[0] || ''}`;
        },
    },

    created() {
        this.firstName = this.user.username || '';
        this.lastName = this.user.surname || '';
    },

    methods: {
        loadPhoto(e) {
            const photo = e.target.files[0];
            if (isFileNotImage(photo)) {
                this.$awn.alert('Incorrect file type. There should be a picture');
                return;
            }
            const fr = new FileReader();
            fr.onload = (() => (e) => {
                this.avatarFileBase64 = e.target.result;
                this.avatarFile = photo;
            })(photo);
            setTimeout(() => {
                fr.readAsDataURL(photo);
            });
        },

        async save() {
            if (this.loading) return;

            this.loading = true;

            try {
                const fd = new FormData;
                fd.append('username', this.firstName);
                fd.append('surname', this.lastName);
                if (this.avatarFile) {
                    fd.append('image', this.avatarFile);
                }
                await this.$store.dispatch('updateUser', fd);
                this.$awn.success('Profile was updated successfully');
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.settings-profile {
    padding: 1.5rem 1.5rem 5rem;
}

.title {
    margin-bottom: 1.5rem;

    font-size: rem(20px);
    font-weight: bold;
}

.user {
    display: flex;
    align-items: center;

    margin-bottom: 1.5rem;

    &__photo {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;

        width: rem(60px);
        height: rem(60px);
        margin-right: 1.25rem;
        overflow: hidden;

        font-size: rem(22px);
        font-weight: 500;
        color: #fff;

        background: $blue;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &__name {
        font-size: rem(18px);
    }

    .links {
        margin-left: auto;

        .app-link {
            margin-left: rem(12px);
        }

        label input {
            position: absolute;
            left: -999999px;

            visibility: hidden;
        }
    }
}

.fields {
    display: flex;
    flex-wrap: wrap;

    .app-text-field {
        width: 40%;
        margin-right: 10%;
    }
}

.app-button {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
}

@include mobile_or_tablet {
    .settings-profile {
        padding-bottom: 2rem;
    }

    .title {
        font-size: 1.5rem;
    }

    .user {
        .links {
            display: flex;
            flex-direction: column;

            .app-link {
                margin: 0.5rem 0;
            }
        }
    }

    .fields {
        .app-text-field {
            width: 100%;
            margin-right: 0;
        }
    }

    .app-button {
        position: static;

        display: flex;

        margin-top: 1rem;
        margin-left: auto;
    }
}
</style>
