<template>
    <app-modal
        class="invite-members-popup"
        mobile-closer="mobile-closer"
        name="invite-members-popup"
        @beforeOpen="beforeOpen"
        @closed="closed"
    >
        <div
            class="close-btn"
            @click="$modal.hide('invite-members-popup')"
        >
            <img
                :src="require('@/assets/images/icons/closer.svg')"
                alt="close"
            />
        </div>
        <div class="title">
            Invite People
        </div>
        <div class="subtitle">
            Your teammates will receive a notification that will give them access
            to your team. If your teammates aren’t registered they will receive an email with a link
            to the registration process
        </div>
        <div
            v-for="(item, index) in users"
            :key="index"
            class="row"
        >
            <app-text-field
                v-model="item.email"
                :error="getFieldError(index)"
                class="app-text-field--small app-text-field--theme-solid"
                placeholder="name@example.com"
                type-input="email"
            />
        </div>
        <div
            class="add-link"
            @click="addNewUser"
        >
            <div class="add-link__icon">
                <img
                    :src="require('@/assets/images/icons/plus.svg')"
                    alt="plus"
                />
            </div>
            <div class="add-link__title">
                Add More
            </div>
        </div>
        <app-button
            middle="middle"
            @click="send"
        >
            Send Invites
        </app-button>
    </app-modal>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
    name: 'invite-members-popup',

    data: () => ({
        users: [],
        members: null,
        type: null,
        id: null,
    }),

    validations: {
        users: {
            required,
            minLength: minLength(1),

            ownerEmail() {
                return !this.users.some(u => u.email === this.userEmail);
            },

            $each: {
                email: {
                    email,
                    notAccessed(value) {
                        return !this.members.some(u => u.email === value);
                    },
                },
            },
        },
    },

    computed: {
        user() {
            return this.$store.getters.user;
        },

        userEmail() {
            return this.$store.getters.user.email;
        },
    },

    methods: {
        ...mapActions(['sendInvite']),
        addNewUser() {
            this.users.push({
                email: '',
            });
            this.$v.$reset();
        },

        getFieldError(index) {
            if (this.$v.users.$each.$iter[index].$dirty) {
                if (!this.$v.users.minLength) {
                    return 'Required field';
                } else if (!this.$v.users.$each.$iter[index].email.email) {
                    return 'Incorrect e-mail format';
                } else if (!this.$v.users.$each.$iter[index].email.notAccessed) {
                    return 'This email already has access to this project';

                } else if (!this.$v.users.$each.$iter[index].email.ownerEmail) {
                    return 'You cannot invite yourself ';

                    //TODO: return
                    // } else if (!this.$v.users.$each.$iter[index].email.oneOfAll) {
                    //     return 'Fill in at least one field';
                    // }
                }
            }
            return null;
        },

        send() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.users.forEach((item) => {
                item.type = this.type;
                item.user = this.user.username;
                item.id = this.id;
                item.email === '' ? null : this.sendInvite(item);
            });

            this.$modal.hide('invite-members-popup');
        },

        beforeOpen(e) {
            this.members = e.params.members;
            this.type = e.params.type;
            this.id = e.params.id;
            this.addNewUser();
        },

        closed() {
            this.users = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.invite-members-popup {
    &::v-deep .app-modal__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        width: rem(408px);
        padding: rem(12px);
    }

    .title {
        font-weight: 500;
        font-size: rem(14px);
        line-height: rem(17px);
        color: #000000;
        width: 100%;
        text-align: center;
    }

    .subtitle {
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(15px);
        color: #828181;
        width: 100%;
        text-align: left;
    }

    .title + .subtitle {
        margin-top: rem(17px);
    }

    .subtitle + .row {
        margin-top: rem(17px);
    }

    .row {
        display: flex;
        align-items: center;
        width: 100%;

        .app-text-field {
            width: 100%;
        }

        .dropper {
            width: rem(14px);
            margin-bottom: 1.25rem;

            cursor: pointer;
        }
    }

    .add-link {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(15px);
        color: $grey-light31;
        cursor: pointer;

    }

    .add-link__icon + .add-link__title {
        margin-left: rem(6px);
    }

    .row + .row,
    .row + .add-link {
        margin-top: rem(12px);
    }

    .row + .app-button {
        margin-top: 21px;
    }

    .app-button {
        width: rem(112px);
        margin: rem(21px) auto 0 auto;
    }

    .close-btn {
        position: absolute;
        display: flex;
        width: rem(10px);
        height: rem(10px);
        right: rem(12px);
        top: rem(12px);
        cursor: pointer;
    }

    @include mobile_or_tablet {
        &::v-deep {
            .app-modal__container {
                width: 44rem;
                height: 32rem;
            }
        }
    }

    @include mobile_or_P {
        &::v-deep {
            .vm--modal {
                height: 100% !important;
            }

            .app-modal__container {
                width: 100vw;
                height: 100%;
            }

            .app-modal__closer {
                display: none;
            }
        }
    }
}
</style>
