import ReconnectingWebSocket from 'reconnecting-websocket';
import sharedb from 'sharedb/lib/client';
import richText from 'rich-text';
import wsProtocol from '@/js/wsProtocol';

sharedb.types.register(richText.type);

const connection = () => {
    const socket = new ReconnectingWebSocket(`${wsProtocol}://${process.env.VUE_APP_EDITOR_BACKEND_HOST}/editor-link`);
    return new sharedb.Connection(socket);
};

export default connection;
