<template lang="pug">
    .editor-slash-popup(
        ref="slashPopup"
        v-show="isOpen"
        v-click-outside="close"
        :style="styles"
    )
        .component-menu(ref="popup")
          .empty(v-show="notFilteredList") Nothing found
          .component(
              v-for="(component, i) in componentsFilteredList"
              @click.stop="component.click"
              @mouseover="onHover(i)"
              @mousedown.prevent
              :class="componentClasses(i)"
          )
              .image-container
                  img(:src="component.icon")
              .text-container
                  .title {{ component.title }}
                  .text {{ component.description }}
        editor-slash-popup-preview(:preview-title="selectedComponent && selectedComponent.title")
</template>

<script>
import Scrollbar from 'smooth-scrollbar';
import { keyboards } from '@/js/editor/const';
import EditorSlashPopupPreview from './EditorSlashPopupPreview.vue';

export default {
    name: 'editor-slash-popup',

    components: {
      EditorSlashPopupPreview,
    },

    props: {
        format: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isOpen: false,
            styles: {},
            searchText: '',
            indexPosition: 0,
            closeCounter: 1,
            selectedComponentIndex: -1,
            scrollbar: null,
            heightPopup: 246,
        };
    },

    computed: {
        componentsList() {
            return [
                {
                    icon: require('@/assets/images/icons/slash-popup/task.svg'),
                    title: 'Task',
                    description: 'Create and appoint tasks',
                    click: this.insertComponent('formatting', {
                        list: this.format.list !== 'unchecked' && this.format.list !== 'checked' ? 'unchecked' : false,
                    }),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/images.svg'),
                    title: 'Image',
                    description: 'Add picture to the document',
                    click: this.insertComponent('insertImage', this.rangeForComponent),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/h1.svg'),
                    title: 'Heading 1',
                    description: 'Big Heading',
                    click: this.insertComponent('formatting', { header: this.format.header !== 1 ? 1 : false }),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/h2.svg'),
                    title: 'Heading 2',
                    description: 'Medium Heading',
                    click: this.insertComponent('formatting', { header: this.format.header !== 2 ? 2 : false }),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/h3.svg'),
                    title: 'Heading 3',
                    description: 'Small Heading',
                    click: this.insertComponent('formatting', { header: this.format.header !== 3 ? 3 : false }),
                },

                {
                    icon: require('@/assets/images/icons/slash-popup/table.svg'),
                    title: 'Table',
                    description: 'Insert Table',
                    click: this.insertComponent('insertTable', [3, 3]),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/mention.svg'),
                    title: 'Mention',
                    description: 'Ping someone and they will be notified',
                    click: this.insertComponent('insertMention'),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/link.svg'),
                    title: 'Link',
                    description: 'Insert link',
                    click: this.insertComponent('insertLink', this.rangeForComponent),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/list-bulleted.svg'),
                    title: 'Bulleted list',
                    description: 'Create a bullet List',
                    click: this.insertComponent('formatting', { list: this.format.list !== 'bullet' ? 'bullet' : false }),
                },
                {
                    icon: require('@/assets/images/icons/slash-popup/list-numbered.svg'),
                    title: 'Numbered List',
                    description: 'Create a numbered list',
                    click: this.insertComponent('formatting', { list: this.format.list !== 'ordered' ? 'ordered' : false }),
                },
                {
                  icon: require('@/assets/images/icons/slash-popup/list-item.svg'),
                    title: 'Page',
                    description: 'Embed a sub-doc inside this doc',
                    click: this.insertComponent('insertDocument', {
                        meta: {
                            createBy: 'popupSymbol',
                            typeDocument: 'newDocument',
                            popup: 'slashPopup',
                            searchText: this.searchText,
                        },
                    }),
                },
            ];
        },

        componentsFilteredList() {
            if (this.searchText) return [...this.componentsList].filter(component => {
                return component.title.toLowerCase().trim().indexOf(this.searchText.toLowerCase().trim()) > -1;
            });
            return this.componentsList;
        },

        notFilteredList() {
            return this.componentsFilteredList.length === 0;
        },

        range() {
            return {
                index: this.indexPosition - 1,
                length: this.searchText.length + 1,
            };
        },

        rangeForComponent() {
            return {
                index: this.range.index,
                length: 0,
            };
        },

        selectedComponent () {
          return this.componentsFilteredList[this.selectedComponentIndex];
        },
    },
    watch: {
        notFilteredList() {
          if (this.notFilteredList) {
              this.close();
          }
        },
    },

    methods: {
        open(params) {
            window.disableScroll();
            this.isOpen = true;

            this.styles = {
                top: `${params.top}px`,
                left: `${params.left}px`,
            };

            this.$nextTick(() => {
                this.scrollbar = Scrollbar.init(this.$refs.popup);
            });
        },

        componentClasses(index) {
            return [{ 'component__selected': index === this.selectedComponentIndex }];
        },

        close() {
            this.isOpen = false;
            window.enableScroll();
        },

        onHover (idx) {
          this.selectedComponentIndex = idx;
        },

        closeAndClear(needDeleteText = true) {
            this.close();
            this.clear(needDeleteText);
        },

        clear(needDeleteText = true) {
            if (needDeleteText) {
                this.$emit('deleteText', this.range);
            }
            this.inputSearchClear();
            this.setIndexPositionClear();
            this.closeCounter = 1;
            this.selectedComponentIndex = -1;
        },

        addLetter(globalIndexPosition, letter) {
            this.setText(globalIndexPosition, (endIndexPosition) => {
                return this.searchText.slice(0, endIndexPosition)
                    + letter
                    + this.searchText.slice(endIndexPosition);
            });
        },

        removeLetter(globalIndexPosition) {
            this.setText(globalIndexPosition, (endIndexPosition) => {
                return this.searchText = this.searchText.slice(0, endIndexPosition) + this.searchText.slice(endIndexPosition + 1);
            });
        },

        setText(globalIndexPosition, getText) {
            if (this.notFilteredList) {
                if (this.closeCounter === 3) {
                    this.closeAndClear();
                    return;
                }
                this.closeCounter++;
                return;
            }

            const indexPosition = globalIndexPosition - this.indexPosition;
            this.searchText = getText(indexPosition);
        },

        inputSearchClear() {
            this.searchText = '';
        },

        setIndexPosition(index) {
            this.indexPosition = index;
        },

        setIndexPositionClear() {
            this.indexPosition = 0;
        },

        insertComponent(event, options) {
            return () => {
                this.$emit(event, options);
                this.closeAndClear();
            };
        },

        onEnter() {
            if (!this.notFilteredList && this.selectedComponentIndex >= 0) {
                this.componentsFilteredList[this.selectedComponentIndex].click();
            } else {
                this.closeAndClear(false);
            }
        },

        scroll(upOrDown = true) {
            const value = upOrDown ? -200 : 200;
            this.scrollbar.scrollTop = this.scrollbar.offset.y + value;
            this.scrollbar.update();
        },

        onArrow(keyName) {
            if (keyName === keyboards.up.keyName) {
                if (this.selectedComponentIndex + 1 > 1) {
                    if (this.selectedComponentIndex + 1 < this.componentsFilteredList.length && (this.selectedComponentIndex % 3 === 0)) {
                        this.scroll();
                    }
                    this.selectedComponentIndex--;
                }
            } else if (keyName === keyboards.down.keyName) {
                if (this.selectedComponentIndex + 1 < this.componentsFilteredList.length) {
                    if (this.selectedComponentIndex > -1 && ((this.selectedComponentIndex+1) % 3 === 0)) {
                        this.scroll(false);
                    }
                    this.selectedComponentIndex++;
                }
            }
        },

    },
};
</script>

<style scoped lang="scss">
.editor-slash-popup {
    position: absolute;
    display: flex;
    flex-direction: row;

    .component-menu {
        overflow: hidden;
        overflow-y: scroll;
        scroll-behavior: smooth;
        border-radius: rem(9px);
        background: white;
        box-shadow: 0 0 rem(9.8px) rgba(0, 0, 0, 0.25);
        width: rem(222px);
        height: 246px;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: #4A4A4A;
        }
    }

    .empty {
        padding: rem(7px);
    }

    .component {
        display: flex;

        justify-items: center;

        align-items: center;

        padding: rem(7px) rem(8px);
        padding-bottom: rem(5px);

        cursor: pointer;

        &:last-child {
            padding-bottom: rem(8px);
        }

        &:hover, &__selected {
            background: $hover-background;
        }
    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        border-radius: 3px;

        padding: rem(3px);
        margin-right: 9px;
        border: 1px solid $grey-light2;
    }

    .text-container {
        width: rem(113px);

        .title {
            margin-bottom: rem(6px);

            font-size: rem(14px);
            line-height: rem(17px);
            font-weight: 600;
        }

        .text {
            font-weight: 500;
            color: $grey-light31;
            font-size: rem(10px);
            line-height: rem(12px);
        }
    }
}
</style>
