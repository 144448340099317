<template lang="pug">
    .profile-menu-help
        .profile-menu-help__header
            img.profile-menu-help__logo(src="@/assets/images/logo_white.png" alt="")
            .profile-menu-help__name Hi {{ user.username }}
            img.profile-menu-help__closer(
                src="@/assets/images/icons/closer_2.svg"
                @click.stop="$emit('close')"
            )
        .profile-menu-help__content
            .profile-menu-help__title Support
            .profile-menu-help__links
                .profile-menu-help__link
                    .profile-menu-help__link-icon
                        img(src="@/assets/images/icons/contact_support.svg")
                    | Contact<br> Support
                a.profile-menu-help__link(
                    href="https://reskript.typeform.com/to/UjtPPfVz"
                    target="_blank"
                )
                    .profile-menu-help__link-icon
                        img(src="@/assets/images/icons/request_feature.svg")
                    | Request<br> feature
                a.profile-menu-help__link(
                    href="https://reskript.typeform.com/to/mIuXFYwz"
                    target="_blank"
                )
                    .profile-menu-help__link-icon
                        img(src="@/assets/images/icons/report_bug.svg")
                    | Report<br> Bug

</template>

<script>
export default {
    name: 'profile-menu-help',

    computed: {
        user() {
            return this.$store.getters.user;
        },
    },
};
</script>

<style scoped lang="scss">
.profile-menu-help {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: #fff;

    &__header {
        position: relative;

        padding: 0.75rem 1.1rem;

        background: $blue;
    }

    &__logo {
        width: 4.55rem;
        margin-bottom: rem(19px);
    }

    &__name {
        font-size: rem(28px);
        color: #fff;
    }

    &__closer {
        position: absolute;
        top: 1rem;
        right: 1.2rem;

        width: 1.25rem;

        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }

    &__content {
        padding: 1.2rem;
    }

    &__title {
        margin-bottom: rem(26px);

        font-size: rem(21px);
        font-weight: 600;
    }

    &__links {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        font-size: rem(18px);
        line-height: 1.2;
        text-align: center;

        cursor: pointer;
        transition: box-shadow .1s;

        &-icon {
            display: flex;
            justify-content: center;

            height: rem(60px);
        }

        &:nth-of-type(1) &-icon img {
            height: 79%;
        }

        &:nth-of-type(2) &-icon img {
            height: 81%;
        }

        &:nth-of-type(3) &-icon img {
            height: 73%;
            margin-top: 5%;
        }

        @media (hover: hover) {
            &:hover {
                box-shadow: 0 0 0 0.625rem $hover-background, inset 0 0 0 10rem $hover-background;
            }
        }
    }
}
</style>
