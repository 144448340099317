<template lang="pug">
    .editor-header-share
        button(@click="duplicate(document)") Duplicate
</template>

<script>
import duplicateMixin from '@/js/mainContextMenu/duplicateMixin';

export default {
    name: 'editor-header-duplicate',

    mixins: [ duplicateMixin ],

    props: {
        document: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
</style>
