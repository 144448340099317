<template lang="pug">
    .settings.default-layout-block

        app-text-field.app-text-field-custom(
            v-model="description"
            label="Description:"
            :disabled="!isUserProjectOwner"
            placeholder="Write a project description"
            @blur="saveProject"
        )
        template(v-if="isUserProjectOwner")

        app-button( v-if="isUserProjectOwner" @click="deleteProject") Delete Project
        app-button( v-else @click="leaveProject") Leave Project
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { updateProject, moveProjectsToBin, leaveFromProject } from '@/js/api/requests/project';
import ManageTeam from '@/mixins/ManageTeamMixin';

export default {
    name: 'project-detail-settings',

    mixins: [ManageTeam],

    props: {
        project: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        name: '',
        description: '',
        activeIconId: null,
        activeColor: null,

        projectIconsOpened: false,
        loading: false,
    }),

    validations: {
        name: { required },
    },


    created() {
        this.description = this.project.description || '';
        this.name = this.project.name;
    },

    methods: {
        async saveProject() {
            if (this.loading) return;

            this.loading = true;

            try {
                await updateProject(this.project.id, {
                    description: this.description,
                    name: this.name,
                });
                this.project.description = this.description;
                this.$awn.success('Project settings was updated successfully');
            } catch (e) {
                this.$awn.alert('Something went wrong, please try again later');
            } finally {
                this.loading = false;
            }
        },

        deleteProject() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await moveProjectsToBin(this.project.id);
                            this.$router.push('/');
                        } catch (e) {
                            this.$awn.alert('Something went wrong, please try again later');
                        }
                    }
                },
            });
        },

        leaveProject() {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await leaveFromProject(this.project.id);
                            this.$router.push('/');
                        } catch (e) {
                            this.$awn.alert('Something went wrong, please try again later');
                        }
                    }
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@include Settings();

</style>
