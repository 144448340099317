<template lang="pug">
    .plans
        .plan(v-for="plan in PLANS")
            .plan__block-up
                .plan__title
                    .title {{ plan.title }}
                    img(v-if="plan.icon" class="img" :src="plan.icon")
                .plan__price
                    .price {{ price(plan) }}
                        .info(v-if="plan.priceText" v-html="plan.priceText")
                .plan__options(v-if="plan.options")
                    .options-title(v-if="plan.optionsTitle") {{ plan.optionsTitle }}
                    .options
                        .option(v-for="option in options(plan)" :class="optionClasses(option)")
                            img(v-if="option.icon" class="icon" :src="option.icon")
                            .title(v-html="option.title")
            .plan__block-down
                .plan__end-period(v-if="planEndPeriod(plan)") {{ planEndPeriod(plan) }}
                app-button.plan__btn(@click="btnClick(plan)" :class="btnClasses(plan)" :disabled="btnDisabled(plan)") {{ btnText(plan) }}
</template>

<script>
import { PLANS, PLANS_NAMES } from '@/js/const';
import moment from 'moment';

export default {
    name: 'plans',

    props: {
        registrationPage: {
            type: Boolean,
            default: false,
        },
        btnClick: {
            type: Function,
            required: true,
        },
        btnClasses: {
            type: Function,
            required: true,
        },
        btnDisabled: {
            type: Function,
            required: true,
        },
        btnText: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            PLANS,
        };
    },

    computed: {
        planPeriodSwitcher() {
            return this.$store.getters.planPeriodSwitcher;
        },
        planName() {
            return this.$store.getters.planName;
        },
        planPeriod() {
            return this.$store.getters.planPeriod;
        },
    },

    methods: {
        optionClasses(option) {
            return option.classes ? option.classes : '';
        },
        options(plan) {
            let options = [];
            if (
                //this.registrationPage &&
                plan.name === PLANS_NAMES.PRO
            ) {
                options.push({
                    icon: require('@/assets/images/icons/ellipse.svg'),
                    title: 'More is coming!',
                    classes: 'plan-option-color-gl',
                });
            }
            return [ ...plan.options, ...options ];
        },
        price(plan) {
            const price = plan.prices[this.planPeriodSwitcher];
            return price > 0 ? price + '$' : 'Free';
        },
        planEndPeriod(plan) {
            if (
                //текущий про
                plan.name === PLANS_NAMES.PRO && this.planName === plan.name && this.planPeriodSwitcher === this.planPeriod
            ) {
                const date = this.$store.getters.planEndPeriod;
                if (date) {
                    return 'Plan renews on ' + moment(date).format('DD/MM/YY');
                }
            }
            return '';
        },
    },
};
</script>

<style scoped lang="scss">
.plans {
    display: flex;
    width: 100%;
    justify-content: center;

    @media (max-width: 1200px) {
        display: block;
    }
}
.plan {
    margin-right: rem(115px);
    box-shadow: 0 0 rem(9.8px) rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    width: rem(186px);
    padding: rem(10px) rem(14px);
    padding-bottom: rem(13px);

    @media (max-width: 1200px) {
        margin: 0 auto;
        width: rem(224px);
        padding: rem(13px) rem(7px);
    }

    &::v-deep .app-button--disabled {
        background: $grey-light10;
    }

    &:last-child {
        margin-right: 0 !important;
        @media (max-width: 1200px) {
            margin: 0 auto !important;
            margin-top: rem(32px) !important;
        }
    }

    &__block {

    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(10px);
        @media (max-width: 1200px) {
            justify-content: left;
        }
        .title {
            display: inline-block;
            font-weight: 500;
            font-size: rem(14px);
            @media (max-width: 1200px) {
                font-size: rem(18px);
            }
        }
        .img {
            display: inline-block;
            width: rem(17px);
            height: rem(17px);
            margin-left: rem(11px);
            @media (max-width: 1200px) {
                width: rem(22px);
                height: rem(22px);
            }
        }
    }
    &__price {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(10px);
        position: relative;
        @media (max-width: 1200px) {
            justify-content: left;
            margin-bottom: rem(20px);
            margin-top: rem(20px);
        }
        .price {
            display: inline-block;
            position: relative;
            color: $blue5;
            font-weight: 600;
            font-size: rem(24px);
            text-align: center;
            @media (max-width: 1200px) {
                text-align: left;
                width: 100%;
                font-size: rem(36px);
            }
        }
        .info {
            position: absolute;
            right: rem(-63px);
            top: rem(1px);
            display: inline-block;
            color: $grey-light29;
            margin-left: rem(0px);
            font-size: rem(9px);
            text-align: left;
            @media (max-width: 1200px) {
                right: rem(23px);
                font-size: rem(12px);
                top: rem(5px);
            }
        }
    }
    &__options {
        .options-title {
            font-size: rem(12px);
            font-weight: 500;
            margin-bottom: rem(12px);
            @media (max-width: 1200px) {
                font-size: rem(14px);
                margin-bottom: rem(15px);
            }
        }
        .options {
            margin-bottom: rem(28px);
            @media (max-width: 1200px) {
                margin-bottom: rem(18px);
            }
            .option {
                display: flex;
                margin-bottom: rem(10px);
                line-height: rem(13px);
                @media (max-width: 1200px) {
                    align-items: center;
                    margin-bottom: rem(11px);
                }
                .icon {
                    width: rem(9px);
                    height: rem(9px);
                }
                .title {
                    font-size: rem(10px);
                    font-weight: 500;
                    margin-left: rem(12px);
                    line-height: rem(12px);
                    @media (max-width: 1200px) {
                        font-size: rem(12px);
                        &::v-deep {
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &__btn {
        min-height: auto;
        min-width: auto;
        font-size: rem(12px);
        font-weight: 600;
        padding: rem(5px) 0px;
        display: block;
        width: 100%;
    }
    &__end-period {
        color: $grey-light11;
        font-size: rem(8px);
        margin-bottom: rem(5px);
        font-weight: bold;
    }
    .plan-option-color {
        &-gl {
            color: $grey-light22;
        }
    }
}
</style>
